<div [alarisLoading]="vsService.loading$">
	<div class="edit-panel-header">
		<alaris-icon name="icon-product"></alaris-icon>
		{{
		(editable
			? (product.id ? 'products.editEntry' : 'products.addEntry')
			: 'products.details') | translate
		}}
	</div>
	<div alarisFade="vertical" [formGroup]="productForm" class="form-group edit-panel-content">
		<alaris-input [label]="'fc.name' | translate"
					  [placeholder]="'fc.name' | translate"
					  [readonly]="!editable"
					  data-testid="nameInput" formControlName="name">
		</alaris-input>
		<ng-container *ngIf="!product.id">
			<alaris-select2 [displayWith]="displayChannelSelected"
							[label]="'fc.channel' | translate"
							[placeholder]="'fc.channel' | translate"
							data-testid="trafficTypeSelect" formControlName="trafficType">
				<alaris-options2 *ngFor="let opt of chService.list$ | async"
								 [attr.data-testid]="'trafficTypeOption/' + opt.channelType" [value]="opt.channelType">
					{{ opt.channelType }}
				</alaris-options2>
			</alaris-select2>
			<fieldset *ngIf="productForm.get('trafficType')?.value === TrafficType.VIBER" formGroupName="settings">
				<b>{{'gl.priority' | translate}}</b>
				<div class="radio-button-zone" formGroupName="viberSettings">
					<alaris-radio [alarisComplexTooltip]="viberPriorityComponent"
								  [attr.data-testid]="'messagePurposeRadio/' + MessagePurpose.PROMOTION"
								  [componentData]="MessagePurpose.PROMOTION"
								  [id]="'Promotional'"
								  [value]="MessagePurpose.PROMOTION"
								  formControlName="messagePurpose"
								  name="priority">
						{{'gl.promotional' | translate}}
					</alaris-radio>
					<alaris-radio [alarisComplexTooltip]="viberPriorityComponent"
								  [attr.data-testid]="'messagePurposeRadio/' + MessagePurpose.TRANSACTION"
								  [componentData]="MessagePurpose.TRANSACTION"
								  [id]="'Transactional'"
								  [value]="MessagePurpose.TRANSACTION"
								  formControlName="messagePurpose" name="priority">
						{{'gl.transactional' | translate}}
					</alaris-radio>
				</div>
			</fieldset>
			<alaris-select2 [displayWith]="displayCurrencySelected"
							[label]="'fc.currency' | translate"
							[placeholder]="'fc.currency' | translate"
							data-testid="currencySelect" formControlName="currencyId">
				<alaris-select-filter2 *ngIf="((curService.list$ | async)?.length || 0) > 5"
									   [formControl]="filterCurrencyControl" data-testid="currencyFilter">
				</alaris-select-filter2>
				<alaris-options2 *ngFor="let opt of filterCurrencyList"
								 [attr.data-testid]="'currencyOption/' + opt.name" [value]="opt.id">
					{{ opt.name }}
				</alaris-options2>
			</alaris-select2>
			<alaris-select2 [displayWith]="displayBillingModeSelected"
							[label]="'fc.billingMode' | translate"
							[placeholder]="'fc.billingMode' | translate"
							data-testid="billingModeSelect" formControlName="billingMode">
				<alaris-options2 *ngFor="let opt of billingModes"
								 [attr.data-testid]="'billingModeOption/' + opt" [value]="opt">
					{{'enums.' + opt | translate}}
				</alaris-options2>
			</alaris-select2>
		</ng-container>

		<ng-container *ngIf="product.id">
			<alaris-input [formControl]="editModeChannelControl"
						  [label]="'fc.channel' | translate"
						  [readonly]="true" data-testid="channelInput">
			</alaris-input>
			<alaris-input [formControl]="editModeCurrencyControl"
						  [label]="'fc.currency' | translate"
						  [readonly]="true" data-testid="currencyInput">
			</alaris-input>
			<alaris-input [formControl]="editModeBillingModeControl"
						  [label]="'fc.billingMode' | translate"
						  [readonly]="true" data-testid="billingModeInput">
			</alaris-input>
		</ng-container>
	</div>
	<div class="edit-panel-footer">
		<button (click)="save()"
				[disabled]="productForm.invalid || !productForm.dirty"
				alaris-button bType="primary"
				data-testid="saveBtn"
				size="lg" type="button">
			<alaris-icon class="in-primary" name="icon-success"></alaris-icon>
			{{(product.id ? 'actions.save' : 'actions.create') | translate}}
		</button>
		<button (click)="close()" alaris-button bType="outline" data-testid="closeBtn" size="lg" type="button">
			{{ 'actions.cancel' | translate }}
		</button>
	</div>
</div>


