<div class="statistics">
	<div>
		<span class="label">{{ 'rates.import.totalRates' | translate }}</span> <br>
		<b>{{ statistics.total }}</b>
	</div>
	<div>
		<span class="label">{{ 'rates.import.newRates' | translate }}</span> <br>
		<b>{{ statistics.added }}</b>
	</div>
	<div>
		<span class="label">{{ 'rates.import.updatedRates' | translate }}</span> <br>
		<b>{{ statistics.updated }}</b>
	</div>
	<div>
		<span class="label">{{ 'rates.import.closedRates' | translate }}</span> <br>
		<b>{{ statistics.closed }}</b>
	</div>

	<button (click)="exportAnalysis()" alaris-button data-testid="exportAnalysisBtn" size="lg">
		<alaris-icon name="icon-import"></alaris-icon>
		{{ 'rates.import.exportAnalysis' | translate }}
	</button>

</div>

<alaris-card [alarisLoading]="loading$" class="card">
	<div class="table-management"></div>
	<div class="content">
		<alaris-table (filterChange)="applyFilter($event)"
					  (sortingChange)="applySorting($event)"
					  [filters]="filters"
					  [sorting]="sorting"
					  [tHeaders]="tHeaders"
					  [tRows]="tRows"
					  [templates]="{country, network, activeFrom, activeTill, status, newActiveFrom, error,
							  	newActiveTo, oldActiveFrom, oldActiveTo, oldPrice, newPrice }"
					  alarisOverflowFader>
		</alaris-table>
	</div>
	<div class="footer">
		<alaris-pagination (page)="pageChange($event)"
						   [activePage]="activePage"
						   [hidden]="tRows.length === 0"
						   [pageSizeOptions]="pageSizeOptions"
						   [pageSize]="pageSize"
						   [total]="isFiltersApplied ? tRowsFiltered.length: tRowsDefault.length">
		</alaris-pagination>
	</div>
</alaris-card>

<ng-template #country [alarisCellCtx]="tRows[0]" let-data="data" let-key="key">
	{{ data.country.name }}
	<div class="label">{{ 'columns.mcc' | translate }}: {{ data.country.mcc | mccmnc }}</div>
</ng-template>

<ng-template #network [alarisCellCtx]="tRows[0]" let-data="data" let-key="key">
	{{ data.network?.name || ((data.network?.mnc || data.network?.mnc === 0) ? '' : ('endpoints.allNetworks' | translate)) }}
	@if (data.network && (data.network.mnc || data.network.mnc === 0)) {
		<div class="label">{{ 'columns.mnc' | translate }}: {{ data.network.mnc | mccmnc }}</div>
	}
</ng-template>

<ng-template #activeFrom [alarisCellCtx]="tRows[0]" let-data="data" let-key="key">
	{{ data.activeFrom | date: 'd MMM y, HH:mm' }}
</ng-template>

<ng-template #activeTill [alarisCellCtx]="tRows[0]" let-data="data" let-key="key">
	{{ data.activeTill | date: 'd MMM y, HH:mm' }}
</ng-template>

<ng-template #status [alarisCellCtx]="tRows[0]" let-data="data" let-key="key">
	<span [class]="data[key]" class="status">
		{{ 'enums.' + data[key] | translate }}
	</span>
</ng-template>

<!--Templates for Table with updated data-->

<ng-template #oldActiveFrom [alarisCellCtx]="tRows[0]" let-data="data" let-key="key">
	@if (data.status !== AnalysisStatus.NEW) {
		{{ data.activeFrom | date: 'd MMM y, HH:mm' }}
	}
</ng-template>

<ng-template #oldActiveTo [alarisCellCtx]="tRows[0]" let-data="data" let-key="key">
	@if (data.status !== AnalysisStatus.NEW) {
		{{ data.activeTill | date: 'd MMM y, HH:mm' }}
	}
</ng-template>

<ng-template #newActiveFrom [alarisCellCtx]="tRows[0]" let-data="data" let-key="key">
	{{ (data.changes ? data.changes.activeFrom : data.activeFrom) | date: 'd MMM y, HH:mm' }}
</ng-template>

<ng-template #newActiveTo [alarisCellCtx]="tRows[0]" let-data="data" let-key="key">
	{{ (data.changes ? data.changes.activeTill : data.activeTill) | date: 'd MMM y, HH:mm' }}
</ng-template>

<ng-template #oldPrice [alarisCellCtx]="tRows[0]" let-data="data" let-key="key">
	@if (data.status !== AnalysisStatus.NEW) {
		<span [class.DECREASE]="data.status===AnalysisStatus.DECREASE"
			  [class.INCREASE]="data.status===AnalysisStatus.INCREASE"
			  class="old-price">
			{{ data.price | number:'1.0-5' }}
		</span>
	}
</ng-template>

<ng-template #newPrice [alarisCellCtx]="tRows[0]" let-data="data" let-key="key">
	<span [class.DECREASE]="data.status===AnalysisStatus.DECREASE"
		  [class.INCREASE]="data.status===AnalysisStatus.INCREASE"
		  class="new-price">{{ (data.changes?.price ?? data.price) | number:'1.0-5' }}</span>
	<span *ngIf="data.status === AnalysisStatus.INCREASE || data.status === AnalysisStatus.DECREASE"
		  class="label">{{
			data.status !== AnalysisStatus.INCREASE ?
				' -' + data.changes?.delta + '%' :
				' +' + data.changes?.delta + '%'
		}}
	</span>
</ng-template>

<ng-template #error [alarisCellCtx]="tRows[0]" let-data="data" let-key="key">
	@if (data[key]) {
		<span [alarisTooltip]="data[key] | as: 'string'" [overflow]="true" class="error">
			{{ data[key] }}
		</span>
	}
</ng-template>
