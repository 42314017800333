<alaris-card [cdkContextMenuTriggerFor]="ctxMenu" class="host" size="auto">
	<div class="box">
		<div class="label">
			<alaris-icon [name]="'icon-' + paymentSystem.type + '-small'"></alaris-icon>
		</div>
		<div>
			<b (click)="actions[0].action(paymentSystem)"
			   [attr.data-testid]="'openPaySystemPanelBtn/' + paymentSystem.id"
			   [class.disabled]="!activeStatusControl.value" class="system-name">
				{{ paymentSystem.name }}
			</b>
			<br>
			<span class="system-type">{{ paymentSystem.type }}</span>
		</div>
	</div>

	<div class="box">
	<span [class.disabled]="!activeStatusControl.value">
		{{ paymentSystem.lastUpdated | date: 'd MMM y, HH:mm' }}
	</span>
		<alaris-toggle [attr.data-testid]="'statusToggle/' + paymentSystem.id"
					   [formControl]="activeStatusControl"
					   [readonly]="!([AP_PERMISSIONS.PAYMENTS_SYSTEMS_E] | alarisPBAC)">
		</alaris-toggle>
		<button (click)="$event.stopPropagation()"
				(contextmenu)="$event.stopPropagation()"
				[cdkMenuTriggerFor]="ctxMenu"
				[attr.data-testid]="'actionsDropdownToggle/' + paymentSystem.id"
				class="action__button">
			<alaris-icon name="icon-dot-vertical"></alaris-icon>
		</button>
	</div>

</alaris-card>

<ng-template #ctxMenu>
	<alaris-ctx-action [actions]="actions"
					   [attr.data-testid]="'dropdownActions/' + paymentSystem.id"
					   [row]="paymentSystem"
					   class="ctxMenu">
	</alaris-ctx-action>
</ng-template>


