<div [alarisLoading]="subsService.loading$" class="content">
	@if (!hasSubscriptions) {
		<ng-container *ngTemplateOutlet="emptySubs"></ng-container>
	} @else {
		<section class="subs-section">
			<header class="header">
				{{ 'partners.packs' | translate }}
				@if (groupWithPacks || hasAvailablePacks) {
					<alaris-icon (click)="openDialog(SubscriptionType.PACK)"
								 *alarisPBAC="[AP_PERMISSIONS.SUBSCR_E]"
								 name="icon-add" class="plus-icon">
					</alaris-icon>
				}
			</header>

			@switch (true) {
				@case (groupWithPacks) {
					<app-subscriptions-picker-table [rowAction]="deletePack.bind(this)"
													[type]="ModelType.SELECTED"
													[selectionModel]="packsPickerService.selectedModel"
													[subscriptionType]="SubscriptionType.PACK">
					</app-subscriptions-picker-table>
				}
				@case (hasAvailablePacks) {
					<app-subscriptions-picker-empty (addPacks)="openDialog(SubscriptionType.PACK)"
													type="packs">
					</app-subscriptions-picker-empty>
				}
				@default {
					<ng-container *ngTemplateOutlet="emptySubs"></ng-container>
				}
			}
		</section>

		<section class="subs-section">
			<header class="header">
				{{ 'partners.plans' | translate }}
				@if (groupWithPlans || hasAvailablePlans) {
					<alaris-icon (click)="openDialog(SubscriptionType.PLAN)"
								 *alarisPBAC="[AP_PERMISSIONS.SUBSCR_E]"
								 name="icon-add" class="plus-icon">
					</alaris-icon>
				}
			</header>
			@switch (true) {
				@case (groupWithPlans) {
					<app-subscriptions-picker-table (addSubscription)="openDialog(SubscriptionType.PLAN)"
													[rowAction]="deletePlan.bind(this)"
													[type]="ModelType.SELECTED"
													[selectionModel]="plansPickerService.selectedModel"
													[subscriptionType]="SubscriptionType.PLAN">
					</app-subscriptions-picker-table>
				}
				@case (hasAvailablePlans) {
					<app-subscriptions-picker-empty (addPlans)="openDialog(SubscriptionType.PLAN)"
													type="plans">
					</app-subscriptions-picker-empty>
				}
				@default {
					<ng-container *ngTemplateOutlet="emptySubs"></ng-container>
				}
			}
		</section>
	}
</div>

<ng-template #emptySubs>
	<app-subscriptions-picker-empty (goToSubscriptions)="goToSubscriptions.emit()"
									type="subscriptions">
	</app-subscriptions-picker-empty>
</ng-template>
