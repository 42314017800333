import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { BehaviorSubject, Observable, of, Subject, takeUntil, tap } from 'rxjs';


import { SenderSubscription, SenderSubscriptionEvent } from '@campaign-portal/namespace/entities/sender-id/specs';
import { RPCResult } from '@campaign-portal/namespace/common/rpc.response';
import {
	EnabledDisabledStatus,
	SenderSubscriptionEventStatus,
	SenderSubscriptionEventType
} from '@campaign-portal/namespace/common/enums';
import { exist } from '@campaign-portal/namespace/common/id';

import { AlarisDialogService, CustomValidators, Day, LAST_DAY } from '@campaign-portal/components-library';

import { EventsService } from '../events/events.service';
import { OwnerService } from '@helpers/services/owner.service';
import { CanDeactivateComponent } from '@helpers/can-deactivate/can-deactivate.component';
import { CanDeactivateGuardService } from '@helpers/can-deactivate/can-deactivate.guard';
import { SendersListService } from '../senders-list/senders-list.service';

export type SendersDialogType = 'Confirm' | 'Decline' | 'Unsubscribe' | 'Sender Disable' | 'Sender Expired' | 'Delete';

export interface SendersDialogData {
	type: SendersDialogType;
	event?: SenderSubscriptionEvent<exist>;
	subscription?: SenderSubscription<exist>;
}

@Component({
	selector: 'app-senders-dialog',
	templateUrl: './senders-dialog.component.html',
	styleUrls: ['./senders-dialog.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SendersDialogComponent extends CanDeactivateComponent implements OnDestroy {
	readonly periodForm: FormGroup;
	readonly comment = new FormControl('');
	readonly Day = Day;

	readonly event: SenderSubscriptionEvent<exist> | undefined;
	readonly subscription: SenderSubscription<exist> | undefined;

	readonly loading$ = new BehaviorSubject<boolean>(false);
	readonly allowedDeactivation = new BehaviorSubject<boolean>(true);
	protected readonly ngUnsubscribe = new Subject<void>();

	constructor(
		@Inject(DIALOG_DATA) public readonly data: SendersDialogData,
		public readonly isOwner: OwnerService,
		private readonly sendersListService: SendersListService,
		private readonly dialogRef: DialogRef<unknown>,
		private readonly cd: ChangeDetectorRef,
		private readonly eventsService: EventsService,
		private readonly guard: CanDeactivateGuardService,
		private readonly dialog: AlarisDialogService
	) {
		super();
		this.addDialogGuard(this.dialog, dialogRef, this.guard);
		this.event = data.event;
		this.subscription = data.subscription;
		this.periodForm = new FormGroup({
			activeFrom: new FormControl(),
			activeTill: new FormControl()
		}, [CustomValidators.moreThan('activeFrom', 'activeTill')]);
	}

	get disabled(): boolean {
		return this.loading$.getValue() || this.periodForm.invalid;
	}

	get activeSender(): SenderSubscription<exist> | undefined {
		return this.sendersListService.list.find(sender => {
			return sender.name === this.event?.sender?.name
				&& sender.trafficType === this.event.sender.trafficType
				&& sender.enabled === EnabledDisabledStatus.ENABLED;
		});
	}

	get withPeriod(): boolean {
		return this.isOwner.is || !!this.activeSender;
	}

	get minEndDate(): Day {
		const currentDay = Day.currentLocal();
		const startDay = Day.fromSystemDate(this.periodForm.controls.activeFrom.value) ?? Day.currentLocal();
		return new Date(currentDay.toSystemDate() ?? '').getTime() > new Date(startDay?.toSystemDate() ?? '').getTime()
			? currentDay
			: startDay;
	}

	get maxDate(): Day {
		if ( this.isOwner.is ) {
			// case  this.data.type === 'Sender Expired'
			// sender expired dialog is available to owner only
			// no need to add condition
			return LAST_DAY;
		} else {
			const activeSender = this.activeSender;
			return activeSender
				? activeSender.activeTill ? Day.fromSystemDate(activeSender.activeTill) ?? LAST_DAY : LAST_DAY
				: LAST_DAY;
		}
	}

	close(): void {
		this.dialogRef.close(false);
	}

	confirm(): void {
		this.loading$.next(true);
		let caller: Observable<RPCResult<unknown>> = of({ Success: false });

		switch (this.data.type) {
			case 'Confirm':
				if ( this.event ) {
					const confirmEvent: SenderSubscriptionEvent = {
						...this.event,
						sender: {
							...this.event.sender,
							...this.periodForm.value
						}
					};
					caller = this.eventsService.confirm(confirmEvent)
						.pipe(tap((resp) => {
							if ( resp.Success ) {
								this.sendersListService.refresh$.next();
							}
						}));
				}
				break;
			case 'Decline':
				if ( this.event ) {
					const declineEvent: SenderSubscriptionEvent = {
						...this.event,
						comment: this.comment.value
					};
					caller = this.eventsService.decline(declineEvent);
				}
				break;
			case 'Delete':
				if ( this.subscription ) {
					caller = this.sendersListService.delete(this.subscription.id);
				}
				break;
			case 'Unsubscribe':
				if ( this.subscription ) {
					const event: SenderSubscriptionEvent = {
						id: null,
						type: SenderSubscriptionEventType.SUBSCRIPTION_UPDATE,
						status: SenderSubscriptionEventStatus.UNSUBSCRIBED,
						sender: this.subscription,
						history: []
					};

					caller = this.eventsService.unsubscribe(event);
				}
				break;
			case 'Sender Disable':
				caller = of({ Success: true });
				break;
			case 'Sender Expired':
				if ( this.subscription ) {
					caller = this.sendersListService.update({
						...this.subscription,
						enabled: this.subscription.enabled === EnabledDisabledStatus.ENABLED ?
							EnabledDisabledStatus.DISABLED : EnabledDisabledStatus.ENABLED,
						...this.periodForm.value
					});
				}
				break;
			default:
				break;

		}

		this.allowedDeactivation.next(false);
		caller.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
			(resp) => {
				this.allowedDeactivation.next(true);
				this.loading$.next(false);
				this.dialogRef.close(resp.Success);
				this.cd.markForCheck();
			}
		);
	}

	ngOnDestroy(): void {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
		this.loading$.complete();
	}
}
