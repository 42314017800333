import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { ConnectedPosition } from '@angular/cdk/overlay';

import { SubscriptionGroup } from '@campaign-portal/namespace/entities/subscription-groups/specs';
import { exist } from '@campaign-portal/namespace/common/id';
import { RowActionSimple } from '@campaign-portal/components-library';

@Component({
	selector: 'app-subscription-group-card',
	templateUrl: './subscription-group-card.component.html',
	styleUrl: './subscription-group-card.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubscriptionGroupCardComponent {
	@Input({ required: true }) group!: SubscriptionGroup<exist>;
	@Input() actions: RowActionSimple<SubscriptionGroup<exist>>[] = [];

	menuOpened = false;
	readonly position: ConnectedPosition[] = [
		{
			originX: 'end',
			originY: 'bottom',
			overlayX: 'end',
			overlayY: 'top',
			offsetY: -4,
			offsetX: 0
		}
	];

	@HostBinding('class.has-subscriptions')
	get hasSubscriptions(): boolean {
		return Boolean(this.group.packs.length || this.group.plans.length);
	}

	constructor() {
	}
}
