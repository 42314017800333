import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import {
	AbstractCRUDService,
	AlarisApiService,
	AlarisLanguageService,
	AlarisToasterService,
	ErrorNotifierConfig
} from '@campaign-portal/components-library';

import {
	CreateResponse,
	DeleteRequest,
	DeleteResponse,
	ReadResponse
} from '@campaign-portal/namespace/common/implementations';
import { RPCRequestParams } from '@campaign-portal/namespace/common/rpc.params';
import { exist, Id } from '@campaign-portal/namespace/common/id';
import {
	TransactionHistory,
	TransactionHistoryCampaignDetail
} from '@campaign-portal/namespace/entities/transaction-history/specs';

@Injectable({
	providedIn: 'root'
})
export class TransactionsService implements AbstractCRUDService {
	readonly loading$ = new BehaviorSubject<boolean>(false);

	readonly create = this.update;
	private readonly errorNotifier = (): ErrorNotifierConfig => ({ title: this.title });

	constructor(
		private readonly api: AlarisApiService,
		private readonly alarisToaster: AlarisToasterService,
		private readonly langService: AlarisLanguageService
	) {
	}

	get entity(): string {
		return this.langService.translate('notifications.entities.transaction');
	}

	get title(): string {
		return this.langService.translate('notifications.titles.transaction');
	}

	update(tHistory: TransactionHistory): Observable<CreateResponse<TransactionHistory>> {
		const notify = (response: CreateResponse<TransactionHistory>): void => {
			if ( response.Success ) {
				const message = this.langService.translate(
					tHistory.id ? 'notifications.actions.update' : 'notifications.actions.create', {
						entity: this.entity,
						name: ''
					});
				this.alarisToaster.success(message, this.title);
			}
		};
		return this.api.loader<CreateResponse<TransactionHistory>>(
			`TransactionHistory.${tHistory.id ? 'Update' : 'Create'}`,
			{ Data: { Entities: [tHistory] } },
			this.loading$, this.errorNotifier, notify
		);
	}

	delete(id: Id<exist>): Observable<DeleteResponse<TransactionHistory>> {
		const params: DeleteRequest<TransactionHistory<exist>> = { Data: { Ids: [id] } };
		const notify = (response: DeleteResponse<TransactionHistory>): void => {
			if ( response.Success ) {
				const message = this.langService.translate('notifications.actions.delete', { entity: this.entity });
				this.alarisToaster.success(message, this.title);
			}
		};
		return this.api.loader<DeleteResponse<TransactionHistory>>(
			'TransactionHistory.Delete', params, this.loading$, this.errorNotifier, notify);
	}

	read(params?: RPCRequestParams): Observable<ReadResponse<TransactionHistory[]>> {
		return this.api.loader<ReadResponse<TransactionHistory[]>>(
			'TransactionHistory.Read', params, this.loading$, this.errorNotifier
		);
	}

	details(
		transactionHistory: TransactionHistory<exist>
	): Observable<ReadResponse<TransactionHistoryCampaignDetail[]>> {
		const params = {
			Data: {
				Ids: [transactionHistory.id]
			}
		};
		return this.api.loader<ReadResponse<TransactionHistoryCampaignDetail[]>>(
			'TransactionHistory.CampaignDetails', params, this.loading$, this.errorNotifier
		);
	}
}
