import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DialogRef } from '@angular/cdk/dialog';

@Component({
	selector: 'app-lost-subscription-group-data-dialog',
	templateUrl: './lost-subscription-group-data-dialog.component.html',
	styleUrl: '../../subscription-group-dialog/subscription-group-dialog.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class LostSubscriptionGroupDataDialogComponent {
	constructor(
		private readonly dialogRef: DialogRef
	) {
	}

	close(): void {
		this.dialogRef.close(false);
	}

	confirm(): void {
		this.dialogRef.close(true);
	}
}
