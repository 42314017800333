<div class="edit-panel-header">
	<alaris-icon name="icon-invoice-_glass"></alaris-icon>
	<span> {{transaction.id}}: {{ 'finance.transactionDetails' | translate}} </span>
</div>
<div alarisFade="vertical" class="edit-panel-content">
	<div class="price-zone">
		<fieldset>
			<span class="label">{{'gl.totalAmount' | translate}}</span> <br>
			<span class="amount">{{transaction.amount | currency: bs.currencyCode}}</span>
		</fieldset>
		<!--			<button (click)="export()" alaris-button bType="primary" size="md" type="button">-->
		<!--				<alaris-icon class="in-primary" name="icon-import"></alaris-icon>-->
		<!--				{{'gl.exportDetails' | translate}}-->
		<!--			</button>-->
	</div>
	<div class="details-zone">
		<fieldset>
			<span class="label">{{'gl.date' | translate}}</span> <br>
			{{transaction.creationDate | date: 'd MMM y, HH:mm'}}
		</fieldset>
		<fieldset>
			<span class="label">{{'gl.partner' | translate}}</span> <br>
			<ng-container *ngIf="transaction.partnerId === (profile.user$ | async)?.partnerId; else defaultPartner">
				<alaris-tag [fill]="false" [rounded]="true" size="lg">
					<span class="partner">{{ 'finance.myPartner' | translate }}</span>
				</alaris-tag>
			</ng-container>
			<ng-template #defaultPartner>
				{{transaction.partnerId ? (transaction.partnerId | partner)?.name || '' : ''}}
			</ng-template>
		</fieldset>
		<fieldset>
			<span class="label">{{'gl.description' | translate}}</span> <br>
			{{transaction.description}}
		</fieldset>
	</div>
	<ng-container *ngIf="tableData$ | async as tableData">
		<div *ngFor="let campaign of tableData[0]" class="card">
			<div class="header">
				<div class="detail">
					<alaris-icon [name]="cu.icon(campaign.channel)"></alaris-icon>
					<fieldset>
						<div class="label">
							{{ cu.purpose(campaign.channel, campaign.viberSettings?.messagePurpose) | translate}}
						</div>
						<div class="rate">{{campaign.rate}}</div>
					</fieldset>
				</div>
				<b>{{campaign.amount | currency: bs.currencyCode}}</b>
			</div>
			<div class="body">
				<alaris-table [tHeaders]="tableData[1]"
							  [tRows]="campaign.details"
							  [templates]="{amount}">
				</alaris-table>
			</div>
		</div>
	</ng-container>
</div>

<ng-template #amount let-data="data" let-key="key">
	<span>{{data.amount | currency: bs.currencyCode}}</span>
</ng-template>
