import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';

import { AbstractCRUDService } from '@campaign-portal/components-library';

import { EntityField, InputComplexType, InputType } from '@campaign-portal/namespace/common/entityField';
import { ReadResponse } from '@campaign-portal/namespace/common/implementations';
import { FilterType } from '@campaign-portal/namespace/common/rpc.params';

@Injectable()
export class MccmncFieldsService implements AbstractCRUDService, OnDestroy {

	readonly entity = '';
	readonly title = '';
	readonly loading$ = new BehaviorSubject<boolean>(false);

	private readonly headers: EntityField[] = [
		{
			id: 0,
			name: 'columns.mcc',
			variable: 'mcc',
			required: true,
			type: InputType.TEXT,
			readonly: true,
			filterType: FilterType.EXACT
		},
		{
			id: 1,
			name: 'columns.country',
			variable: 'country',
			required: true,
			type: InputType.TEXT,
			readonly: true,
			filterType: FilterType.LIKE,
			property: 'name'
		},
		{
			id: 2,
			name: 'columns.mnc',
			variable: 'mnc',
			required: true,
			type: InputType.TEXT,
			readonly: true,
			filterType: FilterType.EXACT
		},
		{
			id: 3,
			name: 'columns.network',
			variable: 'network',
			required: true,
			readonly: true,
			type: InputType.TEXT,
			filterType: FilterType.LIKE,
			property: 'name'
		},
		{
			id: 4,
			name: 'columns.countryDialCode',
			variable: 'countryDialCode',
			required: true,
			type: InputType.TEXT,
			readonly: true,
			filterType: FilterType.EXACT
		},
		{
			id: 5,
			name: 'columns.lastUpdated',
			variable: 'lastUpdated',
			required: true,
			type: InputComplexType.DATE_RANGE,
			readonly: true,
			filterType: FilterType.BETWEEN
		}
	];

	constructor() {
	}

	ngOnDestroy(): void {
		this.loading$.complete();
	}

	create(): Observable<void> {
		return of(undefined);
	}

	delete(): Observable<void> {
		return of(undefined);
	}

	read(): Observable<ReadResponse<EntityField[]>> {
		return of({
			Success: true,
			Total: this.headers.length,
			Data: this.headers
		});
	}

	update(): Observable<void> {
		return of(undefined);
	}
}

