import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { AlarisLanguageService, ChannelUtilsService } from '@campaign-portal/components-library';

import { EntityField } from '@campaign-portal/namespace/common/entityField';
import { ReadResponse } from '@campaign-portal/namespace/common/implementations';

import { SubscriptionsFieldsService } from '../../subscriptions/subscriptions-list/subscriptions.fields.service';
import { EnumsMapperService } from '@helpers/services/enums.service';
import { CurrencyService } from '@helpers/services/currency.service';
import { ChannelsService } from '@helpers/services/channels.service';
import { OwnerService } from '@helpers/services/owner.service';

@Injectable()
export class ProductsFieldsService extends SubscriptionsFieldsService {

	override headers: EntityField[] = [
		this.name,
		this.trafficType,
		this.currencyId,
		this.billingMode
	];

	constructor(
		lService: AlarisLanguageService,
		cu: ChannelUtilsService,
		enums: EnumsMapperService,
		channelsService: ChannelsService,
		currencyService: CurrencyService,
		isOwner: OwnerService
	) {
		super(lService, cu, enums, channelsService, currencyService, isOwner);
	}

	override read(): Observable<ReadResponse<EntityField[]>> {
		return of({
			Success: true,
			Total: this.headers.length,
			Data: this.headers
		});
	}

}

