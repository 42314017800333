<alaris-card *alarisLet="(isFiltersApplied || !!selectedTabFilter.value) as hasFilter">
	<div *ngIf="(tRows.length > 0) || (tRows.length === 0 && hasFilter)" class="table-management">
		<div class="left">
			<span class="total" data-testid="total">{{ totalTitle | translate }}: {{ total }}</span>
			<alaris-tabs-button [formControl]="selectedTabFilter" [tabs]="tabsFilter"
								data-testid="userTypeTabs">
			</alaris-tabs-button>
			<button (click)="editTableFilter()" alaris-button bType="outline" data-testid="editFilterBtn" size="lg">
				<alaris-icon class="in-outline" name="icon-filter-vertical"></alaris-icon>
				{{ enableButtons.filters.title | translate }}
				<div *ngIf="isFiltersApplied" class="indicator"></div>
			</button>
		</div>
		<div class="right">
			<button (click)="openEditPanel()"
					*alarisPBAC="[AP_PERMISSIONS.USERS_E]"
					alaris-button bType="primary" data-testid="createBtn" size="lg">
				<alaris-icon class="in-primary" name="icon-plus"></alaris-icon>
				{{ 'users.addEntry' | translate }}
			</button>
		</div>
	</div>
	<div [alarisLoading]="loading$" class="content">
		@if (tRows.length === 0 && !hasFilter) {
			<app-empty-table (newUser)="openEditPanel()"
							 [disabled]="!([AP_PERMISSIONS.USERS_E] | alarisPBAC)"
							 type="users">
			</app-empty-table>
		} @else {
			<alaris-table (filterChange)="_applyFilter($event)"
						  (sortingChange)="_applySorting($event)"
						  [ctxActions]="ctxActions"
						  [dblClickRowAction]="openEditPanel.bind(this)"
						  [filters]="filters"
						  [rowActions]="mainActions"
						  [sorting]="sorting"
						  [tHeaders]="tHeaders"
						  [tRows]="tRows"
						  [templates]="{email, isActive, role, partnerId, lastLoginDate}"
						  alarisOverflowFader>
			</alaris-table>
		}
	</div>
	<div class="footer">
		<alaris-pagination (page)="_pageChange($event)"
						   [activePage]="activePage"
						   [hidden]="total === 0"
						   [pageSizeOptions]="pageSizeOptions"
						   [pageSize]="pageSize"
						   [total]="total"
						   class="pagination">
		</alaris-pagination>
	</div>
</alaris-card>

<ng-template #email [alarisCellCtx]="tRows[0]" let-data="data" let-key="key">
	<div class="name-column">
		<!--		<div *ngIf="this.data.avatarUrl"-->
		<!--			 [style.background]="'url(' + this.data.avatarUrl + ') center center / cover no-repeat'" class="avatar">-->
		<!--		</div>-->
		<div class="avatar">
			{{ (this.data.details.firstName | as: 'string')?.toUpperCase() | slice:0:1 }}
			{{ ((this.data.details.lastName | as: 'string')?.toUpperCase() | slice:0:1) || '' }}
		</div>
		<b class="fullName">{{ data.details.firstName + ' ' + (data.details.lastName || '') }}</b>
		<span class="email">{{ data.email }}</span>
	</div>
</ng-template>

<ng-template #isActive [alarisCellCtx]="tRows[0]" let-data="data">
	<alaris-tag>
		<span [style.background]="data.isActive ? 'var(--as-BgPositive)' : 'var(--as-BgNegative)'" class="dot">
		</span>
		{{ (data.isActive ? 'gl.active' : 'gl.inactive') | translate | lowercase }}
	</alaris-tag>
</ng-template>

<ng-template #role [alarisCellCtx]="tRows[0]" let-data="data">
	{{ 'enums.' + data.role | translate }}
</ng-template>

<ng-template #partnerId [alarisCellCtx]="tRows[0]" let-data="data">
	<ng-container *ngIf="data.partnerId === (profile.user$ | async)?.partnerId; else defaultPartner">
		<alaris-tag [fill]="false" [rounded]="true" size="lg">
			{{ 'tb.yoursUsers' | translate }}
		</alaris-tag>
	</ng-container>
	<ng-template #defaultPartner>
		{{ data.partnerId ? (data.partnerId | partner)?.name || '' : '' }}
	</ng-template>
</ng-template>

<ng-template #lastLoginDate [alarisCellCtx]="tRows[0]" let-data="data" let-key="key">
	{{ data.lastLoginDate | date: 'd MMM y, HH:mm' }}
</ng-template>
