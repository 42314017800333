import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BehaviorSubject, filter } from 'rxjs';

import {
	AlarisBalanceService,
	AlarisComplexTableComponent,
	AlarisDialogService,
	AlarisEditPanelService,
	AlarisProfileService,
	AlarisTableSettingsService,
	AlarisTableStateService,
	DEFAULT_BUTTONS,
	EditPanelWidth,
	RowActionSimple,
	TabButtonEntity,
	TableSortIndicator
} from '@campaign-portal/components-library';
import { TableFiltersIndicator } from '@campaign-portal/components-library/lib/table';

import { exist } from '@campaign-portal/namespace/common/id';
import { TableTypes } from '@campaign-portal/namespace/entities/table-settings/specs';
import { Partner } from '@campaign-portal/namespace/entities/partners/specs';
import { FilterType, SortDirection } from '@campaign-portal/namespace/common/rpc.params';

import { PartnerEntity } from '@helpers/types/app.classes-interfaces';
import { PartnersFieldsService } from './partners.fields.service';
import { ContractCompaniesService } from '../settings/contract-companies/contract-companies.service';
import { PartnersDialogComponent, PartnersDialogType } from './partners-dialog/partners-dialog.component';
import { PartnersDetailsComponent } from './partners-details/partners-details.component';
import { EditPartnerComponent } from './edit-partner/edit-partner.component';
import { CurrencyService } from '@helpers/services/currency.service';
import { EnumsMapperService } from '@helpers/services/enums.service';
// import { ContractCompany } from '@campaign-portal/namespace/entities/contract-company/specs';
import { PartnersTableService } from './partners.table.service';
import { AP_PERMISSIONS } from '@helpers/types/permissions';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { PartnersService } from './partners.service';

@Component({
	selector: 'app-partners',
	templateUrl: './partners.component.html',
	styleUrls: [
		// eslint-disable-next-line max-len
		'../../../../node_modules/@campaign-portal/components-library/src/assets/templates/table-complex/complex-table.component.scss',
		'./partners.component.scss'],
	providers: [PartnersFieldsService, PartnersTableService],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PartnersComponent extends AlarisComplexTableComponent<Partner<exist>> implements OnInit {
	readonly AP_PERMISSIONS = AP_PERMISSIONS;

	readonly resellerFilter = new FormControl();
	readonly tabs: TabButtonEntity<boolean | null>[] = [
		{ label: 'tb.all', value: null },
		{ label: 'tb.reseller', value: true }
	];

	override sorting: TableSortIndicator = new Map()
		.set('name', { enabled: true, value: SortDirection.ASC });

	// @ts-ignore
	override readonly mainActions: RowActionSimple<Partner<exist>>[] = this.getActions();

	// @ts-ignore
	override readonly ctxActions: RowActionSimple<Partner<exist>>[] = [...this.mainActions].map(a => ({
		...a,
		icon: ''
	}));

	override readonly loading$ = new BehaviorSubject<boolean>(true);

	override readonly id = 'partners';

	constructor(
		public readonly entityService: PartnersTableService,
		public override readonly fieldsService: PartnersFieldsService,
		public override readonly tableSettingsService: AlarisTableSettingsService,
		public override readonly editPanel: AlarisEditPanelService,
		public override readonly cd: ChangeDetectorRef,
		public readonly partnersService: PartnersService,
		public readonly bs: AlarisBalanceService,
		private readonly dialog: AlarisDialogService,
		private readonly ccService: ContractCompaniesService,
		private readonly curService: CurrencyService,
		private readonly enums: EnumsMapperService,
		private readonly profile: AlarisProfileService,
		override readonly stateService: AlarisTableStateService
	) {
		super(
			entityService,
			fieldsService,
			tableSettingsService,
			editPanel,
			cd,
			TableTypes.INTERNAL,
			DEFAULT_BUTTONS,
			stateService
		);
		this.entityService.stateID = this.id;
	}

	override ngOnInit(): void {
		super.ngOnInit();

		this.partnersService.refresh$
			.pipe(
				filter(Boolean),
				takeUntilDestroyed(this.destroyRef)
			)
			.subscribe(() => this.load());

		this.resellerFilter.valueChanges
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe(() => {
				this._applyFilter(this.filters);
			});
	}

	override applySorting($event: TableSortIndicator): void {
		if (
			[...$event].find(([variable, sort]) => {
				return variable !== 'id' && sort?.value !== SortDirection.EMPTY;
			})
		) {
			$event.delete('id');
		} else {
			$event = $event.has('id')
				? $event
				: $event.set('id', { enabled: true, value: SortDirection.DESC });
		}
		super.applySorting($event);
	}

	override applyFilter($event: TableFiltersIndicator): void {
		super.applyFilter($event);
		this.readParams.Filters?.forEach(
			f => {
				if ( f.Field === 'financialSettings' ) {
					f.Value = (f.Value as string[]).map(i => this.enums.get('CreditType')[i]);
				}
				return f;
			}
		);

		if ( this.resellerFilter.value ) {
			this.readParams.Filters?.push({
				Field: 'isReseller', Type: FilterType.EXACT, Value: this.resellerFilter.value
			});
		}
	}

	openEditPanel(partner?: Partner<exist>): void {
		const defaultCCId = this.ccService.list[0]?.id ?? 0;
		const defaultCurrencyId = this.curService.list[0].id ?? 0;

		const entity = partner ? partner : new PartnerEntity(null, defaultCCId, defaultCurrencyId);
		this.editPanel.open(EditPartnerComponent, EditPanelWidth.MD, {
			partner: entity
		});
	}

	openDetailsPanel(partner?: Partner<exist>): void {
		this.editPanel.open(PartnersDetailsComponent, EditPanelWidth.SM, {
			partner
		})
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe(result => {
				if ( result ) {
					this.openEditPanel(partner);
				}
			});
	}

	delete(partner?: Partner<exist>): void {
		this.openSpecificDialog('Delete', partner);
	}

	override restoreState(): void {
		super.restoreState();

		const isResellerFilter = this.readParams.Filters?.find((f) => {
			return f.Field === 'isReseller' && f.Value;
		});

		if ( isResellerFilter ) {
			this.resellerFilter.setValue(true);
		}
	}

	private openSpecificDialog(
		type: PartnersDialogType,
		partner?: Partner<exist>
	): void {
		this.dialog.open(PartnersDialogComponent, {
			data: { partner, type },
			autoFocus: false
		});
	}

	private getActions(): RowActionSimple<Partner<exist>>[] {
		const details: RowActionSimple<Partner<exist>> = {
			icon: 'icon-contact',
			label: 'actions.details',
			action: this.openDetailsPanel.bind(this)
		};
		const edit: RowActionSimple<Partner<exist>> = {
			icon: 'icon-edit',
			label: 'actions.edit',
			action: this.openEditPanel.bind(this)
		};
		const _delete: RowActionSimple<Partner<exist>> = {
			icon: 'icon-delete',
			label: 'actions.delete',
			action: this.delete.bind(this),
			highlight: true,
			separator: true
		};
		return this.profile.allowed([AP_PERMISSIONS.PARTNERS_E])
			? [details, edit, _delete]
			: [details];
	}
}
