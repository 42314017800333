import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';

import {
	AlarisBalanceService,
	AlarisEditPanelService,
	ChannelUtilsService,
	EditPanelInputData
} from '@campaign-portal/components-library';

import { exist } from '@campaign-portal/namespace/common/id';
import { TrafficType } from '@campaign-portal/namespace/common/enums';
import { Edr } from '@campaign-portal/namespace/entities/edr/specs';

import { OwnerService } from '@helpers/services/owner.service';
import { EdrExportService } from '../edr-export.service';

@Component({
	selector: 'app-edr-export-details-panel',
	templateUrl: './edr-export-details-panel.component.html',
	styleUrls: ['./edr-export-details-panel.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class EdrExportDetailsPanelComponent {

	readonly edr: Edr<exist>;
	readonly TrafficType = TrafficType;

	constructor(
		private readonly editPanel: AlarisEditPanelService,
		public readonly edrExportService: EdrExportService,
		public readonly cu: ChannelUtilsService,
		public readonly bs: AlarisBalanceService,
		public readonly isOwner: OwnerService,
		@Inject(EditPanelInputData) private readonly inputData: EditPanelInputData
	) {
		this.edr = this.inputData.edr as Edr<exist>;
	}

	close(): void {
		this.editPanel.close();
	}
}
