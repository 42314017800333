import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	OnChanges,
	Output,
	SimpleChanges
} from '@angular/core';

@Component({
	selector: 'app-subscriptions-picker-empty',
	templateUrl: './subscriptions-picker-empty.component.html',
	styleUrl: './subscriptions-picker-empty.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubscriptionsPickerEmptyComponent implements OnChanges {
	@Input() type: 'subscriptions'
		| 'packs'
		| 'plans' = 'plans';
	@Output() addPacks = new EventEmitter<void>();
	@Output() addPlans = new EventEmitter<void>();
	@Output() goToSubscriptions = new EventEmitter<void>();

	note = '';
	subNote = '';
	buttonText = '';
	buttonIcon = '';

	ngOnChanges(changes: SimpleChanges): void {
		if ( changes.type ) {
			switch (this.type) {
				case 'packs':
					this.note = 'subscriptions.groups.selectPacksNote';
					this.buttonText = 'subscriptions.addPacks';
					this.buttonIcon = 'icon-add';
					break;
				case 'plans':
					this.note = 'subscriptions.groups.selectPlansNote';
					this.buttonText = 'subscriptions.addPlans';
					this.buttonIcon = 'icon-add';
					break;
				case 'subscriptions':
					this.note = 'subscriptions.groups.noAvailableSubs';
					this.subNote = 'subscriptions.groups.goToSubscriptionsNote';
					this.buttonText = 'subscriptions.groups.goToSubscriptions';
					this.buttonIcon = 'icon-arrow-right';
					break;
			}
		}
	}

	clicked(): void {
		switch (this.type) {
			case 'packs':
				this.addPacks.emit();
				break;
			case 'plans':
				this.addPlans.emit();
				break;
			case 'subscriptions':
				this.goToSubscriptions.emit();
				break;
		}
	}
}
