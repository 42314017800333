import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';

import { AlarisEditPanelService, AlarisProfileService, EditPanelInputData } from '@campaign-portal/components-library';

import { PaymentSystemName } from '@campaign-portal/namespace/common/enums';
import { PaymentSystem, PaySystemSettings } from '@campaign-portal/namespace/entities/payment-systems/specs';
import { FieldType } from '@campaign-portal/namespace/common/entityField';

import { PaymentSystemsService } from '../payment-systems.service';
import { CanDeactivateComponent } from '@helpers/can-deactivate/can-deactivate.component';
import { CanDeactivateGuardService } from '@helpers/can-deactivate/can-deactivate.guard';
import { AP_PERMISSIONS } from '@helpers/types/permissions';

@Component({
	selector: 'app-update-payment-system',
	templateUrl: './update-payment-system.component.html',
	styleUrls: ['./update-payment-system.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class UpdatePaymentSystemComponent extends CanDeactivateComponent implements OnInit, OnDestroy {
	readonly AP_PERMISSIONS = AP_PERMISSIONS;
	readonly PaymentSystemName = PaymentSystemName;
	readonly paymentSystem!: PaymentSystem;
	readonly availablePaySystem: PaymentSystemName[] = Object.values(PaymentSystemName);
	paymentSystemForm!: FormGroup;

	readonly allowedDeactivation = new BehaviorSubject<boolean>(true);

	protected readonly ngUnsubscribe: Subject<void> = new Subject<void>();

	constructor(
		@Inject(EditPanelInputData) private readonly inputData: EditPanelInputData,
		private readonly editPanel: AlarisEditPanelService,
		private readonly profile: AlarisProfileService,
		public readonly paymentSystemsService: PaymentSystemsService,
		private readonly guard: CanDeactivateGuardService
	) {
		super();
		this.addEditPanelGuard(editPanel, this.guard);
		this.paymentSystem = this.inputData.paymentSystem as PaymentSystem;
	}

	ngOnInit(): void {
		this.paymentSystemForm = new FormGroup({
			name: new FormControl(this.paymentSystem.name, Validators.required),
			type: new FormControl(this.paymentSystem.id ? this.paymentSystem.type : null, Validators.required),
			isActive: new FormControl(this.paymentSystem.isActive, Validators.required),
			settings: new FormGroup({})
		});

		if ( this.paymentSystem.id ) {
			this.manageSettingsControls(this.paymentSystem.type);
		}

		if ( !this.profile.allowed([AP_PERMISSIONS.PAYMENTS_SYSTEMS_E]) ) {
			this.paymentSystemForm.disable();
		}

		this.paymentSystemForm.get('type')?.valueChanges
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(type => this.manageSettingsControls(type)
			);
	}

	manageSettingsControls(type: PaymentSystemName): void {
		switch (type) {
			case PaymentSystemName.PAYPAL:
				this.removeControls(
					['publicKey', 'secretKey'], this.paymentSystemForm.get('settings') as FormGroup
				);
				this.setControls(
					['clientId', 'clientSecret', 'fee'], this.paymentSystemForm.get('settings') as FormGroup
				);
				break;
			case PaymentSystemName.STRIPE:
				this.removeControls(
					['clientId', 'clientSecret'], this.paymentSystemForm.get('settings') as FormGroup
				);
				this.setControls(
					['publicKey', 'secretKey', 'fee'], this.paymentSystemForm.get('settings') as FormGroup
				);
				break;
			default:
				break;
		}
	}

	getValueFromPaymentSystem(key: string): unknown {
		return this.paymentSystem.settings?.filter(setting => setting.name === key)[0]?.value;
	}

	setControls(controls: string[], formGroup: FormGroup): void {
		controls.forEach(control => formGroup.addControl(
			control, new FormControl(this.getValueFromPaymentSystem(control), Validators.required), { emitEvent: false }
		));
	}

	removeControls(controls: string[], formGroup: FormGroup): void {
		controls.forEach(control => formGroup.removeControl(control, { emitEvent: false }));
	}

	prepareSettingsToUpdate(): PaySystemSettings[] {
		return Object.entries(this.paymentSystemForm.get('settings')?.value)
			.map(([name, value]) => ({ name, type: typeof value as FieldType, value }));
	}

	close(): void {
		this.editPanel.close(true);
	}

	save(): void {
		const paymentSystem: PaymentSystem = {
			...this.paymentSystem,
			...this.paymentSystemForm.value,
			settings: this.prepareSettingsToUpdate()
		};

		this.allowedDeactivation.next(false);
		this.paymentSystemsService.update(paymentSystem)
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((resp) => {
				this.allowedDeactivation.next(true);
				if ( resp.Success ) {
					this.close();
				}
			});
	}

	ngOnDestroy(): void {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}
}
