import { Injectable, signal } from '@angular/core';
import { BehaviorSubject, Observable, tap } from 'rxjs';

import {
	AbstractCRUDService,
	AlarisApiService,
	AlarisLanguageService,
	AlarisToasterService,
	ErrorNotifierConfig
} from '@campaign-portal/components-library';

import {
	CreateResponse,
	DeleteRequest,
	DeleteResponse,
	ReadResponse
} from '@campaign-portal/namespace/common/implementations';
import { RPCRequestParams } from '@campaign-portal/namespace/common/rpc.params';
import { exist, Id } from '@campaign-portal/namespace/common/id';
import { MCCMNC } from '@campaign-portal/namespace/entities/mccmnc/specs';
import { CountriesNetworksService } from '@helpers/services/countries-networks.service';

@Injectable({
	providedIn: 'root'
})
export class MccmncService implements AbstractCRUDService {
	public readonly networkDialCodes$ = signal(new Set<number>());
	readonly loading$ = new BehaviorSubject<boolean>(false);

	readonly create = this.update;
	private readonly errorNotifier = (): ErrorNotifierConfig => ({ title: this.title });

	constructor(
		private readonly api: AlarisApiService,
		private readonly alarisToaster: AlarisToasterService,
		private readonly langService: AlarisLanguageService,
		private readonly countriesNetwork: CountriesNetworksService
	) {
	}

	get entity(): string {
		return this.langService.translate('notifications.entities.refBook');
	}

	get title(): string {
		return this.langService.translate('notifications.titles.refBook');
	}

	read(params?: RPCRequestParams): Observable<ReadResponse<MCCMNC<exist>[]>> {
		return this.api.loader<ReadResponse<MCCMNC<exist>[]>>(
			'MCCMNC.Read', params, this.loading$, this.errorNotifier
		)
			.pipe(tap((res) => {
				const networkDialCodes = new Set<number>();
				res.Data.forEach(i => {
					i.networkDialCodes?.forEach(dc => networkDialCodes.add(dc.dialCode ?? 0));
				});
				this.networkDialCodes$.set(networkDialCodes);
			}));
	}

	update(mccmnc: MCCMNC): Observable<CreateResponse<MCCMNC>> {
		const notify = (response: CreateResponse<MCCMNC>): void => {
			if ( response.Success ) {
				const message = this.langService.translate(
					mccmnc.id ? 'notifications.actions.update' : 'notifications.actions.create', {
						entity: this.entity,
						name: ''
					});
				this.alarisToaster.success(message, this.title);
			}
		};
		return this.api.loader<CreateResponse<MCCMNC>>(
			`MCCMNC.${mccmnc.id ? 'Update' : 'Create'}`,
			{ Data: { Entities: [mccmnc] } }, this.loading$, this.errorNotifier, notify
		).pipe(tap(() => {
			this.countriesNetwork.refresh$.next();
		}));
	}

	delete(id: Id<exist>): Observable<DeleteResponse<MCCMNC>> {
		const params: DeleteRequest<MCCMNC<exist>> = { Data: { Ids: [id] } };
		const notify = (response: DeleteResponse<MCCMNC>): void => {
			if ( response.Success ) {
				const message = this.langService.translate('notifications.actions.delete', { entity: this.entity });
				this.alarisToaster.success(message, this.title);
			}
		};
		return this.api.loader<DeleteResponse<MCCMNC>>(
			'MCCMNC.Delete', params, this.loading$, this.errorNotifier, notify
		).pipe(tap(() => {
			this.countriesNetwork.refresh$.next();
		}));
	}

}
