import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';

import {
	filterWildcardData,
	LocalTableUtils,
	TableEntityField,
	TableFiltersIndicator,
	TableSortIndicator
} from '@campaign-portal/components-library';

import { InputType } from '@campaign-portal/namespace/common/entityField';
import { FilterType, SortDirection } from '@campaign-portal/namespace/common/rpc.params';

export interface AllCountriesDialogData {
	items: string[];
}

export interface CountryElement {
	country: string;
}

@Component({
	selector: 'app-all-countries-hint',
	templateUrl: './all-countries-hint.component.html',
	styleUrls: ['./all-countries-hint.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AllCountriesHintComponent {
	readonly headers: TableEntityField[] = [
		new TableEntityField({
			id: 0,
			name: 'columns.country',
			variable: 'country',
			required: true,
			type: InputType.TEXT,
			filterType: FilterType.LIKE,
			readonly: true
		})
	];
	readonly filters: TableFiltersIndicator = new Map()
		.set('country', { enabled: true, value: undefined });
	readonly sorting: TableSortIndicator = new Map()
		.set('country', { enabled: true, value: SortDirection.EMPTY });

	tRows: CountryElement[];
	items: string[];
	private readonly tRowsDefault: CountryElement[];

	constructor(
		@Inject(DIALOG_DATA) public readonly data: AllCountriesDialogData,
		private readonly dialogRef: DialogRef
	) {
		this.items = data.items;
		this.tRowsDefault = this.tRows = this.items ?
			this.items.map(country => ({ country }))
			: [];
	}

	applyFilter($event: TableFiltersIndicator): void {
		this.tRows = [...this.tRowsDefault];
		for ( const [_, filter] of $event.entries() ) {
			if ( filter?.value && filter.enabled ) {
				const subStr = (filter.value as string).trim().toLowerCase();
				this.tRows = filterWildcardData(subStr, this.tRows, 'country');
			}
		}
	}

	applySorting($event: TableSortIndicator): void {
		for ( const [_, sort] of $event.entries() ) {
			if ( sort?.value && sort.enabled ) {
				this.tRows = this.tRows.sort((a, b) => {
					const first = a.country;
					const second = b.country;
					return LocalTableUtils.sortComparisonFn(sort.value ?? SortDirection.EMPTY, first, second);
				});
			}
		}
	}

	close(): void {
		this.dialogRef.close();
	}
}
