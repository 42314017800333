import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	HostBinding,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	SimpleChanges,
	ViewChild
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BehaviorSubject, filter, finalize, map, merge, Observable, Subject, switchMap, takeUntil } from 'rxjs';
import { CdkAccordionItem } from '@angular/cdk/accordion';

import { FileInfo } from '@campaign-portal/namespace/common/fileInfo';
import { FileParsedColumn, FileRow } from '@campaign-portal/namespace/entities/files/specs';
import { FilterType } from '@campaign-portal/namespace/common/rpc.params';
import { DateTimeFormat, DateTimeFormats, MergeType, SubscriptionType } from '@campaign-portal/namespace/common/enums';
import { EntityField, InputType } from '@campaign-portal/namespace/common/entityField';
import { RatesAnalysisRequest, RatesImportRequest } from '@campaign-portal/namespace/entities/rates/specs';
import { exist, Id } from '@campaign-portal/namespace/common/id';
import { Subscription } from '@campaign-portal/namespace/entities/subscriptions/specs';
import { RatesImportHistory } from '@campaign-portal/namespace/entities/rates-import-history/specs';

import {
	AlarisEditPanelService,
	AlarisFilesService,
	AlarisStepperComponent,
	EditPanelWidth,
	filterWildcardData,
	Typecast
} from '@campaign-portal/components-library';

import { CurrencyService } from '@helpers/services/currency.service';
import { VendorSubscriptionsService } from '@helpers/services/vendor-subscriptions.service';
import { ProductEntity } from '@helpers/types/app.classes-interfaces';
import { RatesFieldsService } from '@helpers/services/rates.fields.service';
import { RatesService } from '@helpers/services/rates.service';
import { CanDeactivateComponent } from '@helpers/can-deactivate/can-deactivate.component';
import { RatesImportHistoryService } from '@helpers/components/import-history/rates-import-history.service';
import { EditProductComponent } from 'src/app/pages/vendors/products/edit-product/edit-product.component';

interface Form {
	fileId: FormControl<string>;
	columns: FormControl<FileParsedColumn[]>;
	mergeType: FormControl<MergeType>;
	defaultDate: FormControl<string>;
	subscriptionId: FormControl<Id>;
	dateFormat: FormControl<DateTimeFormat | null>;
}

@Component({
	selector: 'app-rates-import',
	templateUrl: './rates-import.component.html',
	styleUrls: ['./rates-import.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush

})
export class RatesImportComponent extends CanDeactivateComponent implements OnChanges, OnInit, OnDestroy {

	@ViewChild(AlarisStepperComponent) readonly stepper!: AlarisStepperComponent;
	@ViewChild('accordionItem1') readonly accordionItem1!: CdkAccordionItem;
	@ViewChild('accordionItem2') readonly accordionItem2!: CdkAccordionItem;
	@ViewChild('accordionItem3') readonly accordionItem3!: CdkAccordionItem;

	@Input() predefinedSubscriptionId?: Id;
	@Input() withBanner = true;

	@Input()
	@HostBinding('class.bordered')
		bordered = false;

	@Output() readonly rateUpload = new EventEmitter<RatesImportRequest>();

	readonly DateTimeFormats: DateTimeFormat[] = [...DateTimeFormats];
	readonly dateTimeControl = new FormControl<string>('', {
		nonNullable: true,
		validators: Validators.pattern(/^(?:[yYMdHms][.\/ \-\:]?)+$/)
	});

	readonly filterProductControl = new FormControl('');
	readonly filterProductList$ = new BehaviorSubject<Subscription<exist>[]>([]);

	readonly MergeType = MergeType;
	fileInfo?: FileInfo;
	headers: Record<string, string> = {};
	rows: FileRow[] = [];
	defaultFields: EntityField[] = [];
	fieldsForMapping: EntityField[] = [];
	initialColumns: FileParsedColumn[] = [];
	addedProductId: Id = null;
	isAnalysisStepSuccess = false;

	ratesImportHistory$!: Observable<RatesImportHistory[]>;

	readonly paramsForm = new FormGroup<Form>({
		fileId: new FormControl<string>('', { nonNullable: true, validators: Validators.required }),
		columns: new FormControl<FileParsedColumn[]>([], { nonNullable: true, validators: Validators.required }),
		mergeType: new FormControl<MergeType>(MergeType.UPDATE, { nonNullable: true, validators: Validators.required }),
		defaultDate: new FormControl<string>('', { nonNullable: true, validators: Validators.required }),
		subscriptionId: new FormControl<Id>(
			null, this.predefinedSubscriptionId === undefined ? Validators.required : []
		),
		dateFormat: new FormControl<DateTimeFormat | null>({ value: null, disabled: true })
	});


	readonly allowedDeactivation = new BehaviorSubject<boolean>(true);
	readonly loading$ = new BehaviorSubject<boolean>(false);

	protected readonly ngUnsubscribe = new Subject<void>();

	constructor(
		public readonly fieldsService: RatesFieldsService,
		public readonly vsService: VendorSubscriptionsService,
		public readonly editPanel: AlarisEditPanelService,
		public readonly ratesService: RatesService,
		private readonly router: Router,
		private readonly currencyService: CurrencyService,
		private readonly filesService: AlarisFilesService,
		private readonly cd: ChangeDetectorRef,
		public readonly ratesImportHistoryService: RatesImportHistoryService
	) {
		super();
	}

	get requestParams(): RatesAnalysisRequest {
		const params: RatesAnalysisRequest = {
			Data: {
				fileId: '',
				columns: [],
				mergeType: MergeType.UPDATE,
				defaultDate: '',
				subscriptionId: null
			}
		};
		Object.assign(params.Data, this.paramsForm.value);
		return params;
	}

	get firstStepDisabled(): boolean {
		return !this.paramsForm.controls.fileId.value;
	}

	get secondStepDisabled(): boolean {
		const columns = this.paramsForm.controls.columns.value || [];

		const hasDateFormat = this.paramsForm.controls.dateFormat.disabled ||
			this.paramsForm.controls.dateFormat.enabled && this.paramsForm.controls.dateFormat.valid;
		const hasPrice = columns.some(item => item.columnName === 'price');
		const hasMCCMNC = columns.some(item => item.columnName === 'mccmnc');
		const hasMCC = columns.some(item => item.columnName === 'mcc');
		const hasMNC = columns.some(item => item.columnName === 'mnc');
		const hasReqProps = (hasPrice || this.paramsForm.controls.mergeType.value === MergeType.CLOSE) &&
			(hasMCCMNC || (hasMCC && hasMNC)) &&
			this.paramsForm.controls.defaultDate.value &&
			hasDateFormat;

		if ( this.predefinedSubscriptionId === undefined ) {
			return !(hasReqProps && this.paramsForm.controls.subscriptionId.value);
		} else if ( this.predefinedSubscriptionId === null ) {
			return !hasReqProps;
		} else {
			return !(hasReqProps && this.paramsForm.controls.subscriptionId.value);
		}
	}

	get thirdStepDisabled(): boolean {
		return !this.isAnalysisStepSuccess;
	}

	ngOnChanges(changes: SimpleChanges): void {
		if ( changes.predefinedSubscriptionId && this.predefinedSubscriptionId !== undefined ) {
			this.paramsForm.controls.subscriptionId.setValue(this.predefinedSubscriptionId);
		}
	}

	ngOnInit(): void {
		this.fieldsService.read()
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((resp) => {
				this.defaultFields = [
					...resp.Data
						.filter(column => !['subscriptionId', 'channel', 'currencyId'].includes(column.variable)),
					{
						id: 10,
						name: 'columns.mccmnc',
						variable: 'mccmnc',
						required: true,
						type: InputType.NUMBER,
						readonly: true,
						filterType: FilterType.EXACT
					}];
				this.fieldsForMapping = [...this.defaultFields];
			});

		this.vsService.map$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((_map) => {
			if ( this.addedProductId && _map.has(this.addedProductId) ) {
				this.paramsForm.controls.subscriptionId.setValue(this.addedProductId);
				this.addedProductId = null;
			}
		});

		this.paramsForm.controls.mergeType.valueChanges
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(() => {
				this.applyFieldsFilters();
			});

		merge(this.filterProductControl.valueChanges, this.vsService.list$, this.vsService.map$)
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(() => {
				this.filterProductList$.next(
					filterWildcardData(this.filterProductControl.value, this.vsService.list$.getValue(), 'name')
				);
			});

		this.checkRatesImportHistory();
	}

	ngOnDestroy(): void {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
		this.loading$.complete();
	}

	displayPartners = (id: Id<exist>): string => {
		return this.vsService.map.get(id)?.name ?? '';
	};

	addFiles($event: (FileInfo | undefined)[]): void {
		if ( $event[0] ) {
			this.loading$.next(true);
			this.fileInfo = $event[0];
			this.paramsForm.controls.fileId.setValue(this.fileInfo?.id);
			this.filesService.parse(this.fileInfo.id)
				.pipe(takeUntil(this.ngUnsubscribe))
				.subscribe((resp) => {
					this.rows = resp.Data;
					this.loading$.next(false);
					this.stepper.next();
				});
		} else {
			this.fileInfo = undefined;
			this.paramsForm.reset();
			this.isAnalysisStepSuccess = false;
		}
		this.cd.detectChanges();
	}

	setDateTimeFormat(): void {
		if ( this.dateTimeControl.value && this.dateTimeControl.valid ) {
			this.DateTimeFormats.push(this.dateTimeControl.value);
			this.paramsForm.controls.dateFormat.setValue(this.dateTimeControl.value);
		}
	}


	import(): void {
		if ( this.predefinedSubscriptionId === this.paramsForm.get('subscriptionId')?.value ) {
			return this.rateUpload.emit(this.requestParams);
		}
		this.loading$.next(true);
		this.allowedDeactivation.next(false);
		this.ratesService.import(this.requestParams)
			.pipe(
				finalize(() => {
					this.loading$.next(false);
					this.allowedDeactivation.next(true);
				}),
				takeUntil(this.ngUnsubscribe)
			)
			.subscribe((res) => {
				if ( res.Success ) {
					this.router.navigate(['rates'], {
						queryParams: {
							mode: 'import-history'
						}
					});
				}
			});
	}

	openProductPanel(): void {
		this.editPanel.open(EditProductComponent, EditPanelWidth.SM, {
			product: new ProductEntity(null, this.currencyService.list[0].id)
		})
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((productId) => {
				this.addedProductId = productId as Id | null;
			});
	}

	setColumns(columns: FileParsedColumn[]): void {
		this.paramsForm.controls.columns.setValue(columns);
		this.applyFieldsFilters();
		if ( columns.some(column => column.columnName === 'activeFrom' || column.columnName === 'activeTill') ) {
			this.paramsForm.controls.dateFormat.enable();
			this.paramsForm.controls.dateFormat.addValidators(Validators.required);
			this.paramsForm.controls.dateFormat.updateValueAndValidity();
			return;
		}
		this.paramsForm.controls.dateFormat.removeValidators(Validators.required);
		this.paramsForm.controls.dateFormat.updateValueAndValidity();
		this.paramsForm.controls.dateFormat.disable();
	}

	saveParsedColumns(): void {
		this.initialColumns = this.paramsForm.controls.columns.value;
	}

	checkRatesImportHistory(): void {
		this.ratesImportHistory$ = this.paramsForm.controls.subscriptionId.valueChanges
			.pipe(
				filter(Typecast.isNumber),
				switchMap(id => this.ratesImportHistoryService.read({
					Filters: [
						{ Field: 'subscriptionId', Type: FilterType.EXACT, Value: id },
						{
							Field: 'subscriptionId',
							Type: FilterType.EXACT,
							Value: SubscriptionType.VENDOR,
							Property: 'type'
						}
					]
				})),
				map(h => h.Data)
			);

		if ( this.predefinedSubscriptionId ) {
			this.ratesImportHistory$ = this.ratesImportHistoryService.read({
				Filters: [
					{ Field: 'subscriptionId', Type: FilterType.EXACT, Value: this.predefinedSubscriptionId }
				]
			})
				.pipe(map(h => h.Data));
		}
	}

	private applyFieldsFilters(): void {
		this.fieldsForMapping = [...this.defaultFields];

		const mergeType = this.paramsForm.controls.mergeType.value;
		if ( mergeType === MergeType.CLOSE ) {
			this.fieldsForMapping = this.fieldsForMapping.filter(f => !['activeFrom', 'price'].includes(f.variable));
		}

		const columns = this.paramsForm.controls.columns.value;
		const hasMCCMNC = columns.some(column => column.columnName === 'mccmnc');
		if ( hasMCCMNC ) {
			this.fieldsForMapping = this.fieldsForMapping.filter(f => !['mcc', 'mnc'].includes(f.variable));
		}

		const hasMCCorMNC = columns.some(column => ['mcc', 'mnc'].includes(column.columnName));
		if ( hasMCCorMNC ) {
			this.fieldsForMapping = this.fieldsForMapping.filter(f => f.variable !== 'mccmnc');
		}
	}
}
