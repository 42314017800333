import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { map, Subject, takeUntil } from 'rxjs';

import { Partner } from '@campaign-portal/namespace/entities/partners/specs';
import { exist } from '@campaign-portal/namespace/common/id';
import { Package, Subscription } from '@campaign-portal/namespace/entities/subscriptions/specs';
import { FilterInterval, SortDirection } from '@campaign-portal/namespace/common/rpc.params';
import { SubscriptionType, TrafficType } from '@campaign-portal/namespace/common/enums';
import { ValueObject } from '@campaign-portal/namespace/common/valueObject';
import { IdObject } from '@campaign-portal/namespace/common/idObject';

import { TableFiltersIndicator, TableSortIndicator } from '@campaign-portal/components-library/lib/table/src/base';
import {
	AlarisBalanceService,
	AlarisLanguageService,
	AlarisProfileService,
	ChannelUtilsService,
	filterWildcardData,
	LocalTableUtils
} from '@campaign-portal/components-library';

import { SubscriptionsFieldsService } from '../../subscriptions/subscriptions-list/subscriptions.fields.service';
import { SubscriptionsService } from '../../subscriptions/subscriptions-list/subscriptions.service';
import { CurrencyService } from '@helpers/services/currency.service';
import { AP_PERMISSIONS } from '@helpers/types/permissions';

export interface SubscriptionDialogData {
	partner: Partner;
	isReseller: boolean;
}

export interface SubscriptionDialogResult {
	subscribe: Subscription<exist>[];
	unsubscribe: Subscription<exist>[];
}

@Component({
	selector: 'app-subscriptions-dialog',
	templateUrl: './partners-subscriptions-dialog.component.html',
	styleUrls: ['./partners-subscriptions-dialog.component.scss'],
	providers: [SubscriptionsFieldsService],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PartnersSubscriptionsDialogComponent implements OnInit, OnDestroy {

	readonly partner: Partner;
	readonly isReseller: boolean;
	editable = false;
	readonly loading$ = this.subscriptionsService.loading$;

	// table setup
	readonly tHeaders = this.ssFieldService.partnerSubscriptionHeaders;
	filters: TableFiltersIndicator = new Map()
		.set('name', { enabled: true, value: undefined })
		.set('type', { enabled: true, value: [] })
		.set('currencyId', { enabled: true, value: [] })
		.set('trafficType', { enabled: true, value: [] })
		.set('packSettings', { enabled: true, value: undefined });

	sorting: TableSortIndicator = new Map()
		.set('name', { enabled: true, value: SortDirection.EMPTY })
		.set('type', { enabled: true, value: SortDirection.EMPTY })
		.set('currencyId', { enabled: true, value: SortDirection.EMPTY })
		.set('trafficType', { enabled: true, value: SortDirection.EMPTY })
		.set('packSettings', { enabled: true, value: SortDirection.EMPTY });

	available: Subscription<exist>[] = [];
	availableTRows: Subscription<exist>[] = [];

	subscribed: Subscription<exist>[] = [];
	subscribedTRows: Subscription<exist>[] = [];

	deltaSubscribe: Subscription<exist>[] = [];
	deltaUnsubscribe: Subscription<exist>[] = [];

	readonly filter: TableFiltersIndicator = new Map();

	// utility
	protected readonly SubscriptionType = SubscriptionType;
	protected readonly TrafficType = TrafficType;
	private readonly ngUnsubscribe = new Subject<void>();

	constructor(
		public readonly bs: AlarisBalanceService,
		public readonly cu: ChannelUtilsService,
		private readonly ls: AlarisLanguageService,
		@Inject(DIALOG_DATA) private readonly data: SubscriptionDialogData,
		private readonly dialogRef: DialogRef<SubscriptionDialogResult | undefined>,
		private readonly ssFieldService: SubscriptionsFieldsService,
		private readonly subscriptionsService: SubscriptionsService,
		private readonly currencyService: CurrencyService,
		private readonly profile: AlarisProfileService,
		private readonly router: Router,
		private readonly cd: ChangeDetectorRef
	) {
		this.partner = this.data.partner;
		this.isReseller = this.data.isReseller;
	}

	ngOnInit(): void {
		this.editable = this.profile.allowed([AP_PERMISSIONS.PARTNERS_E, AP_PERMISSIONS.SUBSCR_E]);

		this.partner.packs.forEach((id) => {
			const pack = this.subscriptionsService.packsMap.get(id);
			if ( pack ) {
				this.subscribed.push(pack);
			}
		});
		this.partner.plans.forEach((id) => {
			const plan = this.subscriptionsService.plansMap.get(id);
			if ( plan ) {
				this.subscribed.push(plan);
			}
		});
		this.subscribedTRows = [...this.subscribed];

		this.subscriptionsService.available({
			Data: this.partner.id
				? { partnerId: this.partner.id }
				: { ccId: this.partner.ccId }
			// Filters: [
			// 	{
			// 		Field: 'type',
			// 		Type: FilterType.IN,
			// 		Value: this.isReseller ? [SubscriptionType.RESELLER] : [SubscriptionType.PLAN, SubscriptionType.PACK]
			// 	},
			// 	{
			// 		Field: 'currencyId',
			// 		Type: FilterType.EXACT,
			// 		Value: this.partner.financialSettings.currencyId
			// 	}
			// ]
		})
			.pipe(
				takeUntil(this.ngUnsubscribe),
				map((resp) => {
					const data = resp.Data.filter((item) => {
						if ( this.partner.financialSettings.currencyId === item.currencyId ) {
							return this.isReseller
								? item.type === SubscriptionType.RESELLER
								: [SubscriptionType.PLAN, SubscriptionType.PACK].includes(item.type);
						}
						return false;
					});
					return {
						Success: resp.Success,
						Total: data.length,
						Data: data
					};
				})
			)
			.subscribe((resp) => {
				this.available = resp.Data || [];
				this.availableTRows = [...this.available];
			});
	}

	ngOnDestroy(): void {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}

	close(result?: SubscriptionDialogResult): void {
		this.dialogRef.close(result);
	}

	confirmAction(): void {
		const result: SubscriptionDialogResult = {
			subscribe: this.deltaSubscribe,
			unsubscribe: this.deltaUnsubscribe
		};
		this.close(result);
	}

	closeAction(): void {
		this.close();
	}

	applyFilter($event: TableFiltersIndicator): void {
		// prepare to filter
		this.filters = $event;
		this.prepareSubscribed();
		this.prepareAvailable();
		this.applySorting(this.sorting);

		// filter
		$event.forEach((filter, variable) => {
			if ( filter?.enabled && filter?.value ) {
				switch (variable) {
					case 'name':
						this.availableTRows = filterWildcardData(
							filter?.value as string || '',
							this.availableTRows,
							'name'
						);
						this.subscribedTRows = filterWildcardData(
							filter?.value as string || '',
							this.subscribedTRows,
							'name'
						);
						break;
					case 'type':
					case 'trafficType':
						if ( Array.isArray(filter.value) && filter.value.length === 0 || filter.value === null ) {
							break;
						} else if ( Array.isArray(filter.value) ) {
							this.availableTRows = this.availableTRows.filter(
								sub => (filter.value as ValueObject[])
									.map(item => item.value)
									.includes(sub[variable])
							);
							this.subscribedTRows = this.subscribedTRows.filter(
								sub => (filter.value as ValueObject[])
									.map(item => item.value)
									.includes(sub[variable])
							);
						}
						break;
					case 'currencyId':
						if ( Array.isArray(filter.value) && filter.value.length === 0 || filter.value === null ) {
							break;
						} else if ( Array.isArray(filter.value) ) {
							this.availableTRows = this.availableTRows.filter(
								sub => (filter.value as IdObject<exist>[])
									.map(item => item.id)
									.includes(sub[variable] as exist)
							);
							this.subscribedTRows = this.subscribedTRows.filter(
								sub => (filter.value as IdObject<exist>[])
									.map(item => item.id)
									.includes(sub[variable] as exist)
							);
						}
						break;
					case 'packSettings': {
						const filterFn = (sub: Subscription): boolean => {
							const price: number | undefined = (sub as Package).packSettings?.packPrice;
							const fV = filter?.value as FilterInterval<number | null>;
							if ( price === undefined ) {
								return fV.End === null;
							}
							const less = fV.End !== null ? price <= fV.End : true;
							const more = fV.Start !== null ? price >= fV.Start : true;
							return more && less;
						};
						this.availableTRows = this.availableTRows.filter(filterFn);
						this.subscribedTRows = this.subscribedTRows.filter(filterFn);
						break;
					}
					default:
						break;
				}
			}
		});
		this.cd.detectChanges();
	}

	applySorting($event: TableSortIndicator): void {
		this.sorting = $event;
		$event.forEach((sort, variable) => {
			const sortingFn = (a: Subscription, b: Subscription): number => {
				let first: unknown;
				let second: unknown;
				switch (variable) {
					case 'name':
						first = a.name;
						second = b.name;
						break;
					case 'type':
						first = this.ls.translate('enums.' + a.type);
						second = this.ls.translate('enums.' + b.type);
						break;
					case 'currencyId':
						first = this.currencyService.map.get(a.currencyId)?.name ?? '';
						second = this.currencyService.map.get(b.currencyId)?.name ?? '';
						break;
					case 'trafficType':
						first = this.ls.translate(this.cu.name(a.trafficType));
						second = this.ls.translate(this.cu.name(b.trafficType));
						break;
					case 'packSettings':
						first = (a as Package).packSettings?.packPrice;
						second = (b as Package).packSettings?.packPrice;
						break;
					default:
						break;
				}
				return LocalTableUtils.sortComparisonFn(sort?.value ?? SortDirection.EMPTY, first, second);
			};

			this.availableTRows.sort(sortingFn);
			this.subscribedTRows.sort(sortingFn);
		});
	}

	subscribe(item: Subscription<exist>): void {
		if ( this.editable ) {
			this.deltaSubscribe.push(item);
			this.applyFilter(this.filters);
		}
	}

	unsubscribe(item: Subscription<exist>): void {
		if ( this.editable ) {
			this.deltaUnsubscribe.push(item);
			this.applyFilter(this.filters);
		}
	}

	removeFromDelta(sub: Subscription<exist>, type: 'subscribe' | 'unsubscribe'): void {
		if ( type === 'subscribe' ) {
			this.deltaSubscribe = this.deltaSubscribe.filter(item => item.id !== sub.id);
			this.applyFilter(this.filters);
			return;
		}
		this.deltaUnsubscribe = this.deltaUnsubscribe.filter(item => item.id !== sub.id);
		this.applyFilter(this.filters);
		return;
	}

	navigate(): Promise<boolean> {
		this.close();
		return this.router.navigate(['subscriptions']);
	}

	private filterPlans(item: Subscription<exist>, array: Subscription<exist>[]): Subscription<exist>[] {
		if ( [SubscriptionType.PLAN, SubscriptionType.RESELLER].includes(item.type) ) {
			switch (item.trafficType) {
				case TrafficType.TELEGRAM:
				case TrafficType.WHATSAPP:
				case TrafficType.SMS:
					return array.filter((sub) => {
						return sub.type === SubscriptionType.PACK ? true : sub.trafficType !== item.trafficType;
					});
				case TrafficType.VIBER:
					return array.filter((sub) => {
						return sub.type === SubscriptionType.PACK ? true : sub.trafficType !== item.trafficType ?
							true
							: sub.settings
							?.viberSettings.messagePurpose !== item.settings
							?.viberSettings.messagePurpose;
					});
			}
		}
		return array;
	}

	private prepareAvailable(): void {
		const subId = this.deltaSubscribe.map(item => item.id);
		this.availableTRows = this.deltaSubscribe.reduce(
			(result, item) => {
				result = this.filterPlans(item, result);
				return result;
			},
			this.available.filter(item => !subId.includes(item.id))
		);
	}

	private prepareSubscribed(): void {
		const unsubId = this.deltaUnsubscribe.map(item => item.id);
		this.subscribedTRows = this.subscribed.filter(item => !unsubId.includes(item.id));
	}
}
