<div [alarisLoading]="endpointsService.loading$">
	<div class="edit-panel-header">
		<alaris-icon name="icon-endpoint"></alaris-icon>
		{{
			(([AP_PERMISSIONS.ENDPOINTS_E] | alarisPBAC)
				? endpoint.id ? 'endpoints.editEntry' : 'endpoints.createEntry'
				: 'endpoints.details') | translate
		}}
	</div>
	<div [formGroup]="endpointForm" alarisFade="vertical" class="form-group edit-panel-content">
		<alaris-alert>
			{{ 'endpoints.priorityNote' | translate }}
		</alaris-alert>
		<div class="in-row">
			<alaris-input [label]="'fc.name' | translate"
						  [placeholder]="'fc.name' | translate"
						  data-testid="nameInput" formControlName="name">
			</alaris-input>
			<alaris-input-number [errors]="errors"
								 [label]="'fc.priority' | translate"
								 [placeholder]="'fc.priority' | translate"
								 data-testid="priorityInput"
								 formControlName="priority">
			</alaris-input-number>
		</div>
		<div class="in-row">
			<alaris-tabs-button [tabs]="tabs" data-testid="protocolTabs" formControlName="endpointProtocol"
								size="xl">
			</alaris-tabs-button>
			<alaris-input *ngIf="endpointForm.controls.endpointProtocol.value === ConnectionType.SMPP"
						  [label]="'fc.port' | translate"
						  [placeholder]="'fc.port' | translate"
						  data-testid="portInput" formControlName="port">
			</alaris-input>
		</div>
		<alaris-toggle data-testid="activeToggle" formControlName="isActive">
			{{ 'gl.enabled' | translate }}
		</alaris-toggle>
		@if (profile.allowed([AP_PERMISSIONS.V_SUBSCR_R])) {
			<alaris-select2 [displayWith]="displayVendorProduct"
							[label]="'fc.vendorProduct' | translate"
							[placeholder]="'fc.vendorProduct' | translate"
							data-testid="vendorProduct" formControlName="vendorSubscription">
				<alaris-select-filter2 *ngIf="((vsService.list$ | async)?.length || 0) > 5"
									   [formControl]="filterSubControl" data-testid="vendorProductFilter">
				</alaris-select-filter2>
				<alaris-options2 *ngFor="let opt of filterSubList"
								 [attr.data-testid]="'vendorProductOption/' + opt.name" [value]="opt.id">
					{{ opt.name }}
				</alaris-options2>
			</alaris-select2>
		} @else {
			<div class="note">
				<alaris-icon name="icon-password-hide"></alaris-icon>
				<alaris-icon name="icon-locked"></alaris-icon>
				{{ 'fc.vendorProduct' | translate }}
				<small>{{ 'gl.unavailable' | translate }}</small>
			</div>
		}

		<b>
			{{
				endpointForm.get('endpointProtocol')?.value === ConnectionType.SMPP ?
					ConnectionType.SMPP : ConnectionType.HTTP
			}}
			{{ 'gl.settings' | translate }}
		</b>
		<ng-container [ngTemplateOutletContext]="{form: endpointForm}"
					  [ngTemplateOutlet]="endpointForm.get('endpointProtocol')?.value === ConnectionType.SMPP ?
					  	SMPP_SETTINGS : HTTP_SETTINGS">
		</ng-container>
		@if (profile.allowed([AP_PERMISSIONS.V_SUBSCR_R])) {
			<alaris-multi-select2 [displayWith]="displayGroups"
								  [label]="'fc.subsGroups' | translate"
								  [placeholder]="'fc.subsGroups' | translate"
								  [optionsCount]="((subGroupsService.list$ | async) ?? []).length"
								  data-testid="subscriptionGroups" formControlName="groups" mode="tag"
								  selectedLabel="name">
				@if (((subGroupsService.list$ | async)?.length || 0) > 5) {
					<alaris-multi-select-filter2 [formControl]="filterSubGroupsControl"
												 data-testid="subscriptionGroupsFilter">
					</alaris-multi-select-filter2>
				}
				@for (opt of filterSubGroupsList; track opt) {
					<alaris-multi-options2 [attr.data-testid]="'subscriptionGroupsOption/' + opt.name"
										   [value]="opt.id">
						{{ opt.name }}
					</alaris-multi-options2>
				}
			</alaris-multi-select2>
		}
		<app-country-network (selectionChange)="countryNetworkUpdate($event)"
							 [editable]="[AP_PERMISSIONS.ENDPOINTS_E] | alarisPBAC"
							 [entity]="endpoint"
							 [invalid]="endpointForm.controls.availableCountryNetList.invalid"
							 noEntryNote="endpoints.selectCountryNetwork">
		</app-country-network>
	</div>
	<div class="edit-panel-footer">
		<button (click)="save()"
				*alarisPBAC="[AP_PERMISSIONS.ENDPOINTS_E]"
				[disabled]="endpointForm.invalid ||
				!(endpointForm.dirty || smppSettingsForm.dirty || httpSettingsForm.dirty)"
				alaris-button bType="primary"
				data-testid="saveBtn"
				size="lg" type="button">
			<alaris-icon class="in-primary" name="icon-success"></alaris-icon>
			{{ (endpoint.id ? 'actions.save' : 'actions.create') | translate }}
		</button>
		<button (click)="close()" alaris-button bType="outline" data-testid="closeBtn" size="lg" type="button">
			{{ (([AP_PERMISSIONS.ENDPOINTS_E] | alarisPBAC) ? 'actions.cancel' : 'actions.close') | translate }}
		</button>
	</div>
</div>

<ng-template #HTTP_SETTINGS #form let-form="form">
	<ng-container [formGroup]="form">
		<alaris-input [label]="'fc.login' | translate"
					  [placeholder]="'fc.login' | translate"
					  autocomplete="new-password"
					  data-testid="loginInput"
					  formControlName="login" type="text">
		</alaris-input>
		<alaris-input [label]="'fc.password' | translate"
					  [placeholder]="'fc.password' | translate"
					  autocomplete="new-password"
					  data-testid="passwordInput"
					  formControlName="password" type="password">
		</alaris-input>
		<ng-container [formGroup]="httpSettingsForm">
			<ng-container formGroupName="sendRequestSettings">
				<alaris-select2 [label]="'fc.method' | translate"
								[placeholder]="'fc.method' | translate"
								data-testid="methodSelect" formControlName="method">
					<alaris-options2 *ngFor="let opt of availableHTTPMethods"
									 [attr.data-testid]="'methodOption/' + opt" [value]="opt">
						{{ opt }}
					</alaris-options2>
				</alaris-select2>
				<alaris-text-area data-testid="requestTemplateTextarea" formControlName="requestTemplate">
					{{ 'fc.requestTemplate' | translate }}
				</alaris-text-area>
				<alaris-text-area data-testid="additionalHeadersTextarea" formControlName="additionalHeaders">
					{{ 'fc.additionalHeaders' | translate }}
				</alaris-text-area>
				<alaris-text-area data-testid="bodyTemplateTextarea" formControlName="bodyTemplate">
					{{ 'fc.bodyTemplate' | translate }}
				</alaris-text-area>
			</ng-container>
			<br>
			<ng-container formGroupName="dlrRequestSettings">
				<alaris-select2 [label]="'fc.methodDLR' | translate"
								[placeholder]="'fc.methodDLR' | translate"
								data-testid="methodDLRSelect" formControlName="method">
					<alaris-options2 *ngFor="let opt of availableHTTPMethods"
									 [attr.data-testid]="'methodDLROption/' + opt" [value]="opt">
						{{ opt }}
					</alaris-options2>
				</alaris-select2>
				<alaris-text-area data-testid="requestTemplateTextarea" formControlName="requestTemplate">
					{{ 'fc.requestTemplateDLR' | translate }}
				</alaris-text-area>
				<alaris-text-area data-testid="bodyTemplateTextarea" formControlName="bodyTemplate">
					{{ 'fc.bodyTemplateDLR' | translate }}
				</alaris-text-area>
			</ng-container>
		</ng-container>
	</ng-container>
</ng-template>

<ng-template #SMPP_SETTINGS #form let-form="form">
	<ng-container [formGroup]="form">
		<alaris-input [label]="'fc.hostName' | translate"
					  [placeholder]="'fc.hostName' | translate"
					  data-testid="urlInput" formControlName="url">
		</alaris-input>
		<alaris-input [label]="'fc.systemID' | translate"
					  [placeholder]="'fc.systemID' | translate"
					  autocomplete="new-password"
					  data-testid="loginInput"
					  formControlName="login" type="text">
		</alaris-input>
		<alaris-input [label]="'fc.password' | translate"
					  [placeholder]="'fc.password' | translate"
					  autocomplete="new-password"
					  data-testid="passwordInput"
					  formControlName="password" type="password">
		</alaris-input>
		<ng-container [formGroup]="smppSettingsForm">
			<alaris-input [label]="'fc.systemType' | translate"
						  [placeholder]="'fc.systemType' | translate"
						  data-testid="systemTypeBtn" formControlName="systemType">
			</alaris-input>
			<alaris-input [label]="'fc.serviceType' | translate"
						  [placeholder]="'fc.serviceType' | translate"
						  data-testid="serviceTypeInput" formControlName="serviceType">
			</alaris-input>
		</ng-container>
	</ng-container>
</ng-template>
