<div [alarisLoading]="loading$">
	@if (subscription.id) {
		<alaris-tabs [underline]="false" id="subscriptionDetails">
			<alaris-tab [label]="'tabs.main' | translate" tabId="main">
				<ng-container *ngTemplateOutlet="form"></ng-container>
			</alaris-tab>
			<alaris-tab [label]="'tabs.subscribers' | translate" tabId="subscribers">
				<app-subscribers [subscription]="subscription"></app-subscribers>
			</alaris-tab>
			<alaris-tab *ngIf="[SubscriptionType.PLAN, SubscriptionType.RESELLER].includes(subscription.type)"
						[label]="'tabs.importHistory' | translate" tabId="importHistory">
				<app-import-history (importHistoryChange)="updateActiveImportHistory($event)"
									(initImportMode)="openImportPanel()"
									[subscriptionId]="subscription.id!"
									[withImport]="[AP_PERMISSIONS.SUBSCR_E] | alarisPBAC">
				</app-import-history>
			</alaris-tab>
		</alaris-tabs>
	} @else {
		<ng-container *ngTemplateOutlet="form"></ng-container>
	}

	<ng-template #form>
		<alaris-card class="form" size="auto">
			<ng-container [formGroup]="subscriptionForm">
				<div *ngIf="subscription.id" class="info">
					<span>{{ 'columns.id' | translate }}-{{ subscription.id }}</span>
					<span class="small-dot"></span>
					<span>
						{{ 'subscriptions.created' | translate }}
						{{ subscription.creationDate | date: 'd MMM, HH:mm' | noValue }}
						<ng-container *ngIf="subscription.lastUpdated">
							, {{ 'subscriptions.updated' | translate }}
							{{ subscription.lastUpdated | date: 'd MMM, HH:mm' }}
						</ng-container>
					</span>
				</div>
				<b>{{ 'gl.general' | translate }}</b>
				<alaris-input [errors]="errors"
							  [label]="'fc.name' | translate"
							  [placeholder]="'fc.name' | translate"
							  data-testid="nameInput" formControlName="name">
				</alaris-input>
				<alaris-text-area data-testid="descriptionTextarea" formControlName="description">
					{{ 'gl.description' | translate }}
				</alaris-text-area>
				<alaris-toggle data-testid="activeToggle" formControlName="isActive">
					{{ 'gl.active' | translate }}
				</alaris-toggle>

				<b>{{ 'gl.settings' | translate }}</b>
				<div class="in-row">
					<alaris-select2 [displayWith]="displayType"
									[label]="'gl.type' | translate"
									[placeholder]="'gl.type' | translate"
									[readonly]="subscriptionForm.controls.type.disabled"
									data-testid="typeSelect" formControlName="type">
						<alaris-options2 *ngFor="let opt of planTypes"
										 [attr.data-testid]="'typeOption/' + opt" [value]="opt">
							{{ 'enums.' + opt | translate }}
						</alaris-options2>
					</alaris-select2>

					<alaris-select2 [label]="'fc.channel' | translate"
									[placeholder]="'fc.channel' |translate"
									[readonly]="subscriptionForm.controls.trafficType.disabled"
									data-testid="'trafficTypeSelect'" formControlName="trafficType">
						<alaris-options2 *ngFor="let opt of [TrafficType.SMS, TrafficType.VIBER]"
										 [attr.data-testid]="'trafficTypeOption/' + opt" [value]="opt">
							{{ opt }}
						</alaris-options2>
					</alaris-select2>

					<alaris-select2 [displayWith]="displayBillingModeSelected"
									[label]="'fc.billingMode' | translate"
									[placeholder]="'fc.billingMode' | translate"
									[readonly]="subscriptionForm.controls.billingMode.disabled"
									data-testid="billingModeSelect" formControlName="billingMode">
						<alaris-options2 *ngFor="let opt of billingModes"
										 [attr.data-testid]="'billingModeOption/' + opt" [value]="opt">
							{{ 'enums.' + opt | translate }}
						</alaris-options2>
					</alaris-select2>
				</div>

				<fieldset *ngIf="subscriptionForm.controls.trafficType.value === TrafficType.VIBER" class="in-row"
						  formGroupName="settings">
					<b>{{ 'gl.priority' | translate }}</b>
					<ng-container formGroupName="viberSettings">
						<alaris-radio [alarisComplexTooltip]="viberPriorityComponent"
									  [attr.data-testid]="'priorityRadio/' + MessagePurpose.PROMOTION"
									  [componentData]="MessagePurpose.PROMOTION"
									  [id]="'Promotional'"
									  [value]="MessagePurpose.PROMOTION"
									  formControlName="messagePurpose" name="priority">
							{{ 'gl.promotional' | translate }}
						</alaris-radio>
						<alaris-radio [alarisComplexTooltip]="viberPriorityComponent"
									  [attr.data-testid]="'priorityRadio/' + MessagePurpose.TRANSACTION"
									  [componentData]="MessagePurpose.TRANSACTION"
									  [id]="'Transactional'"
									  [value]="MessagePurpose.TRANSACTION"
									  formControlName="messagePurpose"
									  name="priority">
							{{ 'gl.transactional' | translate }}
						</alaris-radio>
					</ng-container>
				</fieldset>

				<alaris-multi-select2 [displayWith]="displayCompanySelected"
									  [label]="'fc.cc' | translate"
									  [optionsCount]="(ccService.list$ | async)?.length || 0"
									  [placeholder]="'fc.cc' | translate"
									  data-testid="ccIdsMultiSelect" formControlName="contractCompanies">
					<alaris-select-filter2 *ngIf="((ccService.list$ | async)?.length || 0) > 5"
										   [formControl]="filterCcControl" data-testid="ccIdsFilter">
					</alaris-select-filter2>
					<alaris-multi-options2 *ngFor="let cc of filterCCList"
										   [attr.data-testid]="'ccIdsOption/' + cc.name" [value]="cc.id">
						{{ cc.name }}
					</alaris-multi-options2>
				</alaris-multi-select2>
				<alaris-multi-select2 [displayWith]="displayPersonalForSelected"
									  [label]="'fc.personalFor' | translate"
									  [optionsCount]="(partnerService.list$ | async)?.length || 0"
									  [placeholder]="'fc.personalFor' | translate"
									  data-testid="personalForMultiSelect" formControlName="personalFor">
					<alaris-select-filter2 *ngIf="((partnerService.list$ | async)?.length || 0) > 5"
										   [formControl]="filterPartnersControl" data-testid="personalForFilter">
					</alaris-select-filter2>
					<alaris-multi-options2 *ngFor="let partner of filterPartnersList"
										   [attr.data-testid]="'personalForOption/' + partner.name"
										   [value]="partner.id">
						{{ partner.name }}
					</alaris-multi-options2>
				</alaris-multi-select2>
				@if (isOwner.is) {
					@if ((this.subGroupsService.list$ | async)?.length) {
						<alaris-select2 [displayWith]="displayGroup"
										[label]="'subscriptions.group' | translate"
										[placeholder]="'subscriptions.group' | translate"
										data-testid="groupSelect"
										[stateAddonEnabled]="{ reset: true, state: false }"
										formControlName="groupId">
							@if (((subGroupsService.list$ | async)?.length || 0) > 5) {
								<alaris-select-filter2 [formControl]="filterGroupsControl"
													   data-testid="vendorProductFilter">
								</alaris-select-filter2>
							}
							@for (opt of filterGroupsList; track opt) {
								<alaris-options2 [attr.data-testid]="'groupOption/' + opt.name"
												 [value]="opt.id">
									{{ opt.name }}
								</alaris-options2>
							}

						</alaris-select2>
					} @else {
						<div class="group-stub">
							<div class="note">
								{{ 'subscriptions.groups.addToSubscriptionNote' | translate }} <br>
								{{ 'subscriptions.groups.noGroupsNote' | translate }}
							</div>
							<button (click)="createGroup()" alarisButton
									data-testid="createGroupBtn" bType="secondary" size="md">
								<alaris-icon name="icon-add"></alaris-icon>
								{{ 'subscriptions.groups.createGroup' | translate }}
							</button>
						</div>
					}
				}
				<ng-container *ngIf="subscriptionForm.controls.type.value === SubscriptionType.PACK"
							  [formGroup]="packageForm">
					<div class="in-row flex-start" formGroupName="packSettings">
						<div>
							<alaris-input-date [label]="'fc.startDate' | translate"
											   [min]="minActiveFromPack"
											   [readonly]="packageForm.controls.packSettings.controls.activeFrom.disabled"
											   [toSystemDate]
											   data-testid="activeFromInputDate" formControlName="activeFrom">
							</alaris-input-date>
							<div *ngIf="packageForm.controls.packSettings.hasError('moreThan')" class="error">
								{{ 'errors.moreThan' | translate }}
							</div>
						</div>

						<alaris-input-date [label]="('fc.endDate' | translate)"
										   [min]="minActiveToPack"
										   [readonly]="packageForm.controls.packSettings.controls.activeTo.disabled"
										   [toSystemDate]
										   data-testid="activeToInputDate" formControlName="activeTo">
						</alaris-input-date>
					</div>
				</ng-container>
			</ng-container>

			<b>{{ 'subscriptions.details' | translate }}</b>
			<div class="in-row flex-start">
				<alaris-select2 [displayWith]="displayCurrencySelected"
								[formControl]="subscriptionForm.controls.currencyId"
								[label]="'fc.currency' | translate"
								[placeholder]="'fc.currency' | translate"
								[readonly]="subscriptionForm.controls.currencyId.disabled"
								data-testid="currencySelect">
					<alaris-select-filter2 *ngIf="((currencyService.list$ | async)?.length || 0) > 5"
										   [formControl]="filterCurrencyControl" data-testid="currencyFilter">
					</alaris-select-filter2>
					<alaris-options2 *ngFor="let currency of filterCurrencyList"
									 [attr.data-testid]="'currencyOption/' + currency.name" [value]="currency.id">
						{{ currency.name }}
					</alaris-options2>
				</alaris-select2>

				<ng-container *ngIf="subscriptionForm.controls.type.value === SubscriptionType.PACK">
					<alaris-input-number [allowNegativeNumbers]="false"
										 [formControl]="packageForm.controls.messagesTotal"
										 [label]="'fc.numberMessages' | translate"
										 [placeholder]="'fc.numberMessages' | translate"
										 [readonly]="packageForm.controls.messagesTotal.disabled"
										 data-testid="messageTotalInputNumber">
					</alaris-input-number>
					<alaris-input-number [allowNegativeNumbers]="false"
										 [class.ng-warning]="warnAboutPrice"
										 [formControl]="packageForm.controls.packSettings.controls.packPrice"
										 [label]="'fc.price' | translate"
										 [placeholder]="'fc.price' | translate"
										 [readonly]="packageForm.controls.packSettings.controls.packPrice.disabled"
										 data-testid="packPriceInputNumber">
					</alaris-input-number>
				</ng-container>
			</div>
			<ng-container *ngIf="subscriptionForm.controls.type.value === SubscriptionType.PACK">
				<alaris-toggle [formControl]="packageForm.controls.packSettings.controls.isPromo"
							   [readonly]="packageForm.controls.packSettings.controls.isPromo.disabled"
							   data-testid="promoToggle">
					{{ 'subscriptions.promo' | translate }}
				</alaris-toggle>
				<alaris-alert *ngIf="!subscription.id" type="info">
					{{ 'subscriptions.promoNote' | translate }}
				</alaris-alert>
				<ng-container *ngIf="(subscriptionService.loading$ | async)">
					<div class="min-price-loader">
						<alaris-spinner size="xs"></alaris-spinner>
						{{ 'subscriptions.minPackPriceLoading' | translate }}
					</div>
				</ng-container>
				<div *ngIf="minPackPrice !== undefined && minPackPrice !== null" class="min-price">
					{{ ('subscriptions.minPackPrice' | translate) + ' ' + (minPackPrice | currency: bs.currencyCode) }}
				</div>

				<app-country-network (selectionChange)="packageForm.controls.availableCountryNetList.setValue($event)"
									 [editable]="!subscriptionForm.controls.id.value"
									 [entity]="package"
									 noEntryNote="subscriptions.selectCountryNetwork">
				</app-country-network>
			</ng-container>

			<br>

			<ng-container
				*ngIf="[SubscriptionType.PLAN, SubscriptionType.RESELLER].includes($any(subscriptionForm.controls.type.value))">
				<div *ngIf="!subscriptionForm.controls.id.value && !(ratesImportParams | async)" class="import-stub">
					{{ 'subscriptions.importRates' | translate }}
					<button (click)="openImportPanel()" [disabled]="subscriptionForm.invalid" alaris-button
							bType="outline"
							data-testid="openImportPanelBtn" size="lg">
						<alaris-icon name="icon-import"></alaris-icon>
						{{ 'gl.addEntry' | translate }}
					</button>
				</div>

				<ng-container *ngIf="subscriptionForm.controls.id.value && !(ratesImportParams | async)">
					<alaris-alert *ngIf="activeImportHistory.length" class="active-imports-alert">
						{{ 'subscriptions.activeRateImportsNote' | translate }}
					</alaris-alert>
					<div [alarisLoading]="importHistoryService.loading$" class="active-imports">
						<alaris-card *ngFor="let history of activeImportHistory"
									 class="active-import" size="auto">
							<alaris-icon (click)="export(history)"
										 [alarisTooltip]="'actions.download' | translate"
										 class="icon" name="icon-arrow-save">
							</alaris-icon>
							<div class="file-info">
								<alaris-link (click)="export(history)"
											 [alarisTooltip]="history.file.name"
											 [overflow]="true"
											 [underline]="false" class="name" data-testid="exportHistoryFileLink">
									{{ history.file.name }}
								</alaris-link>
								<alaris-tag [mode]="history.status === ImportStatus.NEW ? 'info' : 'gray'">
									{{ ('enums.' + history.status) | translate }}
								</alaris-tag>
							</div>

							<div *ngIf="history?.createdBy" class="user">
								<alaris-icon name="icon-user-circle"></alaris-icon>
								{{ history?.createdBy }}
							</div>

							<div *ngIf="history?.creationDate" class="date">
								<alaris-icon name="icon-data"></alaris-icon>
								{{ history?.creationDate  | date: 'd MMM y, HH:mm' }}
							</div>
						</alaris-card>
					</div>

					<app-rates-table (importRequest)="openImportPanel()"
									 (refreshRequest)="refreshImportHistory()"
									 [subscriptionId]="subscriptionForm.controls.id.value!"
									 [withImport]="[AP_PERMISSIONS.SUBSCR_E] | alarisPBAC"
									 class="bordered">
					</app-rates-table>
				</ng-container>

				<div *ngIf="ratesImportParams | async" class="import-stub">
					{{ 'subscriptions.ratesPrepared' | translate }}
				</div>
			</ng-container>
		</alaris-card>

		<div class="edit-panel-footer">
			<button (click)="save()"
					*alarisPBAC="[AP_PERMISSIONS.SUBSCR_E]"
					[disabled]="disabled || !allowedDeactivation.getValue()"
					alaris-button bType="primary"
					data-testid="saveBtn"
					size="lg" type="button">
				<alaris-icon class="in-primary" name="icon-success"></alaris-icon>
				{{ (subscriptionForm.get('id')?.value ? 'actions.save' : 'actions.create') | translate }}
			</button>
			<button (click)="close()" alaris-button bType="outline" data-testid="closeBtn" size="lg" type="button">
				{{ (([AP_PERMISSIONS.SUBSCR_E] | alarisPBAC) ? 'actions.cancel' : 'actions.close') | translate }}
			</button>
		</div>
	</ng-template>
</div>
