<div class="network-codes-title">
	<span>
		<b>{{'mccmnc.networkDialCodes' | translate}}</b>
		<span class="label">
			({{tRowsDefault.length}})
		</span>
	</span>
	<button (click)="showForm = true"
			*ngIf="!showForm && editable"
			alaris-button bType="outline" class="plus" data-testid="showNetworkCodeFormBtn" size="md">
		<alaris-icon name="icon-plus"></alaris-icon>
	</button>
</div>

<ng-container *ngIf="showForm">
	<alaris-alert bg="primary" style="margin-bottom: 1rem">
		<div>{{ 'mccmnc.networkDialCodeHint' | translate }}</div>
	</alaris-alert>
	<alaris-input-number [allowNegativeNumbers]="false"
						 [errors]="errors"
						 [formControl]="dialCodeControl"
						 [label]="'fc.networkDialCode' | translate"
						 [placeholder]="'fc.networkDialCode' | translate"
						 data-testid="networkCodeInputNumber"
						 maskPattern="separator.0" thousandSeparator="">
	</alaris-input-number>
	<div class="buttons">
		<button (click)="addNetworkCode()"
				[disabled]="dialCodeControl.invalid"
				alaris-button
				bType="primary" data-testid="addNetworkCodeBtn">
			{{ 'actions.add' | translate }}
		</button>
		<button (click)="showForm = false" alaris-button bType="outline" data-testid="hideNetworkCodeFormBtn">
			{{ 'actions.close' | translate }}
		</button>
	</div>
</ng-container>

<alaris-table (filterChange)="changeFilters($event)"
			  (sortingChange)="applySorting($event)"
			  *ngIf="tRowsDefault.length"
			  [filters]="filters"
			  [resizable]="false"
			  [sorting]="sorting"
			  [tHeaders]="headers"
			  [tRows]="tRows"
			  [templates]="{ dialCode }"
			  class="dial-codes-table">
</alaris-table>

<ng-template #dialCode let-data='data'>
	<span class="dialCode">
		{{ data.dialCode }}
		<alaris-icon (click)="removeNetworkCode(data.dialCode)"
					 *ngIf="editable"
					 class="removeIcon" name="icon-close">
		</alaris-icon>
	</span>
</ng-template>
