import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject, takeUntil } from 'rxjs';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';

import { exist } from '@campaign-portal/namespace/common/id';
import { RPCResult } from '@campaign-portal/namespace/common/rpc.response';
import { MCCMNC } from '@campaign-portal/namespace/entities/mccmnc/specs';

import { MccmncService } from '../mccmnc.service';
import { CanDeactivateComponent } from '@helpers/can-deactivate/can-deactivate.component';
import { CanDeactivateGuardService } from '@helpers/can-deactivate/can-deactivate.guard';
import { AlarisDialogService } from '@campaign-portal/components-library';

export type MCCMNCDialogType = 'Delete';

export interface MCCMNCDialogData {
	type: MCCMNCDialogType;
	mccmnc: MCCMNC<exist>;
}

@Component({
	selector: 'app-mccmnc-dialog',
	templateUrl: './mccmnc-dialog.component.html',
	styleUrls: ['./mccmnc-dialog.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class MccmncDialogComponent extends CanDeactivateComponent implements OnDestroy {
	readonly mccmnc!: MCCMNC<exist>;

	readonly loading$ = new BehaviorSubject<boolean>(false);
	readonly allowedDeactivation = new BehaviorSubject<boolean>(true);
	protected readonly ngUnsubscribe = new Subject<void>();

	constructor(
		@Inject(DIALOG_DATA) public readonly data: MCCMNCDialogData,
		private readonly dialogRef: DialogRef<unknown>,
		private readonly cd: ChangeDetectorRef,
		private readonly mccmncService: MccmncService,
		private readonly guard: CanDeactivateGuardService,
		private readonly dialog: AlarisDialogService
	) {
		super();
		this.addDialogGuard(this.dialog, dialogRef, this.guard);
		this.mccmnc = data.mccmnc;
	}

	get disabled(): boolean {
		return this.loading$.getValue();
	}

	close(): void {
		this.dialogRef.close(false);
	}

	confirm(): void {
		this.loading$.next(true);
		let caller: Observable<RPCResult<unknown>> = of({ Success: false });

		switch (this.data.type) {
			case 'Delete':
				caller = this.mccmncService.delete(this.mccmnc.id);
				break;
			default:
				break;

		}

		this.allowedDeactivation.next(false);
		caller.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
			(resp) => {
				this.allowedDeactivation.next(true);
				this.loading$.next(false);
				this.dialogRef.close(resp.Success);
				this.cd.markForCheck();
			}
		);
	}

	ngOnDestroy(): void {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
		this.loading$.complete();
	}
}
