<alaris-card size="auto">
	<div class="head">
		<alaris-icon (click)="close()" class="close" data-testid="closeAdditionalBtn" name="icon-close"></alaris-icon>
		<h3>{{'endpoints.select' | translate}}</h3>
	</div>
	<div class="content">
		<div class="table-zone left">
			<app-country-network-table [type]="ModelType.AVAILABLE"
									   [selectionModel]="selectionService.availableModel"
									   [action]="moveToOtherModel.bind(this)">
			</app-country-network-table>
		</div>
		<div class="table-zone right">
			<app-country-network-table [type]="ModelType.TEMPORARY"
									   [selectionModel]="selectionService.temporaryModel"
									   [action]="moveToOtherModel.bind(this)">
			</app-country-network-table>
		</div>
	</div>

	<div class="footer">
		<button (click)="close()"
				alaris-button
				bType="outline"
				data-testid="cancelBtn" size="lg">
			{{ 'actions.cancel' | translate }}
		</button>
		<button (click)="confirm()"
				[disabled]="disabled"
				alaris-button
				bType="primary"
				data-testid="confirmBtn" size="lg">
			{{ 'gl.apply' | translate }}
		</button>
	</div>
</alaris-card>
