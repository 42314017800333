<alaris-card [alarisLoading]="subGroupsService.loading$">
	@if (groups.length > 0 || groups.length === 0 && hasFilter) {
		<div class="table-management">
			<div class="left">
				<div class="total" data-testid="total">
					{{ 'subscriptions.groups.title' | translate }}: {{ groups.length }}
				</div>
				<alaris-input [formControl]="searchByNameControl"
							  [placeholder]="'subscriptions.groups.searchByName' | translate"
							  class="searchByName"
							  data-testid="searchByName"
							  [stateAddonEnabled]="{ reset: true, state: false }"
							  size="md">
					<alaris-icon extra name="icon-search"></alaris-icon>
				</alaris-input>
			</div>
			<div class="right">
				<button (click)="edit()"
						*alarisPBAC="[AP_PERMISSIONS.SUBSCR_E]"
						alaris-button bType="primary" data-testid="createBtn" size="lg">
					<alaris-icon name="icon-add"></alaris-icon>
					{{ 'subscriptions.groups.createGroup' | translate }}
				</button>
			</div>
		</div>
	}

	<div alarisFade="vertical" class="content">
		@if (groups.length === 0 && !(subGroupsService.loading$ | async)) {
			<app-empty-table (clearFilter)="searchByNameControl.reset()"
							 (newSubscriptionGroup)="edit()"
							 [type]="hasFilter ? 'subscription-groups-with-filter': 'subscription-groups'"
							 class="empty-table">
			</app-empty-table>
		} @else {
			<div class="card-container">
				@for (group of groups; track group) {
					<app-subscription-group-card (click)="edit(group)"
												 [actions]="actions"
												 [attr.data-testid]="'subscriptionGroupCard/' + group.id"
												 [group]="group">
					</app-subscription-group-card>
				}
			</div>
		}
	</div>
</alaris-card>
