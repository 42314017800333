import { RowActionSimple } from '@campaign-portal/components-library';
import { SenderSubscription } from '@campaign-portal/namespace/entities/sender-id/specs';
import { exist } from '@campaign-portal/namespace/common/id';

import { SendersListComponent } from './senders-list.component';

export class ActionsFactory {
	private readonly detailsActions: RowActionSimple<SenderSubscription<exist>>[] = [
		{
			icon: '',
			label: 'actions.details',
			action: this.component.openEditPanel.bind(this.component)
		}
	];

	private readonly ownerActions: RowActionSimple<SenderSubscription<exist>>[] = [
		{
			icon: '',
			label: 'actions.edit',
			action: this.component.openEditPanel.bind(this.component)
		},
		{
			icon: '',
			label: 'actions.delete',
			action: this.component.delete.bind(this.component),
			disabled: (row: SenderSubscription): boolean => row.subscribers.length !== 0,
			highlight: true
		}
	];

	private readonly resellerActions: RowActionSimple<SenderSubscription<exist>>[] = [
		{
			icon: '',
			label: 'actions.edit',
			action: this.component.openEditPanel.bind(this.component)
		},
		{
			icon: '',
			label: 'actions.unsubscribe',
			action: this.component.unsubscribe.bind(this.component),
			disabled: (row: SenderSubscription): boolean => !row.subscribers.length,
			highlight: true
		}
	];

	constructor(public readonly component: SendersListComponent) {
	}

	getActions(isOwner: boolean, editable: boolean): RowActionSimple<SenderSubscription<exist>>[] {
		return editable
			? isOwner
				? this.ownerActions
				: this.resellerActions
			: this.detailsActions;
	}
}

