import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { DialogRef } from '@angular/cdk/dialog';

import { exist } from '@campaign-portal/namespace/common/id';

import { CountryNetworkSelectionService } from '@helpers/components/country-network/country-network-selection.service';
import { ModelType } from '@helpers/types/app.classes-interfaces';
import { CountryNetworkRef } from '@campaign-portal/namespace/entities/mccmnc/specs';

@Component({
	selector: 'app-select-item-dialog',
	templateUrl: './select-item-dialog.component.html',
	styleUrls: [
		'../../../../../assets/templates/dialog.component.scss',
		'./select-item-dialog.component.scss'
	],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectItemDialogComponent implements OnDestroy {

	readonly ModelType = ModelType;

	readonly ngUnsubscribe = new Subject<void>();

	constructor(
		public readonly selectionService: CountryNetworkSelectionService,
		public readonly cd: ChangeDetectorRef,
		private readonly dialogRef: DialogRef<unknown>
	) {
	}

	get disabled(): boolean {
		return !this.selectionService.temporaryModel.hasValue();
	}

	moveToOtherModel(item: CountryNetworkRef<exist>): void {
		this.selectionService.moveToOtherModel(item);
	}

	close(): void {
		this.dialogRef.close(false);
	}

	confirm(): void {
		this.selectionService.applyChanges();
		this.dialogRef.close(true);
	}

	ngOnDestroy(): void {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}
}
