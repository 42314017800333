import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject, takeUntil } from 'rxjs';

import { AbstractCRUDService } from '@campaign-portal/components-library';

import { EntityField, InputComplexType, InputType } from '@campaign-portal/namespace/common/entityField';
import { ReadResponse } from '@campaign-portal/namespace/common/implementations';
import { FilterType } from '@campaign-portal/namespace/common/rpc.params';
import { CountriesNetworksService } from '@helpers/services/countries-networks.service';
import { ChannelsService } from '@helpers/services/channels.service';
import { CurrencyService } from '@helpers/services/currency.service';
import { VendorSubscriptionsService } from '@helpers/services/vendor-subscriptions.service';

@Injectable({
	providedIn: 'root'
})
export class RatesFieldsService implements AbstractCRUDService, OnDestroy {

	readonly entity = '';
	readonly title = '';
	readonly loading$ = new BehaviorSubject<boolean>(false);

	private readonly country: EntityField = {
		id: 0,
		name: 'columns.country',
		variable: 'country',
		required: true,
		type: InputComplexType.MULTISELECT,
		data: [],
		readonly: true,
		filterType: FilterType.IN,
		property: 'name'
	};
	private readonly mcc: EntityField = {
		id: 1,
		name: 'columns.mcc',
		variable: 'mcc',
		required: true,
		type: InputType.NUMBER,
		readonly: true,
		filterType: FilterType.LIKE
	};
	private readonly network: EntityField = {
		id: 2,
		name: 'columns.network',
		variable: 'network',
		required: true,
		type: InputComplexType.MULTISELECT,
		data: [],
		readonly: true,
		filterType: FilterType.IN,
		property: 'name'
	};
	private readonly mnc: EntityField = {
		id: 3,
		name: 'columns.mnc',
		variable: 'mnc',
		required: true,
		type: InputType.NUMBER,
		readonly: true,
		filterType: FilterType.LIKE
	};
	private readonly subscriptionId: EntityField = {
		id: 4,
		name: 'columns.product',
		variable: 'subscriptionId',
		required: true,
		type: InputComplexType.MULTISELECT,
		data: [],
		readonly: true,
		filterType: FilterType.IN,
		property: 'id'
	};
	private readonly channel: EntityField = {
		id: 5,
		name: 'columns.channel',
		variable: 'channel',
		required: true,
		type: InputComplexType.MULTISELECT,
		data: [],
		readonly: true,
		filterType: FilterType.IN,
		property: 'trafficType'
	};
	private readonly activeFrom: EntityField = {
		id: 6,
		name: 'columns.startDate',
		variable: 'activeFrom',
		required: true,
		type: InputComplexType.DATE_RANGE,
		readonly: true,
		filterType: FilterType.BETWEEN
	};
	private readonly activeTill: EntityField = {
		id: 7,
		name: 'columns.endDate',
		variable: 'activeTill',
		required: true,
		type: InputComplexType.DATE_RANGE,
		readonly: true,
		filterType: FilterType.BETWEEN
	};
	private readonly price: EntityField = {
		id: 8,
		name: 'columns.price',
		variable: 'price',
		required: true,
		type: InputComplexType.RANGE,
		data: [],
		readonly: true,
		filterType: FilterType.BETWEEN
	};
	private readonly currencyId: EntityField = {
		id: 9,
		name: 'columns.currency',
		variable: 'currencyId',
		required: true,
		type: InputComplexType.MULTISELECT,
		data: [],
		readonly: true,
		filterType: FilterType.IN,
		property: 'id'
	};

	private readonly headers: EntityField[] = [
		this.country,
		this.mcc,
		this.network,
		this.mnc,
		this.subscriptionId,
		this.channel,
		this.activeFrom,
		this.activeTill,
		this.price,
		this.currencyId
	];

	private readonly ngUnsubscribe = new Subject<void>();

	constructor(
		private readonly chService: ChannelsService,
		private readonly countriesNetworksService: CountriesNetworksService,
		private readonly currencyService: CurrencyService,
		private readonly vsService: VendorSubscriptionsService
	) {
		this.chService.list$
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((list) => {
				this.channel.data = list;
			});
		this.currencyService.list$
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((list) => {
				this.currencyId.data = list;
			});
		this.vsService.list$
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((list) => {
				this.subscriptionId.data = list;
			});
		this.countriesNetworksService.networksList$
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((list) => {
				this.network.data = list;
			});
		this.countriesNetworksService.countriesList$
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((list) => {
				this.country.data = list;
			});
	}

	ngOnDestroy(): void {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
		this.loading$.complete();
	}

	create(): Observable<void> {
		return of(undefined);
	}

	delete(): Observable<void> {
		return of(undefined);
	}

	read(): Observable<ReadResponse<EntityField[]>> {
		return of({
			Success: true,
			Total: this.headers.length,
			Data: this.headers
		});
	}

	update(): Observable<void> {
		return of(undefined);
	}
}

