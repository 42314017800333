import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, Observable, Subject, takeUntil } from 'rxjs';

import {
	AlarisEditPanelService,
	AlarisLanguageService,
	AlarisMultiSelectDisplayWithFn,
	AlarisProfileService,
	ChannelUtilsService,
	CustomValidators,
	Day,
	EditPanelInputData,
	filterWildcardData
} from '@campaign-portal/components-library';

import { SenderSubscription } from '@campaign-portal/namespace/entities/sender-id/specs';
import {
	EnabledDisabledStatus,
	SenderSubscriptionEventStatus,
	TrafficType
} from '@campaign-portal/namespace/common/enums';
import { exist, Id } from '@campaign-portal/namespace/common/id';
import { CreateResponse } from '@campaign-portal/namespace/common/implementations';
import { Partner } from '@campaign-portal/namespace/entities/partners/specs';

import { ChannelsService } from '@helpers/services/channels.service';
import { SendersSubscriptionsService } from '../senders-subscriptions.service';
import { PartnersService } from '../../../partners/partners.service';
import { SendersListService } from '../../senders-list/senders-list.service';
import { OwnerService } from '@helpers/services/owner.service';
import { CanDeactivateComponent } from '@helpers/can-deactivate/can-deactivate.component';
import { CanDeactivateGuardService } from '@helpers/can-deactivate/can-deactivate.guard';
import { AP_PERMISSIONS } from '@helpers/types/permissions';

@Component({
	selector: 'app-edit-sender-subscriptions',
	templateUrl: './edit-sender-subscription.component.html',
	styleUrls: ['./edit-sender-subscription.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditSenderSubscriptionComponent extends CanDeactivateComponent implements OnInit, OnDestroy {
	readonly Day = Day;
	readonly AP_PERMISSIONS = AP_PERMISSIONS;
	readonly subscription: SenderSubscription;

	// unsubscribe: (sender: SenderSubscription<exist>) => Observable<unknown>;
	readonly subscriptionForm: FormGroup;

	activeSendersList: SenderSubscription[] = [];
	readonly filterSendersControl = new FormControl('');
	filterSendersList: SenderSubscription[] = [];
	readonly filterPartnersControl = new FormControl('');
	filterPartnersList: Partner<exist>[] = [];

	readonly allowedDeactivation = new BehaviorSubject<boolean>(true);

	protected readonly ngUnsubscribe: Subject<void> = new Subject<void>();

	constructor(
		public readonly isOwner: OwnerService,
		@Inject(EditPanelInputData) private readonly inputData: EditPanelInputData,
		public readonly channelService: ChannelsService,
		public readonly cu: ChannelUtilsService,
		public readonly subscriptionsService: SendersSubscriptionsService,
		public readonly sendersService: SendersListService,
		public readonly partnersService: PartnersService,
		private readonly profile: AlarisProfileService,
		private readonly editPanel: AlarisEditPanelService,
		private readonly lService: AlarisLanguageService,
		private readonly guard: CanDeactivateGuardService
	) {
		super();
		this.addEditPanelGuard(editPanel, this.guard);
		// this.unsubscribe = this.inputData.unsubscribe as (sender: SenderSubscription<exist>) => Observable<unknown>;
		this.subscription = this.inputData.subscription as SenderSubscription;

		this.subscriptionForm = new FormGroup({
			id: new FormControl(this.subscription.id),
			name: new FormControl<SenderSubscription>(
				this.sendersService.list.find(
					(sender) => {
						return sender.name === this.subscription.name
							&& sender.trafficType === this.subscription.trafficType;
					}
				)!,
				Validators.required
			),
			trafficType: new FormControl(this.subscription.trafficType, Validators.required),
			enabled: new FormControl(this.subscription.enabled === EnabledDisabledStatus.ENABLED, Validators.required),
			activeFrom: new FormControl({
				value: (this.subscription.activeFrom ? new Date(this.subscription.activeFrom) : null),
				disabled: !!this.subscription.activeFrom &&
					this.subscription.activeFrom < Day.currentLocal().toISONativeDate()
			}, Validators.required),
			activeTill: new FormControl(this.subscription.activeTill ? new Date(this.subscription.activeTill) : null),
			comment: new FormControl(this.subscription.comment),
			documents: new FormControl(this.subscription.documents || []),
			partner: new FormControl<Id[] | null>(this.subscription.partner ? [this.subscription.partner] : [],
				[CustomValidators.requiredArrayOrNull]
			)
		}, [CustomValidators.moreThan('activeFrom', 'activeTill')]);
	}

	get unsubscribeDisabled(): boolean {
		return this.subscription.enabled === EnabledDisabledStatus.ENABLED
			? this.subscription.activeTill ? this.subscription.activeTill > new Date().toISOString() : true
			: true;
	}

	get minEndDate(): Day {
		const currentDay = Day.currentLocal();
		const startDay = Day.fromSystemDate(this.subscriptionForm.controls.activeFrom.value) ?? Day.currentLocal();
		return new Date(currentDay.toSystemDate() ?? '').getTime() > new Date(startDay?.toSystemDate() ?? '').getTime()
			? currentDay
			: startDay;
	}

	displayChannel = (value: TrafficType): string => {
		return this.lService.translate(this.cu.name(value));
	};

	displayPartners: AlarisMultiSelectDisplayWithFn<Id<exist>> = (id: Id<exist>[] | null): string => {
		if ( id === null ) {
			return this.lService.translate('gl.all');
		}
		return id.map(i => (this.partnersService.map.get(i ?? 0)?.name ?? '')).join(', ');
	};

	ngOnInit(): void {
		if ( !this.profile.allowed([AP_PERMISSIONS.SENDERS_E]) ) {
			this.subscriptionForm.disable();
		}

		this.sendersService.list$
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((list) => {
				this.activeSendersList = list.filter((sender) => {
					const enabled = sender.enabled === EnabledDisabledStatus.ENABLED;
					const status = sender.lastEvent
						? [
							SenderSubscriptionEventStatus.CONFIRMED, SenderSubscriptionEventStatus.PERIOD_CHANGED
						].includes(sender.lastEvent)
						: true;
					const active = sender.activeTill ? new Date(sender.activeTill) >= new Date() : true;
					return enabled && status && active;
				});
				this.filterSendersList = filterWildcardData(this.filterSendersControl.value, this.activeSendersList, 'name');
			});

		this.partnersService.list$
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((list) => {
				this.filterPartnersList = filterWildcardData(this.filterSendersControl.value, list, 'name');
			});

		this.filterPartnersControl.valueChanges
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((value) => {
				this.filterPartnersList = filterWildcardData(value, this.partnersService.list, 'name');
			});

		this.filterSendersControl.valueChanges
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((value) => {
				this.filterSendersList = filterWildcardData(value, this.activeSendersList, 'name');
			});

		this.subscriptionForm.valueChanges
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(() => {
				this.subscriptionForm.markAsTouched();
			});

		this.subscriptionForm.controls.name.valueChanges
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((sender) => {
				this.subscriptionForm.controls.trafficType.setValue(sender.trafficType, {});
			});
	}

	close(): void {
		this.editPanel.close();
	}

	// onUnsubscribe(): void {
	// 	this.unsubscribe(this.subscription as SenderSubscription<exist>)
	// 		.pipe(takeUntil(this.ngUnsubscribe))
	// 		.subscribe(() => {
	// 			this.close();
	// 		});

	save(): void {
		const activeFrom = this.subscriptionForm.controls.activeFrom.value === Day.fromISONativeDate(this.subscription.activeFrom)?.toISONativeDate() ?
			this.subscription.activeFrom : this.subscriptionForm.controls.activeFrom.value;
		const activeTill = this.subscriptionForm.controls.activeTill.value === Day.fromISONativeDate(this.subscription.activeTill)?.toISONativeDate() ?
			this.subscription.activeTill : this.subscriptionForm.controls.activeTill.value;
		const partners: Id<exist>[] = this.subscriptionForm.controls.partner.value === null
			? this.partnersService.list.map(p => p.id)
			: this.subscriptionForm.controls.partner.value;

		const senderSubscriptions: SenderSubscription[] = partners.map((partnerId) =>
			({
				...this.subscription,
				...this.subscriptionForm.value,
				name: this.subscriptionForm.controls.name.value.name,
				enabled: this.subscriptionForm.controls.enabled.value
					? EnabledDisabledStatus.ENABLED
					: EnabledDisabledStatus.DISABLED,
				partner: partnerId,
				activeFrom,
				activeTill
			})
		);

		this.allowedDeactivation.next(false);
		(this.subscriptionsService[this.subscription.id ? 'update' : 'create'](
			// @ts-expect-error
			senderSubscriptions
		) as Observable<CreateResponse<SenderSubscription[]>>)
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((resp) => {
				this.allowedDeactivation.next(true);
				if ( resp.Success ) {
					this.editPanel.close(resp);
				}
			});
	}

	ngOnDestroy(): void {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}
}

