<alaris-dialog [closeAction]="close.bind(this)"
			   [confirmAction]="confirm.bind(this)"
			   [disabled]="disabled"
			   [loading$]="loading$">
	<h3 [ngSwitch]="data.type" slot="header">
		<ng-container *ngSwitchCase="'Delete'">
			{{(portal.portalType === 'ADMIN_PORTAL' ? 'portals.deletePanel' : 'portals.deletePortal') | translate }}
		</ng-container>
	</h3>
	<div class="content" slot="content">
		<div [ngSwitch]="data.type">
			<ng-container *ngSwitchCase="'Delete'">
				{{
				(portal.portalType === 'ADMIN_PORTAL' ?
						'portals.deletePanelDialogNote'
						: 'portals.deletePortalDialogNote') | translate
				}}
			</ng-container>
		</div>
	</div>
</alaris-dialog>
