import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';

import {
	AlarisDialogService,
	AlarisEditPanelService,
	AlarisProfileService,
	EditPanelWidth,
	RowActionSimple
} from '@campaign-portal/components-library';

import { exist } from '@campaign-portal/namespace/common/id';
import { ContractCompany } from '@campaign-portal/namespace/entities/contract-company/specs';

import {
	CompanyDialogType,
	ContractCompanyDialogComponent
} from './contract-company-dialog/contract-company-dialog.component';
import { ContractCompanyEntity } from '@helpers/types/app.classes-interfaces';
import { ContractCompaniesService } from './contract-companies.service';
import { EditContractCompanyComponent } from './edit-contract-company/edit-contract-company.component';
import { AP_PERMISSIONS } from '@helpers/types/permissions';

@Component({
	selector: 'app-contract-companies',
	templateUrl: './contract-companies.component.html',
	styleUrls: [
		// eslint-disable-next-line max-len
		'../../../../../node_modules/@campaign-portal/components-library/src/assets/templates/table-complex/complex-table.component.scss',
		'./contract-companies.component.scss'
	],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContractCompaniesComponent implements OnInit, OnDestroy {
	readonly AP_PERMISSIONS = AP_PERMISSIONS;

	readonly actions: RowActionSimple<ContractCompany<exist>>[] = this.getAction();
	companies: ContractCompany<exist>[] = [];

	readonly loading$ = new BehaviorSubject<boolean>(false);

	private readonly ngUnsubscribe: Subject<void> = new Subject<void>();

	constructor(
		public readonly editPanel: AlarisEditPanelService,
		private readonly contractCompaniesService: ContractCompaniesService,
		private readonly profile: AlarisProfileService,
		private readonly dialog: AlarisDialogService
	) {
	}

	ngOnInit(): void {
		this.load();
	}

	load(): void {
		this.loading$.next(true);
		this.contractCompaniesService.read()
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(response => {
				this.companies = response.Data;
				this.loading$.next(false);
			});
	}

	delete(company?: ContractCompany<exist>): void {
		this.openSpecificDialog('Delete', company);

	}

	editCompany(company?: ContractCompany<exist>): void {
		const entity = company ? company : new ContractCompanyEntity(null);
		this.editPanel.open(EditContractCompanyComponent, EditPanelWidth.SM, {
			company: entity
		})
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(result => {
				if ( result ) {
					this.load();
				}
			});
	}

	ngOnDestroy(): void {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
		this.loading$.complete();
	}

	private openSpecificDialog(
		type: CompanyDialogType,
		company?: ContractCompany<exist>
	): void {
		this.dialog.open(ContractCompanyDialogComponent, {
			data: { company, type },
			autoFocus: false
		}).closed
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((result) => {
				if ( result ) {
					this.load();
				}
			});
	}

	private getAction(): RowActionSimple<ContractCompany<exist>>[] {
		const edit: RowActionSimple<ContractCompany<exist>> = {
			icon: '',
			label: 'actions.edit',
			action: this.editCompany.bind(this)
		};
		const details: RowActionSimple<ContractCompany<exist>> = {
			...edit,
			label: 'actions.details'
		};
		const _delete: RowActionSimple<ContractCompany<exist>> = {
			icon: '',
			label: 'actions.delete',
			action: this.delete.bind(this),
			highlight: true,
			separator: true
		};
		return this.profile.allowed([AP_PERMISSIONS.CC_E])
			? [edit, _delete]
			: [details];
	}
}
