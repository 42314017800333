import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, merge, Subject, takeUntil } from 'rxjs';
import { Router } from '@angular/router';

import {
	AlarisBalanceService,
	AlarisEditPanelService,
	AlarisLanguageService,
	CustomValidators,
	EditPanelInputData,
	filterWildcardData,
	RowActionSimple,
	TableEntityField
} from '@campaign-portal/components-library';

import { exist, Id } from '@campaign-portal/namespace/common/id';
import { Subscription } from '@campaign-portal/namespace/entities/subscriptions/specs';
import { Partner } from '@campaign-portal/namespace/entities/partners/specs';
import { CreditType, SubscriptionType } from '@campaign-portal/namespace/common/enums';
import { ContractCompany } from '@campaign-portal/namespace/entities/contract-company/specs';
import { Currency } from '@campaign-portal/namespace/entities/currency/specs';

import { CanDeactivateGuardService } from '@helpers/can-deactivate/can-deactivate.guard';
import { CurrencyService } from '@helpers/services/currency.service';
import { PartnersService } from '../partners.service';
import { ContractCompaniesService } from '../../settings/contract-companies/contract-companies.service';
import { SubscriptionsService } from '../../subscriptions/subscriptions-list/subscriptions.service';
import { SubscriptionHelper } from './subscription-helper';
import { SubscriptionsFieldsService } from '../../subscriptions/subscriptions-list/subscriptions.fields.service';
import { AP_PERMISSIONS } from '@helpers/types/permissions';

interface PartnerFormControls {
	ccId: FormControl<Id<exist> | null>;
	name: FormControl<string | null>;
	isReseller: FormControl<boolean>;
	isActive: FormControl<boolean>;
	financialSettings: FormGroup<{
		sendAlerts: FormControl<boolean>;
		creditLimit: FormControl<number | null>;
		currencyId: FormControl<Id<exist> | null>;
		creditType: FormControl<CreditType.PREPAID | CreditType.POSTPAID | CreditType.NO_LIMIT | null>;
	}>;
	plans: FormControl<Id<exist>[]>;
	packs: FormControl<Id<exist>[]>;
}


@Component({
	selector: 'app-edit-partner',
	templateUrl: './edit-partner.component.html',
	styleUrls: ['./edit-partner.component.scss'],
	providers: [SubscriptionsFieldsService],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditPartnerComponent extends SubscriptionHelper implements OnInit, OnDestroy {

	override readonly partner: Partner;
	readonly partnerForm: FormGroup<PartnerFormControls>;
	readonly creditTypeList = Object.values(CreditType);
	readonly AP_PERMISSIONS = AP_PERMISSIONS;
	readonly CreditType = CreditType;
	readonly errors = [{
		key: 'positiveNumber',
		value: 'errors.positiveNumber'
	}];

	tRows: Subscription<exist>[] = [];
	tHeaders: TableEntityField[] = [];
	readonly action: RowActionSimple<Subscription<exist>> = {
		icon: 'icon-edit',
		label: 'actions.edit',
		action: this.addSubscriptions.bind(this)
	};

	readonly filterCcControl = new FormControl('');
	readonly filterCurrencyControl = new FormControl('');
	filterCCList: ContractCompany<exist>[] = [];
	filterCurrencyList: Currency<exist>[] = [];
	readonly allowedDeactivation = new BehaviorSubject(true);
	private readonly ngUnsubscribe: Subject<void> = new Subject<void>();

	constructor(
		@Inject(EditPanelInputData) private readonly inputData: EditPanelInputData,
		public override readonly subService: SubscriptionsService,
		public readonly currencyService: CurrencyService,
		public readonly partnersService: PartnersService,
		public readonly ccService: ContractCompaniesService,
		public readonly ssFieldService: SubscriptionsFieldsService,
		public readonly bs: AlarisBalanceService,
		private readonly editPanel: AlarisEditPanelService,
		private readonly lService: AlarisLanguageService,
		private readonly guard: CanDeactivateGuardService,
		private readonly router: Router,
		private readonly cd: ChangeDetectorRef
	) {
		super(inputData.partner as Partner, subService);
		this.partner = this.inputData.partner as Partner;

		this.partnerForm = new FormGroup({
			name: new FormControl(this.partner.name, Validators.required),
			ccId: new FormControl<Id<exist> | null>(this.partner.id ? this.partner.ccId : null,
				{
					validators: Validators.required
				}
			),
			isReseller: new FormControl<boolean>(this.partner.isReseller || false, { nonNullable: true }),
			isActive: new FormControl<boolean>(this.partner.id ? this.partner.isActive : true, {
				nonNullable: true,
				validators: Validators.required
			}),
			financialSettings: new FormGroup({
				currencyId: new FormControl<Id<exist> | null>(
					this.partner.id
						? this.partner.financialSettings.currencyId as Id<exist>
						: null,
					Validators.required
				),
				creditType: new FormControl(
					this.partner.id ? this.partner.financialSettings.creditType : null, Validators.required
				),
				creditLimit: new FormControl({
					value: this.partner.financialSettings.creditLimit
						? this.partner.financialSettings.creditLimit
						: null,
					disabled: this.partner.financialSettings.creditType !== CreditType.POSTPAID
				}, { validators: [Validators.required, CustomValidators.positiveNumber] }),
				sendAlerts: new FormControl<boolean>(this.partner.financialSettings.sendAlerts || false,
					{
						nonNullable: true,
						validators: Validators.required
					}
				)
			}),
			plans: new FormControl(this.partner.plans ?? [], { nonNullable: true }),
			packs: new FormControl(this.partner.packs ?? [], { nonNullable: true })
		});

		merge(
			this.editPanel.closingComponent$,
			this.editPanel.escKeyInComponent$,
			this.editPanel.backdropClickComponent$
		)
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(() => {
				if ( !this.editPanel.canBeClosed ) {
					this.guard.lostDataDialog()
						.pipe(takeUntil(this.ngUnsubscribe))
						.subscribe(result => {
							this.allowedDeactivation.next(result);
							this.editPanel.close();
						});
				}
			});
		this.editPanel.canBeClosed$ = this.allowedDeactivation;

	}

	get ccAndCurrency(): boolean {
		const ccId = this.partnerForm.controls.ccId.value;
		const currencyId = this.partnerForm.controls.financialSettings.controls.currencyId.value;
		return (ccId !== null && currencyId !== null);
	}

	ngOnInit(): void {
		this.currencyService.list$
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((list) => {
				this.filterCurrencyList = filterWildcardData(this.filterCurrencyControl.value, list, 'name');
				this.cd.detectChanges();
			});

		this.ccService.list$
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((list) => {
				this.filterCCList = filterWildcardData(this.filterCcControl.value, list, 'name');
				this.cd.detectChanges();
			});


		this.tRows = [
			...this.convertIdsToSubscriptions(this.partner.plans, SubscriptionType.PLAN),
			...this.convertIdsToSubscriptions(this.partner.packs, SubscriptionType.PACK)
		];
		this.tHeaders = this.ssFieldService.partnerSubscriptionHeaders;
		this.cd.detectChanges();

		this.filterCcControl.valueChanges
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((value) => {
				this.filterCCList = filterWildcardData(value, this.ccService.list, 'name');
			});

		this.filterCurrencyControl.valueChanges
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((value) => {
				this.filterCurrencyList = filterWildcardData(value, this.currencyService.list, 'name');
			});

		this.partnerForm.get('financialSettings.creditType')?.valueChanges
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((value: CreditType | null) => {
				if ( value === CreditType.POSTPAID ) {
					this.partnerForm.get('financialSettings.creditLimit')?.enable();
				} else {
					this.partnerForm.get('financialSettings.creditLimit')?.disable();
				}
			});
	}

	close(result: boolean): void {
		this.editPanel.close(result);
	}

	save(): void {
		if ( this.ccAndCurrency ) {
			this.allowedDeactivation.next(false);
			const partner: Partner = {
				...this.partner,
				...this.partnerForm.value as Partner
			};
			this.partnersService.update(partner)
				.pipe(takeUntil(this.ngUnsubscribe))
				.subscribe((resp) => {
					this.allowedDeactivation.next(true);
					if ( resp.Success ) {
						this.close(resp.Success);
					}
				});
		}
	}

	displayCurrencySelected = (id: Id<exist>): string => {
		return this.currencyService.map.get(id ?? 0)?.name ?? '';
	};

	displayCreditType = (type: CreditType | null): string => {
		return type ? this.lService.translate('enums.' + type) : '';
	};

	displayCompanySelected = (id: Id<exist>): string => {
		return this.ccService.map.get(id ?? 0)?.name ?? '';
	};

	addSubscriptions(): void {
		if ( this.ccAndCurrency ) {
			const partner: Partner = {
				...this.partner,
				...this.partnerForm.value as Partner
			};
			this.partnersService.subscriptionsDialog(partner)
				.pipe(takeUntil(this.ngUnsubscribe))
				.subscribe((result) => {
					if ( result ) {
						const [newPlans, newPacks] = this.partnersService
							.prepareSubscriptions(
								result,
								this.partnerForm.controls.plans.value || [],
								this.partnerForm.controls.packs.value || []
							);

						this.partnerForm.controls.plans.setValue(newPlans);
						this.partnerForm.controls.plans.markAsDirty();
						this.partnerForm.controls.packs.setValue(newPacks);
						this.partnerForm.controls.packs.markAsDirty();
						this.tRows = [
							...this.convertIdsToSubscriptions(newPlans, SubscriptionType.PLAN),
							...this.convertIdsToSubscriptions(newPacks, SubscriptionType.PACK)
						];
						this.cd.detectChanges();
					}
				});
		}
	}


	goToCC(): void {
		this.router.navigate(['settings', 'contract-companies']);
		this.close(false);
	}

	ngOnDestroy(): void {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}
}
