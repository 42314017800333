import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, combineLatest, map, Observable, of, Subject, takeUntil } from 'rxjs';

import { AbstractCRUDService, AlarisLanguageService, AlarisProfileService } from '@campaign-portal/components-library';

import { EntityField, InputComplexType, InputType } from '@campaign-portal/namespace/common/entityField';
import { ReadResponse } from '@campaign-portal/namespace/common/implementations';
import { FilterType } from '@campaign-portal/namespace/common/rpc.params';
import { ConnectionType } from '@campaign-portal/namespace/common/enums';
import { VendorSubscriptionsService } from '@helpers/services/vendor-subscriptions.service';
import { AP_PERMISSIONS } from '@helpers/types/permissions';
import { CountriesNetworksService } from '@helpers/services/countries-networks.service';

@Injectable()
export class EndpointsFieldsService implements AbstractCRUDService, OnDestroy {

	readonly entity = '';
	readonly title = '';
	readonly loading$ = new BehaviorSubject<boolean>(true);
	private readonly ngUnsubscribe: Subject<void> = new Subject<void>();
	private readonly loaded$ = combineLatest([this.countriesNetworks.loading$, this.vsService.loading$])
		.pipe(
			takeUntil(this.ngUnsubscribe),
			map(([countriesLoaded, productsLoaded]) => {
				return !countriesLoaded && !productsLoaded;
			}));

	private readonly name: EntityField = {
		id: 0,
		name: 'columns.name',
		variable: 'name',
		required: true,
		type: InputType.TEXT,
		readonly: true,
		filterType: FilterType.LIKE
	};
	private readonly isActive: EntityField = {
		id: 1,
		name: 'columns.enabled',
		variable: 'isActive',
		required: true,
		type: InputComplexType.SELECT,
		readonly: true,
		data: [
			{ value: true, name: this.lService.translate('enums.ENABLED') },
			{ value: false, name: this.lService.translate('enums.DISABLED') }
		],
		filterType: FilterType.EXACT
	};
	private readonly isOnline: EntityField = {
		id: 2,
		name: 'columns.state',
		variable: 'isOnline',
		required: true,
		type: InputComplexType.SELECT,
		readonly: true,
		data: [
			{ value: true, name: this.lService.translate('gl.online') },
			{ value: false, name: this.lService.translate('gl.offline') }
		],
		filterType: FilterType.EXACT
	};
	private readonly endpointProtocol: EntityField = {
		id: 3,
		name: 'columns.connectionType',
		variable: 'endpointProtocol',
		required: true,
		type: InputComplexType.SELECT,
		readonly: true,
		data: Object.keys(ConnectionType),
		filterType: FilterType.EXACT
	};
	private readonly priority: EntityField = {
		id: 4,
		name: 'columns.priority',
		variable: 'priority',
		required: true,
		type: InputComplexType.RANGE,
		readonly: true,
		filterType: FilterType.BETWEEN
	};
	private readonly vendorSubscription: EntityField = {
		id: 5,
		name: 'columns.vendorProduct',
		variable: 'vendorSubscription',
		required: true,
		type: InputComplexType.MULTISELECT,
		readonly: true,
		filterType: FilterType.IN
	};
	private readonly availableCountryNetList: EntityField = {
		id: 6,
		name: 'columns.country',
		variable: 'availableCountryNetList',
		required: true,
		type: InputComplexType.MULTISELECT,
		readonly: true,
		filterType: FilterType.IN
	};
	private readonly groups: EntityField = {
		id: 6,
		name: 'columns.groups',
		variable: 'groups',
		required: true,
		type: InputComplexType.MULTISELECT,
		readonly: true,
		filterType: FilterType.IN
	};
	private readonly headers: EntityField[] = [
		this.name,
		this.isActive,
		this.isOnline,
		this.endpointProtocol,
		this.priority,
		this.vendorSubscription,
		this.availableCountryNetList,
		this.groups
	];

	constructor(
		private readonly lService: AlarisLanguageService,
		private readonly countriesNetworks: CountriesNetworksService,
		private readonly vsService: VendorSubscriptionsService,
		private readonly profile: AlarisProfileService
	) {
		if ( !this.profile.allowed([AP_PERMISSIONS.V_SUBSCR_R]) ) {
			this.headers = this.headers.filter(header =>
				!['vendorSubscription', 'groups'].includes(header.variable)
			);
		}

		this.countriesNetworks.list$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((list) => {
			this.availableCountryNetList.data = list
				.sort((a, b) => {
					return a.country > b.country
						? 1
						: a.country < b.country
							? -1
							: 0;
				})
				.map((c) => {
					return { name: `${c.country} - ${c.network}`, value: c.id };
				});

		});
		this.vsService.list$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((list) => {
			this.vendorSubscription.data = list;
		});
		this.loaded$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((loaded) => {
			this.loading$.next(!loaded);
		});
	}

	ngOnDestroy(): void {
		this.loading$.complete();
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}

	create(): Observable<void> {
		return of(undefined);
	}

	delete(): Observable<void> {
		return of(undefined);
	}

	read(): Observable<ReadResponse<EntityField[]>> {
		return of({
			Success: true,
			Total: this.headers.length,
			Data: this.headers
		});
	}

	update(): Observable<void> {
		return of(undefined);
	}
}
