import { Injectable } from '@angular/core';
import { AlarisTableStateService, LocalTableService, LocalTableUtils } from '@campaign-portal/components-library';
import { SenderSubscription } from '@campaign-portal/namespace/entities/sender-id/specs';
import { exist } from '@campaign-portal/namespace/common/id';
import { SendersSubscriptionsService } from './senders-subscriptions.service';
import { SenderListTableService } from '../senders-list/sender-list.table.service';
import { RPCRequestParams } from '@campaign-portal/namespace/common/rpc.params';
import { ReadResponse } from '@campaign-portal/namespace/common/implementations';
import { PartnersService } from '../../partners/partners.service';

@Injectable()
export class SenderSubscriptionsTableService extends LocalTableService<SenderSubscription<exist>> {

	readonly applyFilters = SenderListTableService._applyFilters;

	// applySorting = SenderListTableService._applySorting;

	constructor(
		service: SendersSubscriptionsService,
		stateService: AlarisTableStateService,
		private readonly partnersService: PartnersService
	) {
		super(service, stateService);
	}

	override applySorting(
		result: ReadResponse<SenderSubscription<exist>[]>,
		params: RPCRequestParams
	): ReadResponse<SenderSubscription<exist>[]> {
		let data = SenderListTableService._applySorting(result, params).Data;
		const sorting = params.Sorting?.reverse();
		if ( sorting ) {
			sorting.forEach((sort) => {
				if ( sort.Field === 'partner' ) {
					data = data.sort((a, b) => {
						const first = this.partnersService.map.get(a.partner)!.name;
						const second = this.partnersService.map.get(b.partner)!.name;

						return LocalTableUtils.sortComparisonFn(sort.Dir, first, second);
					});
				}
			});
		}
		return { Data: data, Total: result.Total, Success: true };
	}


}
