import { Injectable } from '@angular/core';
import { map, Observable, tap } from 'rxjs';

import {
	AbstractCRUDService,
	AlarisApiService,
	AlarisDialogService,
	AlarisLanguageService,
	AlarisToasterService,
	ErrorNotifierConfig,
	ExtendableRefBookService,
	sortData
} from '@campaign-portal/components-library';

import {
	CreateResponse,
	DeleteRequest,
	DeleteResponse,
	ReadResponse
} from '@campaign-portal/namespace/common/implementations';
import { RPCRequestParams } from '@campaign-portal/namespace/common/rpc.params';
import { exist, Id } from '@campaign-portal/namespace/common/id';
import { Partner } from '@campaign-portal/namespace/entities/partners/specs';
import {
	PartnersSubscriptionsDialogComponent,
	SubscriptionDialogResult
} from './partners-subscriptions-dialog/partners-subscriptions-dialog.component';
import { SubscriptionType } from '@campaign-portal/namespace/common/enums';

@Injectable({
	providedIn: 'root'
})
export class PartnersService extends ExtendableRefBookService<Partner<exist>> implements AbstractCRUDService {
	readonly create = this.update;
	private readonly errorNotifier = (): ErrorNotifierConfig => ({ title: this.title });

	constructor(
		private readonly api: AlarisApiService,
		private readonly alarisToaster: AlarisToasterService,
		private readonly langService: AlarisLanguageService,
		private readonly dialog: AlarisDialogService
	) {
		super();
	}

	get entity(): string {
		return this.langService.translate('notifications.entities.partner');
	}

	get title(): string {
		return this.langService.translate('notifications.titles.partner');
	}

	override load(): Observable<ReadResponse<Partner<exist>[]>> {
		return this.api.loader<ReadResponse<Partner<exist>[]>>('Partners.Read', {}, this.loading$, this.errorNotifier)
			.pipe(map((resp) => {
				sortData(resp.Data, 'name');
				return super.process(resp);
			}));
	}

	read(params?: RPCRequestParams): Observable<ReadResponse<Partner<exist>[]>> {
		return this.api.loader<ReadResponse<Partner<exist>[]>>(
			'Partners.Read', params, this.loading$, this.errorNotifier
		);
	}

	update(partner: Partner): Observable<CreateResponse<Partner>> {
		const notify = (response: CreateResponse<Partner>): void => {
			if ( response.Success ) {
				const message = this.langService.translate(
					partner.id ? 'notifications.actions.update' : 'notifications.actions.create', {
						entity: this.entity,
						name: partner.name
					});
				this.alarisToaster.success(message, this.title);
			}
		};
		return this.api.loader<CreateResponse<Partner>>(
			`Partners.${partner.id ? 'Update' : 'Create'}`,
			{ Data: { Entities: [partner] } },
			this.loading$, this.errorNotifier, notify
		).pipe(tap(() => {
			this.refresh$.next();
		}));
	}

	delete(id: Id<exist>): Observable<DeleteResponse<Partner>> {
		const params: DeleteRequest<Partner<exist>> = { Data: { Ids: [id] } };
		const notify = (response: DeleteResponse<Partner>): void => {
			if ( response.Success ) {
				const message = this.langService.translate('notifications.actions.delete', { entity: this.entity });
				this.alarisToaster.success(message, this.title);
			}
		};
		return this.api.loader<DeleteResponse<Partner>>(
			'Partners.Delete', params, this.loading$, this.errorNotifier, notify
		).pipe(tap(() => {
			this.refresh$.next();
		}));
	}

	subscriptionsDialog(
		partner: Partner, isReseller: boolean = partner.isReseller
	): Observable<SubscriptionDialogResult | undefined> {
		return this.dialog.open<SubscriptionDialogResult | undefined>(PartnersSubscriptionsDialogComponent,
			{
				data: { partner, isReseller },
				autoFocus: false
			})
			.closed;
	}

	prepareSubscriptions(
		result: SubscriptionDialogResult,
		plans: Id<exist>[],
		packs: Id<exist>[]
	): [newPlans: Id<exist>[], newPacks: Id<exist>[]] {
		const subscribePacks = result.subscribe
			.filter(item => item.type === SubscriptionType.PACK)
			.map(item => item.id);
		const subscribePlan = result.subscribe
			.filter(
				item => [SubscriptionType.PLAN, SubscriptionType.RESELLER].includes(item.type)
			)
			.map(item => item.id);
		const unsubscribePacks = result.unsubscribe
			.filter(item => item.type === SubscriptionType.PACK)
			.map(item => item.id);
		const unsubscribePlan = result.unsubscribe
			.filter(
				item => [SubscriptionType.PLAN, SubscriptionType.RESELLER].includes(item.type)
			)
			.map(item => item.id);

		const newPlans = [
			...plans.filter((plan: exist) => {
				return !unsubscribePlan.some(unsub => unsub === plan);
			}),
			...subscribePlan
		];
		const newPacks = [
			...packs.filter((pack: exist) => {
				return !unsubscribePacks.some(unsub => unsub === pack);
			}),
			...subscribePacks
		];
		return [newPlans, newPacks];
	}
}
