<div class="edit-panel-header">
	<alaris-icon name="icon-sender"></alaris-icon>
	{{ 'senders.requestSenderID' | translate}}
</div>
<div alarisFade="vertical" class="edit-panel-content">
	<div [formGroup]="senderIdForm" class="form-group">
		<alaris-input [label]="'fc.sender' | translate"
					  [placeholder]="'fc.sender' | translate"
					  data-testid="nameInput" formControlName="name">
		</alaris-input>
		<alaris-multi-select2 [displayWith]="displayChannel"
							  [label]="'fc.channel' | translate"
							  [optionsCount]="(channelService.list$ | async)?.length || 0"
							  [placeholder]="'fc.channel' | translate"
							  data-testid="channelsMultiSelect" formControlName="channels">
			<alaris-multi-options2 *ngFor="let opt of channelService.list$ | async"
								   [attr.data-testid]="'channelsOption/' + opt.channelType" [value]="opt.channelType">
				{{ cu.name(opt.channelType) | translate }}
			</alaris-multi-options2>
		</alaris-multi-select2>
		<alaris-text-area data-testid="commentTextarea" formControlName="comment">
			{{ 'fc.comment' | translate }}
		</alaris-text-area>
		<alaris-file-loader (fileListChanges)="senderIdForm.get('documents')?.setValue($event)"
							[exportInternally]="true"
							[required]="true"
							data-testid="documentFile" fileTypes=".pdf">
		</alaris-file-loader>
	</div>
</div>
<div class="edit-panel-footer">
	<button (click)="save()"
			[disabled]="senderIdForm.invalid || !!(sendersService.loading$ | async)"
			alaris-button bType="primary" data-testid="saveBtn"
			size="lg" type="button">
		<alaris-icon class="in-primary" name="icon-success"></alaris-icon>
		{{'actions.create' | translate}}
	</button>
	<button (click)="close()" alaris-button bType="outline" data-testid="closeBtn"
			size="lg" type="button">{{ 'actions.cancel' | translate }}
	</button>
</div>
