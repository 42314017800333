import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ConnectedPosition } from '@angular/cdk/overlay';
import { FormControl } from '@angular/forms';
import { Subject, switchMap, takeUntil } from 'rxjs';

import { RowActionSimple } from '@campaign-portal/components-library';

import { PaymentSystem } from '@campaign-portal/namespace/entities/payment-systems/specs';
import { exist } from '@campaign-portal/namespace/common/id';

import { PaymentSystemsService } from '../payment-systems.service';
import { AP_PERMISSIONS } from '@helpers/types/permissions';

@Component({
	selector: 'app-payment-system-card',
	templateUrl: './payment-system-card.component.html',
	styleUrls: ['./payment-system-card.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaymentSystemCardComponent implements OnInit, OnDestroy {
	readonly AP_PERMISSIONS = AP_PERMISSIONS;

	@Input() paymentSystem!: PaymentSystem<exist>;
	@Input() actions: RowActionSimple<PaymentSystem<exist>>[] = [];
	@Output() readonly refresh = new EventEmitter();

	readonly position: ConnectedPosition[] = [
		{
			originX: 'end',
			originY: 'bottom',
			overlayX: 'end',
			overlayY: 'top',
			offsetY: 0,
			offsetX: 0
		}
	];
	activeStatusControl!: FormControl;

	private readonly ngUnsubscribe: Subject<void> = new Subject<void>();

	constructor(
		private readonly paymentSystemsService: PaymentSystemsService
	) {
	}

	ngOnInit(): void {
		this.activeStatusControl = new FormControl(this.paymentSystem.isActive);
		this.activeStatusControl.valueChanges.pipe(
			switchMap(
				status => this.paymentSystemsService.update(
					{ ...this.paymentSystem, isActive: status }
				)
			)
		)
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(() => this.refresh.emit());
	}

	ngOnDestroy(): void {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}
}
