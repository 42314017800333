import { Injectable } from '@angular/core';
import { map, Observable, Subject, tap } from 'rxjs';

import {
	AbstractCRUDService,
	AlarisApiService,
	AlarisLanguageService,
	AlarisToasterService,
	ErrorNotifierConfig,
	ExtendableRefBookService
} from '@campaign-portal/components-library';

import {
	CreateResponse,
	DeleteRequest,
	DeleteResponse,
	ReadResponse,
	UpdateResponse
} from '@campaign-portal/namespace/common/implementations';
import { FilterType, RPCRequestParams } from '@campaign-portal/namespace/common/rpc.params';
import { exist, Id } from '@campaign-portal/namespace/common/id';
import { RPCResult } from '@campaign-portal/namespace/common/rpc.response';
import {
	Rate,
	RatesAnalysisExportRequest,
	RatesAnalysisExportResponse,
	RatesAnalysisRequest,
	RatesAnalysisResponse,
	RatesExportRequest,
	RatesExportResponse,
	RatesImportRequest,
	RatesUpdateBulkRequest
} from '@campaign-portal/namespace/entities/rates/specs';
import { SubscriptionType } from '@campaign-portal/namespace/common/enums';

@Injectable({
	providedIn: 'root'
})
export class RatesService extends ExtendableRefBookService<Rate<exist>> implements AbstractCRUDService {
	readonly importComplete$ = new Subject<boolean | undefined>();

	readonly create = this.update;
	override readonly load = this.read;
	private readonly errorNotifier = (): ErrorNotifierConfig => ({ title: this.title });

	constructor(
		private readonly api: AlarisApiService,
		private readonly alarisToaster: AlarisToasterService,
		private readonly langService: AlarisLanguageService
	) {
		super();
	}

	get entity(): string {
		return this.langService.translate('notifications.entities.rate');
	}

	get title(): string {
		return this.langService.translate('notifications.titles.rate');
	}

	read(params?: RPCRequestParams): Observable<ReadResponse<Rate<exist>[]>> {
		// Read only Vendor Rates
		const updatedParams = {
			...params,
			Filters: [
				...params?.Filters ?? [],
				{
					Field: 'subscriptionId',
					Type: FilterType.EXACT,
					Value: SubscriptionType.VENDOR,
					Property: 'type'
				}
			]
		};
		return this.api.loader<ReadResponse<Rate<exist>[]>>(
			'Rates.Read', updatedParams, this.loading$, this.errorNotifier
		)
			.pipe(map(resp => super.process(resp)));
	}

	update(rate: Rate, refresh = true): Observable<CreateResponse<Rate>> {
		const notify = (response: CreateResponse<Rate>): void => {
			if ( response.Success ) {
				const message = this.langService.translate(
					rate.id ? 'notifications.actions.update' : 'notifications.actions.create', {
						entity: this.entity,
						name: ''
					});
				this.alarisToaster.success(message, this.title);
			}
		};
		return this.api.loader<CreateResponse<Rate>>(
			`Rates.${rate.id ? 'Update' : 'Create'}`,
			{ Data: { Entities: [rate] } }, this.loading$, this.errorNotifier, notify
		).pipe(
			tap(() => refresh ? this.refresh$.next() : null)
		);
	}

	delete(id: Id<exist>): Observable<DeleteResponse<Rate>> {
		const params: DeleteRequest<Rate<exist>> = { Data: { Ids: [id] } };
		const notify = (response: DeleteResponse<Rate>): void => {
			if ( response.Success ) {
				const message = this.langService.translate('notifications.actions.delete', { entity: this.entity });
				this.alarisToaster.success(message, this.title);
			}
		};
		return this.api.loader<DeleteResponse<Rate>>(
			'Rates.Delete', params, this.loading$, this.errorNotifier, notify
		);
	}

	bulkPriceUpdate(rates: Rate<exist>[] | null, price: number): Observable<RPCResult<Rate<exist>>> {
		const params: RatesUpdateBulkRequest = {
			Data: {
				Ids: rates !== null ? rates.map(r => r.id) : null,
				price
			}
		};
		const notify = (response: RPCResult<Rate<exist>>): void => {
			if ( response.Success ) {
				const entity = this.langService.translate('notifications.entities.ratePrice');
				const message = this.langService.translate('notifications.actions.update', { entity, name: '' });
				this.alarisToaster.success(message, this.title);
			}
		};
		return this.api.loader<RPCResult<Rate<exist>>>(
			'Rates.UpdateBulk', params, this.loading$, this.errorNotifier, notify
		);
	}

	bulkClose(rates: Rate<exist>[] | null, activeTill: string): Observable<UpdateResponse<Rate[]>> {
		const params: RatesUpdateBulkRequest = {
			Data: {
				Ids: rates !== null ? rates.map(r => r.id) : null,
				activeTill
			}
		};
		const notify = (response: UpdateResponse<Rate[]>): void => {
			if ( response.Success ) {
				const message = this.langService.translate('notifications.actions.close', { entity: this.entity });
				this.alarisToaster.success(message, this.title);
			}
		};
		return this.api.loader<UpdateResponse<Rate[]>>(
			'Rates.UpdateBulk', params, this.loading$, this.errorNotifier, notify
		);
	}

	export(params?: RatesExportRequest): Observable<RatesExportResponse> {
		const notify = (response: RatesExportResponse): void => {
			if ( response.Success ) {
				const message = this.langService.translate('notifications.actions.export', { entity: this.entity });
				this.alarisToaster.success(message, this.title);
			}
		};
		return this.api.loader<RatesExportResponse>('Rates.Export', params, this.loading$, this.errorNotifier, notify);
	}

	analysisRead(params?: RatesAnalysisRequest): Observable<RatesAnalysisResponse> {
		return this.api.loader<RatesAnalysisResponse>('Rates.Analysis.Read', params, this.loading$, this.errorNotifier);
	}

	analysisExport(params?: RatesAnalysisExportRequest): Observable<RatesAnalysisExportResponse> {
		const notify = (response: RatesAnalysisExportResponse): void => {
			if ( response.Success ) {
				const entity = this.langService.translate('notifications.entities.rateAnalysis');
				const message = this.langService.translate('notifications.actions.export', { entity });
				this.alarisToaster.success(message, this.title);
			}
		};
		return this.api.loader<RatesAnalysisExportResponse>(
			'Rates.Analysis.Export', params, this.loading$, this.errorNotifier, notify
		);
	}

	import(params?: RatesImportRequest): Observable<RPCResult<Rate[]>> {
		const notify = (response: RPCResult<Rate[]>): void => {
			if ( response.Success ) {
				const message = this.langService.translate('notifications.actions.import', { entity: this.entity });
				this.alarisToaster.success(message, this.title);
			}
		};
		return this.api.loader<RPCResult<Rate[]>>('Rates.Import', params, this.loading$, this.errorNotifier, notify)
			.pipe(
				map((result) => {
					if ( result.Success ) {
						this.importComplete$.next(true);
					}
					return result;
				})
			);
	}
}
