<alaris-card *alarisLet="(isFiltersApplied || !!selectedFilter.value) as hasFilter"
			 [alarisLoading]="loading$">
	<div *ngIf="tRows.length > 0 || tRows.length === 0 && hasFilter" class="table-management">
		<div class="left">
			<span class="total" data-testid="total">{{ totalTitle | translate }}: {{ total }}</span>
			<alaris-tabs-button [formControl]="selectedFilter" [tabs]="tabs"
								data-testid="subscriptionTypeTabs">
			</alaris-tabs-button>
			<button (click)="editTableFilter()"
					alaris-button bType="outline" data-testid="editFilterBtn" size="lg">
				<alaris-icon class="in-outline" name="icon-filter-vertical"></alaris-icon>
				{{ enableButtons.filters.title | translate }}
				<div *ngIf="isFiltersApplied" class="indicator"></div>
			</button>
		</div>
		<div *alarisPBAC="[AP_PERMISSIONS.SUBSCR_E]" class="right">
			<button (click)="navigate()" alaris-button bType="primary" data-testid="createBtn" size="lg">
				<alaris-icon class="in-primary" name="icon-plus"></alaris-icon>
				{{ 'subscriptions.addEntry' | translate }}
			</button>
		</div>
	</div>
	<div [alarisFade]="'vertical'" class="content">
		<app-empty-table (newSubscriptions)="navigate()"
						 *ngIf="tRows.length === 0 && !hasFilter; else cards"
						 [disabled]="!([AP_PERMISSIONS.SUBSCR_E] | alarisPBAC)"
						 type="subscriptions">
		</app-empty-table>
		<ng-template #cards>
			<app-subscription-card *ngFor="let subscription of tRows"
								   [actions]="actions"
								   [attr.data-testid]="'subscriptionCard/' + subscription.id"
								   [subscription]="subscription">
			</app-subscription-card>
		</ng-template>
	</div>
	<div class="footer"></div>
</alaris-card>
