import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';

import {
	AbstractCRUDService,
	AlarisApiService,
	AlarisLanguageService,
	AlarisProfileService,
	AlarisToasterService,
	ErrorNotifierConfig
} from '@campaign-portal/components-library';

import {
	CreateResponse,
	DeleteRequest,
	DeleteResponse,
	ReadResponse
} from '@campaign-portal/namespace/common/implementations';
import { RPCRequestParams } from '@campaign-portal/namespace/common/rpc.params';
import { exist, Id } from '@campaign-portal/namespace/common/id';
import { Endpoint } from '@campaign-portal/namespace/entities/endpoints/specs';
import { AP_PERMISSIONS } from '@helpers/types/permissions';

@Injectable({
	providedIn: 'root'
})
export class EndpointsService implements AbstractCRUDService {
	readonly loading$ = new BehaviorSubject<boolean>(false);

	readonly create = this.update;
	private readonly errorNotifier = (): ErrorNotifierConfig => ({ title: this.title });

	constructor(
		private readonly api: AlarisApiService,
		private readonly alarisToaster: AlarisToasterService,
		private readonly profile: AlarisProfileService,
		private readonly langService: AlarisLanguageService
	) {
	}

	get entity(): string {
		return this.langService.translate('notifications.entities.endpoint');
	}

	get title(): string {
		return this.langService.translate('notifications.titles.endpoint');
	}

	read(params?: RPCRequestParams): Observable<ReadResponse<Endpoint<exist>[]>> {
		let request: Observable<ReadResponse<Endpoint<exist>[]>> = of({
			Success: true,
			Total: 0,
			Data: []
		});
		if ( this.profile.allowed([AP_PERMISSIONS.ENDPOINTS_R]) ) {
			request = this.api.loader<ReadResponse<Endpoint<exist>[]>>(
				'Endpoints.Read', params, this.loading$, this.errorNotifier
			);
		}
		return request;
	}

	update(endpoint: Endpoint): Observable<CreateResponse<Endpoint<exist>[]>> {
		const notify = (response: CreateResponse<Endpoint<exist>[]>): void => {
			if ( response.Success ) {
				const message = this.langService.translate(
					endpoint.id ? 'notifications.actions.update' : 'notifications.actions.create', {
						entity: this.entity,
						name: endpoint.name
					});
				this.alarisToaster.success(message, this.title);
			}
		};
		return this.api.loader<CreateResponse<Endpoint<exist>[]>>(
			`Endpoints.${endpoint.id ? 'Update' : 'Create'}`,
			{ Data: { Entities: [endpoint] } }, this.loading$, this.errorNotifier, notify
		);
	}

	delete(id: Id<exist>): Observable<DeleteResponse<Endpoint>> {
		const params: DeleteRequest<Endpoint<exist>> = { Data: { Ids: [id] } };
		const notify = (response: DeleteResponse<Endpoint>): void => {
			if ( response.Success ) {
				const message = this.langService.translate('notifications.actions.delete', { entity: this.entity });
				this.alarisToaster.success(message, this.title);
			}
		};
		return this.api.loader<DeleteResponse<Endpoint>>(
			'Endpoints.Delete', params, this.loading$, this.errorNotifier, notify
		);
	}
}
