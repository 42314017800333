<alaris-card *alarisLet="(isFiltersApplied || !!selectedTabFilter.value) as hasFilter">
	<div *ngIf="tRows.length > 0 || tRows.length === 0 && hasFilter" class="table-management">
		<div class="left">
			<span class="total" data-testid="total">{{ totalTitle | translate }}: {{ total }}</span>
			<alaris-tabs-button [formControl]="selectedTabFilter" [tabs]="tabsFilter"
								data-testid="paymentTypeTabs">
			</alaris-tabs-button>
			<button (click)="editTableFilter()" alaris-button bType="outline" data-testid="editFilterBtn" size="lg">
				<alaris-icon class="in-outline" name="icon-filter-vertical"></alaris-icon>
				{{ enableButtons.filters.title | translate }}
				<div *ngIf="isFiltersApplied" class="indicator"></div>
			</button>
		</div>
		<div class="right">
			<button (click)="load()" alaris-button bType="outline" data-testid="refreshBtn" size="lg">
				<alaris-icon name="icon-refresh-clock-wise"></alaris-icon>
				{{ 'actions.refreshPage' | translate }}
			</button>
			<button (click)="openAddPaymentPanel()"
					*alarisPBAC="[AP_PERMISSIONS.PAYMENTS_E]"
					alaris-button bType="primary" data-testid="createBtn" size="lg">
				<alaris-icon class="in-primary" name="icon-plus"></alaris-icon>
				{{ 'payments.addPayment' | translate }}
			</button>
		</div>
	</div>
	<!--	<div *ngIf="isNoteVisible" class="note">-->
	<!--		<alaris-icon name="icon-info"></alaris-icon>-->
	<!--		{{'payments.note' | translate}}-->
	<!--		<alaris-icon (click)="isNoteVisible = false" class="close" name="icon-close"></alaris-icon>-->
	<!--	</div>-->
	<div [alarisLoading]="loading$" class="content">
		@if (tRows.length === 0 && !hasFilter) {
			<app-empty-table (newPayment)="openAddPaymentPanel()"
							 [disabled]="!([AP_PERMISSIONS.PAYMENTS_E] | alarisPBAC)"
							 type="payments">
			</app-empty-table>
		} @else {
			<alaris-table (filterChange)="_applyFilter($event)"
						  (sortingChange)="_applySorting($event)"
						  [ctxActions]="ctxActions"
						  [dblClickRowAction]="openUpdatePaymentPanel.bind(this)"
						  [filters]="filters"
						  [rowActions]="rowActions"
						  [sorting]="sorting"
						  [tHeaders]="tHeaders"
						  [tRows]="tRows"
						  [templates]="{id, amount, status, creationDate, partnerId}"
						  alarisOverflowFader>
			</alaris-table>
		}
	</div>
	<div class="footer">
		<alaris-pagination (page)="_pageChange($event)"
						   [activePage]="activePage"
						   [hidden]="total === 0"
						   [pageSizeOptions]="pageSizeOptions"
						   [pageSize]="pageSize"
						   [total]="total"
						   class="pagination">
		</alaris-pagination>
	</div>
</alaris-card>

<ng-template #id [alarisCellCtx]="tRows[0]" let-data="data" let-key="key">
	<div class="id-column">
		<alaris-icon [class.incoming]="data.amount > 0"
					 [class.outgoing]="data.amount < 0"
					 [name]="data.amount > 0 ?
					 'icon-arrow-line-down': 'icon-arrow-line-up'">
		</alaris-icon>
		{{ data.id }}
		<alaris-tag *ngIf="data.type === PaymentSource.MANUAL">{{ 'payments.manual' | translate }}</alaris-tag>
	</div>
</ng-template>

<ng-template #amount [alarisCellCtx]="tRows[0]" let-data="data" let-key="key">
	<ng-container *ngTemplateOutlet="sharedTemplates.get('amount');
				context: {$implicit: data.amount}">
	</ng-container>
</ng-template>

<ng-template #status [alarisCellCtx]="tRows[0]" let-data="data" let-key="key">
	<alaris-tag [mode]="data.status === PaymentStatus.CONFIRMED ? 'success': 'error'" [rounded]="true">
		{{ 'enums.' + data.status | translate }}
	</alaris-tag>
</ng-template>

<ng-template #creationDate [alarisCellCtx]="tRows[0]" let-data="data" let-key="key">
	{{ data?.creationDate | date: 'd MMM y, HH:mm' }}
</ng-template>

<ng-template #partnerId [alarisCellCtx]="tRows[0]" let-data="data" let-key="key">
	<ng-container *ngTemplateOutlet="sharedTemplates.get('partnerId');
				context: {$implicit: data.partnerId}">
	</ng-container>
</ng-template>
