import {
	AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	OnDestroy,
	OnInit,
	TemplateRef,
	ViewChild
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';

import {
	AlarisBalanceService,
	ChartUtilsService,
	Day,
	LocalTableUtils,
	SharedTemplatesService,
	TableCellInput,
	TableEntityField,
	TableSortIndicator
} from '@campaign-portal/components-library';
import { FilterType, RPCRequestParams, SortDirection } from '@campaign-portal/namespace/common/rpc.params';

import { AnalyticsByClient } from '@campaign-portal/namespace/entities/charts/specs';
import { TableCellTemplates } from '@campaign-portal/components-library/lib/table/src/base';

import { AnalyticsByClientService } from './analytics-by-client.service';
import { AnalyticsByClientFieldsService } from './analytics-by-client.fields.service';

@Component({
	selector: 'app-analytics-by-client',
	templateUrl: './analytics-by-client.component.html',
	styleUrls: [
		'./analytics-by-client.component.scss'
	],
	providers: [AnalyticsByClientFieldsService],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AnalyticsByClientComponent implements OnInit, AfterViewInit, OnDestroy {
	@ViewChild('clientName') readonly clientNameRef!: TemplateRef<TableCellInput<AnalyticsByClient>>;
	@ViewChild('totalMessage') readonly totalMessageRef!: TemplateRef<TableCellInput<AnalyticsByClient>>;
	@ViewChild('general') readonly generalRef!: TemplateRef<TableCellInput<AnalyticsByClient>>;

	readonly Day = Day;
	readonly SortDirection = SortDirection;

	readonly periodType = new FormControl<string>(this.cu.periodTypes[2].value, { nonNullable: true });
	readonly range = new FormControl();

	readonly tHeaders: TableEntityField[];
	tRows: AnalyticsByClient[] = [];

	sorting: TableSortIndicator;
	readonly loading$ = new BehaviorSubject<boolean>(true);

	templates!: TableCellTemplates<AnalyticsByClient>;

	private readParams: RPCRequestParams = {};
	private readonly ngUnsubscribe: Subject<void> = new Subject<void>();


	constructor(
		public readonly sharedTemplatesService: SharedTemplatesService,
		public readonly dataService: AnalyticsByClientService,
		public readonly fieldsService: AnalyticsByClientFieldsService,
		public readonly cu: ChartUtilsService,
		public readonly bs: AlarisBalanceService
	) {
		this.tHeaders = LocalTableUtils.toTableFields(this.fieldsService.headers);
		this.sorting = this.tHeaders.reduce(
			(result, header) => {
				result.set(header.variable, { enabled: true, value: SortDirection.EMPTY });
				return result;
			}, new Map() as TableSortIndicator);
	}

	ngOnInit(): void {
		this.range.valueChanges
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((range) => {
				if ( range ) {
					this.readParams = {
						...this.readParams,
						Grouping: {
							CubeType: this.cu.periodCubeTypeReducer(this.periodType.value, false),
							Groups: [], isDetailed: false, isTotal: false
						},
						Filters: [{ Field: 'groupingDate', Type: FilterType.BETWEEN, Value: range }]
					};
					this.load();
				}
			});
	}

	ngAfterViewInit(): void {
		this.templates = this.tHeaders.reduce((result, header) => {
			if ( header.variable === 'clientName' ) {
				Object.assign(result, { [header.variable]: this.clientNameRef });
			} else if ( header.variable === 'totalMessage' ) {
				Object.assign(result, { [header.variable]: this.totalMessageRef });
			} else {
				Object.assign(result, { [header.variable]: this.generalRef });
			}
			return result;
		}, {});
	}

	load(): void {
		this.loading$.next(true);
		this.dataService.read(this.readParams)
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((resp) => {
				this.tRows = resp.Data;
				this.loading$.next(false);
			});
	}

	applySorting($event: TableSortIndicator): void {
		this.loading$.next(true);
		$event.forEach(
			(sort, variable) => {
				if ( sort.enabled ) {
					const tRows = [...this.tRows];
					this.tRows = tRows.sort(
						(a, b) => {
							// 'clientName' | 'revenue' | 'cost' | 'margin' | 'balance' | 'totalMessage'
							let first;
							let second;
							switch (variable) {
								case 'clientName':
								case 'revenue':
								case 'cost':
								case 'margin':
								case 'balance':
								case 'totalMessage':
									first = a[variable];
									second = b[variable];
									break;
								default:
									// @ts-expect-error: AnalyticsByClient is a Record after all
									first = a[variable];
									// @ts-expect-error: AnalyticsByClient is a Record after all
									second = b[variable];
									break;

							}

							return LocalTableUtils.sortComparisonFn(sort.value ?? SortDirection.EMPTY, first, second);
						}
					);
				}
			}
		);
		this.loading$.next(false);
	}

	calculateViewPort(): string {
		return this.tRows.length > 10 ? '33.125rem' : (this.tRows.length * 3 + 3.125) + 'rem';
	}

	ngOnDestroy(): void {
		this.loading$.complete();
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}
}
