import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ConnectedPosition } from '@angular/cdk/overlay';
import { Router } from '@angular/router';

import { ContractCompany } from '@campaign-portal/namespace/entities/contract-company/specs';
import { exist } from '@campaign-portal/namespace/common/id';

import { AlarisBalanceService, AlarisTableStateService, RowActionSimple } from '@campaign-portal/components-library';
import { FilterType } from '@campaign-portal/namespace/common/rpc.params';

@Component({
	selector: 'app-contract-company-card',
	templateUrl: './contract-company-card.component.html',
	styleUrls: ['./contract-company-card.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContractCompanyCardComponent {

	@Input() company!: ContractCompany<exist>;
	@Input() actions: RowActionSimple<ContractCompany<exist>>[] = [];

	readonly position: ConnectedPosition[] = [
		{
			originX: 'end',
			originY: 'bottom',
			overlayX: 'end',
			overlayY: 'top',
			offsetY: 0,
			offsetX: 0
		}
	];

	constructor(
		public readonly bs: AlarisBalanceService,
		private readonly router: Router,
		private readonly tableState: AlarisTableStateService
	) {
	}

	navigate(): void {
		this.tableState.store.set('partners', {
			filters: new Map().set('ccId', {
				enabled: true,
				value: [this.company],
				filterType: FilterType.IN,
				property: 'id'
			}),
			isFiltersApplied: true,
			readParams: {
				Filters: [{
					Field: 'ccId',
					Type: FilterType.IN,
					Value: [this.company],
					Property: 'id'
				}]
			},
			sorting: new Map()
		});
		this.router.navigateByUrl('partners');
	}
}
