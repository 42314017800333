import { ChangeDetectionStrategy, Component, DestroyRef, inject, Inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import {
	AlarisEditPanelService,
	AlarisFilesService,
	AlarisLanguageService,
	AlarisProfileService,
	ChannelUtilsService,
	EditPanelInputData,
	PROFILE_SERVICE_INJECTOR,
	RowActionSimple
} from '@campaign-portal/components-library';

import {
	SenderSubscriptionEvent,
	SenderSubscriptionHistory
} from '@campaign-portal/namespace/entities/sender-id/specs';
import { FileInfo } from '@campaign-portal/namespace/common/fileInfo';
import { SenderSubscriptionEventStatus } from '@campaign-portal/namespace/common/enums';
import { exist } from '@campaign-portal/namespace/common/id';

import { EventsService } from '../events.service';
import { EventsFieldsService } from '../events.fields.service';

@Component({
	selector: 'app-events-details',
	templateUrl: './events-details.component.html',
	styleUrls: ['./events-details.component.scss'],
	providers: [EventsFieldsService],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class EventsDetailsComponent {

	readonly event: SenderSubscriptionEvent<exist>;
	readonly actions: RowActionSimple<SenderSubscriptionEvent<exist>>[] = [];
	readonly selectedAction: FormControl;
	readonly tRows: SenderSubscriptionHistory[];

	private readonly destroyRef = inject(DestroyRef);

	constructor(
		@Inject(PROFILE_SERVICE_INJECTOR) public readonly profile: AlarisProfileService,
		public readonly eventsService: EventsService,
		public readonly eventFieldsService: EventsFieldsService,
		public readonly cu: ChannelUtilsService,
		@Inject(EditPanelInputData) private readonly inputData: EditPanelInputData,
		private readonly editPanel: AlarisEditPanelService,
		private readonly filesService: AlarisFilesService,
		private readonly lService: AlarisLanguageService
	) {
		this.event = this.inputData.event as SenderSubscriptionEvent<exist>;
		this.actions = this.inputData.actions as RowActionSimple<SenderSubscriptionEvent<exist>>[];
		this.selectedAction = new FormControl({
			disabled: !(this.event.status === SenderSubscriptionEventStatus.NEW ||
				this.event.status === SenderSubscriptionEventStatus.IN_PROGRESS),
			value: null
		});
		this.tRows = [
			{ status: this.event.status, lastUpdated: this.event.lastUpdated, type: this.event.type },
			...this.event.history
		];
	}

	displayLabel = (action: RowActionSimple<SenderSubscriptionEvent<exist>> | null): string => {
		const label = action?.label ?? ('enums.' + this.event.status);
		return this.lService.translate(label);
	};

	close(): void {
		this.editPanel.close();
	}

	upload(data: FileInfo | FileInfo[]): void {
		const files = Array.isArray(data) ? data.map(item => item.id) : [data.id];
		const fileName = Array.isArray(data) ? '' : data.name;
		this.filesService.export(files, fileName)
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe();
	}

	save(): void {
		this.editPanel.close();
		this.selectedAction.value.action(this.event);
	}
}
