import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';

import {
	AlarisEditPanelService,
	CustomValidators,
	EditPanelInputData,
	filterWildcardData
} from '@campaign-portal/components-library';

import { exist, Id } from '@campaign-portal/namespace/common/id';
import { Payments } from '@campaign-portal/namespace/entities/payments/specs';
import { Partner } from '@campaign-portal/namespace/entities/partners/specs';

import { CanDeactivateComponent } from '@helpers/can-deactivate/can-deactivate.component';
import { CanDeactivateGuardService } from '@helpers/can-deactivate/can-deactivate.guard';
import { PartnersService } from '../../../partners/partners.service';
import { PaymentsService } from '../payments.service';

@Component({
	selector: 'app-add-payment',
	templateUrl: './add-payment.component.html',
	styleUrls: ['./add-payment.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddPaymentComponent extends CanDeactivateComponent implements OnInit, OnDestroy {
	readonly payment!: Payments;
	readonly paymentForm!: FormGroup;

	readonly filterPartnersControl = new FormControl('');
	filterPartnersList: Partner<exist>[] = [];

	readonly errors = [{
		key: 'zeroNotAllowed',
		value: 'errors.zeroNotAllowed'
	}];

	readonly allowedDeactivation = new BehaviorSubject<boolean>(true);

	protected readonly ngUnsubscribe: Subject<void> = new Subject<void>();

	constructor(
		@Inject(EditPanelInputData) private readonly inputData: EditPanelInputData,
		private readonly editPanel: AlarisEditPanelService,
		public readonly partnerService: PartnersService,
		public readonly paymentsService: PaymentsService,
		private readonly cd: ChangeDetectorRef,
		private readonly guard: CanDeactivateGuardService
	) {
		super();
		this.addEditPanelGuard(editPanel, this.guard);
		this.payment = this.inputData.payment as Payments;
		this.paymentForm = new FormGroup({
			amount: new FormControl<number | null>(null, [
				Validators.required, CustomValidators.zeroNotAllowed
			]),
			partnerId: new FormControl<Id>(this.payment.partnerId, Validators.required),
			comment: new FormControl<string>(this.payment.comment || '')
		});
	}

	ngOnInit(): void {

		this.partnerService.list$
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((list) => {
				this.filterPartnersList = filterWildcardData(this.filterPartnersControl.value, list, 'name');
				this.cd.detectChanges();
			});

		this.filterPartnersControl.valueChanges
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((value) => {
				this.filterPartnersList = filterWildcardData(value, this.partnerService.list, 'name');
			});
	}

	close(): void {
		this.editPanel.close(true);
	}

	save(): void {
		this.allowedDeactivation.next(false);
		this.paymentsService.update({
			...this.payment,
			...this.paymentForm.value,
			amount: parseFloat(this.paymentForm.get('amount')?.value)
		})
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((resp) => {
				this.allowedDeactivation.next(true);
				if ( resp.Success ) {
					this.close();
				}
			});
	}

	displaySelected = (id: Id<exist>): string => {
		return this.partnerService.list.find(i => i.id === id)?.name ?? '';
	};

	ngOnDestroy(): void {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}
}
