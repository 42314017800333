import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { BehaviorSubject, Observable, of, Subject, takeUntil } from 'rxjs';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';

import { exist } from '@campaign-portal/namespace/common/id';
import { RPCResult } from '@campaign-portal/namespace/common/rpc.response';
import { Endpoint } from '@campaign-portal/namespace/entities/endpoints/specs';
import { Rate } from '@campaign-portal/namespace/entities/rates/specs';
import { Subscription } from '@campaign-portal/namespace/entities/subscriptions/specs';

import { EndpointsService } from '../endpoints/endpoints.service';
import { RatesService } from '@helpers/services/rates.service';
import { CanDeactivateComponent } from '@helpers/can-deactivate/can-deactivate.component';
import { CanDeactivateGuardService } from '@helpers/can-deactivate/can-deactivate.guard';
import { AlarisDialogService, Day } from '@campaign-portal/components-library';
import { VendorSubscriptionsService } from '@helpers/services/vendor-subscriptions.service';

export type VendorRatesDialogType = 'Delete' | 'Price' | 'Close' | 'DeletePeriod' | 'DeleteProduct';

export interface VendorRatesDialogData {
	type: VendorRatesDialogType;
	endpoint?: Endpoint<exist>;
	rates?: Rate<exist>[];
	product?: Subscription<exist>;
}

@Component({
	selector: 'app-vendors-dialog',
	templateUrl: './vendors-dialog.component.html',
	styleUrls: ['./vendors-dialog.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class VendorsDialogComponent extends CanDeactivateComponent implements OnDestroy {
	readonly Day = Day;
	readonly endpoint!: Endpoint<exist>;
	readonly rates!: Rate<exist>[] | null;
	readonly product!: Subscription<exist>;

	readonly dialogControl = new FormControl(null, Validators.required);
	readonly loading$ = new BehaviorSubject<boolean>(false);
	readonly allowedDeactivation = new BehaviorSubject<boolean>(true);
	protected readonly ngUnsubscribe = new Subject<void>();

	constructor(
		@Inject(DIALOG_DATA) public readonly data: VendorRatesDialogData,
		private readonly dialogRef: DialogRef<unknown>,
		private readonly cd: ChangeDetectorRef,
		private readonly endpointsService: EndpointsService,
		private readonly ratesService: RatesService,
		private readonly vsService: VendorSubscriptionsService,
		private readonly guard: CanDeactivateGuardService,
		private readonly dialog: AlarisDialogService
	) {
		super();
		this.addDialogGuard(this.dialog, dialogRef, this.guard);
		this.endpoint = data.endpoint as Endpoint<exist>;
		this.rates = data.rates as Rate<exist>[] | null;
		this.product = data.product as Subscription<exist>;
	}

	get disabled(): boolean {
		switch (this.data.type) {
			case 'Delete':
			case 'DeletePeriod':
			case 'DeleteProduct':
				return this.loading$.getValue();
			default:
				return !this.dialogControl.value;
		}
	}

	get getConfirmText(): string {
		switch (this.data.type) {
			case 'DeletePeriod':
				return 'actions.delete';
			default:
				return 'actions.confirm';
		}
	}

	close(): void {
		this.dialogRef.close(false);
	}

	confirm(): void {
		this.loading$.next(true);
		let caller: Observable<RPCResult<unknown>> = of({ Success: false });

		switch (this.data.type) {
			case 'Delete':
				caller = this.endpointsService.delete(this.endpoint.id);
				break;
			case 'Price':
				caller = this.ratesService.bulkPriceUpdate(this.rates, this.dialogControl.value ?? 0);
				break;
			case 'Close':
				caller = this.ratesService.bulkClose(this.rates, this.dialogControl.value ?? '');
				break;
			case 'DeletePeriod':
				if ( this.rates && this.rates[0].id ) {
					caller = this.ratesService.delete(this.rates[0].id);
				}
				break;
			case 'DeleteProduct':
				caller = this.vsService.delete(this.product.id);
				break;
			default:
				break;

		}

		this.allowedDeactivation.next(false);
		caller.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
			(resp) => {
				this.allowedDeactivation.next(true);
				this.loading$.next(false);
				this.dialogRef.close(resp.Success);
				this.cd.markForCheck();
			}
		);
	}

	ngOnDestroy(): void {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
		this.loading$.complete();
	}

}
