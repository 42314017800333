<div class="edit-panel-header">
	<alaris-icon name="icon-export"></alaris-icon>
	{{'rates.ratesExport' | translate}}
</div>
<div alarisFade="vertical" class="edit-panel-content form-group">
	<div class="note">
		<alaris-icon name="icon-info"></alaris-icon>
		{{total}} {{'rates.ratesWillBeExported' | translate}}
	</div>
	<b class="section-title">{{'statistics.whatColumnExport' | translate}}</b>
	<div [formGroup]="fakeGroup" class="columns-settings">
		<div class="header">
			<alaris-checkbox (change)="$event ? toggleAllRows() : null"
							 [checked]="selection.hasValue() && isAllSelected()"
							 [formControl]="fakeControl"
							 [indeterminate]="selection.hasValue() && !isAllSelected()"
							 data-testid="selectAllCheckbox">
				{{'gl.selectAll' | translate}}
			</alaris-checkbox>
			<span class="counter">
					{{'gl.selected' | translate}}: {{selection.selected.length + '/' + tHeads.length}}
				</span>
		</div>
		<div class="content">
			<ol *ngFor="let field of tHeads">
				<alaris-checkbox (change)="$event ? selection.toggle(field) : null"
								 (click)="$event.stopPropagation()"
								 [attr.data-testid]="'checkbox/' + field.id"
								 [checked]="selection.isSelected(field)"
								 [formControlName]="field.variable">
					{{field.name | translate}}
				</alaris-checkbox>
			</ol>
		</div>
	</div>
</div>
<div class="edit-panel-footer">
	<button (click)="export()"
			[disabled]="selection.isEmpty()"
			alaris-button bType="primary"
			data-testid="exportBtn"
			size="lg" type="button">
		<alaris-icon class="in-primary" name="icon-success"></alaris-icon>
		{{'actions.export'  | translate}}
	</button>
	<button (click)="close()" alaris-button bType="outline" data-testid="closeBtn" size="lg" type="button">
		{{ 'actions.cancel' | translate }}
	</button>
</div>
