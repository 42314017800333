<alaris-tabs #sendersTabs (tabChanged)="cd.detectChanges()" [underline]="false" id="sendersTabs">
	<ng-container *alarisPBAC="[AP_PERMISSIONS.SENDERS_R]">
		<alaris-tab [label]="'tabs.senderList' | translate" tabId="senderIdList">
			<app-senders-list></app-senders-list>
		</alaris-tab>
		<alaris-tab [label]="'tabs.senderSubscriptions' | translate" tabId="senderIdSubscriptions">
			@if (sendersTabs.activeTab?.tabId === 'senderIdSubscriptions') {
				<app-senders-subscriptions></app-senders-subscriptions>
			}
		</alaris-tab>
	</ng-container>
	<alaris-tab *alarisPBAC="[AP_PERMISSIONS.SENDERS_E]"
				[badge]="(events$ | async)?.length" [label]="'tabs.events' | translate" tabId="events">
		@if (sendersTabs.activeTab?.tabId === 'events') {
			<app-events></app-events>
		}
	</alaris-tab>
</alaris-tabs>

