import { ChangeDetectionStrategy, Component, computed, Inject, OnDestroy, OnInit, signal } from '@angular/core';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { BehaviorSubject, finalize, Observable, of, Subject, takeUntil } from 'rxjs';
import { Router } from '@angular/router';

import { exist } from '@campaign-portal/namespace/common/id';
import { SubscriptionGroup } from '@campaign-portal/namespace/entities/subscription-groups/specs';
import { FilterType, RPCRequestParams } from '@campaign-portal/namespace/common/rpc.params';
import { RPCResult } from '@campaign-portal/namespace/common/rpc.response';
import { Endpoint } from '@campaign-portal/namespace/entities/endpoints/specs';
import { AlarisDialogService } from '@campaign-portal/components-library';

import { SubscriptionGroupsService } from '../subscription-groups.service';
import { EndpointsService } from '../../../vendors/endpoints/endpoints.service';
import { CanDeactivateComponent } from '@helpers/can-deactivate/can-deactivate.component';
import { CanDeactivateGuardService } from '@helpers/can-deactivate/can-deactivate.guard';

export type SubscriptionGroupDialogType = 'Delete' | 'GoToSubscription';

export interface SubscriptionGroupsDialogData {
	group: SubscriptionGroup;
	type: SubscriptionGroupDialogType;
}

@Component({
	selector: 'app-subscription-group-dialog',
	templateUrl: './subscription-group-dialog.component.html',
	styleUrl: './subscription-group-dialog.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubscriptionGroupDialogComponent extends CanDeactivateComponent implements OnInit, OnDestroy {
	readonly linkedEndpoints = signal<Endpoint<exist>[]>([]);
	readonly hasLinkedEndpoints = computed<boolean>(() => !!this.linkedEndpoints().length);

	readonly group: SubscriptionGroup;

	readonly loading$ = new BehaviorSubject<boolean>(false);
	readonly allowedDeactivation = new BehaviorSubject<boolean>(true);
	readonly ngUnsubscribe = new Subject<void>();

	constructor(
		@Inject(DIALOG_DATA) public readonly data: SubscriptionGroupsDialogData,
		private readonly subGroupsService: SubscriptionGroupsService,
		private readonly endpointsService: EndpointsService,
		private readonly router: Router,
		private readonly dialogRef: DialogRef,
		private readonly guard: CanDeactivateGuardService,
		private readonly dialog: AlarisDialogService
	) {
		super();
		this.addDialogGuard(this.dialog, dialogRef, this.guard);
		this.group = data.group;
	}

	get linkedEndpointNames(): string {
		return this.linkedEndpoints().map(endpoint => endpoint.name).join(', ');
	}

	get confirmButtonIcon(): string {
		switch (this.data.type) {
			case 'Delete':
				return this.hasLinkedEndpoints() ? 'icon-arrow-right' : '';
			default:
				return '';
		}
	}

	get confirmButtonText(): string {
		switch (this.data.type) {
			case 'Delete':
				return this.hasLinkedEndpoints() ? 'subscriptions.groups.goToVendors' : 'actions.confirm';
			case 'GoToSubscription':
				return 'gl.saveAndGo';
			default:
				return '';
		}
	}

	get closeButtonText(): string {
		switch (this.data.type) {
			case 'GoToSubscription':
				return 'gl.goWithoutSaving';
			default:
				return 'actions.cancel';
		}
	}

	ngOnInit(): void {
		if ( this.data.type === 'Delete' ) {
			this.checkLinkedEndpoints();
		}
	}

	close(): void {
		this.dialogRef.close(false);
	}

	closeAction(): void {
		switch (this.data.type) {
			case 'GoToSubscription':
				this.dialogRef.close(true);
				break;
			default:
				this.close();
		}
	}

	confirm(): void {
		if ( this.data.type === 'Delete' && this.hasLinkedEndpoints() ) {
			this.router.navigate(['rates'], {
				queryParams: { vendorsTabs: 'endpoints' }
			}).then();
			this.close();
			return;
		}

		this.loading$.next(true);
		let caller: Observable<RPCResult<unknown>> = of({ Success: false });

		switch (this.data.type) {
			case 'Delete':
				if ( this.group.id ) {
					caller = this.subGroupsService.delete(this.group.id);
				}
				break;
			case 'GoToSubscription':
				caller = this.subGroupsService.update(this.group);
				break;
			default:
				break;
		}

		this.allowedDeactivation.next(false);
		caller.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
			(resp) => {
				this.allowedDeactivation.next(true);
				this.loading$.next(false);
				this.dialogRef.close(resp.Success);
			}
		);
	}

	ngOnDestroy(): void {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}

	private checkLinkedEndpoints(): void {
		const params: RPCRequestParams = {
			Filters: [{
				Field: 'groups',
				Type: FilterType.IN,
				Property: 'id',
				Value: [this.group]
			}]
		};

		this.loading$.next(true);
		this.endpointsService.read(params)
			.pipe(
				finalize(() => this.loading$.next(false)),
				takeUntil(this.ngUnsubscribe)
			)
			.subscribe(resp => {
				this.linkedEndpoints.set(resp.Data);
			});
	}
}
