<div [class]="type" class="note">
	<div>{{ note | translate }}</div>
	<div>{{ subNote | translate }}</div>
</div>
@if (buttonText) {
	<button (click)="clicked()" alarisButton
			[attr.data-testid]="buttonText + 'Btn'" bType="secondary" size="md" type="button">
		<alaris-icon [name]="buttonIcon"></alaris-icon>
		{{ buttonText | translate }}
	</button>
}
