import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { merge } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { SubscriptionGroup } from '@campaign-portal/namespace/entities/subscription-groups/specs';
import { exist } from '@campaign-portal/namespace/common/id';
import {
	AlarisDialogService,
	AlarisEditPanelService,
	AlarisProfileService,
	EditPanelWidth,
	filterWildcardData,
	RowActionSimple
} from '@campaign-portal/components-library';

import { SubscriptionGroupsService } from './subscription-groups.service';
import { EditSubscriptionGroupComponent } from './edit-subscription-group/edit-subscription-group.component';
import {
	SubscriptionGroupDialogComponent,
	SubscriptionGroupDialogType
} from './subscription-group-dialog/subscription-group-dialog.component';
import { SubscriptionGroupEntity } from '@helpers/types/app.classes-interfaces';
import { AP_PERMISSIONS } from '@helpers/types/permissions';

@Component({
	selector: 'app-subscription-groups',
	templateUrl: './subscription-groups.component.html',
	styleUrls: [
		// eslint-disable-next-line max-len
		'../../../../../node_modules/@campaign-portal/components-library/src/assets/templates/table-complex/complex-table.component.scss',
		'./subscription-groups.component.scss'
	],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubscriptionGroupsComponent implements OnInit {

	groups: SubscriptionGroup<exist>[] = [];
	readonly searchByNameControl = new FormControl<string | null>('');
	readonly actions: RowActionSimple<SubscriptionGroup<exist>>[] = this.getActions();

	readonly AP_PERMISSIONS = AP_PERMISSIONS;
	readonly destroyRef = inject(DestroyRef);

	constructor(
		readonly subGroupsService: SubscriptionGroupsService,
		private readonly editPanel: AlarisEditPanelService,
		private readonly profile: AlarisProfileService,
		private readonly dialog: AlarisDialogService,
		private readonly cd: ChangeDetectorRef
	) {
	}

	get hasFilter(): boolean {
		return Boolean(this.searchByNameControl.value);
	}

	ngOnInit(): void {
		this.subGroupsService.refresh$.next();

		merge(
			this.searchByNameControl.valueChanges,
			this.subGroupsService.list$
		)
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe(() => {
				const name = this.searchByNameControl.value;
				this.groups = filterWildcardData(name, this.subGroupsService.list, 'name');
				this.cd.detectChanges();
			});
	}

	edit(group?: SubscriptionGroup<exist>): void {
		const entity = group ?? new SubscriptionGroupEntity(null);
		this.editPanel.open(EditSubscriptionGroupComponent, EditPanelWidth.SM, {
			group: entity
		});
	}

	delete(group?: SubscriptionGroup<exist>): void {
		if ( group ) {
			this.openSpecificDialog('Delete', group);
		}
	}

	private openSpecificDialog(
		type: SubscriptionGroupDialogType,
		group: SubscriptionGroup<exist>
	): void {
		this.dialog.open(SubscriptionGroupDialogComponent, {
			data: { group, type },
			autoFocus: false
		});
	}

	private getActions(): RowActionSimple<SubscriptionGroup<exist>>[] {
		const edit: RowActionSimple<SubscriptionGroup<exist>> = {
			icon: '',
			label: 'actions.edit',
			action: this.edit.bind(this),
			highlight: false
		};
		const details: RowActionSimple<SubscriptionGroup<exist>> = {
			...edit,
			label: 'actions.details'
		};
		const _delete: RowActionSimple<SubscriptionGroup<exist>> = {
			icon: '',
			label: 'actions.delete',
			action: this.delete.bind(this),
			highlight: true
		};
		return this.profile.allowed([AP_PERMISSIONS.SUBSCR_E])
			? [edit, _delete]
			: [details];
	}
}
