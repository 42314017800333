<alaris-dialog [closeAction]="close.bind(this)"
			   [confirmAction]="confirm.bind(this)"
			   [loading$]="loading$"
			   [disabled]="disabled">
	<h3 slot="header" [ngSwitch]="data.type">
		<ng-container *ngSwitchCase="'Delete'">{{ "mccmnc.deleteEntry" | translate }}</ng-container>
	</h3>
	<div slot="content" class="content">
		<div [ngSwitch]="data.type">
			<ng-container *ngSwitchCase="'Delete'">
				<p>{{ 'mccmnc.deleteEntryNote' | translate }}</p>
			</ng-container>
		</div>
	</div>
</alaris-dialog>
