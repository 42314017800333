import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { ControlValueAccessorConnector, DataTestId } from '@campaign-portal/components-library';

@Component({
	selector: 'app-color-select',
	templateUrl: './color-select.component.html',
	styleUrls: ['./color-select.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: ColorSelectComponent,
			multi: true
		},
		DataTestId
	]
})
export class ColorSelectComponent extends ControlValueAccessorConnector {
	@Input() colors: string[] = [];

	readonly prefix = Math.random().toString(16).split('.')[1];

	constructor() {
		super();
	}
}
