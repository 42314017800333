import { Injectable } from '@angular/core';

import {
	AlarisTableStateService,
	filterWildcardFn,
	LocalTableService,
	LocalTableUtils
} from '@campaign-portal/components-library';

import { SenderSubscriptionEvent } from '@campaign-portal/namespace/entities/sender-id/specs';
import { exist } from '@campaign-portal/namespace/common/id';
import { RPCRequestParams } from '@campaign-portal/namespace/common/rpc.params';
import { ReadResponse } from '@campaign-portal/namespace/common/implementations';
import { IdObject } from '@campaign-portal/namespace/common/idObject';
import { Channel } from '@campaign-portal/namespace/entities/channels/specs';

import { EventsService } from './events.service';
import { PartnersService } from '../../partners/partners.service';

@Injectable()
export class EventsTableService extends LocalTableService<SenderSubscriptionEvent<exist>> {

	readonly cancel = this.service.cancel.bind(this.service);
	readonly inProgress = this.service.inProgress.bind(this.service);
	readonly getStatusColor = this.service.getStatusColor.bind(this.service);

	constructor(
		public override readonly service: EventsService,
		private readonly partnersService: PartnersService,
		stateService: AlarisTableStateService
	) {
		super(service, stateService);
	}

	applyFilters(
		result: ReadResponse<SenderSubscriptionEvent<exist>[]>,
		params: RPCRequestParams
	): ReadResponse<SenderSubscriptionEvent<exist>[]> {
		const filters = params.Filters;
		if ( filters === undefined ) {
			return result;
		} else {
			let data = result.Data;
			filters.forEach((filter) => {
				switch (filter.Field) {
					case 'name':
						data = data.filter(event => filterWildcardFn(event.sender.name, filter.Value as string));
						break;
					case 'status':
						if ( filter.Value ) {
							data = LocalTableUtils.filterInValueObjects(filter, data);
						}
						break;
					case 'trafficType':
						if ( filter.Value ) {
							const channels = (filter.Value as Channel[]).map(ch => ch.channelType);
							data = data
								.filter(event => channels.includes(event.sender.trafficType));
						}
						break;
					case 'lastUpdated':
						data = LocalTableUtils
							.filterDateRange(filter, data as Record<string, string>[]) as SenderSubscriptionEvent<exist>[];
						break;
					case 'documents':
						data = data.filter((event) => {
							return filter.Value
								? event.sender.documents && event.sender.documents.length > 0
								: !event.sender.documents || event.sender.documents.length === 0;
						});
						break;
					case 'partner':
						if ( filter.Value ) {
							const ids = (filter.Value as IdObject<exist>[]).map(item => item.id);
							data = data.filter(item => ids.includes(item.sender.partner ?? 0));
						}
						break;
					case 'sender':
						if ( filter.Value ) {
							data = data.filter((event) => {
								return filter.Type === 'EXACT'
									? event.sender.partner === filter.Value
									: event.sender.partner !== filter.Value;
							});
						}
						break;
					default:
						break;
				}
			});
			return { Success: true, Total: data.length, Data: data };
		}
	}

	applySorting(
		result: ReadResponse<SenderSubscriptionEvent<exist>[]>,
		params: RPCRequestParams
	): ReadResponse<SenderSubscriptionEvent<exist>[]> {
		const sorting = params.Sorting?.reverse();
		if ( !sorting ) {
			return result;
		} else {
			let data = result.Data;
			sorting.forEach((sort) => {
				data = data.sort((a, b) => {
					let first: any;
					let second: any;
					switch (sort.Field) {
						case 'name':
							first = a.sender.name;
							second = b.sender.name;
							break;
						case 'status':
							first = a.status;
							second = b.status;
							break;
						case 'trafficType':
							first = a.sender.trafficType;
							second = b.sender.trafficType;
							break;
						case 'lastUpdated':
							first = a.lastUpdated ? new Date(a.lastUpdated) : a.lastUpdated;
							second = b.lastUpdated ? new Date(b.lastUpdated) : b.lastUpdated;
							break;
						case 'partner':
							first = this.partnersService.map.get(a.sender.partner)?.name ?? '';
							second = this.partnersService.map.get(b.sender.partner)?.name ?? '';
							break;
						case 'documents':
							first = a.sender.documents !== undefined && a.sender.documents.length > 0 ? 1 : 0;
							second = b.sender.documents !== undefined && b.sender.documents.length > 0 ? 1 : 0;
							break;
						default:
							break;
					}

					return LocalTableUtils.sortComparisonFn(sort.Dir, first, second);
				});
			});
			return { Data: data, Total: result.Total, Success: true };
		}
	}
}
