import { ChangeDetectionStrategy, Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { exist, Id } from '@campaign-portal/namespace/common/id';
import { RateHistoryEntity } from '@campaign-portal/namespace/entities/rates/specs';
import { RateStatus } from '@campaign-portal/namespace/common/enums';

import { AlarisProfileService, CustomValidators, Day, RowActionSimple } from '@campaign-portal/components-library';

import { RateHistory } from '@helpers/types/app.classes-interfaces';
import { AP_PERMISSIONS } from '@helpers/types/permissions';
import { ConnectedPosition } from '@angular/cdk/overlay';
import { EditRateService } from '../edit-rate.service';

export enum CardState {
	DEFAULT = 'DEFAULT',
	EDIT = 'EDIT',
	CREATE = 'CREATE'
}

@Component({
	selector: 'app-edit-rate-card',
	templateUrl: './edit-rate-card.component.html',
	styleUrls: ['./edit-rate-card.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditRateCardComponent implements OnInit {
	@Input() rHistory: RateHistoryEntity<Id> = new RateHistory(null);
	@Input() currency!: string;
	@Input() currentPrice!: number;
	@Input() state = CardState.DEFAULT;
	@Output() readonly updateRate = new EventEmitter<RateHistoryEntity<Id>>();
	@Output() readonly deleteRate = new EventEmitter<RateHistoryEntity<exist>>();
	readonly RateStatus = RateStatus;
	readonly AP_PERMISSIONS = AP_PERMISSIONS;
	readonly CardState = CardState;
	rHistoryForm!: FormGroup;
	readonly Day = Day;
	readonly position: ConnectedPosition[] = [
		{
			originX: 'end',
			originY: 'bottom',
			overlayX: 'end',
			overlayY: 'top',
			offsetY: 0,
			offsetX: 0
		}
	];

	readonly actions: RowActionSimple<RateHistoryEntity<Id>>[] = [
		{
			icon: '',
			label: 'actions.edit',
			action: this.edit.bind(this)
		},
		{
			icon: '',
			label: 'actions.delete',
			action: this.delete.bind(this),
			highlight: true,
			separator: true,
			disabled: (rH: RateHistoryEntity<exist>): boolean => rH.type !== RateStatus.PLANNED
		}
	];

	readonly errors = [
		{ key: 'moreThan', value: 'errors.moreThan' },
		{ key: 'pattern', value: 'errors.decimal' }

	];

	constructor(
		public readonly mode: EditRateService,
		private readonly profile: AlarisProfileService
	) {
	}

	@HostListener('dblclick') setCreateState(): void {
		if ( this.rHistory.type !== RateStatus.CLOSED
			&& this.profile.allowed([AP_PERMISSIONS.V_SUBSCR_E])
		) {
			this.edit();
		}
	}

	ngOnInit(): void {
		this.rHistoryForm = new FormGroup({
			price: new FormControl(
				this.rHistory.price === 0 ? '' : this.rHistory.price,
				[Validators.required, Validators.pattern(/^\d*(\.\d{0,5})?$/)]
			),
			activeFrom: new FormControl(
				{
					value: this.rHistory.activeFrom,
					disabled: this.rHistory.type === RateStatus.CURRENT
				},
				Validators.required
			),
			activeTill: new FormControl(this.rHistory.activeTill),
			closeRate: new FormControl(false)
		}, [CustomValidators.moreThan('activeFrom', 'activeTill')]);
	}

	save(): CardState {
		const activeFrom = this.rHistoryForm.controls.activeFrom.value === Day.fromISONativeDate(this.rHistory.activeFrom)?.toISONativeDate() ?
			this.rHistory.activeFrom : this.rHistoryForm.controls.activeFrom.value;
		const activeTill = this.rHistoryForm.controls.activeTill.value === Day.fromISONativeDate(this.rHistory.activeTill)?.toISONativeDate() ?
			this.rHistory.activeTill : this.rHistoryForm.controls.activeTill.value;
		this.updateRate.emit({
			...this.rHistoryForm.value,
			activeFrom,
			activeTill,
			price: parseFloat(this.rHistoryForm.get('price')?.value),
			id: this.rHistory.id
		});
		this.mode.editMode.set(false);
		return this.state = CardState.DEFAULT;
	}

	delete(): CardState {
		if ( this.rHistory.id ) {
			this.deleteRate.emit(this.rHistory as RateHistoryEntity<exist>);
		}
		this.mode.editMode.set(false);
		return this.state = CardState.DEFAULT;
	}

	close(): CardState {
		this.resetForm();
		this.mode.editMode.set(false);
		return this.state = CardState.DEFAULT;
	}

	edit(): void {
		this.mode.editMode.set(true);
		this.state = CardState.EDIT;
	}


	closeAddPeriodMode(): void {
		this.mode.addMode.set(false);
	}

	private resetForm(): void {
		this.rHistoryForm.setValue({
			price: this.rHistory.price,
			activeFrom: this.rHistory.activeFrom,
			activeTill: this.rHistory.activeTill ?? null,
			closeRate: false
		});
		this.rHistoryForm.markAsPristine();
	}
}
