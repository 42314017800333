import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output
} from '@angular/core';

import {
	AlarisBalanceService,
	AlarisComplexTableComponent,
	AlarisEditPanelService,
	AlarisTableSettingsService,
	TableFiltersIndicator
} from '@campaign-portal/components-library';

import { Rate } from '@campaign-portal/namespace/entities/rates/specs';
import { exist, Id } from '@campaign-portal/namespace/common/id';
import { TableSettingsEntries, TableTypes } from '@campaign-portal/namespace/entities/table-settings/specs';
import { RateStatus } from '@campaign-portal/namespace/common/enums';
import { FilterType } from '@campaign-portal/namespace/common/rpc.params';

import { CurrencyService } from '@helpers/services/currency.service';
import { RatesFieldsService } from '@helpers/services/rates.fields.service';
import { SubscriptionRatesService } from './rates.service';


@Component({
	selector: 'app-rates-table',
	templateUrl: './rates-table.component.html',
	styleUrls: [
		// eslint-disable-next-line max-len
		'../../../../../../../node_modules/@campaign-portal/components-library/src/assets/templates/table-complex/complex-table.component.scss',
		'./rates-table.component.scss'
	],
	providers: [SubscriptionRatesService],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class RatesTableComponent extends AlarisComplexTableComponent<Rate<exist>> implements OnInit {
	@Input() subscriptionId!: Id<exist>;
	@Input() withImport = true;
	@Output() readonly importRequest = new EventEmitter();
	@Output() readonly refreshRequest = new EventEmitter();

	readonly RateStatus = RateStatus;


	readonly tSettingsModified: TableSettingsEntries = [
		['country', { visible: true }],
		['mcc', { visible: true }],
		['network', { visible: true }],
		['mnc', { visible: true }],
		['channel', { visible: false }],
		['currencyId', { visible: false }],
		['subscriptionId', { visible: false }]
	];

	constructor(
		public readonly entityService: SubscriptionRatesService,
		public override readonly fieldsService: RatesFieldsService,
		public override readonly tableSettingsService: AlarisTableSettingsService,
		public override readonly editPanel: AlarisEditPanelService,
		public override readonly cd: ChangeDetectorRef,
		public readonly currencyService: CurrencyService,
		public readonly bs: AlarisBalanceService
	) {
		super(
			entityService,
			fieldsService,
			tableSettingsService,
			editPanel,
			cd,
			TableTypes.INTERNAL
		);
	}

	override ngOnInit(): void {
		this.readParams = {
			Filters: [{ Field: 'subscriptionId', Type: FilterType.EXACT, Value: this.subscriptionId }]
		};
		super.ngOnInit();
	}

	override applyFilter($event: TableFiltersIndicator): void {
		super.applyFilter($event);
		this.readParams.Filters?.forEach((f) => {
			switch (f.Field) {
				case 'country':
					f.Value = Array.isArray(f.Value) ? f.Value.map((country) => {
						return { name: country };
					}) : null;
					break;
				case 'network':
					f.Value = Array.isArray(f.Value) ? f.Value.map((net) => {
						return { name: net };
					}) : null;
					break;
				default:
					break;
			}
		});


		this.readParams.Filters?.push({
			Field: 'subscriptionId',
			Type: FilterType.EXACT,
			Value: this.subscriptionId
		});
	}

	override import(): void {
		this.importRequest.emit();
	}

	override refresh(): void {
		this.refreshRequest.emit();
		super.refresh();
	}

}

