import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';

import {
	AlarisApiService,
	AlarisLanguageService,
	AlarisToasterService,
	ErrorNotifierConfig
} from '@campaign-portal/components-library';

import {
	DeleteRequest,
	DeleteResponse,
	ReadResponse,
	UpdateResponse
} from '@campaign-portal/namespace/common/implementations';
import { RPCRequestParams } from '@campaign-portal/namespace/common/rpc.params';
import { exist, Id } from '@campaign-portal/namespace/common/id';
import { Portal, PortalPair } from '@campaign-portal/namespace/entities/portals/specs';

@Injectable({
	providedIn: 'root'
})
export class PortalsService {
	list: PortalPair<exist>[] = [];
	readonly map: Map<number, PortalPair> = new Map<number, PortalPair>();

	readonly loading$ = new BehaviorSubject<boolean>(false);


	readonly create = this.update;
	private readonly errorNotifier = (): ErrorNotifierConfig => ({ title: this.title });

	constructor(
		private readonly api: AlarisApiService,
		private readonly alarisToaster: AlarisToasterService,
		private readonly langService: AlarisLanguageService
	) {
	}

	get entity(): string {
		return this.langService.translate('notifications.entities.portal');
	}

	get title(): string {
		return this.langService.translate('notifications.titles.portal');
	}

	read(params?: RPCRequestParams): Observable<PortalPair<exist>[]> {
		return this.api.loader<ReadResponse<PortalPair<exist>[]>>(
			'Portals.Read', params, this.loading$, undefined, this.errorNotifier
		).pipe(
			map(
				resp => {
					this.list = resp.Data;
					resp.Data.forEach(
						entity => this.map.set(entity.id, entity)
					);
					return resp.Data;
				}
			)
		);
	}

	update(portal: Portal): Observable<UpdateResponse<Portal<exist>>> {
		const notify = (response: UpdateResponse<Portal<exist>>): void => {
			if ( response.Success ) {
				const message = this.langService.translate(
					portal.id ? 'notifications.actions.updatePortal' : 'notifications.actions.createPortal', {
						name: portal.name
					});
				this.alarisToaster.success(message, this.title);
			}
		};
		return this.api.loader<UpdateResponse<Portal<exist>>>(
			`Portals.${portal.id ? 'Update' : 'Create'}`,
			{ Data: { Entities: [portal] } },
			this.loading$, this.errorNotifier, notify
		);
	}

	delete(id: Id<exist>): Observable<DeleteResponse<Portal<exist>>> {
		const params: DeleteRequest<Portal<exist>> = { Data: { Ids: [id] } };
		const notify = (response: DeleteResponse<Portal<exist>>): void => {
			if ( response.Success ) {
				const message = this.langService.translate('notifications.actions.delete', { entity: this.entity });
				this.alarisToaster.success(message, this.title);
			}
		};
		return this.api.loader<DeleteResponse<Portal<exist>>>(
			'Portals.Delete', params, this.loading$, this.errorNotifier, notify
		);
	}
}
