<alaris-card>
	<div class="table-management" *ngIf="tRows.length !== 0">
		<div class="left">
			<span class="total" data-testid="total">{{ 'gl.total' | translate }}: {{ tRows.length }}</span>
		</div>
		<div class="right"></div>
	</div>
	<div [alarisLoading]="loading$" class="content">
		@if (tRows.length === 0) {
			<app-empty-table type="subscribers"></app-empty-table>
		} @else {
			<alaris-table (sortingChange)="applySorting($event)"
						  [rowActions]="rowActions"
						  [tRows]="tRows"
						  [sorting]="sorting"
						  [tHeaders]="(tHeaders$ | async) ?? []"
						  [templates]="{ccId, subscriptionDate}"
						  alarisOverflowFader>
			</alaris-table>
		}
	</div>
	<div class="footer"></div>
</alaris-card>

<ng-template [alarisCellCtx]="tRows[0]" #ccId let-data="data">
	<div class="no-wrap">
		{{(data.ccId | cCompany)?.name}}
	</div>
</ng-template>

<ng-template [alarisCellCtx]="tRows[0]" #subscriptionDate let-data="data">
	<div class="no-wrap">
		{{getSubscriptionDate(data.id) | date: 'd MMM y, HH:mm'}}
	</div>
</ng-template>
