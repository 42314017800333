<div [cdkContextMenuTriggerFor]="ctxMenu" class="container">
	<header class="header">
		<div class="date">
			@if (group.lastUpdated) {
				{{ ('gl.updated' | translate) + ' ' + (group.lastUpdated | date: 'd MMM y') }}
			} @else if (group.creationDate) {
				{{ ('subscriptions.created' | translate) + ' ' + (group.creationDate | date: 'd MMM y') }}
			}
		</div>

		<button (cdkMenuClosed)="menuOpened = false"
				(cdkMenuOpened)="menuOpened = true"
				(click)="$event.stopPropagation()"
				(contextmenu)="$event.stopPropagation()"
				[attr.data-testid]="'actionDropdownToggle/' + group.id"
				[cdkMenuPosition]="position"
				[cdkMenuTriggerFor]="ctxMenu"
				[class.opened]="menuOpened" class="actions">
			<alaris-icon name="icon-dot-vertical"></alaris-icon>
		</button>
	</header>

	<div class="info">
		<h4 [alarisTooltip]="group.name" [overflow]="true" class="name">
			{{ group.name }}
		</h4>
		<div class="description">{{ group.description }}</div>
	</div>

	<footer class="footer">
		@if (hasSubscriptions) {
			<alaris-icon class="icon" name="icon-package"></alaris-icon>
			<div class="subscriptions-info">
				@if (group.packs.length) {
					<span>{{ 'partners.packs' | translate }}: {{ group.packs.length }}</span>
				}
				@if (group.packs.length && group.plans.length) {
					<span class="dot"></span>
				}
				@if (group.plans.length) {
					<span>{{ 'partners.plans' | translate }}: {{ group.plans.length }}</span>
				}
			</div>
		} @else {
			<alaris-icon class="icon" name="icon-folder-open"></alaris-icon>
			{{ 'subscriptions.groups.noSubscriptions' | translate }}
		}
	</footer>
</div>

<ng-template #ctxMenu>
	<alaris-ctx-action [actions]="actions"
					   [attr.data-testid]="'dropdownActions/' + group.id"
					   [minContainerWidth]="140"
					   [row]="group" cdkMenu>
	</alaris-ctx-action>
</ng-template>
