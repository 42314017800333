import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	OnDestroy,
	TemplateRef,
	ViewChild
} from '@angular/core';
import {
	AlarisBalanceService,
	AlarisProfileService,
	AlarisTableStateService,
	SharedTemplatesService
} from '@campaign-portal/components-library';
import { AP_PERMISSIONS } from '@helpers/types/permissions';

@Component({
	selector: 'app-finance',
	templateUrl: './finance.component.html',
	styleUrls: ['./finance.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FinanceComponent implements AfterViewInit, OnDestroy {
	@ViewChild('partnerId') readonly partnerId!: TemplateRef<unknown>;
	@ViewChild('amount') readonly amount!: TemplateRef<unknown>;

	readonly AP_PERMISSIONS = AP_PERMISSIONS;

	constructor(
		public readonly cd: ChangeDetectorRef,
		public readonly profile: AlarisProfileService,
		public readonly bs: AlarisBalanceService,
		private readonly stateService: AlarisTableStateService,
		private readonly templates: SharedTemplatesService
	) {
	}

	ngAfterViewInit(): void {
		this.templates.share({
			partnerId: this.partnerId,
			amount: this.amount
		});
	}

	ngOnDestroy(): void {
		this.stateService.clear();
	}
}
