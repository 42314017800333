import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './auth/auth.guard';
import { routerLinks } from '@helpers/shared/router-links.const';

import { SignInLayoutComponent } from './layouts/sign-in-layout/sign-in-layout.component';
import { MainLayoutComponent } from './layouts/main-layout/main-layout.component';
import { GoHomeComponent } from './layouts/go-home.component';
import { ActivateComponent } from './auth/activate/activate.component';
import { ResetComponent } from './auth/reset/reset.component';
import { ActivateGuard } from './auth/activate/activate.guard';
import { ResetGuard } from './auth/reset/reset.guard';
import { DropAuthGuard } from './auth/drop-auth.guard';

const routes: Routes = [
	{
		path: 'sign-in',
		component: SignInLayoutComponent,
		canActivate: [DropAuthGuard],
		data: { animationState: 'SignInLayoutComponent' }
	},
	{ path: 'activate', canActivate: [DropAuthGuard, ActivateGuard], component: ActivateComponent },
	{ path: 'reset', canActivate: [DropAuthGuard, ResetGuard], component: ResetComponent },
	{
		path: '',
		component: MainLayoutComponent,
		canActivate: [AuthGuard],
		canActivateChild: [AuthGuard],
		data: { animationState: 'MainLayoutComponent' },
		children: [
			{ path: '', component: GoHomeComponent },
			...Object.values(routerLinks)
		]
	},
	{ path: '**', redirectTo: '' }
];

@NgModule({
	declarations: [],
	imports: [
		RouterModule.forRoot(routes)
	],
	exports: [
		RouterModule
	]
})
export class AppRoutingModule {
}
