<alaris-dialog [closeAction]="closeAction.bind(this)"
			   [confirmAction]="confirmAction.bind(this)"
			   [confirmButtonText]="editable ? 'gl.confirm' : ''"
			   [loading$]="loading$"
			   [size]="'auto'"
			   class="dialog">
	<ng-container slot="header">
		<h3>{{
				subscribed.length > 0
					? ('partners.subscriptionsDialog.title' | translate)
					: ('partners.available'  | translate)
			}}</h3>
	</ng-container>
	<ng-container slot="content">
		<alaris-tabs #tabs
					 *ngIf="subscribed.length > 0; else availableTable"
					 [underline]="false" class="alaris-tabs">
			<alaris-tab [label]="'partners.subscribed' | translate" tabId="subscribed">
				<ng-container *ngIf="tabs.activeTab?.tabId === 'subscribed'">
					<ng-container
						*ngTemplateOutlet="table; context: {rows: subscribedTRows, tab: 'subscribed'}">
					</ng-container>
				</ng-container>
			</alaris-tab>
			<alaris-tab [label]="'partners.available'  | translate" tabId="available">
				<ng-container *ngIf="tabs.activeTab?.tabId === 'available'">
					<ng-container *ngTemplateOutlet="availableTable"></ng-container>
				</ng-container>
			</alaris-tab>
		</alaris-tabs>
		<div class="result">
			<div class="selected">
				<div *ngIf="deltaSubscribe.length > 0" class="list">
					<strong>{{ 'partners.subscriptionsDialog.forSubscribe' |  translate }}:</strong>
					<alaris-tag *ngFor="let sub of deltaSubscribe"
								[fill]="sub.type === SubscriptionType.PACK"
								class="tag"
								mode="info">
						{{ sub.name }}
						<alaris-icon (click)="removeFromDelta(sub, 'subscribe')"
									 class="pointer" data-testid="subscribeDeltaBtn"
									 name="icon-close">
						</alaris-icon>
					</alaris-tag>
				</div>
				<div *ngIf="deltaUnsubscribe.length > 0" class="list">
					<strong>{{ 'partners.subscriptionsDialog.forUnsubscribe' |  translate }}:</strong>
					<alaris-tag *ngFor="let unsub of deltaUnsubscribe"
								[fill]="unsub.type === SubscriptionType.PACK"
								class="tag text-truncate"
								mode="warning">
						{{ unsub.name }}
						<alaris-icon (click)="removeFromDelta(unsub, 'unsubscribe')"
									 class="pointer" data-testid="unsubscribeDeltaBtn"
									 name="icon-close">
						</alaris-icon>
					</alaris-tag>
				</div>
			</div>
		</div>
		<alaris-alert class="note">
			{{ 'partners.subscriptionsDialog.note' |  translate }}
		</alaris-alert>


	</ng-container>
</alaris-dialog>

<ng-template #availableTable>
	<ng-container *ngIf="available.length === 0">
		<app-empty-table (newSubscriptions)="navigate()" [disabled]="!editable"
						 [showImg]="false"
						 type="subscriptions">
		</app-empty-table>
	</ng-container>
	<ng-container *ngIf="available.length > 0">
		<ng-container
			*ngTemplateOutlet="table; context: {rows: availableTRows, tab: 'available'}">
		</ng-container>
	</ng-container>
</ng-template>
<ng-template #table let-actions="actions" let-rows="rows" let-tab="tab">
	<alaris-table (filterChange)="applyFilter($event)"
				  (sortingChange)="applySorting($event)"
				  [filters]="filters"
				  [sorting]="sorting"
				  [tHeaders]="tHeaders"
				  [tRows]="rows"
				  [templates]="{
				  			name: tab === 'subscribed' ? nameMinus : namePlus,
				  			currencyId, type, trafficType, packSettings
				  }"
				  alarisOverflowFader class="alaris-table">
	</alaris-table>
</ng-template>

<ng-template [alarisCellCtx]="available[0]" #namePlus let-data="data">
	<div (click)="subscribe(data)" [class.pointer]="editable" class="name-column" data-testid="subscribeBtn">
		<div class="name">
			{{ data.name }}
			<ng-container *ngIf="data.type === SubscriptionType.PACK && (data | as: 'any').packSettings?.isPromo">
				({{ ('subscriptions.promo' | translate) }})
			</ng-container>
		</div>
		<div *ngIf="editable"
			 class="icon">
			<alaris-icon name="icon-plus"></alaris-icon>
		</div>
	</div>
</ng-template>

<ng-template [alarisCellCtx]="available[0]" #nameMinus let-data="data">
	<div (click)="unsubscribe(data)" [class.pointer]="editable" class="name-column" data-testid="unsubscribeBtn">
		<div class="name">
			{{ data.name }}
		</div>
		<div *ngIf="editable"
			 class="icon">
			<alaris-icon name="icon-minus"></alaris-icon>
		</div>
	</div>
</ng-template>

<ng-template [alarisCellCtx]="available[0]" #currencyId let-data="data">
	{{ (data.currencyId ?? 0 | alarisCurrency)?.name }}
</ng-template>

<ng-template [alarisCellCtx]="available[0]" #type let-data="data">
	{{ 'enums.' + data.type | translate }}
</ng-template>

<ng-template [alarisCellCtx]="available[0]" #trafficType let-data="data">
	<div class="trafficType">
		<alaris-icon [name]="cu.icon(data.trafficType)"></alaris-icon>
		<div>
			<ng-container *ngIf="data.trafficType !== TrafficType.VIBER">
				{{ cu.name(data.trafficType) | translate }}
			</ng-container>
			<ng-container *ngIf="data.trafficType === TrafficType.VIBER">
				{{ cu.purpose(data.trafficType, data.settings?.viberSettings?.messagePurpose) | translate }}
			</ng-container>
		</div>
	</div>
</ng-template>

<ng-template #packSettings let-data="data">
	<ng-container *ngIf="data.packSettings; else empty">
		<div class="cost">
			<div>{{ data.packSettings.packPrice | currency: bs.currencyCode }}</div>
			<div>( {{ data.messagesTotal }} )</div>
		</div>

	</ng-container>
	<ng-template #empty> -</ng-template>
</ng-template>

