@if (isOwner.is) {
	<alaris-tabs #tabs (tabChanged)="cd.detectChanges()" [underline]="false" id="subscriptionsTabs">
		<alaris-tab [label]="'tabs.subscriptions' | translate" tabId="subscriptions">
			@if (tabs.activeTab?.tabId === 'subscriptions') {
				<ng-container *ngTemplateOutlet="subscriptionsList"></ng-container>
			}
		</alaris-tab>
		<alaris-tab [label]="'tabs.groups' | translate" tabId="groups">
			@if (tabs.activeTab?.tabId === 'groups') {
				<app-subscription-groups></app-subscription-groups>
			}
		</alaris-tab>
	</alaris-tabs>
} @else {
	<ng-container *ngTemplateOutlet="subscriptionsList"></ng-container>
}

<ng-template #subscriptionsList>
	<app-subscriptions-list [class.max-height]="!isOwner.is"></app-subscriptions-list>
</ng-template>
