<alaris-card>
	<div *ngIf="tRows.length > 0 || tRows.length === 0 && isFiltersApplied" class="table-management">
		<div class="left">
			<span class="total" data-testid="total">{{ totalTitle | translate }}: {{ total }}</span>
			<button (click)="editTableFilter()" *ngIf="enableButtons.filters.enabled" alaris-button bType="outline"
					data-testid="editFilterBtn" size="lg">
				<alaris-icon name="icon-filter-vertical"></alaris-icon>
				{{ enableButtons.filters.title | translate }}
				<div *ngIf="isFiltersApplied" class="indicator"></div>
			</button>
		</div>
		<div class="right">
			<button (click)="refresh()" alaris-button bType="outline" data-testid="refreshBtn" size="lg">
				<alaris-icon name="icon-refresh-clock-wise"></alaris-icon>
				{{ 'actions.refreshPage' | translate }}
			</button>
			<button (click)="openEditPanel()"
					*alarisPBAC="[AP_PERMISSIONS.ENDPOINTS_E, AP_PERMISSIONS.V_SUBSCR_R]"
					alaris-button bType="primary" data-testid="createBtn" size="lg">
				<alaris-icon class="in-primary" name="icon-plus"></alaris-icon>
				{{ 'endpoints.addEndpoint' | translate }}
			</button>
		</div>
	</div>
	<div [alarisLoading]="loading$" class="content">
		@if (tRows.length === 0 && !isFiltersApplied) {
			<app-empty-table (newEndpoint)="openEditPanel()"
							 [disabled]="!([AP_PERMISSIONS.ENDPOINTS_E] | alarisPBAC)"
							 type="endpoint">
			</app-empty-table>
		} @else {
			<alaris-table (filterChange)="_applyFilter($event)"
						  (sortingChange)="_applySorting($event)"
						  [ctxActions]="ctxActions"
						  [dblClickRowAction]="openEditPanel.bind(this)"
						  [filters]="filters"
						  [rowActions]="mainActions"
						  [sorting]="sorting"
						  [tHeaders]="tHeaders"
						  [tRows]="tRows"
						  [templates]="{isActive, isOnline, vendorSubscription, availableCountryNetList, groups}"
						  alarisOverflowFader>
			</alaris-table>
		}
	</div>
	<div class="footer">
		<alaris-pagination (page)="_pageChange($event)"
						   [activePage]="activePage"
						   [hidden]="total === 0"
						   [pageSizeOptions]="pageSizeOptions"
						   [pageSize]="pageSize"
						   [total]="total"
						   class="pagination">
		</alaris-pagination>
	</div>
</alaris-card>

<ng-template #isActive [alarisCellCtx]="tRows[0]" let-data="data" let-key="key">
	<div [formGroup]="statusGroup">
		<alaris-toggle (change)="updateStatus(data)"
					   [attr.data-testid]="'updateStatusToggle/' + data.id"
					   [formControlName]="data.id.toString()"
					   [readonly]="!([AP_PERMISSIONS.ENDPOINTS_E] | alarisPBAC)">
		</alaris-toggle>
	</div>
</ng-template>

<ng-template #isOnline [alarisCellCtx]="tRows[0]" let-data="data" let-key="key">
	<div class="status-column">
		<span [style.background]="data.isOnline ? 'var(--as-BgPositive)':  'var(--as-BgNegative)'"
			  class="dot">
		</span>
		{{ (data.isOnline ? 'gl.online' : 'gl.offline') | translate }}
	</div>
</ng-template>

<ng-template #vendorSubscription [alarisCellCtx]="tRows[0]" let-data="data" let-key="key">
	<div class="vendor-product-column">
		{{ (data.vendorSubscription | product)?.name }}
	</div>
</ng-template>

<ng-template #availableCountryNetList [alarisCellCtx]="tRows[0]" let-data="data" let-key="key">
	<div *ngIf="countriesNetworks.loading$ | async">
		<alaris-spinner size="xs" type="info"></alaris-spinner>
	</div>
	<div (click)="seeAllCountries(countries)"
		 *alarisLet="getCountryList(data.availableCountryNetList) as countries"
		 data-testid="seeAllCountriesBtn">
		@if (countriesNetworks.list.length === data.availableCountryNetList?.length) {
			{{ 'endpoints.allCountries' | translate }}
		} @else {
			<div class="countries-column">
				<span [alarisTooltip]="'gl.allCountryHint' | translate"
					  class="names">
					{{ countries.slice(0, 2).join(', ') }}
				</span>
				@if (countries.length > 2) {
					<alaris-tag [rounded]="true" size="lg" mode="gray-light">
						<span class="text-wrap">+ {{ countries.length - 2 }}</span>
					</alaris-tag>
				}
			</div>
		}
	</div>
</ng-template>

<ng-template #groups [alarisCellCtx]="tRows[0]" let-data="data" let-key="key">
	@if (data.groups && data.groups.length) {
		@if (subGroupsService.list.length === data.groups.length) {
			{{ 'subscriptions.groups.allGroups' | translate }}
		} @else {
			<div class="groups-column">
				<span class="names">{{ getSubscriptionGroupList(data.groups) }}</span>
				@if (data.groups.length > 2) {
					<alaris-tag [rounded]="true" size="lg" mode="gray-light">
						<span class="text-wrap">+ {{ data.groups.length - 2 }}</span>
					</alaris-tag>
				}
			</div>
		}
	}
</ng-template>
