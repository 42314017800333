import {
	ChangeDetectionStrategy,
	Component,
	DestroyRef,
	EventEmitter,
	HostBinding,
	inject,
	Input,
	OnInit,
	Output
} from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { Package, Subscription } from '@campaign-portal/namespace/entities/subscriptions/specs';
import { exist } from '@campaign-portal/namespace/common/id';
import {
	AlarisBalanceService,
	AlarisLanguageService,
	ChannelUtilsService,
	filterWildcardData,
	LocalTableUtils,
	TableEntityField
} from '@campaign-portal/components-library';
import { SubscriptionType, TrafficType } from '@campaign-portal/namespace/common/enums';
import { TableFiltersIndicator, TableSortIndicator } from '@campaign-portal/components-library/lib/table/src/base';
import { FilterInterval, SortDirection } from '@campaign-portal/namespace/common/rpc.params';
import { IdObject } from '@campaign-portal/namespace/common/idObject';
import { InputComplexType } from '@campaign-portal/namespace/common/entityField';

import { SubscriptionsFieldsService } from '../../../subscriptions-list/subscriptions.fields.service';
import { CurrencyService } from '@helpers/services/currency.service';
import { ModelType, PackageEntity } from '@helpers/types/app.classes-interfaces';
import { EnumsMapperService } from '@helpers/services/enums.service';
import { AP_PERMISSIONS } from '@helpers/types/permissions';
import { fractional } from '@helpers/utils/math';

const DIVIDER_ID = -1;
const DIVIDER: Subscription<exist> = new PackageEntity(DIVIDER_ID);

@Component({
	selector: 'app-subscriptions-picker-table',
	templateUrl: './subscriptions-picker-table.component.html',
	styleUrl: './subscriptions-picker-table.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [SubscriptionsFieldsService]
})
export class SubscriptionsPickerTableComponent implements OnInit {
	@HostBinding('attr.type')
	@Input() type: ModelType = ModelType.TEMPORARY;
	@Input() subscriptionType: SubscriptionType = SubscriptionType.PACK;
	@Input({ required: true }) selectionModel!: SelectionModel<Subscription<exist>>;
	@Input() selectedModel?: SelectionModel<Subscription<exist>>;
	@Output() addSubscription = new EventEmitter<void>();
	tRows: Subscription<exist>[] = [];
	tHeaders: TableEntityField[] = [];
	total = 0;
	isFiltersApplied = false;
	isSortingApplied = false;
	filters: TableFiltersIndicator = new Map()
		.set('name', { enabled: true, value: undefined })
		.set('billingMode', { enabled: true, value: [] })
		.set('currencyId', { enabled: true, value: [] })
		.set('packSettings', { enabled: true, value: undefined });
	sorting: TableSortIndicator = new Map()
		.set('name', { enabled: true, value: SortDirection.EMPTY })
		.set('billingMode', { enabled: true, value: SortDirection.EMPTY })
		.set('currencyId', { enabled: true, value: SortDirection.EMPTY })
		.set('packSettings', { enabled: true, value: SortDirection.EMPTY });
	readonly ModelType = ModelType;
	readonly AP_PERMISSIONS = AP_PERMISSIONS;
	readonly SubscriptionType = SubscriptionType;
	readonly TrafficType = TrafficType;
	readonly fractional = fractional;
	private readonly destroyRef = inject(DestroyRef);

	constructor(
		public readonly bs: AlarisBalanceService,
		public readonly cu: ChannelUtilsService,
		private readonly ls: AlarisLanguageService,
		private readonly enums: EnumsMapperService,
		private readonly currencyService: CurrencyService,
		private readonly subFieldsService: SubscriptionsFieldsService
	) {
	}

	get tDefaultRows(): Subscription<exist>[] {
		return [...this.selectionModel.selected];
	}

	get hasDivider(): boolean {
		return Boolean(
			this.type === ModelType.TEMPORARY
			&& !this.isSortingApplied
			&& !this.isFiltersApplied
			&& this.getEarlierSelectedSubs().length
			&& this.getNewSelectedSubs().length
		);
	}

	@Input() rowAction: (tRow: Subscription<exist>) => void = () => {
	};

	@Input() bulkAction: (tRows: Subscription<exist>[]) => void = () => {
	};

	isDivider(row: Subscription<exist>): boolean {
		return row.id === DIVIDER_ID;
	}

	ngOnInit(): void {
		this.tHeaders = this.subFieldsService.getGroupSubscriptionHeaders(this.subscriptionType);
		this.applyRows();

		this.selectionModel.changed
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe(() => {
				this.applyRows();
			});
	}

	_bulkAction(): void {
		const rows = this.tRows.filter(row => row.id !== DIVIDER_ID);
		this.bulkAction(rows);
	}

	_applySorting($event: TableSortIndicator): void {
		this.isSortingApplied = this.checkSorting($event);
		this.sorting = $event;
		if ( this.hasDivider ) {
			this.tRows = [...this.getNewSelectedSubs(), DIVIDER, ...this.getEarlierSelectedSubs()];
		} else {
			this.tRows = this.tRows.filter(row => row.id !== DIVIDER_ID);
			this.applySorting($event);
		}
	}

	_applyFilter($event: TableFiltersIndicator): void {
		this.isFiltersApplied = this.checkFilters($event);
		this.filters = $event;
		if ( this.hasDivider ) {
			this.tRows = [...this.getNewSelectedSubs(), DIVIDER, ...this.getEarlierSelectedSubs()];
		} else {
			this.tRows = this.tDefaultRows;
			this.applyFilter($event);
			this.applySorting(this.sorting);
		}
	}

	applySorting($event: TableSortIndicator): void {
		$event.forEach((sort, variable) => {
			if ( sort?.enabled ) {
				this.tRows = [...this.tRows].sort((a: Subscription, b: Subscription): number => {
					let first: unknown;
					let second: unknown;
					switch (variable) {
						case 'name':
							first = a.name;
							second = b.name;
							break;
						case 'billingMode':
							first = this.ls.translate('enums.' + a.billingMode);
							second = this.ls.translate('enums.' + b.billingMode);
							break;
						case 'currencyId':
							first = this.currencyService.map.get(a.currencyId)?.name ?? '';
							second = this.currencyService.map.get(b.currencyId)?.name ?? '';
							break;
						case 'packSettings':
							first = (a as Package).packSettings?.packPrice;
							second = (b as Package).packSettings?.packPrice;
							break;
						default:
							break;
					}
					return LocalTableUtils.sortComparisonFn(sort?.value ?? SortDirection.EMPTY, first, second);
				});
			}
		});
	}

	applyFilter($event: TableFiltersIndicator): void {
		let rows = [...this.tRows];
		$event.forEach((filter, variable) => {
			if ( filter?.enabled && filter.value ) {
				switch (variable) {
					case 'name':
						rows = filterWildcardData(filter.value as string, rows, 'name');
						break;
					case 'billingMode':
						if ( Array.isArray(filter.value) && filter.value.length > 0 ) {
							rows = rows.filter(sub => (filter.value as string[])
								.map(item => this.enums.get('DecisionMode')[item])
								.includes(sub.billingMode));
						}
						break;
					case 'currencyId':
						if ( Array.isArray(filter.value) && filter.value.length > 0 ) {
							rows = rows.filter(
								sub => (filter.value as IdObject<exist>[])
									.map(item => item.id)
									.includes(sub.currencyId ?? -1)
							);
						}
						break;
					case 'packSettings': {
						rows = rows.filter((sub: Subscription): boolean => {
							const price: number | undefined = (sub as Package).packSettings?.packPrice;
							const fV = filter.value as FilterInterval<number | null>;
							if ( price === undefined ) {
								return fV.End === null;
							}
							const more = fV.Start !== null ? price >= fV.Start : true;
							const less = fV.End !== null ? price <= fV.End : true;
							return more && less;
						});
						break;
					}
					default:
						break;
				}
			}
		});

		this.tRows = [...rows];
	}

	private checkSorting(indicators: TableSortIndicator): boolean {
		const nonEmptySort = [...indicators].find(([_, sort]) => {
			return sort.value !== SortDirection.EMPTY;
		});
		return Boolean(nonEmptySort);
	}

	private checkFilters(indicators: TableFiltersIndicator): boolean {
		const filters: string[] = [];
		indicators.forEach(
			(fV, variable) => {
				const header = this.tHeaders.find(h => h.variable === variable);
				const hasValue = Array.isArray(fV?.value)
					? fV?.value.length
					: header?.type === InputComplexType.CHECKBOX && fV?.value !== null
						? true : fV?.value;
				if ( hasValue ) {
					filters.push(variable);
				}
			}
		);
		return !!filters.length;
	}

	private applyRows(): void {
		this.total = this.tDefaultRows.length;
		this._applyFilter(this.filters);
	}

	private getNewSelectedSubs(): Subscription<exist>[] {
		const temporarySelected = this.selectionModel.selected;
		return temporarySelected.filter(sub => {
			return !this.selectedModel?.isSelected(sub);
		});
	}

	private getEarlierSelectedSubs(): Subscription<exist>[] {
		const temporarySelected = this.selectionModel.selected;
		return temporarySelected.filter(sub => {
			return this.selectedModel?.isSelected(sub);
		});
	}
}
