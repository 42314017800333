import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	OnDestroy,
	ViewChild
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { AlarisTableStateService, AlarisTabsComponent } from '@campaign-portal/components-library';
import { AP_PERMISSIONS } from '@helpers/types/permissions';

import { OwnerService } from '@helpers/services/owner.service';

@Component({
	selector: 'app-vendors',
	templateUrl: './vendors.component.html',
	styleUrls: ['./vendors.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class VendorsComponent implements AfterViewInit, OnDestroy {

	@ViewChild('ratesTabs') readonly ratesTabs!: AlarisTabsComponent;

	readonly AP_PERMISSIONS = AP_PERMISSIONS;

	constructor(
		public readonly cd: ChangeDetectorRef,
		public readonly isOwner: OwnerService,
		private readonly stateService: AlarisTableStateService,
		private readonly router: Router,
		private readonly route: ActivatedRoute
	) {
	}

	ngAfterViewInit(): void {
		if ( this.route.snapshot.queryParamMap.get('mode') === 'import-history' ) {
			this.ratesTabs.setActiveTab('importHistory');
		}
		this.cd.detectChanges();
	}

	initiateImport(): void {
		this.router.navigate(['rates-import']);
	}

	ngOnDestroy(): void {
		this.stateService.clear();
	}
}
