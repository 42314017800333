<div [alarisLoading]="loading$" [backdrop]="false">
	<div class="period">
		<span class="label">{{ 'dashboard.clientAnalytics' | translate }}</span>
		<div class="range">
			<alaris-dashboard-time-filter (periodTypeChange)="periodType.setValue($event)"
										  [formControl]="range"
										  [toSystemDate]="true"
										  data-testid="periodDateRange">
			</alaris-dashboard-time-filter>
		</div>
	</div>

	<ng-container *ngIf="dataService.firstLoading$ | async; else data">
		<div class="in-row">
			<div *ngFor="let i of [0,1,2,3]" class="card stub-card"></div>
		</div>
	</ng-container>
	<ng-template #data>
		<div class="in-row">
			<div *ngFor="let data of ((dataService.analytics$ | async) ?? {} | keyvalue); let last = last" class="card">
				<div class="label">{{ ('dashboard.' + data.key) | translate | titlecase }}</div>
				<div class="data"><b>{{ last ? (data.value | number) : (data.value | currency: bs.currencyCode) }}</b>
				</div>
			</div>
		</div>
	</ng-template>

	<alaris-table
		(sortingChange)="applySorting($event)"
		[(sorting)]="sorting"
		[fixFirstColumn]="false"
		[resizable]="false"
		[style.height]="calculateViewPort()"
		[tHeaders]="tHeaders"
		[tRows]="tRows"
		[templates]="templates"
		class="table"
	></alaris-table>
	<div *ngIf="loading$ | async" class="stub-rows">
		<ng-container *ngIf="tRows.length === 0">
			<div *ngFor="let i of [0,1,2]" class="stub-row"></div>
		</ng-container>
	</div>
</div>
<ng-template #clientName let-head="key" let-row="data">
	{{ row[head] }}
</ng-template>
<ng-template #totalMessage let-head="key" let-row="data">
	{{ row[head] | number }}
</ng-template>
<ng-template #general let-head="key" let-row="data">
	{{ row[head] | currency: bs.currencyCode }}
</ng-template>

