import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { BehaviorSubject, finalize, Observable, of, Subject, takeUntil } from 'rxjs';

import { UUID } from '@campaign-portal/namespace/common/id';
import { RPCResult } from '@campaign-portal/namespace/common/rpc.response';

import { UsersService } from '../users.service';
import { CanDeactivateComponent } from '@helpers/can-deactivate/can-deactivate.component';
import { CanDeactivateGuardService } from '@helpers/can-deactivate/can-deactivate.guard';
import { AlarisDialogService } from '@campaign-portal/components-library';
import { User } from '@campaign-portal/namespace/common/user';

export type UsersDialogType = 'Delete' | 'ChangePasswordByHand' | 'ChangePasswordByEmail';

export interface UsersDialogData {
	type: UsersDialogType;
	user: User<UUID>;
}

@Component({
	selector: 'app-users-dialog',
	templateUrl: './users-dialog.component.html',
	styleUrls: ['./users-dialog.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class UsersDialogComponent extends CanDeactivateComponent implements OnDestroy {
	readonly user!: User<UUID>;
	password = '';
	readonly captcha = new FormGroup({
		answer: new FormControl('', {
			nonNullable: true,
			validators: [Validators.required]
		}),
		key: new FormControl('', {
			nonNullable: true,
			validators: [Validators.required]
		})
	});

	readonly updateCaptcha$ = new Subject<void>();
	readonly loading$ = new BehaviorSubject<boolean>(false);
	readonly allowedDeactivation = new BehaviorSubject<boolean>(true);
	protected readonly ngUnsubscribe = new Subject<void>();

	constructor(
		@Inject(DIALOG_DATA) public readonly data: UsersDialogData,
		private readonly dialogRef: DialogRef<unknown>,
		private readonly cd: ChangeDetectorRef,
		private readonly usersService: UsersService,
		private readonly guard: CanDeactivateGuardService,
		private readonly dialog: AlarisDialogService
	) {
		super();
		this.addDialogGuard(this.dialog, dialogRef, this.guard);
		this.user = data.user;
	}

	get disabled(): boolean {
		switch (this.data.type) {
			case 'ChangePasswordByHand':
				return this.loading$.getValue() || !this.password;
			case 'ChangePasswordByEmail':
				return this.loading$.getValue() || !this.captcha.value;
			default:
				return this.loading$.getValue();
		}
	}

	close(): void {
		this.dialogRef.close(false);
	}

	confirm(): void {
		this.loading$.next(true);
		let caller: Observable<RPCResult<unknown>> = of({ Success: false });

		switch (this.data.type) {
			case 'Delete':
				caller = this.usersService.delete(this.user.id);
				break;
			case 'ChangePasswordByEmail':
				caller = this.usersService.passwordResetByEmail({
					Email: this.user.email,
					Captcha: this.captcha.getRawValue()
				}).pipe(finalize(() => this.updateCaptcha$.next()));
				break;
			case 'ChangePasswordByHand':
				caller = this.usersService.passwordResetByHand({
					Email: this.user.email,
					Password: this.password
				});
				break;
			default:
				break;

		}

		this.allowedDeactivation.next(false);
		caller.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
			(resp) => {
				this.allowedDeactivation.next(true);
				this.loading$.next(false);
				this.dialogRef.close(resp.Success);
				this.cd.markForCheck();
			}
		);
	}

	ngOnDestroy(): void {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
		this.loading$.complete();
		this.updateCaptcha$.complete();
	}
}
