import { Injectable } from '@angular/core';
import {
	AlarisTableStateService,
	filterWildcardFn,
	LocalTableService,
	LocalTableUtils
} from '@campaign-portal/components-library';
import { RatesImportHistory } from '@campaign-portal/namespace/entities/rates-import-history/specs';
import { RatesImportHistoryService } from '@helpers/components/import-history/rates-import-history.service';
import { ReadResponse } from '@campaign-portal/namespace/common/implementations';
import {
	FilterInterval,
	FilterType,
	RPCRequestParams,
	SortDirection
} from '@campaign-portal/namespace/common/rpc.params';
import { map, Observable, of, Subject } from 'rxjs';
import { ImportStatus, SubscriptionType } from '@campaign-portal/namespace/common/enums';
import { Subscription } from '@campaign-portal/namespace/entities/subscriptions/specs';
import { exist, Id } from '@campaign-portal/namespace/common/id';
import { VendorSubscriptionsService } from '@helpers/services/vendor-subscriptions.service';

@Injectable()
export class RateHistoryTableService extends LocalTableService<RatesImportHistory> {

	public subscriptionId: Id = null;
	public vendorRates = false;
	public readonly loaded$ = new Subject<void>();

	constructor(
		service: RatesImportHistoryService,
		stateService: AlarisTableStateService,
		private readonly vsService: VendorSubscriptionsService
	) {
		super(service, stateService);
	}

	override read(params: RPCRequestParams): Observable<ReadResponse<RatesImportHistory[]>> {
		if ( this.firstLoad ) {
			this.firstLoad = false;
			const state = this.stateService.store.get(this.stateID || '');
			const _params = state
				? {
					...LocalTableUtils.removeState(state, params),
					Paging: { Skip: 0, Take: 1000 },
					Sorting: [...(params.Sorting || []), { Field: 'id', Dir: SortDirection.DESC }]
				}
				: {
					...params,
					Paging: { Skip: 0, Take: 1000 },
					Sorting: [...(params.Sorting || []), { Field: 'id', Dir: SortDirection.DESC }]
				};

			if ( this.subscriptionId ) {
				_params.Filters = [
					...(_params.Filters || []),
					{
						Field: 'subscriptionId',
						Type: FilterType.EXACT,
						Value: this.subscriptionId
					}
				];
			}
			if ( this.vendorRates ) {
				_params.Filters = [
					...(_params.Filters || []),
					{
						Field: 'subscriptionId',
						Type: FilterType.EXACT,
						Value: SubscriptionType.VENDOR,
						Property: 'type'
					}
				];
			}

			return this.service.read(_params)
				.pipe(map((response) => {
					this.list = response.Data;
					this.loaded$.next();
					return this.parse(this.list, params);
				}));
		}
		return of(this.parse(this.list, params));
	}

	applyFilters(
		result: ReadResponse<RatesImportHistory[]>,
		params: RPCRequestParams
	): ReadResponse<RatesImportHistory[]> {
		const filters = params.Filters;
		if ( filters === undefined ) {
			return result;
		} else {
			let data = result.Data;
			filters.forEach((filter) => {
				switch (filter.Field) {
					case 'file':
						if ( filter.Value ) {
							data = data.filter((item) => {
								return filterWildcardFn(item.file.name, filter.Value as string);
							});
						}
						break;
					case 'status':
						if ( filter.Value ) {
							data = data.filter(item => (filter.Value as ImportStatus[]).includes(item.status));
						}
						break;
					case 'createdBy':
						if ( filter.Value ) {
							data = data.filter((item) => {
								return filterWildcardFn(item.createdBy || '', filter.Value as string);
							});
						}
						break;
					case 'creationDate':
						data = data.filter(item => {
							if ( filter.Value ) {
								const start = (filter.Value as FilterInterval<string | null>).Start;
								const end = (filter.Value as FilterInterval<string | null>).End;
								const more = start !== null && item.creationDate
									? new Date(item.creationDate) >= new Date(start)
									: true;
								const less = end !== null && item.creationDate
									? new Date(item.creationDate) <= new Date(end)
									: true;
								return more && less;
							}
							return true;
						});
						break;
					case 'subscriptionId':
						if ( filter.Value && Array.isArray(filter.Value) ) {
							const ids = (filter.Value as Subscription<exist>[]).map(item => item.id);
							data = data.filter(item => ids.includes(item.subscriptionId));
						}
						break;
					case 'total':
					case 'added':
					case 'updated':
					case 'closed':
						data = data.filter(item => {
							if ( filter.Value ) {
								const start = (filter.Value as FilterInterval<number | null>).Start;
								const end = (filter.Value as FilterInterval<number | null>).End;
								const more = start !== null
									? (item as unknown as Record<string, number>)[filter.Field] >= start
									: true;
								const less = end !== null
									? (item as unknown as Record<string, number>)[filter.Field] <= end
									: true;
								return more && less;
							}
							return true;
						});
						break;
					default:
						break;
				}
			});
			return { Success: true, Total: data.length, Data: data };
		}
	}

	applySorting(
		result: ReadResponse<RatesImportHistory[]>,
		params: RPCRequestParams
	): ReadResponse<RatesImportHistory[]> {
		const sorting = params.Sorting?.reverse();
		if ( !sorting ) {
			return result;
		} else {
			let data = result.Data;
			sorting.forEach((sort) => {
				data = data.sort((a, b) => {
					let first: any;
					let second: any;
					switch (sort.Field) {
						case 'file':
							first = a.file.name;
							second = b.file.name;
							break;
						case 'status':
						case 'createdBy':
						case 'total':
						case 'added':
						case 'updated':
						case 'closed':
							first = a[sort.Field];
							second = b[sort.Field];
							break;
						case 'creationDate':
							first = a.creationDate ? new Date(a.creationDate) : a.creationDate;
							second = b.creationDate ? new Date(b.creationDate) : b.creationDate;
							break;
						case 'subscriptionId':
							first = this.vsService.map.get(a.subscriptionId)!.name;
							second = this.vsService.map.get(b.subscriptionId)!.name;
							break;
						default:
							break;
					}

					return LocalTableUtils.sortComparisonFn(sort.Dir, first, second);
				});
			});
			return { Data: data, Total: result.Total, Success: true };
		}
	}
}
