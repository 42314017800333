import { Injectable } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';

import { CountryNetworkRef } from '@campaign-portal/namespace/entities/mccmnc/specs';
import { exist, Id } from '@campaign-portal/namespace/common/id';
import { CountriesNetworksService } from '@helpers/services/countries-networks.service';

@Injectable({
	providedIn: 'root'
})
export class CountryNetworkSelectionService {

	selectedModel!: SelectionModel<CountryNetworkRef<exist>>;
	readonly availableModel!: SelectionModel<CountryNetworkRef<exist>>;
	readonly temporaryModel!: SelectionModel<CountryNetworkRef<exist>>;

	constructor(
		public readonly countriesNetworks: CountriesNetworksService
	) {
		this.availableModel = new SelectionModel<CountryNetworkRef<exist>>(true, []);
		this.temporaryModel = new SelectionModel<CountryNetworkRef<exist>>(true, []);
	}

	setSelected(ids: Id<exist>[] | null): void {
		const selectedEndpointList = ids?.map(
			id => this.countriesNetworks.map.get(id)
		) || this.countriesNetworks.list; // <- for null select all Endpoints
		this.selectedModel = new SelectionModel<CountryNetworkRef<exist>>(true, selectedEndpointList as CountryNetworkRef<exist>[]);
	}

	delete(mccmnc: CountryNetworkRef<exist>, model: SelectionModel<CountryNetworkRef<exist>>): void {
		model.toggle(mccmnc);
	}

	moveToOtherModel(item: CountryNetworkRef<exist>): void {
		this.availableModel.toggle(item);
		this.temporaryModel.toggle(item);
	}

	selectAll(): void {
		this.temporaryModel.select(...this.availableModel.selected);
		this.availableModel.clear();
	}

	clearAll(): void {
		this.availableModel.select(...this.temporaryModel.selected);
		this.temporaryModel.clear();
	}

	selectFiltered(items: CountryNetworkRef<exist>[]): void {
		this.availableModel.deselect(...items);
		this.temporaryModel.select(...items);
	}

	clearFiltered(items: CountryNetworkRef<exist>[]): void {
		this.availableModel.select(...items);
		this.temporaryModel.deselect(...items);
	}

	applyChanges(): void {
		this.selectedModel.clear();
		this.selectedModel.select(...this.temporaryModel.selected);
	}

	clearAllModel(): void {
		this.temporaryModel.clear();
		this.selectedModel.clear();
		this.availableModel.clear();
	}
}
