import { Partner } from '@campaign-portal/namespace/entities/partners/specs';
import { Subscription } from '@campaign-portal/namespace/entities/subscriptions/specs';
import { exist, Id } from '@campaign-portal/namespace/common/id';

import { SubscriptionsService } from '../../subscriptions/subscriptions-list/subscriptions.service';
import { SubscriptionType } from '@campaign-portal/namespace/common/enums';

export class SubscriptionHelper {

	constructor(
		public readonly partner: Partner,
		public readonly subService: SubscriptionsService
	) {
	}

	convertIdsToSubscriptions(
		ids: Id<exist>[],
		type: SubscriptionType.PACK | SubscriptionType.PLAN
	): Subscription<exist>[] {
		if ( type === SubscriptionType.PACK ) {
			return ids.reduce((result: Subscription<exist>[], id) => {
				const subscription = this.subService.packsMap.get(id);
				if ( subscription ) {
					result.push(subscription);
				}
				return result;
			}, []);
		} else {
			return ids.reduce((result: Subscription<exist>[], id) => {
				const subscription = this.subService.plansMap.get(id);
				if ( subscription ) {
					result.push(subscription);
				}
				return result;
			}, []);
		}
	}

	// convertSubscriptionsToIds(subscriptions: Subscription<exist>[]): void {
	// 	subscriptions.forEach(subscription => {
	// 		if (subscription.type === SubscriptionType.PLAN) {
	// 			this.partner.plans.push(subscription.id);
	// 		}
	// 		if (subscription.type === SubscriptionType.PACK) {
	// 			this.partner.packs.push(subscription.id);
	// 		}
	// 	});
	// }
}
