<alaris-card class="host" size="auto">
	<div class="info">
		<div class="name">
			<b>{{ company.name }}</b>
			<button (click)="$event.stopPropagation()"
					[alarisDropdown]="dropdown"
					[attr.data-testid]="'actionsDropdownToggle/' + company.id"
					[positions]="position" class="action__button">
				<alaris-icon name="icon-dot-vertical"></alaris-icon>
			</button>
			<alaris-dropdown #dropdown>
				<alaris-dropdown-actions [actions]="actions" [attr.data-testid]="'dropdownActions/' + company.id"
										 [row]="company">
				</alaris-dropdown-actions>
			</alaris-dropdown>
		</div>
		<div class="bottom-row">
			<alaris-icon name="icon-paper-plane-right"></alaris-icon>
			<span class="label">{{ 'gl.deliveryChannels' | translate }}</span>
			<span>
			<ng-container *ngFor="let channel of company.availableImChannels; let last=last">
				{{ (channel | channel)?.name + (last ? '' : ', ') }}
			</ng-container>
		</span>
		</div>
		<div class="bottom-row">
			<alaris-icon name="icon-money-2"></alaris-icon>
			<span class="label">{{ 'gl.currency' | translate }}</span>
			<span>
			<ng-container *ngFor="let currency of company.availableCurrencies; let last=last">
				{{ (currency | alarisCurrency)?.name + (last ? '' : ', ') }}
			</ng-container>
		</span>
		</div>
		<div class="bottom-row">
			<alaris-icon name="icon-money-cash"></alaris-icon>
			<span class="label">{{ 'gl.paymentSystems' | translate }}</span>
			<span>
			<ng-container *ngFor="let pS of company.availablePaySystems; let last=last">
				{{ (pS | paySystem)?.name + (last ? '' : ', ') }}
			</ng-container>
		</span>
		</div>
	</div>
	<div class="statistics">
		<alaris-link (click)="navigate()"
					 *ngIf="company.totalPartners.total; else noClickable"
					 [attr.data-testid]="'toPartnersLink/' + company.id"
					 [link]="''" class="total-partners">
			{{ company.totalPartners.total }}
		</alaris-link>
		<span class="note">{{ 'contract-companies.totalPartners' | translate }}</span>
		<br>
		<div>
			<span class="note">{{ 'contract-companies.youHaveReached' | translate }}</span>
			<b>{{ company.totalEarn | currency: bs.currencyCode }}</b>
			<span class="note">{{ 'contract-companies.inTotalEarnings' | translate }}</span>
		</div>
	</div>

	<ng-template #noClickable>
		<div class="total-partners">
			{{ company.totalPartners.total }}
		</div>
	</ng-template>

</alaris-card>
