// Cartesian product
// https://resultfor.dev/234401-cartesian-product-of-javascript-object-properties
// https://stackoverflow.com/questions/12303989/cartesian-product-of-multiple-arrays-in-javascript

export function product(args: Array<number[]>): Array<number[]> {
	if ( !args.length ) {
		return [[]];
	}
	const prod = product(args.slice(1));
	const r: Array<number[]> = [];
	args[0].forEach(x => {
		prod.forEach(p => {
			r.push([x].concat(p));
		});
	});
	return r;
}


export function cartesianProduct(obj: Record<string, Array<number>>): Record<string, number>[] {
	const keys = Object.keys(obj);
	const values = keys.map(x => obj[x]);
	return product(values).map(p => {
		const e: Record<string, number> = {};
		keys.forEach((k, n) => {
			e[k] = p[n];
		});
		return e;
	});
}

// Chars
export function sumElementsInArray(array: number[]): number {
	return array.reduce((a, b) => a + b, 0);
}

export function calculateBarChartMax(array: number[][], allArraySum = false): number {
	let max = 0;
	if ( allArraySum ) {
		max = array.map(items => Math.max(...items)).reduce((a, b) => a + b, 0);
	} else {
		max = Math.max(...array.flat());
	}
	return Math.ceil(max);
}


export function generateAxisYLabels(min: number, max: number, horizontalLines: number): string[] {
	const step = Math.floor(max / horizontalLines);
	if ( step === 0 ) {
		return [];
	}
	const arr = [];
	for ( let i = min; i <= max; i += step ) {
		arr.push(i.toString());
	}
	return arr;
}

// eslint-disable-next-line
export function sumByObjectProperty(array: any[], property: string): number {
	return array.reduce((accumulator, object) => {
		return accumulator + object[property];
	}, 0);
}

export function fractional(value: number): string {
	return value.toFixed(2).split('.').at(1) ?? '00';
}
