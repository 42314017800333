import { Role } from '@campaign-portal/namespace/common/user';
import { RoleType } from '@campaign-portal/namespace/common/enums';

export enum AP_PERMISSIONS {
	PORTALS_R = 'portals_read',
	PORTALS_E = 'portals_edit',
	PARTNERS_E = 'partners_edit',
	SUBSCR_R = 'subscriptions_read',
	SUBSCR_E = 'subscriptions_edit',
	V_SUBSCR_R = 'vendor_subscriptions_read',
	V_SUBSCR_E = 'vendor_subscriptions_edit',
	ENDPOINTS_R = 'endpoints_read',
	ENDPOINTS_E = 'endpoints_edit',
	PAYMENTS_R = 'payments_read',
	TRANSACTIONS_R = 'admin_transaction_history_read',
	PAYMENTS_E = 'payments_edit',
	SENDERS_R = 'sender_ids_read',
	SENDERS_E = 'sender_ids_edit',
	STATS_R = 'statistics_read',
	USERS_R = 'users_read',
	USERS_E = 'users_edit',
	PAYMENTS_SYSTEMS_E = 'payment_systems_edit',
	REF_BOOK_E = 'ref_book_edit',
	CC_E = 'contract_companies_edit'
}

export enum AP_RESELLER_PERMISSIONS {
	PORTALS_R = 'portals_read',
	PORTALS_E = 'portals_edit',
	PARTNERS_E = 'partners_edit',
	SUBSCR_R = 'subscriptions_read',
	SUBSCR_E = 'subscriptions_edit',
	V_SUBSCR_R = 'vendor_subscriptions_read',
	PAYMENTS_R = 'payments_read',
	TRANSACTIONS_R = 'admin_transaction_history_read',
	PAYMENTS_E = 'payments_edit',
	SENDERS_R = 'sender_ids_read',
	SENDERS_E = 'sender_ids_edit',
	STATS_R = 'statistics_read',
	USERS_R = 'users_read',
	USERS_E = 'users_edit',
	PAYMENTS_SYSTEMS_E = 'payment_systems_edit',
	CC_E = 'contract_companies_edit'
}

export enum CP_PERMISSIONS {
	API_CONNECTION_E = 'api_connection_edit',
	CONTACTS_R = 'contacts_read',
	CONTACTS_E = 'contacts_edit',
	CAMPAIGNS_R = 'campaigns_read',
	CAMPAIGNS_E = 'campaigns_edit',
	MESSAGE_TEMPLATES_R = 'message_templates_read',
	MESSAGE_TEMPLATES_E = 'message_templates_edit',
	SENDERS_R = 'sender_ids_read',
	SENDERS_E = 'sender_ids_edit',
	STATS_R = 'statistics_read',
	SUBSCR_R = 'subscriptions_read',
	SUBSCR_E = 'subscriptions_edit',
	TRANSACTIONS_R = 'transaction_history_read'
}

export const CAMPAIGN_USER: Role = {
	type: RoleType.CAMPAIGN_USER,
	permissions: Object.values(CP_PERMISSIONS)
};

export const ADMIN: Role = {
	type: RoleType.ADMIN,
	permissions: Object.values(AP_PERMISSIONS)
};

export const CLIENT_MANAGER: Role = {
	type: RoleType.CLIENT_MANAGER,
	permissions: [
		AP_PERMISSIONS.PARTNERS_E, AP_PERMISSIONS.SUBSCR_R, AP_PERMISSIONS.PAYMENTS_R, AP_PERMISSIONS.TRANSACTIONS_R,
		AP_PERMISSIONS.SENDERS_R, AP_PERMISSIONS.STATS_R, AP_PERMISSIONS.USERS_R, AP_PERMISSIONS.USERS_E
	]
};
export const NOC: Role = {
	type: RoleType.NOC,
	permissions: [
		AP_PERMISSIONS.PORTALS_R, AP_PERMISSIONS.PORTALS_E, AP_PERMISSIONS.ENDPOINTS_R, AP_PERMISSIONS.ENDPOINTS_E,
		AP_PERMISSIONS.PAYMENTS_SYSTEMS_E, AP_PERMISSIONS.REF_BOOK_E, AP_PERMISSIONS.USERS_R, AP_PERMISSIONS.USERS_E
	]
};
export const RATE_MANAGER: Role = {
	type: RoleType.RATE_MANAGER,
	permissions: [
		AP_PERMISSIONS.SUBSCR_R, AP_PERMISSIONS.SUBSCR_E, AP_PERMISSIONS.V_SUBSCR_R, AP_PERMISSIONS.V_SUBSCR_E,
		AP_PERMISSIONS.ENDPOINTS_R, AP_PERMISSIONS.ENDPOINTS_E
	]
};
export const FINANCE_MANAGER: Role = {
	type: RoleType.FINANCE_MANAGER,
	permissions: [
		AP_PERMISSIONS.PAYMENTS_R, AP_PERMISSIONS.TRANSACTIONS_R, AP_PERMISSIONS.PAYMENTS_E, AP_PERMISSIONS.PAYMENTS_SYSTEMS_E
	]
};

export const ROLES = {
	ADMIN, CAMPAIGN_USER, CLIENT_MANAGER, NOC, RATE_MANAGER, FINANCE_MANAGER
};

export const AP_PERMISSION_GROUPS = {
	administration: [
		AP_PERMISSIONS.CC_E, AP_PERMISSIONS.REF_BOOK_E, AP_PERMISSIONS.PORTALS_R, AP_PERMISSIONS.PORTALS_E,
		AP_PERMISSIONS.USERS_R, AP_PERMISSIONS.USERS_E, AP_PERMISSIONS.SENDERS_R, AP_PERMISSIONS.SENDERS_E
	],
	partners: [AP_PERMISSIONS.PARTNERS_E],
	rates: [AP_PERMISSIONS.SUBSCR_R, AP_PERMISSIONS.SUBSCR_E],
	routing: [
		AP_PERMISSIONS.V_SUBSCR_R, AP_PERMISSIONS.V_SUBSCR_E,
		AP_PERMISSIONS.ENDPOINTS_R, AP_PERMISSIONS.ENDPOINTS_E
	],
	finance: [
		AP_PERMISSIONS.PAYMENTS_SYSTEMS_E, AP_PERMISSIONS.PAYMENTS_R,
		AP_PERMISSIONS.PAYMENTS_E, AP_PERMISSIONS.TRANSACTIONS_R
	],
	statistics: [AP_PERMISSIONS.STATS_R]
};

export const AP_RESELLER_PERMISSION_GROUPS = {
	...AP_PERMISSION_GROUPS,
	administration: [
		AP_PERMISSIONS.CC_E, AP_PERMISSIONS.PORTALS_R, AP_PERMISSIONS.PORTALS_E,
		AP_PERMISSIONS.USERS_R, AP_PERMISSIONS.USERS_E, AP_PERMISSIONS.SENDERS_R, AP_PERMISSIONS.SENDERS_E
	],
	routing: [
		AP_PERMISSIONS.V_SUBSCR_R
	]
};

export const CP_PERMISSION_GROUPS = {
	contacts: [CP_PERMISSIONS.CONTACTS_R, CP_PERMISSIONS.CONTACTS_E],
	campaign: [
		CP_PERMISSIONS.CAMPAIGNS_R, CP_PERMISSIONS.CAMPAIGNS_E, CP_PERMISSIONS.MESSAGE_TEMPLATES_R,
		CP_PERMISSIONS.MESSAGE_TEMPLATES_E, CP_PERMISSIONS.SENDERS_R, CP_PERMISSIONS.SENDERS_E
	],
	statistics: [CP_PERMISSIONS.STATS_R],
	billing: [CP_PERMISSIONS.SUBSCR_R, CP_PERMISSIONS.SUBSCR_E, CP_PERMISSIONS.TRANSACTIONS_R],
	administration: [
		CP_PERMISSIONS.API_CONNECTION_E
	]
};

export type PermissionGroup = keyof (typeof AP_PERMISSION_GROUPS & typeof CP_PERMISSION_GROUPS);
