import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy } from '@angular/core';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { BehaviorSubject, Observable, of, Subject, takeUntil } from 'rxjs';

import { exist } from '@campaign-portal/namespace/common/id';
import { RPCResult } from '@campaign-portal/namespace/common/rpc.response';
import { PaymentSystem } from '@campaign-portal/namespace/entities/payment-systems/specs';

import { PaymentSystemsService } from '../payment-systems.service';
import { CanDeactivateComponent } from '@helpers/can-deactivate/can-deactivate.component';
import { CanDeactivateGuardService } from '@helpers/can-deactivate/can-deactivate.guard';
import { AlarisDialogService } from '@campaign-portal/components-library';

export type PaymentSystemsDialogType = 'Delete';

export interface PaymentSystemsDialogData {
	type: PaymentSystemsDialogType;
	paymentSystem: PaymentSystem<exist>;
}

@Component({
	selector: 'app-payment-system-dialog',
	templateUrl: './payment-system-dialog.component.html',
	styleUrls: ['./payment-system-dialog.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaymentSystemDialogComponent extends CanDeactivateComponent implements OnDestroy {
	readonly paymentSystem!: PaymentSystem<exist>;
	readonly loading$ = new BehaviorSubject<boolean>(false);
	readonly allowedDeactivation = new BehaviorSubject<boolean>(true);
	protected readonly ngUnsubscribe = new Subject<void>();

	constructor(
		@Inject(DIALOG_DATA) public readonly data: PaymentSystemsDialogData,
		private readonly dialogRef: DialogRef<unknown>,
		private readonly cd: ChangeDetectorRef,
		private readonly paymentSystemsService: PaymentSystemsService,
		private readonly guard: CanDeactivateGuardService,
		private readonly dialog: AlarisDialogService
	) {
		super();
		this.addDialogGuard(this.dialog, dialogRef, this.guard);
		this.paymentSystem = data.paymentSystem;
	}

	get disabled(): boolean {
		return this.loading$.getValue();
	}

	close(): void {
		this.dialogRef.close(false);
	}

	confirm(): void {
		this.loading$.next(true);
		let caller: Observable<RPCResult<unknown>> = of({ Success: false });

		switch (this.data.type) {
			case 'Delete':
				caller = this.paymentSystemsService.delete(this.paymentSystem.id);
				break;
			default:
				break;

		}

		this.allowedDeactivation.next(false);
		caller.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
			(resp) => {
				this.allowedDeactivation.next(true);
				this.loading$.next(false);
				this.dialogRef.close(resp.Success);
				this.cd.markForCheck();
			}
		);
	}

	ngOnDestroy(): void {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
		this.loading$.complete();
	}
}
