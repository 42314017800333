<div class="country-network-title">
	<b>{{ 'endpoints.countryAndNetwork' | translate }}</b>
	<span *ngIf="selectionService.selectedModel.selected.length" class="label">
		({{ selectionService.selectedModel.selected.length }})
	</span>
	<button (click)="openDialog()" *ngIf="editable" class="plus" data-testid="addCountyNetworkBtn">
		<alaris-icon name="icon-plus"></alaris-icon>
	</button>
</div>
<div [class.ng-invalid]="invalid" class="container">
	<app-country-network-table *ngIf="selectionService.selectedModel.hasValue(); else stub"
							   [selectionModel]="selectionService.selectedModel"
							   [action]="editable ? delete.bind(this) : null">
	</app-country-network-table>
</div>

<ng-template #stub>
	<div class="add-new">
		{{ noEntryNote | translate }}
		<button (click)="openDialog()"
				alaris-button bType="outline" data-testid="addEntryBtn" size="lg">
			{{ 'gl.addEntry' |translate }}
		</button>
	</div>
</ng-template>

