import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { AlarisTabsComponent } from '@campaign-portal/components-library';
import { SenderSubscription } from '@campaign-portal/namespace/entities/sender-id/specs';
import { exist } from '@campaign-portal/namespace/common/id';

@Injectable({
	providedIn: 'root'
})
export class SubscriptionManageService {
	tabsComponent!: AlarisTabsComponent;
	readonly activeSubscription = new BehaviorSubject<SenderSubscription<exist> | undefined>(undefined);

	setTabsManager(tabsComponent: AlarisTabsComponent): void {
		this.tabsComponent = tabsComponent;
	}

	setSubscriptionToUpdate(s: SenderSubscription<exist> | undefined): void {
		this.activeSubscription.next(s);
	}
}
