import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable, of, Subject } from 'rxjs';

import { ReadResponse } from '@campaign-portal/namespace/common/implementations';

import { EntityField } from '@campaign-portal/namespace/common/entityField';
import { Edr } from '@campaign-portal/namespace/entities/edr/specs';
import {
	AbstractCRUDService,
	AbstractRefService,
	AlarisApiService,
	AlarisLanguageService,
	AlarisProfileService
} from '@campaign-portal/components-library';
import { OwnerService } from '@helpers/services/owner.service';
import { AP_PERMISSIONS } from '@helpers/types/permissions';

@Injectable({
	providedIn: 'root'
})
export class EdrExportFieldsService implements AbstractRefService<EntityField>, AbstractCRUDService {

	readonly entity = '';
	readonly title = '';

	readonly list$ = new BehaviorSubject<EntityField[]>([]);
	readonly map$ = new BehaviorSubject<Map<string, EntityField>>(new Map());

	readonly refresh$ = new Subject<void>();

	readonly loading$ = new BehaviorSubject<boolean>(false);
	readonly load = this.read;

	constructor(
		private readonly api: AlarisApiService,
		private readonly translate: AlarisLanguageService,
		private readonly isOwner: OwnerService,
		private readonly profile: AlarisProfileService
	) {
	}

	get map(): Map<string, EntityField> {
		return this.map$.getValue();
	}

	get list(): EntityField[] {
		return this.list$.getValue();
	}

	fields(variables: string[]): string {
		return variables.map((field) => {
			return this.translate.translate(
				this.map.get(field)?.name || ''
			);
		}, []).join(', ');
	}

	read(): Observable<ReadResponse<EntityField[]>> {
		this.loading$.next(true);
		return this.api.loader<ReadResponse<EntityField[]>>(
			'EdrExportFields.Read'
		).pipe(
			map((resp) => {
				resp.Data = this.pickAvailableFields(resp.Data);

				this.list$.next(resp.Data || []);
				this.map$.next(this.list.reduce((result, item) => {
					result.set(item.variable, item);
					return result;
				}, new Map()));
				this.loading$.next(false);
				return resp;
			})
		);
	}

	create(): Observable<void> {
		return of(undefined);
	}

	delete(): Observable<void> {
		return of(undefined);
	}

	update(): Observable<void> {
		return of(undefined);
	}

	clear(): void {
		this.map$.next(new Map());
		this.list$.next([]);
	}

	private pickAvailableFields(fields: EntityField[]): EntityField[] {
		if ( !this.isOwner.is ) {
			fields = fields.filter(item => item.variable !== 'endpointName');
		}
		const permissions: [AP_PERMISSIONS, keyof Edr][] = [
			[AP_PERMISSIONS.V_SUBSCR_R, 'vendorSubscription'],
			[AP_PERMISSIONS.SENDERS_R, 'senderName'],
			[AP_PERMISSIONS.SUBSCR_R, 'clientSubscription'],
			[AP_PERMISSIONS.ENDPOINTS_R, 'endpointName']
		];

		permissions.forEach(([permission, variable]) => {
			if ( !this.profile.allowed([permission]) ) {
				fields = fields.filter(field => field.variable !== variable);
			}
		});
		return fields;
	}
}

