<alaris-card>
	<div *ngIf="tRows.length > 0 || tRows.length === 0 && isFiltersApplied" class="table-management">
		<div class="left">
			<span class="total" data-testid="total">{{ totalTitle | translate }}: {{ total }}</span>
			<button (click)="editTableFilter()" alaris-button bType="outline" data-testid="editFilterBtn" size="lg">
				<alaris-icon name="icon-filter-vertical"></alaris-icon>
				{{ enableButtons.filters.title | translate }}
				<div *ngIf="isFiltersApplied" class="indicator"></div>
			</button>
		</div>
		<div class="right">
			<button (click)="refresh()" alaris-button bType="outline" data-testid="refreshBtn" size="lg">
				<alaris-icon name="icon-refresh-clock-wise"></alaris-icon>
				{{ 'actions.refreshPage' | translate }}
			</button>
			<button (click)="import()"
					*ngIf="withImport" alaris-button bType="primary" data-testid="importBtn" size="lg">
				<alaris-icon class="in-primary" name="icon-import"></alaris-icon>
				{{ 'rates.importRates' | translate }}
			</button>
		</div>
	</div>
	<div [alarisLoading]="loading$" class="content">
		@if (tRows.length === 0 && !isFiltersApplied) {
			<app-empty-table (importRates)="import()"
							 [disabled]="!withImport"
							 type="import-history">
			</app-empty-table>
		} @else {
			<alaris-table (filterChange)="_applyFilter($event)"
						  (sortingChange)="_applySorting($event)"
						  [ctxActions]="ctxActions"
						  [dblClickRowAction]="download.bind(this)"
						  [filters]="filters"
						  [rowActions]="rowActions"
						  [sorting]="sorting"
						  [tHeaders]="tHeaders"
						  [tRows]="tRows"
						  [templates]="{ file, status, subscriptionId, creationDate}"
						  alarisOverflowFader>
			</alaris-table>
		}
	</div>
	<div class="footer">
		<alaris-pagination (page)="_pageChange($event)"
						   *ngIf="tRows.length > 0 || tRows.length === 0 && isFiltersApplied"
						   [activePage]="activePage"
						   [hidden]="total === 0"
						   [pageSizeOptions]="pageSizeOptions"
						   [pageSize]="pageSize"
						   [total]="total"
						   class="pagination">
		</alaris-pagination>
	</div>
</alaris-card>

<ng-template #file [alarisCellCtx]="tRows[0]" let-data="data">
	<div class="file-column">
		<alaris-tag mode="success">{{ data.file.name.split('.').slice(-1) }}</alaris-tag>
		<span [alarisTooltip]="data.file.name" [overflow]="true" class="name">
			{{ data.file.name }}
		</span>
	</div>
</ng-template>

<ng-template #status [alarisCellCtx]="tRows[0]" let-data="data" let-key="key">
	<alaris-tag [mode]="statusMode(data.status)" [rounded]="true"
				class="no-wrap">
		{{ ('enums.' + data.status) | translate }}
	</alaris-tag>
</ng-template>

<ng-template #subscriptionId [alarisCellCtx]="tRows[0]" let-data="data">
	{{ vsService.map.get(data.subscriptionId)?.name }}
</ng-template>

<ng-template #creationDate [alarisCellCtx]="tRows[0]" let-data="data" let-key="key">
	{{ data.creationDate | date: 'd MMM y, HH:mm' }}
</ng-template>
