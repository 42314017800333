import { ChangeDetectionStrategy, Component, DestroyRef, inject, Inject, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { AbstractConfigService, CONFIG_SERVICE_INJECTOR } from '@campaign-portal/components-library';

import { ActivateService } from './activate.service';

@Component({
	selector: 'app-activate',
	templateUrl: './activate.component.html',
	styleUrls: ['./activate.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActivateComponent implements OnInit, OnDestroy {

	readonly loading$ = new BehaviorSubject<boolean>(true);

	private readonly destroyRef = inject(DestroyRef);

	constructor(
		@Inject(CONFIG_SERVICE_INJECTOR) private readonly appConfigService: AbstractConfigService,
		private readonly activateService: ActivateService,
		private readonly router: Router
	) {
	}

	get style(): string {
		const light = this.appConfigService.themeType$.getValue() !== 'dark';
		return `background-image: url(${light ? 'assets/img/bg-light.png' : 'assets/img/bg-dark.png'})`;
	}

	ngOnInit(): void {
		this.activateService.activate()
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe({
				next: (resp) => {
					this.loading$.next(!resp.Success);
				},
				error: () => {
					setTimeout(() => {
						this.router.navigate(['sign-in']);
					}, 1500);
				}
			});
	}

	ngOnDestroy(): void {
		this.loading$.complete();
	}

}
