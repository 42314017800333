<div [@layoutChanged]="prepareRoute(outlet)">
	<router-outlet #outlet="outlet"></router-outlet>
</div>

<div *ngIf="!environment.production" class="version-container">
	<div class="version">
		{{ environment.version }}
	</div>
</div>

