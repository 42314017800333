<div [alarisLoading]="mccmncService.loading$">
	<div class="edit-panel-header">
		<alaris-icon name="icon-mccmnc"></alaris-icon>
		{{
			(([AP_PERMISSIONS.REF_BOOK_E] | alarisPBAC)
				? (mccmnc?.id ? 'mccmnc.editEntry' : 'mccmnc.createEntry')
				: 'mccmnc.details') | translate
		}}
	</div>
	<div [formGroup]="mccmncForm" alarisFade="vertical" class="form-group edit-panel-content">
		<ng-container>
			<alaris-input [errors]="errors" [label]="'fc.mcc' | translate"
						  [min]="0"
						  [placeholder]="'fc.mcc' | translate"
						  data-testid="mccInput"
						  formControlName="mcc"
						  type="number">
			</alaris-input>
			<alaris-input [alarisDropdown]="countriesDropdown"
						  [label]="'fc.country' | translate"
						  [placeholder]="'fc.country' | translate"
						  [positions]="positions"
						  data-testid="countryNameInput"
						  formControlName="country">
			</alaris-input>
		</ng-container>
		<ng-container>
			<alaris-input [errors]="errors" [label]="'fc.mnc' | translate"
						  [min]="0"
						  [placeholder]="'fc.mnc' | translate"
						  data-testid="mncInput"
						  formControlName="mnc"
						  type="number">
			</alaris-input>
			<alaris-input [alarisDropdown]="networksDropdown"
						  [label]="('fc.network' | translate)"
						  [placeholder]="'fc.network' | translate"
						  [positions]="positions"
						  data-testid="networkNameInput"
						  formControlName="network">
			</alaris-input>
		</ng-container>

		<alaris-input-number [allowNegativeNumbers]="false"
							 [label]="'fc.countryDialCode' | translate"
							 [placeholder]="'fc.countryDialCode' | translate"
							 data-testid="dialCodeInputNumber"
							 formControlName="countryDialCode"
							 maskPattern="separator.0"
							 thousandSeparator="">
		</alaris-input-number>

		<app-network-dial-codes (codesChange)="updateNetworkCodes($event)"
								*ngIf="mccmncForm.controls.countryDialCode.value"
								[codes]="mccmnc?.networkDialCodes || []"
								[countryDialCode]="mccmncForm.controls.countryDialCode.value"
								[editable]="[AP_PERMISSIONS.REF_BOOK_E] | alarisPBAC">
		</app-network-dial-codes>
	</div>
	<div class="edit-panel-footer">
		<button (click)="save()"
				*alarisPBAC="[AP_PERMISSIONS.REF_BOOK_E]"
				[disabled]="mccmncForm.invalid || !mccmncForm.dirty"
				alaris-button bType="primary"
				data-testid="saveBtn"
				size="lg" type="button">
			<alaris-icon class="in-primary" name="icon-success"></alaris-icon>
			{{ (mccmnc?.id ? 'actions.save' : 'actions.create') | translate }}
		</button>
		<button (click)="close()" alaris-button bType="outline" data-testid="closeBtn" size="lg" type="button">
			{{ (([AP_PERMISSIONS.REF_BOOK_E] | alarisPBAC) ? 'actions.cancel' : 'actions.close') | translate }}
		</button>
	</div>
</div>

<alaris-dropdown #countriesDropdown>
	@if (mccmncForm.controls.country.value) {
		<cdk-virtual-scroll-viewport [maxBufferPx]="36 * 7"
									 [minBufferPx]="36 * 7"
									 [style.height.px]="filteredCountries.length * 36"
									 [style.max-height.px]="180"
									 itemSize="36">
			<div *cdkVirtualFor="let country of filteredCountries; let index = index; templateCacheSize: 0"
				 (click)="mccmncForm.controls.country.setValue(country)"
				 class="option"
				 [attr.data-testid]="'countryAutocomplete/' + index">
				{{ country }}
			</div>
		</cdk-virtual-scroll-viewport>
	}
</alaris-dropdown>

<alaris-dropdown #networksDropdown>
	@if (mccmncForm.controls.network.value) {
		<cdk-virtual-scroll-viewport [maxBufferPx]="36 * 7"
									 [minBufferPx]="36 * 7"
									 [style.height.px]="filteredNetworks.length * 36"
									 [style.max-height.px]="180"
									 itemSize="36">
			<div *cdkVirtualFor="let network of filteredNetworks; let index = index; templateCacheSize: 0"
				 (click)="mccmncForm.controls.network.setValue(network)"
				 class="option"
				 [attr.data-testid]="'networkAutocomplete/' + index">
				{{ network }}
			</div>
		</cdk-virtual-scroll-viewport>
	}
</alaris-dropdown>

