import { ChangeDetectionStrategy, Component, Inject, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';

import {
	AlarisDialogService,
	AlarisEditPanelService,
	ChannelUtilsService,
	EditPanelInputData
} from '@campaign-portal/components-library';

import { exist, Id } from '@campaign-portal/namespace/common/id';
import { Rate, RateHistoryEntity } from '@campaign-portal/namespace/entities/rates/specs';
import { RateStatus } from '@campaign-portal/namespace/common/enums';

import { RatesService } from '@helpers/services/rates.service';
import { AP_PERMISSIONS } from '@helpers/types/permissions';
import { VendorSubscriptionsService } from '@helpers/services/vendor-subscriptions.service';
import { CanDeactivateComponent } from '@helpers/can-deactivate/can-deactivate.component';
import { CanDeactivateGuardService } from '@helpers/can-deactivate/can-deactivate.guard';
import { VendorRatesDialogType, VendorsDialogComponent } from '../../vendors-dialog/vendors-dialog.component';
import { CardState } from './edit-rate-card/edit-rate-card.component';
import { EditRateService } from './edit-rate.service';

@Component({
	selector: 'app-edit-rate',
	templateUrl: './edit-rate.component.html',
	styleUrls: ['./edit-rate.component.scss'],
	providers: [EditRateService],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditRateComponent extends CanDeactivateComponent implements OnDestroy {
	readonly CardState = CardState;
	readonly AP_PERMISSIONS = AP_PERMISSIONS;
	readonly rate!: Rate<exist>;
	readonly allowedDeactivation = new BehaviorSubject<boolean>(true);
	protected readonly ngUnsubscribe: Subject<void> = new Subject<void>();

	constructor(
		@Inject(EditPanelInputData) private readonly inputData: EditPanelInputData,
		private readonly editPanel: AlarisEditPanelService,
		public readonly ratesService: RatesService,
		public readonly channelUtilsService: ChannelUtilsService,
		public readonly vsService: VendorSubscriptionsService,
		public readonly mode: EditRateService,
		private readonly guard: CanDeactivateGuardService,
		private readonly dialog: AlarisDialogService
	) {
		super();
		this.addEditPanelGuard(editPanel, this.guard);
		this.rate = this.inputData.rate as Rate<exist>;
	}


	close(): void {
		this.editPanel.close();
	}

	updateRate(rHistory: Omit<RateHistoryEntity, 'type'>): void {
		const rate: Rate<Id> = {
			...this.rate,
			...rHistory
		};

		this.allowedDeactivation.next(false);
		this.ratesService.update(rate, false)
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(
				() => {
					this.allowedDeactivation.next(true);
					// Simple close -> to update rate and all his events
					this.editPanel.close(true);
				}
			);
	}

	onlyClosedRate(): boolean {
		return !!(this.rate.history?.length === 0 && this.rate.closeRate);
	}

	onlyClosedInHistory(): boolean {
		return !!this.rate.history?.length &&
			this.rate.history?.filter(rate => rate.type === RateStatus.CLOSED).length === this.rate.history?.length;
	}

	convertRate(): RateHistoryEntity<Id> {
		const now = new Date();
		const type = this.rate.activeFrom && now < new Date(this.rate.activeFrom)
			? RateStatus.PLANNED
			: this.rate.activeTill && now > new Date(this.rate.activeTill)
				? RateStatus.CLOSED
				: RateStatus.CURRENT;

		return {
			...this.rate,
			type
		};
	}

	ngOnDestroy(): void {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}

	closeRate(): void {
		this.openSpecificDialog('Close', [this.rate]);
	}

	deleteRate(rHistory: RateHistoryEntity<exist>): void {
		// Rewrite id to delete concrete planned rate (in service we use only id, other date don't use)
		const rate: Rate<exist> = {
			...this.rate,
			id: rHistory.id
		};
		this.openSpecificDialog('DeletePeriod', [rate]);
	}

	private openSpecificDialog(type: VendorRatesDialogType, rates: Rate<exist>[] | null = null): void {
		this.dialog.open(VendorsDialogComponent, {
			data: { type, rates },
			autoFocus: false
		}).closed
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((result) => {
				if ( result ) {
					// Simple close -> to update rate and all his events
					this.editPanel.close(true);
				}
			});
	}
}
