import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import {
	AlarisBalanceService,
	AlarisComplexTableComponent,
	AlarisConfigService,
	AlarisEditPanelService,
	AlarisSettingsManagerComponent,
	AlarisTableSettingsService,
	ChannelUtilsService,
	Day,
	EditPanelWidth,
	TableFiltersIndicator,
	TableSortIndicator
} from '@campaign-portal/components-library';

import { TableTypes } from '@campaign-portal/namespace/entities/table-settings/specs';
import { Edr } from '@campaign-portal/namespace/entities/edr/specs';
import { EntityField } from '@campaign-portal/namespace/common/entityField';
import { Endpoint } from '@campaign-portal/namespace/entities/endpoints/specs';
import { exist } from '@campaign-portal/namespace/common/id';
import { FilterInterval, FilterType, Paging, SortDirection } from '@campaign-portal/namespace/common/rpc.params';

import { EdrExportFieldsService } from './edr-export-fields.service';
import { EdrExportService } from './edr-export.service';
import { ChannelsService } from '@helpers/services/channels.service';
import { EdrExportSettingsPanelComponent } from './edr-export-settings-panel/edr-export-settings-panel.component';
import { SendersListService } from '../../senders/senders-list/senders-list.service';
import { EnumsMapperService } from '@helpers/services/enums.service';
import { EdrExportDetailsPanelComponent } from './edr-export-details-panel/edr-export-details-panel.component';
import { BehaviorSubject, map } from 'rxjs';
import { CountriesNetworksService } from '@helpers/services/countries-networks.service';
import { OwnerService } from '@helpers/services/owner.service';
import { EndpointsService } from '../../vendors/endpoints/endpoints.service';

@Component({
	selector: 'app-edr-export',
	templateUrl: './edr-export.component.html',
	styleUrls: [
		// eslint-disable-next-line max-len
		'../../../../../node_modules/@campaign-portal/components-library/src/assets/templates/table-complex/complex-table.component.scss',
		'./edr-export.component.scss'
	],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class EdrExportComponent extends AlarisComplexTableComponent<Edr> implements OnInit, OnDestroy {
	readonly today = Day.currentLocal();

	override readonly pageSize = 150;
	override readonly paging: Paging = {
		Skip: this.activePage,
		Take: this.pageSize
	};

	override sorting: TableSortIndicator = new Map()
		.set('edrDate', { enabled: true, value: SortDirection.DESC })
		.set('campaignName', { enabled: false, value: SortDirection.EMPTY })
		.set('client', { enabled: false, value: SortDirection.EMPTY })
		.set('clientSubscription', { enabled: false, value: SortDirection.EMPTY })
		.set('vendorSubscription', { enabled: false, value: SortDirection.EMPTY })
		.set('endpointName', { enabled: false, value: SortDirection.EMPTY });

	override filters: TableFiltersIndicator = new Map()
		.set('edrDate', {
			enabled: true,
			value: {
				Start: this.config.localTime ? this.today.toISONativeDate() : this.today.toSystemDate(),
				End: this.config.localTime ? this.today.toISONativeDate(true) : this.today.toSystemDate(true)
			},
			filterType: FilterType.BETWEEN
		});

	override readonly id = 'edr-export';


	constructor(
		public readonly edrExportService: EdrExportService,
		public override readonly fieldsService: EdrExportFieldsService,
		public override readonly tableSettingsService: AlarisTableSettingsService,
		public override readonly editPanel: AlarisEditPanelService,
		public override readonly cd: ChangeDetectorRef,
		public readonly bs: AlarisBalanceService,
		public readonly channelService: ChannelsService,
		public readonly cu: ChannelUtilsService,
		private readonly senderIdsService: SendersListService,
		private readonly countriesNetworks: CountriesNetworksService,
		private readonly endpointsService: EndpointsService,
		private readonly isOwner: OwnerService,
		private readonly enums: EnumsMapperService,
		private readonly config: AlarisConfigService
	) {
		super(
			edrExportService,
			fieldsService,
			tableSettingsService,
			editPanel,
			cd,
			TableTypes.EDR
		);
	}

	override ngOnInit(): void {
		super.ngOnInit();
		this.isFiltersApplied = true;
	}

	override applyHeaders(tHeaders: EntityField[]): void {
		super.applyHeaders(tHeaders);
		this.setRefBooksInFields({
			edrStatus: { list$: new BehaviorSubject<string[]>(Object.keys(this.enums.get('EdrStatus'))) },
			messagePurpose: { list$: new BehaviorSubject<string[]>(Object.keys(this.enums.get('MessagePurpose'))) },
			channelId: this.channelService,
			senderName: this.senderIdsService,
			country: { list$: this.countriesNetworks.countriesList$, loading$: this.countriesNetworks.loading$ },
			net: { list$: this.countriesNetworks.networksList$, loading$: this.countriesNetworks.loading$ }
		});

		if ( this.isOwner.is ) {
			const endpointList$ = new BehaviorSubject<Endpoint<exist>[]>([]);
			this.setRefBooksInFields({
				endpointName: { list$: endpointList$, loading$: this.endpointsService.loading$ }
			})
			this.endpointsService.read()
				.pipe(
					takeUntilDestroyed(this.destroyRef),
					map(resp => resp.Data)
				)
				.subscribe(endpoints => endpointList$.next(endpoints));
		}
	}

	override applyFilter(event: TableFiltersIndicator): void {
		super.applyFilter(event);
		this.readParams.Filters = this.readParams.Filters?.filter((f) => {
			switch (f.Field) {
				case 'edrDate':
					if (
						f.Value
						&& !(f.Value as FilterInterval<string | null>)?.Start
						&& !(f.Value as FilterInterval<string | null>)?.End
					) {
						this.filters.set('edrDate', { enabled: true });
						return false;
					}
					break;
				case 'messagePurpose':
					f.Value = (f.Value as string[]).map(i => this.enums.get('MessagePurpose')[i]);
					break;
				case 'edrStatus':
					f.Value = (f.Value as string[]).map(i => this.enums.get('EdrStatus')[i]);
					break;
				case 'endpointName':
				case 'channelId':
					f.Property = 'id';
					break;
				case 'senderName':
					f.Property = 'name';
					break;
				case 'country':
				case 'net':
				default:
					break;
			}
			return true;
		});
	}

	override editTableSettings(): void {
		this.editPanel.open(AlarisSettingsManagerComponent, EditPanelWidth.MD, {
			tHeads: this.tHeaders,
			tSettings: this.tSettings,
			creationFieldAvailable: false,

			tHeadsChange: this.tHeadsChange,
			tSettingsChange: this.tSettingsChange,
			headEvent: this.tHeadEvent
		});
	}

	edrExportSettings(): void {
		this.editPanel.open(EdrExportSettingsPanelComponent, EditPanelWidth.SM, {
			tHeads: this.tHeaders,
			total: this.total,
			readParams: this.readParams
		});
	}

	edrExportDetails(edr?: Edr): void {
		this.editPanel.open(EdrExportDetailsPanelComponent, EditPanelWidth.SM, {
			edr
		});
	}

	override ngOnDestroy(): void {
		super.ngOnDestroy();
		this.completeRefBooksInFields(['edrStatus', 'messagePurpose', 'endpointName']);
	}
}
