<div [alarisLoading]="paymentsService.loading$">
	<div class="edit-panel-header">
		<alaris-icon name="icon-payment"></alaris-icon>
		{{'payments.addPayment' | translate}}
	</div>
	<ng-container *ngIf="(partnerService.map$ | async)?.size; else noPartner">
		<div alarisFade="vertical" [formGroup]="paymentForm" class="form-group edit-panel-content">
			<alaris-select2 [displayWith]="displaySelected"
							[label]="'fc.partner' | translate"
							[placeholder]="'fc.partner' | translate"
							data-testid="partnerSelect" formControlName="partnerId">
				<alaris-select-filter2 *ngIf="((partnerService.list$ | async)?.length || 0) > 5"
									   [formControl]="filterPartnersControl" data-testid="partnerFilter">
				</alaris-select-filter2>
				<alaris-options2 *ngFor="let opt of filterPartnersList"
								 [attr.data-testid]="'partnerOption/' + opt.name" [value]="opt.id">
					{{ opt.name }}
				</alaris-options2>
			</alaris-select2>
			<alaris-input-number [errors]="errors"
								 [label]="'fc.amount' | translate"
								 [placeholder]="'fc.amount' | translate"
								 data-testid="amountInputNumber" formControlName="amount">
			</alaris-input-number>
			<alaris-text-area data-testid="commentTextarea" formControlName="comment">
				{{'fc.comment' | translate}}
			</alaris-text-area>
		</div>
		<div class="edit-panel-footer">
			<button (click)="save()"
					[disabled]="paymentForm.invalid"
					alaris-button bType="primary"
					data-testid="saveBtn"
					size="lg" type="button">
				<alaris-icon class="in-primary" name="icon-success"></alaris-icon>
				{{'actions.create' | translate}}
			</button>
			<button (click)="close()" alaris-button bType="outline" data-testid="closeBtn" size="lg" type="button">
				{{ 'actions.cancel' | translate }}
			</button>
		</div>
	</ng-container>
</div>
<ng-template #noPartner>
	<div class="stub">
		{{'payments.noPartners' | translate}}
		<alaris-link (click)="close()" data-testid="createPartnerLink" link="partners">
			{{ 'partners.createEntry' | translate }}
		</alaris-link>
	</div>
</ng-template>



