import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { AP_PERMISSIONS } from '@helpers/types/permissions';

@Component({
	selector: 'app-dashboard',
	templateUrl: './dashboard.component.html',
	styleUrls: ['./dashboard.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardComponent {
	readonly AP_PERMISSIONS = AP_PERMISSIONS;

	constructor(
		@Inject(DOCUMENT) { body }: Document
	) {
		body.style.font = `var(--as-font-style) var(--as-font-weight) 
							var(--as-body-font-size)/var(--as-line-height) var(--as-font-family)`;
		body.style.color = 'var(--as-TextPrimary)';
	}

}
