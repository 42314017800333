import { ChangeDetectionStrategy, Component, HostListener, Type } from '@angular/core';
import { BehaviorSubject, filter, merge, of, Subject, switchMap, takeUntil } from 'rxjs';
import { DialogRef } from '@angular/cdk/dialog';

import { AlarisDialogService, AlarisEditPanelService } from '@campaign-portal/components-library';
import { CanDeactivateGuardService } from '@helpers/can-deactivate/can-deactivate.guard';

@Component({
	selector: 'app-can-deactivate',
	template: '',
	changeDetection: ChangeDetectionStrategy.OnPush
})
// eslint-disable-next-line rxjs-angular/prefer-takeuntil
export abstract class CanDeactivateComponent {
	abstract allowedDeactivation: BehaviorSubject<boolean>;
	protected abstract ngUnsubscribe: Subject<any>;

	protected constructor() {
	}

	// remove to prompt disappear when reload page
	@HostListener('window:beforeunload', ['$event'])
	unloadNotification($event: BeforeUnloadEvent): void {
		if ( !this.canDeactivate() ) {
			$event.returnValue = true;
		}
	}

	canDeactivate(): boolean {
		return this.allowedDeactivation.getValue();
	}

	addEditPanelGuard(
		editPanel: AlarisEditPanelService,
		guard: CanDeactivateGuardService,
		component?: Type<unknown>
	): void {
		editPanel.canBeClosed$ = this.allowedDeactivation;
		merge(
			editPanel.closingComponent$,
			editPanel.escKeyInComponent$,
			editPanel.backdropClickComponent$
		)
			.pipe(
				takeUntil(this.ngUnsubscribe),
				switchMap(() => {
					return editPanel.canBeClosed
						? of(true)
						: guard.lostDataDialog(component);
				})
			)
			.subscribe(result => {
				this.allowedDeactivation.next(result);
				editPanel.close();
			});
	}

	addDialogGuard(dialog: AlarisDialogService, dialogRef: DialogRef, guard: CanDeactivateGuardService): void {
		let manualClosingResult: unknown;
		dialog.canBeClosed$ = this.allowedDeactivation;
		merge(
			dialogRef.backdropClick,
			dialogRef.keydownEvents.pipe(filter((value: KeyboardEvent) => value.code === 'Escape')),
			dialog.manualClosing$.pipe(filter((closingDialog => {
				if ( closingDialog.ref === dialogRef ) {
					manualClosingResult = closingDialog.result;
					return true;
				}
				return false;
			})))
		)
			.pipe(
				takeUntil(this.ngUnsubscribe),
				switchMap(() => {
					return dialog.canBeClosed
						? of(true)
						: guard.lostDataDialog();
				}))
			.subscribe((canDeactivate) => {
				this.allowedDeactivation.next(canDeactivate);
				if ( canDeactivate ) {
					dialog.defaultClose.call(dialogRef, manualClosingResult);
					manualClosingResult = undefined;
				}
			});
	}
}
