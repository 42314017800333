<alaris-dialog [closeAction]="close.bind(this)"
			   [confirmAction]="confirm.bind(this)"
			   [loading$]="loading$"
			   [disabled]="disabled">
	<h3 slot="header" [ngSwitch]="data.type">
		<ng-container *ngSwitchCase="'Delete'">{{ "payment-systems.deleteEntry" | translate }}</ng-container>
	</h3>
	<div slot="content" class="content">
		<div [ngSwitch]="data.type">
			<ng-container *ngSwitchCase="'Delete'">
				{{ 'payment-systems.deleteEntryNote' | translate }}
				<b>{{paymentSystem.name}}</b> ?
			</ng-container>
		</div>
	</div>
</alaris-dialog>
