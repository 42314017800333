<alaris-card>
	<div *ngIf="companies.length" class="table-management">
		<div class="left">
			<span class="title" data-testid="subTitle">{{ 'contract-companies.manageContract' | translate }}</span>
		</div>
		<div class="right">
			<button (click)="editCompany()"
					*alarisPBAC="[AP_PERMISSIONS.CC_E]"
					alaris-button bType="primary" data-testid="createBtn" size="lg">
				<alaris-icon class="in-primary" name="icon-plus"></alaris-icon>
				{{'contract-companies.add' | translate}}
			</button>
		</div>
	</div>
	<div [alarisFade]="'vertical'" [alarisLoading]="loading$" class="content cards-container">
		<app-empty-table (newContractCompany)="editCompany()"
						 *ngIf="!companies.length; else table"
						 [disabled]="!([AP_PERMISSIONS.CC_E] | alarisPBAC)"
						 type="contract-company">
		</app-empty-table>
		<ng-template #table>
			<app-contract-company-card *ngFor="let company of companies"
									   [actions]="actions"
									   [company]="company">
			</app-contract-company-card>
		</ng-template>
	</div>
	<div class="footer"></div>
</alaris-card>


