<div (cdkDropListDropped)="drop($event, portalsPair)"
	 *ngIf="portals$ | async as portalsPair; else loader"
	 cdkDropList>
	@if (portalsPair.length) {
		@for (portalPair of portalsPair; track $index) {
			<div cdkDrag cdkDragLockAxis="y" class="container">
				<div class="titleName">
					<alaris-icon cdkDragHandle name="icon-drag"></alaris-icon>
					{{ portalPair.ccName }}
				</div>
				<div class="cards">
					@if (portalPair[PortalType.CAMPAIGN_PORTAL] || ([AP_PERMISSIONS.PORTALS_E] | alarisPBAC)) {
						<alaris-card (click)="navigate(portalPair, PortalType.CAMPAIGN_PORTAL)"
									 [attr.data-testid]="'campaignPortalCard/' + portalPair.ccId"
									 size="auto">
							<ng-container *ngTemplateOutlet="cardWithData; context: {
							portal: portalPair[PortalType.CAMPAIGN_PORTAL],
							type: PortalType.CAMPAIGN_PORTAL,
							ccId: portalPair.ccId}">
							</ng-container>
						</alaris-card>
					}
					@if (portalPair[PortalType.ADMIN_PORTAL] || ([AP_PERMISSIONS.PORTALS_E] | alarisPBAC)) {
						<alaris-card (click)="navigate(portalPair)"
									 [attr.data-testid]="'adminPortalCard/' + portalPair.ccId"
									 size="auto">
							<ng-container *ngTemplateOutlet="cardWithData; context: {
							portal: portalPair[PortalType.ADMIN_PORTAL],
							type: PortalType.ADMIN_PORTAL,
							ccId: portalPair.ccId}">
							</ng-container>
						</alaris-card>
					}
				</div>
			</div>
		}
	} @else {
		<alaris-card>
			<app-empty-table class="stretch" (newContractCompany)="goToCC()" type="portals"></app-empty-table>
		</alaris-card>
	}
</div>

<ng-template #cardWithData let-ccId="ccId" let-portal="portal" let-type="type">

	@if (portal?.id) {
		<div class="card">
			<div class="info">
				<alaris-tag class="note" size="lg">
					{{
						(type === PortalType.ADMIN_PORTAL ?
							'portals.adminPortal' : 'portals.campaignPortal') | translate
					}}
				</alaris-tag>
				<span class="name">
					{{ portal.name }}
				</span>
				{{
					'gl.totalSubscribers' | translate : {
						amount: type === PortalType.ADMIN_PORTAL
							? (ccId | cCompany)?.totalPartners?.resellers
							: (ccId | cCompany)?.totalPartners?.retailers
					}
				}}
				<alaris-link [href]="'https://' + portal.host" data-testid="portalHostLink">
					{{ portal.host }}
				</alaris-link>
			</div>
			<div [class]="portal.customSettings.theme" class="preview">
				@if (portal.customSettings.themeType === 'light') {
					<alaris-icon name="icon-light-theme-portal"></alaris-icon>
				}
				@if (portal.customSettings.themeType === 'dark') {
					<alaris-icon name="icon-dark-theme-portal"></alaris-icon>
				}
			</div>
		</div>
	} @else {
		<div class="stub">
			<div class="icon-box">
				<alaris-icon name="icon-plus"></alaris-icon>
			</div>
			<div>
				{{ (type === PortalType.ADMIN_PORTAL ? 'portals.adminPortal' : 'portals.campaignPortal') | translate }}
				<br>
				<span class="note">
				{{
						(type === PortalType.ADMIN_PORTAL ? 'portals.adminPortalNote' :
							'portals.campaignPortalNote') | translate
					}}
			</span>
			</div>
		</div>
	}
</ng-template>

<ng-template #loader>
	<alaris-card [alarisLoading]="portalsService.loading$" class="hidden"></alaris-card>
</ng-template>
