<alaris-card>
	<div *ngIf="tRows.length > 0 || tRows.length === 0 && isFiltersApplied" class="table-management">
		<div class="left">
			<span class="total" data-testid="total">{{ totalTitle | translate }}: {{ total }}</span>
			<!--			<alaris-tabs-button [formControl]="selectedTabFilter" [tabs]="tabsFilter"></alaris-tabs-button>-->
			<button (click)="editTableFilter()" alaris-button bType="outline" data-testid="editFilterBtn" size="lg">
				<alaris-icon class="in-outline" name="icon-filter-vertical"></alaris-icon>
				{{ enableButtons.filters.title | translate }}
				<div *ngIf="isFiltersApplied" class="indicator"></div>
			</button>
		</div>
		<div class="right">
			<button (click)="refresh()" alaris-button bType="outline" data-testid="refreshBtn" size="lg">
				<alaris-icon name="icon-refresh-clock-wise"></alaris-icon>
				{{ 'actions.refreshPage' | translate }}
			</button>
			<ng-container *alarisPBAC="[AP_PERMISSIONS.SENDERS_E]">
				<button (click)="openEditPanel()"
						*ngIf="isOwner.is; else request" alaris-button bType="primary" data-testid="createBtn"
						size="lg">
					<alaris-icon class="in-primary" name="icon-plus"></alaris-icon>
					{{ 'senders.addSenderID' | translate }}
				</button>
			</ng-container>

			<ng-template #request>
				<button (click)="openRequestPanel()" alaris-button bType="primary" data-testid="requestSenderBtn"
						size="lg">
					<alaris-icon class="in-primary" name="icon-plus"></alaris-icon>
					{{ 'senders.requestSenderID' | translate }}
				</button>
			</ng-template>
		</div>
	</div>
	<div [alarisLoading]="loading$" class="content">
		@if (tRows.length === 0 && !isFiltersApplied) {
			<app-empty-table (newSender)="isOwner.is ? openEditPanel() : openRequestPanel()"
							 [disabled]="!([AP_PERMISSIONS.SENDERS_E] | alarisPBAC)"
							 [type]="isOwner.is ? 'sender-list-owner' : 'sender-list-reseller'">
			</app-empty-table>
		} @else {
			<alaris-table (filterChange)="_applyFilter($event)"
						  (sortingChange)="_applySorting($event)"
						  [ctxActions]="ctxActions"
						  [dblClickRowAction]="openEditPanel.bind(this)"
						  [dropDownRowActions]="dropDownRowActions"
						  [filters]="filters"
						  [sorting]="sorting"
						  [tHeaders]="tHeaders"
						  [tRows]="tRows"
						  [templates]="{name, enabled, trafficType, activeFrom, activeTill, documents, lastUpdated}"
						  alarisOverflowFader>
			</alaris-table>
		}
	</div>
	<div class="footer">
		<alaris-pagination (page)="_pageChange($event)"
						   [activePage]="activePage"
						   [hidden]="total === 0"
						   [pageSizeOptions]="pageSizeOptions"
						   [pageSize]="pageSize"
						   [total]="total"
						   class="pagination">
		</alaris-pagination>
	</div>
</alaris-card>

<ng-template #name [alarisCellCtx]="tRows[0]" let-data="data" let-key="key">
	<div class="senderId-column">
		<alaris-icon [name]="cu.icon(data.trafficType)"></alaris-icon>
		{{ data.name }}
		@if (data.subscribers.length) {
			<alaris-tag mode="success">
				{{ 'senders.totalSubs' | translate : {amount: data.subscribers.length} }}
			</alaris-tag>
		}
		@switch (data.lastEvent) {
			@case (SenderSubscriptionEventStatus.DECLINED) {
				<alaris-tag mode="error">{{ 'enums.DECLINED' | translate }}</alaris-tag>
			}
			@case (SenderSubscriptionEventStatus.IN_PROGRESS) {
				<alaris-tag mode="warning">{{ 'enums.IN_PROGRESS' | translate }}</alaris-tag>
			}
		}
	</div>
</ng-template>

<ng-template #enabled [alarisCellCtx]="tRows[0]" let-data="data" let-key="key">
	<div [formGroup]="statusGroup">
		<alaris-toggle #toggle
					   (change)="updateSenderStatus(data)"
					   (click)="toggleStatus($event, data)"
					   [attr.data-testid]="'enabledToggle/' + data.id"
					   [formControlName]="data.id.toString()"
					   [readonly]="toggle.control.disabled" class="enabled-toggle">
		</alaris-toggle>
	</div>
</ng-template>

<ng-template #activeFrom [alarisCellCtx]="tRows[0]" let-data="data" let-key="key">
	{{ data.activeFrom | date: 'd MMM y, HH:mm' }}
</ng-template>

<ng-template #activeTill [alarisCellCtx]="tRows[0]" let-data="data" let-key="key">
	{{ data.activeTill ? (data.activeTill | timeAgo:'':true) : ('gl.neverExpires' | translate) }}
</ng-template>

<ng-template #documents [alarisCellCtx]="tRows[0]" let-data="data" let-key="key">
	<alaris-icon [name]="data.documents?.length ? 'icon-success-_active':'icon-error-_active'" class="documents-column">
	</alaris-icon>
</ng-template>

<ng-template #lastUpdated [alarisCellCtx]="tRows[0]" let-data="data" let-key="key">
	<span>{{ data.lastUpdated ?? '' | timeAgo: ('gl.ago' | translate) }}</span>
</ng-template>

<ng-template #trafficType [alarisCellCtx]="tRows[0]" let-data="data" let-key="key">
	{{ cu.name(data.trafficType) | translate }}
</ng-template>
