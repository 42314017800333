import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Inject,
	Input,
	OnDestroy,
	OnInit,
	Output
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { BehaviorSubject, ReplaySubject, takeUntil } from 'rxjs';

import {
	AbstractSignUpService,
	CustomValidators,
	PasswordRequirements,
	SIGN_UP_FORM_ERRORS,
	SIGN_UP_SERVICE_INJECTOR
} from '@campaign-portal/components-library';

@Component({
	selector: 'app-change-password-by-hand',
	templateUrl: './change-password-by-hand.component.html',
	styleUrls: ['./change-password-by-hand.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChangePasswordByHandComponent implements OnInit, OnDestroy {
	@Input() passwordRequirements: PasswordRequirements = new PasswordRequirements();
	@Output() readonly passwordChange = new EventEmitter<string>();

	readonly loading$ = new BehaviorSubject<boolean>(false);

	readonly errors = SIGN_UP_FORM_ERRORS.password;

	readonly newPassword = new FormControl<string>('', {
		nonNullable: true,
		validators: [
			Validators.required,
			Validators.minLength(this.passwordRequirements.minLength),
			CustomValidators.pattern(this.passwordRequirements.patternNumbers, { requiredDigits: true }),
			CustomValidators.pattern(this.passwordRequirements.patternLiteralCase, { requiredLiteralCase: true }),
			CustomValidators.pattern(this.passwordRequirements.patternSpecialSymbols, { requiredSpecialSymbols: true })
		]
	});

	private readonly ngUnsubscribe = new ReplaySubject<boolean>(1);

	constructor(
		@Inject(SIGN_UP_SERVICE_INJECTOR) private readonly signUpService: AbstractSignUpService
	) {
	}

	ngOnInit(): void {
		this.newPassword.valueChanges
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(v => {
				if ( this.newPassword.valid ) {
					return this.passwordChange.emit(v);
				}
				this.passwordChange.emit('');
			});
	}

	generate(): void {
		this.loading$.next(true);
		this.signUpService.generatePassword()
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe({
				next: (resp) => {
					this.newPassword.setValue(resp.Data.password || this.newPassword.value);
					this.loading$.next(false);
				},
				error: () => {
					this.loading$.next(false);
				}
			});
	}

	ngOnDestroy(): void {
		this.ngUnsubscribe.next(true);
		this.ngUnsubscribe.complete();
		this.loading$.complete();
	}
}
