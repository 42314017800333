import { creating, exist, Id, UUID } from '@campaign-portal/namespace/common/id';
import { CountryCode, NetworkCode } from '@campaign-portal/namespace/entities/mccmnc/specs';
import {
	ConnectionType,
	CreditType,
	DecisionMode,
	EnabledDisabledStatus,
	PaymentSource,
	PaymentStatus,
	PaymentSystemName,
	RateStatus,
	RoleType,
	SubscriptionType,
	TrafficType
} from '@campaign-portal/namespace/common/enums';
import { Payments } from '@campaign-portal/namespace/entities/payments/specs';
import { ContractCompany, TotalPartnersData } from '@campaign-portal/namespace/entities/contract-company/specs';
import { PaymentSystem, PaySystemSettings } from '@campaign-portal/namespace/entities/payment-systems/specs';
import { InputComplexType } from '@campaign-portal/namespace/common/entityField';
import { Endpoint } from '@campaign-portal/namespace/entities/endpoints/specs';
import { ViberSettings } from '@campaign-portal/namespace/common/viberSettings';
import { Package, PackSettings, Subscription } from '@campaign-portal/namespace/entities/subscriptions/specs';
import { Rate, RateHistoryEntity } from '@campaign-portal/namespace/entities/rates/specs';
import { Partner, PartnerFinancialSettings } from '@campaign-portal/namespace/entities/partners/specs';
import { SenderSubscription } from '@campaign-portal/namespace/entities/sender-id/specs';
import { FileInfo } from '@campaign-portal/namespace/common/fileInfo';
import { Permission, User } from '@campaign-portal/namespace/common/user';
import { SubscriptionGroup } from '@campaign-portal/namespace/entities/subscription-groups/specs';

// Classes

export class PaymentsEntity<ID = creating | exist> implements Payments<ID> {
	[x: string]: unknown;

	id: ID;
	amount: number;
	status: PaymentStatus;
	partnerId: Id;
	user: string;
	type: PaymentSource;


	cancelledDate?: string;
	comment?: string;

	constructor(
		id: ID,
		// tslint:disable-next-line:typedef
		amount = 0,
		status: PaymentStatus = PaymentStatus.CONFIRMED,
		// tslint:disable-next-line:typedef
		partnerId = null,
		// tslint:disable-next-line:typedef
		user = '',
		type: PaymentSource = PaymentSource.MANUAL
	) {
		this.id = id;
		this.amount = amount;
		this.status = status;
		this.partnerId = partnerId;
		this.user = user;
		this.type = type;
	}
}

export class SenderSubscriptionEntity<ID = exist | creating> implements SenderSubscription<ID> {
	[key: string]: unknown

	id: ID;
	name: string;
	trafficType: TrafficType;
	subscribers: Id<exist>[];
	enabled: EnabledDisabledStatus;
	activeFrom: string | null; // if null - current time
	activeTill: string | null; // if null - unlimited

	comment?: string | null;
	lastUpdated?: string | null;
	documents?: FileInfo[];
	partnerId?: Id;

	constructor(
		id: ID,
		// tslint:disable-next-line:typedef
		name = '',
		trafficType: TrafficType = TrafficType.SMS,
		subscribers: Id<exist>[] = [],
		enabled: EnabledDisabledStatus = EnabledDisabledStatus.ENABLED,
		activeFrom: string | null = null,
		activeTill: string | null = null
	) {
		this.id = id;
		this.name = name;
		this.trafficType = trafficType;
		this.subscribers = subscribers;
		this.enabled = enabled;
		this.activeFrom = activeFrom;
		this.activeTill = activeTill;
	}
}

export class ContractCompanyEntity<ID = creating | exist> implements ContractCompany<ID> {
	[key: string]: unknown;

	id: ID;
	name: string;
	settings: { [key: string]: unknown };
	isDefault: boolean;
	availableImChannels: Id<exist>[];
	availableCurrencies: Id<exist>[];
	availableSubscriptions: Id<exist>[];
	availablePaySystems: Id<exist>[];
	totalEarn: number;
	totalPartners: TotalPartnersData;

	constructor(
		id: ID,
		// tslint:disable-next-line:typedef
		name = '',
		settings: { [key: string]: unknown } = {},
		// tslint:disable-next-line:typedef
		isDefault = false,
		availableImChannels: Id<exist>[] = [],
		availableCurrencies: Id<exist>[] = [],
		availableSubscriptions: Id<exist>[] = [],
		availablePaySystems: Id<exist>[] = [],
		// tslint:disable-next-line:typedef
		totalEarn = 0,
		totalPartners: TotalPartnersData = {
			resellers: 0,
			retailers: 0,
			total: 0
		}
	) {
		this.id = id;
		this.name = name;
		this.settings = settings;
		this.isDefault = isDefault;
		this.availableImChannels = availableImChannels;
		this.availableCurrencies = availableCurrencies;
		this.availableSubscriptions = availableSubscriptions;
		this.availablePaySystems = availablePaySystems;
		this.totalEarn = totalEarn;
		this.totalPartners = totalPartners;
	}
}

export class UserEntity<ID = UUID | creating> implements User<ID> {
	id: ID;
	email: string;
	login: string;
	details: { [key: string]: unknown };

	disabledAt: string | null;
	permissions: Permission[];

	isActive: boolean;
	partnerId: Id<exist>;
	role: RoleType;

	avatarUrl?: string;
	lastLoginDate?: string;

	constructor(
		id: ID,
		partnerId: Id<exist>,
		details: { [key: string]: unknown } = { firstName: '', lastName: '' },
		// tslint:disable-next-line:typedef
		email = '',
		// tslint:disable-next-line:typedef
		login = '',
		// tslint:disable-next-line:typedef
		disabledAt: string | null = null,
		isActive = true,
		role: RoleType = RoleType.ADMIN,
		permissions: Permission[] = []
	) {
		this.id = id;
		this.partnerId = partnerId;
		this.details = details;
		this.email = email;
		this.login = login;
		this.disabledAt = disabledAt;
		this.isActive = isActive;
		this.role = role;
		this.permissions = permissions;
	}
}

export class PaymentSystemEntity<ID = exist | creating> implements PaymentSystem<ID> {
	id: ID;
	name: string;
	type: PaymentSystemName;
	settings: PaySystemSettings[];
	isActive: boolean;

	constructor(
		id: ID,
		// tslint:disable-next-line:typedef
		name = '',
		type: PaymentSystemName = PaymentSystemName.PAYPAL,
		settings: PaySystemSettings[] = [{
			name: 'fee',
			type: InputComplexType.CHECKBOX,
			value: true
		}],
		// tslint:disable-next-line:typedef
		isActive = false
	) {
		this.id = id;
		this.name = name;
		this.type = type;
		this.settings = settings;
		this.isActive = isActive;
	}
}

export class EndpointEntity<ID = exist | creating> implements Endpoint<ID> {
	[key: string]: unknown

	id: ID;
	name: string;
	endpointProtocol: ConnectionType;
	isActive: boolean;
	isOnline: boolean;
	url: string | null;
	port: number | null;
	login: string;
	password: string;
	priority: number;
	availableCountryNetList: Id<exist>[] | null;
	vendorSubscription: Id<exist>;

	constructor(
		id: ID,
		// tslint:disable-next-line:typedef
		name = '',
		endpointProtocol: ConnectionType = ConnectionType.SMPP,
		// tslint:disable-next-line:typedef
		isActive = true,
		// tslint:disable-next-line:typedef
		isOnline = false,
		// tslint:disable-next-line:typedef
		url = null,
		// tslint:disable-next-line:typedef
		port = null,
		// tslint:disable-next-line:typedef
		login = '',
		// tslint:disable-next-line:typedef
		password = '',
		// tslint:disable-next-line:typedef
		priority = 100,
		availableCountryNetList: Id<exist>[] = [],
		// tslint:disable-next-line:typedef
		vendorSubscription = 0
	) {
		this.id = id;
		this.name = name;
		this.endpointProtocol = endpointProtocol;
		this.isActive = isActive;
		this.isOnline = isOnline;
		this.url = url;
		this.port = port;
		this.login = login;
		this.password = password;
		this.priority = priority;
		this.availableCountryNetList = availableCountryNetList;
		this.vendorSubscription = vendorSubscription;
	}
}

export class ProductEntity<ID = exist | creating> implements Subscription<ID> {
	[key: string]: unknown

	id: ID;
	type: SubscriptionType;
	name: string;
	trafficType: TrafficType;
	currencyId: Id<exist>;
	viberSettings?: ViberSettings;
	billingMode: DecisionMode;

	constructor(
		id: ID,
		currencyId: Id<exist>,
		type: SubscriptionType = SubscriptionType.VENDOR,
		// tslint:disable-next-line:typedef
		name = '',
		trafficType: TrafficType = TrafficType.SMS,
		billingMode = DecisionMode.BY_DELIVERED
	) {
		this.id = id;
		this.currencyId = currencyId;
		this.type = type;
		this.name = name;
		this.trafficType = trafficType;
		this.billingMode = billingMode;
	}
}

export class RateEntity<ID = exist | creating> implements Rate<ID> {
	[key: string]: unknown

	id: ID;
	activeFrom: string;
	activeTill: string | null;
	price: number;
	subscriptionId: Id<exist>;
	currencyId: Id<exist>;
	country: CountryCode;
	network?: NetworkCode;

	constructor(
		id: ID,
		currencyId: Id<exist>,
		activeFrom: string,
		activeTill: string | null,
		subscriptionId: Id<exist>,
		country: CountryCode,
		price = 0
	) {
		this.id = id;
		this.currencyId = currencyId;
		this.activeFrom = activeFrom;
		this.activeTill = activeTill;
		this.subscriptionId = subscriptionId;
		this.country = country;
		this.price = price;
	}
}

export class RateHistory<ID = exist | creating> implements RateHistoryEntity<ID> {
	[key: string]: unknown

	id: ID;
	activeFrom: string | null;
	activeTill: string | null;
	price: number;
	type: RateStatus;

	constructor(
		id: ID,
		activeFrom = null,
		activeTill = null,
		price = 0,
		type: RateStatus = RateStatus.PLANNED
	) {
		this.id = id;
		this.activeFrom = activeFrom;
		this.activeTill = activeTill;
		this.price = price;
		this.type = type;
	}
}

export class PartnerEntity<ID = exist | creating> implements Partner<ID> {
	[key: string]: unknown

	id: ID;
	name: string;
	ccId: Id<exist>;
	isReseller: boolean;
	financialSettings: PartnerFinancialSettings;
	balance: number;
	isActive: boolean;
	plans: Id<exist>[];
	packs: Id<exist>[];

	currencyId: Id<exist>;

	constructor(
		id: ID,
		ccId: Id<exist>,
		currencyId: Id<exist>,
		name = '',
		isReseller = false,
		financialSettings: PartnerFinancialSettings = {
			currencyId,
			creditType: CreditType.PREPAID,
			creditLimit: 0,
			sendAlerts: false
		},
		balance = 0,
		isActive = false,
		plans: Id<exist>[] = [],
		packs: Id<exist>[] = []
	) {
		this.id = id;
		this.ccId = ccId;
		this.currencyId = currencyId;
		this.name = name;
		this.isReseller = isReseller;
		this.financialSettings = financialSettings;
		this.balance = balance;
		this.isActive = isActive;
		this.packs = packs;
		this.plans = plans;
	}
}

export class PackageEntity<ID = exist | creating> implements Package<ID> {
	[key: string]: unknown

	id: ID;
	name: string;
	type: SubscriptionType.PACK;
	trafficType: TrafficType;
	packSettings: PackSettings;
	messagesTotal: number;
	billingMode: DecisionMode;

	availableCountryNetList?: Id<exist>[] | null;

	constructor(
		id: ID,
		availableCountryNetList: Id<exist>[] | null = [],
		name = '',
		type: SubscriptionType.PACK = SubscriptionType.PACK,
		trafficType: TrafficType = TrafficType.SMS,
		packSettings = {
			activeFrom: new Date().toISOString(),
			activeTo: new Date('2100').toISOString(),
			packPrice: 0,
			isPromo: false
		},
		messagesTotal = 0,
		billingMode = DecisionMode.BY_DELIVERED
	) {
		this.id = id;
		this.availableCountryNetList = availableCountryNetList;
		this.name = name;
		this.type = type;
		this.trafficType = trafficType;
		this.packSettings = packSettings;
		this.messagesTotal = messagesTotal;
		this.billingMode = billingMode;
	}
}

export class SubscriptionGroupEntity<ID = exist | creating> implements SubscriptionGroup<ID> {
	constructor(
		public id: ID,
		public name = '',
		public plans: Id<exist>[] = [],
		public packs: Id<exist>[] = []
	) {
	}
}

// Enums

export enum ModelType {
	AVAILABLE = 'available',
	SELECTED = 'selected',
	TEMPORARY = 'temporary'
}

// Types

// Chart
export interface TotalProgressChart {
	label: string;
	total: number;
	color: string;
}

// Temp
