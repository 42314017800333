<div class="edit-panel-header">
	<alaris-icon name="icon-rate-plan-detail"></alaris-icon>
	{{ (([AP_PERMISSIONS.V_SUBSCR_E] | alarisPBAC) ? 'rates.editRate' : 'rates.details') | translate }}
</div>
<div [alarisLoading]="ratesService.loading$" alarisFade="vertical" class="edit-panel-content">
	<div class="overview">
		<div class="grid">
			<b>{{ 'gl.country' | translate }}</b>
			<alaris-icon name="icon-map-pin"></alaris-icon>
			<div>
				{{ rate.country.name }}
				<div class="label">MCC: {{ rate.country.mcc | mccmnc }}</div>
			</div>
			<b>{{ 'gl.network' | translate }}</b>
			<alaris-icon name="icon-wifi"></alaris-icon>
			<div>
				{{ rate.network?.name || ((rate.network?.mnc || rate.network?.mnc === 0) ? '' : ('endpoints.allNetworks' | translate)) }}
				@if (rate.network && (rate.network.mnc || rate.network.mnc === 0)) {
					<div class="label">MNC: {{ rate.network.mnc | mccmnc }}</div>
				}
			</div>
			<b>{{ 'gl.product' | translate }}</b>
			<alaris-icon name="icon-cube"></alaris-icon>
			{{ (vsService.map$ | async)?.get(rate.subscriptionId)?.name }}
			<b>{{ 'gl.channel' | translate }}</b>
			<alaris-icon name="icon-paper-plane-right"></alaris-icon>
			<ng-container *alarisLet="vsService.map.get(rate.subscriptionId) as product">
				{{
					product ? (channelUtilsService.purpose(
						product.trafficType, product.settings?.viberSettings?.messagePurpose) | translate) : ''
				}}
			</ng-container>
		</div>
		<div *alarisPBAC="[AP_PERMISSIONS.V_SUBSCR_E]" class="in-row">
			<button (click)="mode.addMode.set(true)"
					[disabled]="mode.addMode() || mode.editMode()"
					alaris-button
					bType="primary"
					data-testid="addPeriodBtn" size="lg">
				<alaris-icon class="in-primary" name="icon-plus"></alaris-icon>
				{{ 'rates.addPeriod' | translate }}
			</button>
			<button (click)="closeRate()"
					[disabled]="onlyClosedInHistory() || onlyClosedRate() || mode.addMode()"
					alaris-button bType="outline"
					data-testid="closeRateBtn" size="lg">
				<alaris-icon class="in-primary" name="icon-archive-box"></alaris-icon>
				{{ 'rates.closeRate' | translate }}
			</button>
		</div>
	</div>

	<div class="column-name">
		<div></div>
		<div>{{ 'gl.price' | translate }}</div>
		<div>{{ 'gl.startDate' | translate }}</div>
		<div>{{ 'gl.endDate' | translate }}</div>
	</div>
	@if (mode.addMode()) {
		<app-edit-rate-card (updateRate)="updateRate($event)"
							[currency]="(rate.currencyId | alarisCurrency)?.name ?? ''"
							[state]="CardState.CREATE" data-testid="addPeriodRateCard">
		</app-edit-rate-card>
	}

	@if (rate.history?.length) {
		@for (rHistory of rate.history; track $index) {
			<app-edit-rate-card (deleteRate)="deleteRate($event)"
								(updateRate)="updateRate($event)"
								[attr.data-testid]="'rateCard/' + rHistory.id"
								[currency]="(rate.currencyId | alarisCurrency)?.name ?? ''"
								[currentPrice]="rate.price"
								[rHistory]="rHistory">
			</app-edit-rate-card>
		}
	} @else {
		<app-edit-rate-card (deleteRate)="deleteRate($event)"
							(updateRate)="updateRate($event)"
							[currency]="(rate.currencyId | alarisCurrency)?.name ?? ''"
							[currentPrice]="rate.price"
							[rHistory]="convertRate()" data-testid="emptyHistoryRateCard">
		</app-edit-rate-card>
	}
</div>
<div class="edit-panel-footer">
	<button (click)="close()" alaris-button bType="outline" data-testid="closeBtn" size="lg" type="button">
		{{ 'actions.cancel' | translate }}
	</button>
</div>
