<div *ngIf="type===ModelType.AVAILABLE"
	 class="before-table-zone">
	<b>{{ 'gl.available' | translate }}</b>
	<span class="counter">({{ selectionService.availableModel.selected.length }})</span>
	<ng-container *ngIf="selectionService.availableModel.hasValue()">
		<button (click)="selectionService.selectAll()"
				*ngIf="!isFiltersApplied"
				class="bulk-action" data-testid="selectAllBtn">
			{{ 'gl.selectAll' | translate }}
		</button>
		<button (click)="selectionService.selectFiltered(tRows)"
				*ngIf="isFiltersApplied && tRows.length"
				class="bulk-action" data-testid="selectFilteredBtn">
			{{ 'gl.selectFiltered' | translate : {amount: tRows.length} }}
		</button>
	</ng-container>
</div>
<div *ngIf="type===ModelType.TEMPORARY"
	 class="before-table-zone">
	<b>{{ 'gl.selected' | translate }}</b>
	<span class="counter">({{ selectionService.temporaryModel.selected.length }})</span>
	<ng-container *ngIf="selectionService.temporaryModel.hasValue()">
		<button (click)="selectionService.clearAll()"
				*ngIf="!isFiltersApplied"
				class="bulk-action" data-testid="clearAllBtn">
			{{ 'gl.clearAll' | translate }}
		</button>
		<button (click)="selectionService.clearFiltered(tRows)"
				*ngIf="isFiltersApplied && tRows.length"
				class="bulk-action" data-testid="clearFilteredBtn">
			{{ 'gl.clearFiltered' | translate : {amount: tRows.length} }}
		</button>
	</ng-container>
</div>
<cdk-virtual-scroll-viewport [alarisLoading]="loading$"
							 [backdrop]="false"
							 [itemSize]="48"
							 [style.height]="calculateViewPort()">
	<table>
		<thead [style.top]="inverseOfTranslation">
		<tr>
			<th (click)="sorting.get(head.variable)?.enabled ? changeSortDirection(head) : null"
				*ngFor="let head of (tHeaders$ | async) ?? []; let k = index"
				[attr.data-testid]="'header/' + head.id"
				[class.container]="sorting && !filters">
				<!-- filter with or without -->
				<div *ngIf="filters.get(head.variable)?.enabled; else nonFiltered" class="filter-container">
					<alaris-filter (filterEvent)="changeFilter($event)"
								   [field]="head" [value]="filters.get(head.variable)?.value">
					</alaris-filter>
				</div>
				<ng-template #nonFiltered>
					<div class="header">{{ head.name | translate }}</div>
				</ng-template>
				<!-- end of filter with or without -->
				<!-- sorted or not -->
				<div *ngIf="sorting.get(head.variable)?.enabled &&
				 	sorting.get(head.variable)?.value !== SortDirection.EMPTY"
					 [class.active-sort]="
					 	sorting.get(head.variable)?.value === SortDirection.DESC || SortDirection.ASC">
					<div [class.arrow-asc]="sorting.get(head.variable)?.value === SortDirection.ASC"></div>
					<div [class.arrow-desc]="sorting.get(head.variable)?.value === SortDirection.DESC"></div>
				</div>
				<div *ngIf="sorting.get(head.variable)?.enabled &&
				 sorting.get(head.variable)?.value === SortDirection.EMPTY" class="sortable">
					<div class="arrow-desc"></div>
				</div>
				<!-- end of sorted or not -->
			</th>
		</tr>
		</thead>
		<tbody>
		<tr *cdkVirtualFor="let row of tRows" [attr.data-testid]="'row/' + row.id">
			<td *ngFor="let head of (tHeaders$ | async) ?? []; let last = last"
				[style.width]="(100 / ((tHeaders$ | async)?.length || 1)) + '%'"
				class="text-truncate">
				<div [class.with-action]="last" [ngSwitch]="head.variable">
					<ng-container *ngSwitchCase="'mccmnc'">
						{{ (row.mcc | mccmnc) + ' ' + (row.mnc | mccmnc) }}
					</ng-container>
					<ng-container *ngSwitchCase="'country'">
						{{ row.country }}
					</ng-container>
					<ng-container *ngSwitchCase="'network'">
						<div class="name">
							{{ row.network || ((row.mnc || row.mnc === 0) ? 'row.mnc' : ('endpoints.allNetworks' | translate)) }}
						</div>
						<span (click)="action(row)" *ngIf="type === ModelType.AVAILABLE && action"
							  [attr.data-testid]="'selectMCCMNCBtn/' + row.id" data-role="action">
							{{ 'actions.select' | translate }}
						</span>
						<span (click)="action(row)" *ngIf="type === ModelType.TEMPORARY && action"
							  [attr.data-testid]="'removeMCCMNCBtn/' + row.id" data-role="action">
							{{ 'actions.delete' | translate }}
						</span>
						<span *ngIf="type === ModelType.SELECTED && action" data-role="action">
							<alaris-icon (click)="action(row)"
										 [attr.data-testid]="'removeMCCMNCBtn/' + row.id" name="icon-close">
							</alaris-icon>
						</span>
					</ng-container>
					<ng-container *ngSwitchDefault>
						{{ row[head.variable] }}
					</ng-container>
				</div>
			</td>
		</tr>
		</tbody>
	</table>
</cdk-virtual-scroll-viewport>
