import { environment } from '../environments/environment';

import { APP_INITIALIZER, Injectable, LOCALE_ID, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpBackend, HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { DATE_PIPE_DEFAULT_OPTIONS, registerLocaleData } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';
import localeEn from '@angular/common/locales/en';
import localeEnExtra from '@angular/common/locales/extra/en';
import localeEs from '@angular/common/locales/es';
import localeEsExtra from '@angular/common/locales/extra/es';
import localeRu from '@angular/common/locales/ru';
import localeRuExtra from '@angular/common/locales/extra/ru';

import {
	ACCOUNT_SETTINGS_CONFIG,
	ACCOUNT_SETTINGS_SERVICE_INJECTOR,
	AccountSettingsConfig,
	AlarisAccountSettingsService,
	AlarisApiService,
	AlarisAuthService,
	AlarisBalanceService,
	AlarisConfigService,
	AlarisErrorInterceptor,
	AlarisFilesService,
	AlarisLanguageService,
	AlarisLocalStorageService,
	AlarisPaymentMethodsService,
	AlarisProfileService,
	AlarisResponseInterceptor,
	AlarisRpcInterceptor,
	AlarisSignUpService,
	AlarisTableStateService,
	AUTH_SERVICE_INJECTOR,
	BALANCE_SERVICE_INJECTOR,
	CAPTCHA_CONFIG,
	CaptchaConfig,
	CONFIG_SERVICE_INJECTOR,
	DATE_FILTER_END_OF_THE_DAY_INJECTOR,
	English,
	ENVIRONMENT,
	FILES_SERVICE_INJECTOR,
	ICONS_CONFIG,
	LANGUAGE_CONFIG,
	LANGUAGE_SERVICE_INJECTOR,
	LanguageConfig,
	LocaleIdService,
	LOCALSTORAGE_SERVICE_INJECTOR,
	MENU_MQ_SERVICE_INJECTOR,
	MENU_MQ_WIDTH_VALUE,
	MenuSidebarService,
	PAYMENT_METHODS_SERVICE_INJECTOR,
	PROFILE_CONFIG,
	PROFILE_SERVICE_INJECTOR,
	ProfileConfig,
	REPO_SERVICE_INJECTOR,
	Russian,
	SIGN_IN_CONFIG,
	SIGN_IN_SERVICE_INJECTOR,
	SIGN_UP_CONFIG,
	SIGN_UP_SERVICE_INJECTOR,
	SignInConfig,
	SignUpConfig,
	Spain,
	TABLE_STATE_SERVICE_INJECTOR
} from '@campaign-portal/components-library';

import {
	MissingTranslationHandler,
	MissingTranslationHandlerParams,
	TranslateDefaultParser,
	TranslateLoader,
	TranslateModule,
	TranslateParser
} from '@ngx-translate/core';

import { RoleType } from '@campaign-portal/namespace/common/enums';

import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';

import { EditEndpointComponent } from './pages/vendors/endpoints/edit-endpoint/edit-endpoint.component';
import { UsersDialogComponent } from './pages/users/users-dialog/users-dialog.component';
import { ChangePasswordByHandComponent } from './pages/users/users-dialog/change-password-by-hand/change-password-by-hand.component';
import { TransactionDetailsComponent } from './pages/finance/transaction-history/transaction-details/transaction-details.component';
import { MccmncDialogComponent } from './pages/settings/mccmnc/mccmnc-dialog/mccmnc-dialog.component';
import { ActivateComponent } from './auth/activate/activate.component';
import { MainLayoutComponent } from './layouts/main-layout/main-layout.component';
import { PaymentsComponent } from './pages/finance/payments/payments.component';
import { EndpointsComponent } from './pages/vendors/endpoints/endpoints.component';
import { TransactionHistoryComponent } from './pages/finance/transaction-history/transaction-history.component';
import { SubscriptionCardComponent } from './pages/subscriptions/subscriptions-list/subscription-card/subscription-card.component';
import { ContractCompanyDialogComponent } from './pages/settings/contract-companies/contract-company-dialog/contract-company-dialog.component';
import { PartnersDetailsComponent } from './pages/partners/partners-details/partners-details.component';
import {
	ChannelPipe,
	ContractCompanyPipe,
	CurrencyPipe,
	PartnerPipe,
	PaymentSystemPipe,
	ProductPipe,
	SenderPipe,
	SubscriptionGroupPipe
} from '@helpers/repo/repo.pipe';
import { EditPartnerComponent } from './pages/partners/edit-partner/edit-partner.component';
import { PortalsDialogComponent } from './pages/portals/portals-dialog/portals-dialog.component';
import { HorizontalChartComponent } from './pages/dashboard/horizontal-chart/horizontal-chart.component';
import { EditSenderComponent } from './pages/senders/senders-list/edit-sender/edit-sender.component';
import { SendersSubscriptionsComponent } from './pages/senders/senders-subscriptions/senders-subscriptions.component';
import { EnumsMapperService } from '@helpers/services/enums.service';
import { VendorsDialogComponent } from './pages/vendors/vendors-dialog/vendors-dialog.component';
import { SubscriptionDetailsComponent } from './pages/subscriptions/subscriptions-list/subscription-details/subscription-details.component';
import { PartnersDialogComponent } from './pages/partners/partners-dialog/partners-dialog.component';
import { EmptyTableComponent } from '@helpers/components/empty-table/empty-table.component';
import { ChartTemplatesComponent } from './pages/dashboard/chart-utils/chart-templates.component';
import { FilterPipe } from '@helpers/pipes/filter.pipe';
import { CountryNetworkTableComponent } from '@helpers/components/country-network/country-network-table/country-network-table.component';
import { MessageByChannelChartComponent } from './pages/dashboard/message-by-channel-chart/message-by-channel-chart.component';
import { RatesImportComponent } from '@helpers/components/rates-import/rates-import.component';
import { PaymentSystemDialogComponent } from './pages/settings/payment-systems/payment-system-dialog/payment-system-dialog.component';
import { RatesImportPanelComponent } from './pages/subscriptions/subscriptions-list/subscription-details/rates-import-panel/rates-import-panel.component';
import { EditContractCompanyComponent } from './pages/settings/contract-companies/edit-contract-company/edit-contract-company.component';
import { UpdatePaymentSystemComponent } from './pages/settings/payment-systems/update-payment-system/update-payment-system.component';
import { SubscriptionsDialogComponent } from './pages/subscriptions/subscriptions-list/subscriptions-dialog/subscriptions-dialog.component';
import { RequestSenderComponent } from './pages/senders/senders-list/request-sender/request-sender.component';
import { ContractCompanyCardComponent } from './pages/settings/contract-companies/contract-company-card/contract-company-card.component';
import { StyleComponentsLibsModule } from '@helpers/shared/style-components-libs.module';
import { EdrExportSettingsPanelComponent } from './pages/statistics/edr-export/edr-export-settings-panel/edr-export-settings-panel.component';
import { MessageByCountryComponent } from './pages/dashboard/message-by-country/message-by-country.component';
import { EditSenderSubscriptionComponent } from './pages/senders/senders-subscriptions/edit-sendor-subscription/edit-sender-subscription.component';
import { ResetComponent } from './auth/reset/reset.component';
import { EditPortalComponent } from './pages/portals/edit-portal/edit-portal.component';
import { ColorSelectComponent } from '@helpers/components/color-select/color-select.component';
import { SendersListComponent } from './pages/senders/senders-list/senders-list.component';
import { PaymentSystemCardComponent } from './pages/settings/payment-systems/payment-system-card/payment-system-card.component';
import { MessageBySenderComponent } from './pages/dashboard/message-by-sender/message-by-sender.component';
import { PaymentSystemsComponent } from './pages/settings/payment-systems/payment-systems.component';
import { RepoService } from '@helpers/repo/repo.service';
import { EditProductComponent } from './pages/vendors/products/edit-product/edit-product.component';
import { UpdatePaymentComponent } from './pages/finance/payments/update-payment/update-payment.component';
import { SelectItemDialogComponent } from '@helpers/components/country-network/select-item-dialog/select-item-dialog.component';
import { MccmncComponent } from './pages/settings/mccmnc/mccmnc.component';
import { TaskDetailsDialogComponent } from './pages/statistics/edr-export-task/task-details-dialog/task-details-dialog.component';
import { UsersComponent } from './pages/users/users.component';
import { FinanceDialogComponent } from './pages/finance/finance-dialog/finance-dialog.component';
import { RatesExportPanelComponent } from './pages/vendors/rates/rates-export-panel/rates-export-panel.component';
import { SendersComponent } from './pages/senders/senders.component';
import { MessageByStatusChartComponent } from './pages/dashboard/message-by-status-chart/message-by-status-chart.component';
import { RatesComponent } from './pages/vendors/rates/rates.component';
import { EdrExportComponent } from './pages/statistics/edr-export/edr-export.component';
import { RatesAnalysisComponent } from '@helpers/components/rates-import/rates-analysis/rates-analysis.component';
import { EnumsPipe } from '@helpers/pipes/enums.pipe';
import { MessageByClientComponent } from './pages/dashboard/message-by-client/message-by-client.component';
import { ContractCompaniesComponent } from './pages/settings/contract-companies/contract-companies.component';
import { AddPaymentComponent } from './pages/finance/payments/add-payment/add-payment.component';
import { AnalyticsByClientComponent } from './pages/dashboard/analytics-by-client/analytics-by-client.component';
import { AppComponent } from './app.component';
import { PartnersComponent } from './pages/partners/partners.component';
import { EdrExportTaskComponent } from './pages/statistics/edr-export-task/edr-export-task.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { EventsComponent } from './pages/senders/events/events.component';
import { EditMccmncComponent } from './pages/settings/mccmnc/edit-mccmnc/edit-mccmnc.component';
import { ImportHistoryComponent } from '@helpers/components/import-history/import-history.component';
import { MessageByNetComponent } from './pages/dashboard/message-by-net/message-by-net.component';
import { EditRateComponent } from './pages/vendors/rates/edit-rate/edit-rate.component';
import { VendorsComponent } from './pages/vendors/vendors.component';
import { CountryNetworkComponent } from '@helpers/components/country-network/country-network.component';
import { ProductsComponent } from './pages/vendors/products/products.component';
import { StatisticsComponent } from './pages/statistics/statistics.component';
import { SendersDialogComponent } from './pages/senders/senders-dialog/senders-dialog.component';
import { EventsDetailsComponent } from './pages/senders/events/events-details/events-details.component';
import { EditUserComponent } from './pages/users/edit-user/edit-user.component';
import { VirtualScrollTableComponent } from '@helpers/components/virtual-scroll-table/virtual-scroll-table.component';
import { SubscriptionsComponent } from './pages/subscriptions/subscriptions.component';

import { AppRoutingModule } from './app.routing';
import { LostDataDialogComponent } from '@helpers/can-deactivate/lost-data-dialog/lost-data-dialog.component';
import { GoHomeComponent } from './layouts/go-home.component';
import { SignInLayoutComponent } from './layouts/sign-in-layout/sign-in-layout.component';
import { FinanceComponent } from './pages/finance/finance.component';
import { EditRateCardComponent } from './pages/vendors/rates/edit-rate/edit-rate-card/edit-rate-card.component';
import { PartnersSubscriptionsDialogComponent } from './pages/partners/partners-subscriptions-dialog/partners-subscriptions-dialog.component';
import { RatesTableComponent } from './pages/subscriptions/subscriptions-list/subscription-details/rates-table/rates-table.component';
import { SubscribersComponent } from './pages/subscriptions/subscriptions-list/subscription-details/subscribers/subscribers.component';
import { PortalsComponent } from './pages/portals/portals.component';
import { EdrExportDetailsPanelComponent } from './pages/statistics/edr-export/edr-export-details-panel/edr-export-details-panel.component';
import { AllCountriesHintComponent } from '@helpers/components/all-countries-hint/all-countries-hint.component';
import { NetworkDialCodesComponent } from './pages/settings/mccmnc/network-dial-codes/network-dial-codes.component';
import { AlarisMapperPipe } from '@helpers/pipes/mapper.pipe';
import { ViberPriorityComponent } from '@helpers/components/viber-priority/viber-priority.component';
import { MccmncPipe } from './pages/settings/mccmnc/mccmnc.pipe';
import { SubscriptionsListComponent } from './pages/subscriptions/subscriptions-list/subscriptions-list.component';
import { SubscriptionGroupCardComponent } from './pages/subscriptions/subscription-groups/subscription-group-card/subscription-group-card.component';
import { SubscriptionGroupsComponent } from './pages/subscriptions/subscription-groups/subscription-groups.component';
import { SubscriptionGroupDialogComponent } from './pages/subscriptions/subscription-groups/subscription-group-dialog/subscription-group-dialog.component';
import { EditSubscriptionGroupComponent } from './pages/subscriptions/subscription-groups/edit-subscription-group/edit-subscription-group.component';
import { SubscriptionsPickerComponent } from './pages/subscriptions/subscription-groups/subscriptions-picker/subscriptions-picker.component';
import { SubscriptionsPickerTableComponent } from './pages/subscriptions/subscription-groups/subscriptions-picker/subscriptions-picker-table/subscriptions-picker-table.component';
import { SubscriptionsPickerDialogComponent } from './pages/subscriptions/subscription-groups/subscriptions-picker/subscriptions-picker-dialog/subscriptions-picker-dialog.component';
import { SubscriptionsPickerEmptyComponent } from './pages/subscriptions/subscription-groups/subscriptions-picker/subscriptions-picker-empty/subscriptions-picker-empty.component';
import { LostSubscriptionGroupDataDialogComponent } from './pages/subscriptions/subscription-groups/edit-subscription-group/lost-subscription-group-data-dialog/lost-subscription-group-data-dialog.component';

export function HttpLoaderFactory(http: HttpBackend): MultiTranslateHttpLoader {
	return new MultiTranslateHttpLoader(http, [
		{ prefix: './assets/i18n_lib/app.', suffix: '.json?' + environment.version },
		{ prefix: './assets/i18n/app.', suffix: '.json?' + environment.version }
	]);
}

export class MissingTranslationService implements MissingTranslationHandler {
	handle(params: MissingTranslationHandlerParams): string {
		console.warn(`WARN: '${params.key}' is missing in '${params.translateService.currentLang}' locale`);
		return params.key;
	}
}

@Injectable()
export class TranslateParserService extends TranslateDefaultParser {

	constructor() {
		super();
	}

	override getValue(target: any, key: string): any {
		const result = super.getValue(target, key);
		return typeof result === 'object' ? key : result;
	}

}

/* End of Translations */

/* forRoot() Configs */
const translateConfig = {
	loader: {
		provide: TranslateLoader,
		useFactory: HttpLoaderFactory,
		deps: [HttpBackend]
	},
	missingTranslationHandler: {
		provide: MissingTranslationHandler, useClass: MissingTranslationService
	},
	parser: { provide: TranslateParser, useClass: TranslateParserService },
	// if we set `defaultLanguage` to 'en' right here - we force loading en.json with translations
	// so missing translations will be used from en.file
	defaultLanguage: ''
};

const signInConfig: SignInConfig = {
	url: '',
	supportEmail: '',
	signInMethod: 'Auth.SignIn',
	refreshTokenMethod: 'Auth.Refresh',
	resetPasswordMethod: 'Auth.ResetPassword',
	signUpLink: '/sign-up'
};

const signUpConfig: SignUpConfig = {
	url: '',
	signInLink: '/sign-in',
	termsUrl: '',
	privacyUrl: '',
	supportEmail: '',
	signUpMethod: 'Auth.SignUp',
	registrationFieldsMethod: 'RegistrationFields.Read',
	generatePasswordMethod: 'Users.GeneratePassword'
};

const captchaConfig: CaptchaConfig = {
	baseUrl: 'captcha'
};

const profileConfig: ProfileConfig = {
	profileRoute: '/account-settings',
	termsUrl: '',
	privacyUrl: '',
	supportEmail: ''
};

const accountSettingsConfig: AccountSettingsConfig = {
	resetPasswordMethod: 'Users.ResetPassword',
	userUpdateMethod: 'Users.EditPersonalData',
	partnersReadMethod: 'Partners.Read',
	role: RoleType.ADMIN
};

const languageConfig: LanguageConfig = { languages: [English, Spain], currentLanguage: English };
/* End of forRoot() Configs */


/* App Config */
export function loadConfig(
	appConfig: AlarisConfigService,
	store: AlarisLocalStorageService,
	translate: AlarisLanguageService
): () => Promise<void> {
	return (): Promise<void> => appConfig.load().then(
		() => {
			signInConfig.url = appConfig.api;
			signInConfig.supportEmail = appConfig.supportEmail;

			signUpConfig.url = appConfig.api;
			signUpConfig.termsUrl = appConfig.termsUrl;
			signUpConfig.privacyUrl = appConfig.privacyUrl;
			signUpConfig.supportEmail = appConfig.supportEmail;

			profileConfig.termsUrl = appConfig.termsUrl;
			profileConfig.privacyUrl = appConfig.privacyUrl;
			profileConfig.supportEmail = appConfig.supportEmail;

			languageConfig.languages = appConfig.getLanguages();

			const lang = store.get('lang') ?? 'en';
			translateConfig.defaultLanguage = lang;
			registerLocaleData(localeEn, English.id, localeEnExtra);
			registerLocaleData(localeEs, Spain.id, localeEsExtra);
			registerLocaleData(localeRu, Russian.id, localeRuExtra);
			// https://mcvendrell.medium.com/configuring-ngx-translate-to-load-at-startup-in-angular-1995e7dd6fcc
			translate.set(lang);
		}
	);
}

/* End of App Config */

@NgModule({
	declarations: [
		AppComponent,
		// Layouts
		SignInLayoutComponent,
		GoHomeComponent,
		MainLayoutComponent,
		// Auth
		ActivateComponent,
		ResetComponent,
		// Pipes
		EnumsPipe,
		ChannelPipe,
		CurrencyPipe,
		PartnerPipe,
		ProductPipe,
		ContractCompanyPipe,
		SenderPipe,
		PaymentSystemPipe,
		SubscriptionGroupPipe,
		FilterPipe,
		AlarisMapperPipe,

		// Helpers
		EmptyTableComponent,
		CountryNetworkComponent,
		SelectItemDialogComponent,
		CountryNetworkTableComponent,
		VirtualScrollTableComponent,
		LostDataDialogComponent,
		AllCountriesHintComponent,
		ViberPriorityComponent,
		// Shared
		ImportHistoryComponent,
		RatesImportComponent,
		RatesAnalysisComponent,
		// Pages
		// Dashboard
		DashboardComponent,
		ChartTemplatesComponent,
		MessageByChannelChartComponent,
		MessageByStatusChartComponent,
		AnalyticsByClientComponent,
		MessageByNetComponent,
		MessageByClientComponent,
		HorizontalChartComponent,
		MessageBySenderComponent,
		MessageByCountryComponent,
		// Contract Companies
		ContractCompaniesComponent,
		EditContractCompanyComponent,
		ContractCompanyCardComponent,
		ContractCompanyDialogComponent,
		// Edr Export
		StatisticsComponent,
		EdrExportComponent,
		EdrExportTaskComponent,
		EdrExportDetailsPanelComponent,
		EdrExportSettingsPanelComponent,
		TaskDetailsDialogComponent,
		// Finance
		FinanceComponent,
		PaymentsComponent,
		TransactionHistoryComponent,
		TransactionDetailsComponent,
		FinanceDialogComponent,
		UpdatePaymentComponent,
		AddPaymentComponent,
		// Senders
		SendersComponent,
		EventsComponent,
		SendersListComponent,
		SendersDialogComponent,
		EditSenderComponent,
		EventsDetailsComponent,
		SendersSubscriptionsComponent,
		EditSenderSubscriptionComponent,
		RequestSenderComponent,
		// MCCMNC
		MccmncComponent,
		EditMccmncComponent,
		NetworkDialCodesComponent,
		MccmncDialogComponent,
		// Users
		UsersComponent,
		UsersDialogComponent,
		EditUserComponent,
		ChangePasswordByHandComponent,
		// Payment Systems
		PaymentSystemsComponent,
		PaymentSystemCardComponent,
		PaymentSystemDialogComponent,
		UpdatePaymentSystemComponent,
		// Rates
		VendorsComponent,
		VendorsDialogComponent,
		RatesComponent,
		RatesExportPanelComponent,
		EditRateComponent,
		EditRateCardComponent,
		// Products
		ProductsComponent,
		EditProductComponent,
		// Endpoints
		EndpointsComponent,
		EditEndpointComponent,
		// Partners
		PartnersComponent,
		PartnersDialogComponent,
		PartnersDetailsComponent,
		EditPartnerComponent,
		PartnersSubscriptionsDialogComponent,
		// Subscriptions
		SubscriptionsComponent,
		SubscriptionsListComponent,
		SubscriptionCardComponent,
		SubscriptionsDialogComponent,
		SubscriptionDetailsComponent,
		SubscribersComponent,
		RatesTableComponent,
		RatesImportPanelComponent,
		// Subscription groups
		SubscriptionGroupsComponent,
		EditSubscriptionGroupComponent,
		SubscriptionGroupCardComponent,
		SubscriptionGroupDialogComponent,
		SubscriptionsPickerComponent,
		SubscriptionsPickerTableComponent,
		SubscriptionsPickerDialogComponent,
		SubscriptionsPickerEmptyComponent,
		LostSubscriptionGroupDataDialogComponent,

		// Portals
		PortalsComponent,
		EditPortalComponent,
		ColorSelectComponent,
		PortalsDialogComponent,
		MccmncPipe
	],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		HttpClientModule,
		AppRoutingModule,
		ReactiveFormsModule,
		TranslateModule.forRoot(translateConfig),
		// Alaris, Taiga UI, Angular CDK libraries
		StyleComponentsLibsModule
	],
	providers: [
		{ provide: DATE_PIPE_DEFAULT_OPTIONS, useValue: { timezone: '+0', dateFormat: 'd MMM y, HH:mm' } },
		AlarisConfigService,
		AlarisLocalStorageService,
		AlarisApiService,
		AlarisAuthService,
		AlarisSignUpService,
		AlarisProfileService,
		AlarisLanguageService,
		AlarisFilesService,
		AlarisBalanceService,
		AlarisPaymentMethodsService,
		AlarisAccountSettingsService,
		AlarisTableStateService,

		EnumsMapperService,

		{ provide: ENVIRONMENT, useValue: environment },
		{ provide: LOCALE_ID, useClass: LocaleIdService },
		{
			provide: APP_INITIALIZER,
			useFactory: loadConfig,
			deps: [
				AlarisConfigService,
				AlarisLocalStorageService,
				AlarisLanguageService
			],
			multi: true
		},

		{ provide: CAPTCHA_CONFIG, useValue: captchaConfig },
		{ provide: LANGUAGE_CONFIG, useValue: languageConfig },
		{ provide: PROFILE_CONFIG, useValue: profileConfig },
		{ provide: ICONS_CONFIG, useValue: { baseUrl: '/assets/icons' } },
		{ provide: ACCOUNT_SETTINGS_CONFIG, useValue: accountSettingsConfig },

		{ provide: SIGN_IN_CONFIG, useValue: signInConfig },
		{ provide: SIGN_UP_CONFIG, useValue: signUpConfig },

		{ provide: LOCALSTORAGE_SERVICE_INJECTOR, useExisting: AlarisLocalStorageService },
		{ provide: CONFIG_SERVICE_INJECTOR, useExisting: AlarisConfigService },
		{ provide: REPO_SERVICE_INJECTOR, useExisting: RepoService },
		{ provide: AUTH_SERVICE_INJECTOR, useExisting: AlarisAuthService },
		{ provide: SIGN_IN_SERVICE_INJECTOR, useExisting: AlarisAuthService },
		{ provide: SIGN_UP_SERVICE_INJECTOR, useExisting: AlarisSignUpService },
		{ provide: PROFILE_SERVICE_INJECTOR, useExisting: AlarisProfileService },
		{ provide: LANGUAGE_SERVICE_INJECTOR, useExisting: AlarisLanguageService },
		{ provide: FILES_SERVICE_INJECTOR, useExisting: AlarisFilesService },
		{ provide: BALANCE_SERVICE_INJECTOR, useExisting: AlarisBalanceService },
		{ provide: PAYMENT_METHODS_SERVICE_INJECTOR, useExisting: AlarisPaymentMethodsService },
		{ provide: ACCOUNT_SETTINGS_SERVICE_INJECTOR, useExisting: AlarisAccountSettingsService },
		{ provide: TABLE_STATE_SERVICE_INJECTOR, useExisting: AlarisTableStateService },

		{ provide: HTTP_INTERCEPTORS, useClass: AlarisRpcInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: AlarisResponseInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: AlarisErrorInterceptor, multi: true },

		{ provide: DATE_FILTER_END_OF_THE_DAY_INJECTOR, useValue: true },
		{ provide: MENU_MQ_SERVICE_INJECTOR, useClass: MenuSidebarService },
		{ provide: MENU_MQ_WIDTH_VALUE, useValue: '(max-width: 1365px)' },
		MccmncPipe
	],
	bootstrap: [AppComponent]
})
export class AppModule {
	constructor() {
		console.log(environment.version);
	}
}
