import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable, of } from 'rxjs';

import {
	AbstractCRUDService,
	AlarisApiService,
	AlarisLanguageService,
	AlarisProfileService,
	AlarisToasterService,
	ErrorNotifierConfig,
	ExtendableRefBookService,
	PROFILE_SERVICE_INJECTOR
} from '@campaign-portal/components-library';

import {
	SenderEventActionRequest,
	SenderEventActionResponse,
	SenderSubscriptionEvent
} from '@campaign-portal/namespace/entities/sender-id/specs';
import { RPCRequestParams } from '@campaign-portal/namespace/common/rpc.params';
import { ReadResponse, UpdateResponse } from '@campaign-portal/namespace/common/implementations';
import { SenderSubscriptionEventStatus, SenderSubscriptionEventType } from '@campaign-portal/namespace/common/enums';
import { exist } from '@campaign-portal/namespace/common/id';
import { AP_PERMISSIONS } from '@helpers/types/permissions';

@Injectable({
	providedIn: 'root'
})
export class EventsService extends ExtendableRefBookService<SenderSubscriptionEvent<exist>> implements AbstractCRUDService {
	override readonly loading$ = new BehaviorSubject<boolean>(false);
	private readonly errorNotifier = (): ErrorNotifierConfig => ({ title: this.title });

	constructor(
		private readonly api: AlarisApiService,
		private readonly alarisToaster: AlarisToasterService,
		private readonly langService: AlarisLanguageService,
		@Inject(PROFILE_SERVICE_INJECTOR) private readonly profile: AlarisProfileService
	) {
		super();
	}

	get entity(): string {
		return this.langService.translate('notifications.entities.event');
	}

	get title(): string {
		return this.langService.translate('notifications.titles.event');
	}

	create(): Observable<void> {
		return of(undefined);
	}

	update(event: SenderSubscriptionEvent): Observable<UpdateResponse<SenderSubscriptionEvent>> {
		const params = {
			Data: {
				Entities: [event]
			}
		};
		const notify = (response: UpdateResponse<SenderSubscriptionEvent>): void => {
			if ( response.Success ) {
				const message = this.langService.translate('notifications.actions.update', {
					entity: this.entity, name: ''
				});
				this.alarisToaster.success(message, this.title);
			}
		};
		return this.api.loader<UpdateResponse<SenderSubscriptionEvent>>(
			'SenderEvents.Update', params, this.loading$, this.errorNotifier, notify
		);
	}

	read(params?: RPCRequestParams): Observable<ReadResponse<SenderSubscriptionEvent<exist>[]>> {
		let request: Observable<ReadResponse<SenderSubscriptionEvent<exist>[]>> = of({
			Success: true,
			Total: 0,
			Data: []
		});
		if ( this.profile.allowed([AP_PERMISSIONS.SENDERS_E]) ) {
			request = this.api.loader<ReadResponse<SenderSubscriptionEvent<exist>[]>>(
				'SenderEvents.Read', params, this.loading$, this.errorNotifier
			);
		}
		return request.pipe(map(super.process.bind(this)));
	}

	delete(): Observable<void> {
		return of(undefined);
	}

	confirm(event: SenderSubscriptionEvent): Observable<SenderEventActionResponse> {
		const params: SenderEventActionRequest = {
			Data: {
				Ids: [event.sender.id],
				Entities: [event]
			}
		};
		const notify = (response: SenderEventActionResponse): void => {
			if ( response.Success ) {
				const message = this.langService.translate('notifications.actions.confirm', { entity: this.entity });
				this.alarisToaster.success(message, this.title);
			}
		};
		return this.api.loader<SenderEventActionResponse>(
			'SenderEvents.Confirm', params, this.loading$, this.errorNotifier, notify
		);
	}

	decline(event: SenderSubscriptionEvent): Observable<SenderEventActionResponse> {
		const params: SenderEventActionRequest = {
			Data: {
				Ids: [event.sender.id],
				Entities: [event]
			}
		};
		const notify = (response: SenderEventActionResponse): void => {
			if ( response.Success ) {
				const message = this.langService.translate('notifications.actions.decline', { entity: this.entity });
				this.alarisToaster.success(message, this.title);
			}
		};
		return this.api.loader<SenderEventActionResponse>(
			'SenderEvents.Decline', params, this.loading$, this.errorNotifier, notify
		);
	}

	cancel(event: SenderSubscriptionEvent): Observable<SenderEventActionResponse> {
		const params: SenderEventActionRequest = {
			Data: {
				Ids: [event.sender.id],
				Entities: [event]
			}
		};
		const notify = (response: SenderEventActionResponse): void => {
			if ( response.Success ) {
				const message = this.langService.translate('notifications.actions.cancel', { entity: this.entity });
				this.alarisToaster.success(message, this.title);
			}
		};
		return this.api.loader<SenderEventActionResponse>(
			'SenderEvents.Cancel', params, this.loading$, this.errorNotifier, notify
		);
	}

	unsubscribe(event: SenderSubscriptionEvent): Observable<SenderEventActionResponse> {
		const params: SenderEventActionRequest = {
			Data: {
				Ids: [event.sender.id],
				Entities: [event]
			}
		};
		const notify = (response: SenderEventActionResponse): void => {
			if ( response.Success ) {
				const message = this.langService.translate(
					'notifications.actions.unsubscribe',
					{ entity: this.entity }
				);
				this.alarisToaster.success(message, this.title);
			}
		};
		return this.api.loader<SenderEventActionResponse>(
			'SenderEvents.Unsubscribe', params, this.loading$, this.errorNotifier, notify
		);
	}

	inProgress(e: SenderSubscriptionEvent): Observable<SenderEventActionResponse> {
		const event: SenderSubscriptionEvent = {
			...e,
			type: SenderSubscriptionEventType.SUBSCRIPTION_UPDATE,
			status: SenderSubscriptionEventStatus.IN_PROGRESS
		};
		const params: SenderEventActionRequest = {
			Data: {
				Ids: [event.sender.id],
				Entities: [event]
			}
		};
		const notify = (response: SenderEventActionResponse): void => {
			if ( response.Success ) {
				const message = this.langService.translate('notifications.actions.put', { entity: this.entity });
				this.alarisToaster.success(message, this.title);
			}
		};
		return this.api.loader<SenderEventActionResponse>(
			'SenderEvents.Process', params, this.loading$, this.errorNotifier, notify
		);
	}

	getStatusColor(status: SenderSubscriptionEventStatus): string {
		const map: { [key in SenderSubscriptionEventStatus]: string } = {
			[SenderSubscriptionEventStatus.NEW]: 'var(--as-BgInfo)',
			[SenderSubscriptionEventStatus.IN_PROGRESS]: 'var(--as-BgTertiaryExtra)',
			[SenderSubscriptionEventStatus.CANCELLED]: 'var(--as-IconNegative)',
			[SenderSubscriptionEventStatus.DECLINED]: 'var(--as-IconNegative)',
			[SenderSubscriptionEventStatus.CONFIRMED]: 'var(--as-BgPositive)',
			[SenderSubscriptionEventStatus.UNSUBSCRIBED]: '',
			[SenderSubscriptionEventStatus.PERIOD_CHANGED]: ''
		};
		return map[status];
	}
}
