import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable, of } from 'rxjs';

import { ReadResponse } from '@campaign-portal/namespace/common/implementations';
import { RPCRequestParams } from '@campaign-portal/namespace/common/rpc.params';
import { AnalyticsByClient } from '@campaign-portal/namespace/entities/charts/specs';

import { AbstractCRUDService, AlarisApiService, AlarisLanguageService } from '@campaign-portal/components-library';

import { sumByObjectProperty } from '@helpers/utils/math';


class Analytics implements Record<string, number> {
	readonly revenue!: number;
	readonly cost!: number;
	readonly margin!: number;
	readonly totalMsg!: number;

	constructor(
		revenue = 0,
		cost = 0,
		margin = 0,
		totalMsg = 0
	) {
		Object.assign(this, { revenue, cost, margin, totalMsg });
	}

	[x: string]: number;

}

@Injectable({
	providedIn: 'root'
})
export class AnalyticsByClientService implements AbstractCRUDService {

	readonly entity = '';

	readonly analytics$ = new BehaviorSubject<Analytics>(new Analytics());
	firstLoading$ = new BehaviorSubject<boolean>(true);
	readonly loading$ = new BehaviorSubject<boolean>(false);

	constructor(
		private readonly api: AlarisApiService,
		private readonly langService: AlarisLanguageService
	) {
	}

	get title(): string {
		return this.langService.translate('notifications.titles.chart');
	}

	create(): Observable<void> {
		return of(undefined);
	}

	delete(): Observable<void> {
		return of(undefined);
	}

	update(): Observable<void> {
		return of(undefined);
	}

	read(params?: RPCRequestParams): Observable<ReadResponse<AnalyticsByClient[]>> {
		return this.api.loader<ReadResponse<AnalyticsByClient[]>>(
			'Charts.AnalyticsByClient', params, this.loading$ 
		).pipe(
			map((analytics) => {
				this.firstLoading$ = new BehaviorSubject<boolean>(false);
				this.analytics$.next(new Analytics(
					sumByObjectProperty(analytics.Data, 'revenue'),
					sumByObjectProperty(analytics.Data, 'cost'),
					sumByObjectProperty(analytics.Data, 'margin'),
					sumByObjectProperty(analytics.Data, 'totalMessage')
				));
				return analytics;
			})
		);
	}
}
