import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';

import { AlarisDialogService } from '@campaign-portal/components-library';

import { exist, Id } from '@campaign-portal/namespace/common/id';
import { Endpoint } from '@campaign-portal/namespace/entities/endpoints/specs';
import { Package } from '@campaign-portal/namespace/entities/subscriptions/specs';

import { CountryNetworkFieldsService } from '@helpers/components/country-network/country-network.fields.service';
import { SelectItemDialogComponent } from '@helpers/components/country-network/select-item-dialog/select-item-dialog.component';
import { CountryNetworkSelectionService } from '@helpers/components/country-network/country-network-selection.service';
import { CountryNetworkRef } from '@campaign-portal/namespace/entities/mccmnc/specs';

@Component({
	selector: 'app-country-network',
	templateUrl: './country-network.component.html',
	styleUrls: ['./country-network.component.scss'],
	providers: [CountryNetworkFieldsService]

})
export class CountryNetworkComponent implements OnInit, OnDestroy {

	@Input() entity!: Endpoint | Package;
	@Input() editable = true;
	@Input() noEntryNote = '';
	@Input() invalid = false;
	@Output() readonly selectionChange = new EventEmitter<Id<exist>[] | null>();

	private readonly ngUnsubscribe = new Subject<void>();

	constructor(
		private readonly dialog: AlarisDialogService,
		public readonly selectionService: CountryNetworkSelectionService
	) {
	}

	ngOnInit(): void {
		this.selectionService.setSelected(this.entity.availableCountryNetList ?? null);
	}

	delete(mccmnc: CountryNetworkRef<exist>): void {
		this.selectionService.delete(mccmnc, this.selectionService.selectedModel);
		const selected = this.selectionService.selectedModel.selected.map(i => i.id);
		this.selectionChange.emit(selected);
	}

	public openDialog(): void {
		this.dialog.open(SelectItemDialogComponent)
			.closed
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((result) => {
				if ( result ) {
					const selected = this.selectionService.selectedModel.selected.map(i => i.id);
					this.selectionChange.emit(selected);
				}
			});
	}

	ngOnDestroy(): void {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}
}
