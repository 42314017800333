import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, merge, Subject, takeUntil } from 'rxjs';

import {
	AlarisEditPanelService,
	AlarisLanguageService,
	AlarisProfileService,
	EditPanelInputData,
	filterWildcardData,
	InputError
} from '@campaign-portal/components-library';

import { exist, Id } from '@campaign-portal/namespace/common/id';
import { ConnectionType, HTTPMethodType } from '@campaign-portal/namespace/common/enums';
import { Endpoint } from '@campaign-portal/namespace/entities/endpoints/specs';
import { Subscription } from '@campaign-portal/namespace/entities/subscriptions/specs';
import { SubscriptionGroup } from '@campaign-portal/namespace/entities/subscription-groups/specs';

import { EndpointsService } from '../endpoints.service';

import { AP_PERMISSIONS } from '@helpers/types/permissions';
import { VendorSubscriptionsService } from '@helpers/services/vendor-subscriptions.service';
import { CanDeactivateComponent } from '@helpers/can-deactivate/can-deactivate.component';
import { CanDeactivateGuardService } from '@helpers/can-deactivate/can-deactivate.guard';
import { SubscriptionGroupsService } from '../../../subscriptions/subscription-groups/subscription-groups.service';

@Component({
	selector: 'app-edit-endpoint',
	templateUrl: './edit-endpoint.component.html',
	styleUrls: ['./edit-endpoint.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditEndpointComponent extends CanDeactivateComponent implements OnInit, OnDestroy {
	readonly ConnectionType = ConnectionType;
	readonly AP_PERMISSIONS = AP_PERMISSIONS;
	readonly endpoint!: Endpoint;

	endpointForm!: FormGroup;
	smppSettingsForm!: FormGroup;
	httpSettingsForm!: FormGroup;
	readonly availableHTTPMethods: HTTPMethodType[] = [HTTPMethodType.POST, HTTPMethodType.GET];
	readonly filterSubControl = new FormControl('');
	readonly filterSubGroupsControl = new FormControl('');
	filterSubList: Subscription<exist>[] = [];
	filterSubGroupsList: SubscriptionGroup<exist>[] = [];
	readonly errors: InputError[] = [
		{ key: 'min', value: 'errors.min', params: { value: '1' } },
		{ key: 'max', value: 'errors.max', params: { value: '100' } }
	];

	readonly tabs = [
		{
			label: ConnectionType.SMPP,
			value: ConnectionType.SMPP
		},
		{
			label: ConnectionType.HTTP,
			value: ConnectionType.HTTP
		}
	];

	readonly allowedDeactivation = new BehaviorSubject<boolean>(true);

	protected readonly ngUnsubscribe: Subject<void> = new Subject<void>();

	constructor(
		@Inject(EditPanelInputData) private readonly inputData: EditPanelInputData,
		private readonly editPanel: AlarisEditPanelService,
		public readonly endpointsService: EndpointsService,
		public readonly vsService: VendorSubscriptionsService,
		public readonly subGroupsService: SubscriptionGroupsService,
		public readonly profile: AlarisProfileService,
		private readonly guard: CanDeactivateGuardService,
		private readonly translate: AlarisLanguageService
	) {
		super();
		this.addEditPanelGuard(editPanel, this.guard);
		this.endpoint = this.inputData.endpoint as Endpoint;
	}

	ngOnInit(): void {
		this.vsService.list$
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((list) => {
				this.filterSubList = filterWildcardData(this.filterSubControl.value, list, 'name');
			});

		merge(
			this.subGroupsService.list$,
			this.filterSubGroupsControl.valueChanges
		)
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(() => {
				this.filterSubGroupsList = filterWildcardData(
					this.filterSubGroupsControl.value,
					this.subGroupsService.list,
					'name'
				);
			});

		this.endpointForm = new FormGroup({
			name: new FormControl(this.endpoint.name, Validators.required),
			priority: new FormControl(
				this.endpoint.priority,
				[Validators.required, Validators.min(1), Validators.max(100)]
			),
			endpointProtocol: new FormControl(this.endpoint.endpointProtocol, Validators.required),
			port: new FormControl(
				{
					value: this.endpoint.port,
					disabled: this.endpoint.endpointProtocol === ConnectionType.HTTP
				},
				{ validators: Validators.required }
			),
			isActive: new FormControl(this.endpoint.isActive, Validators.required),
			// settings
			url: new FormControl(
				{
					value: this.endpoint.url,
					disabled: this.endpoint.endpointProtocol === ConnectionType.HTTP
				},
				{ validators: Validators.required }
			),
			login: new FormControl(this.endpoint.login, Validators.required),
			password: new FormControl(this.endpoint.password, Validators.required),
			availableCountryNetList: new FormControl(this.endpoint.availableCountryNetList, Validators.required),
			vendorSubscription: new FormControl(this.endpoint.vendorSubscription || null, Validators.required),
			groups: new FormControl<Id<exist>[] | null>([...this.endpoint.groups ?? []])
		});

		this.smppSettingsForm = new FormGroup({
			systemType: new FormControl(this.endpoint.smppSettings?.systemType),
			serviceType: new FormControl(this.endpoint.smppSettings?.serviceType)
		});

		this.httpSettingsForm = new FormGroup({
			sendRequestSettings: new FormGroup({
				method: new FormControl(this.endpoint.httpSettings?.sendRequestSettings?.method),
				additionalHeaders: new FormControl(this.endpoint.httpSettings?.sendRequestSettings?.additionalHeaders),
				requestTemplate: new FormControl(this.endpoint.httpSettings?.sendRequestSettings?.requestTemplate),
				bodyTemplate: new FormControl(this.endpoint.httpSettings?.sendRequestSettings?.bodyTemplate)
			}),
			dlrRequestSettings: new FormGroup({
				method: new FormControl(this.endpoint.httpSettings?.dlrRequestSettings?.method),
				additionalHeaders: new FormControl(this.endpoint.httpSettings?.dlrRequestSettings?.additionalHeaders),
				requestTemplate: new FormControl(this.endpoint.httpSettings?.dlrRequestSettings?.requestTemplate),
				bodyTemplate: new FormControl(this.endpoint.httpSettings?.dlrRequestSettings?.bodyTemplate)
			})
		});

		if ( !this.profile.allowed([AP_PERMISSIONS.ENDPOINTS_E]) ) {
			this.endpointForm.disable();
			this.smppSettingsForm.disable();
			this.httpSettingsForm.disable();
		}

		this.endpointForm.controls.endpointProtocol.valueChanges
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((value) => {
				if ( value === ConnectionType.HTTP ) {
					this.endpointForm.controls.port.disable();
					this.endpointForm.controls.url.disable();
				} else {
					this.endpointForm.controls.port.enable();
					this.endpointForm.controls.url.enable();
				}
			});

		this.filterSubControl.valueChanges
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((value) => {
				this.filterSubList = filterWildcardData(value, this.vsService.list, 'name');
			});
	}

	displayVendorProduct = (id: Id<exist>): string => {
		const sub = this.vsService.list.find(i => i.id === id);
		return sub?.name ?? '';
	};

	displayGroups = (ids: Id<exist>[] | null): string => {
		if ( ids === null ) {
			return this.translate.translate('subscriptions.groups.allGroups');
		}
		return ids.map((id) => {
			return this.subGroupsService.map.get(id)?.name ?? '';
		}).join(', ');
	};

	countryNetworkUpdate(ids: Id<exist>[] | null): void {
		this.endpointForm.get('availableCountryNetList')?.setValue(ids);
		this.endpointForm.get('availableCountryNetList')?.markAsDirty();
	}

	close(endpoint?: Endpoint<exist>): void {
		this.editPanel.close(endpoint);
	}

	save(): void {
		const groups = this.endpointForm.controls.groups.value ?? this.subGroupsService.list.map(item => item.id);
		const endpoint: Endpoint = {
			...this.endpoint,
			...this.endpointForm.value,
			groups
		};

		if ( this.endpointForm.get('endpointProtocol')?.value === ConnectionType.SMPP ) {
			endpoint.smppSettings = { ...this.smppSettingsForm.value };
		} else {
			endpoint.httpSettings = { ...this.httpSettingsForm.value };
			endpoint.url = null;
			endpoint.port = null;
		}

		this.allowedDeactivation.next(false);
		this.endpointsService.update(endpoint)
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((response) => {
				this.allowedDeactivation.next(true);
				if ( response.Success ) {
					this.close((response.Data as Endpoint<exist>[])[0]);
				}
			});
	}

	ngOnDestroy(): void {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}
}
