import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';

import { ReadResponse } from '@campaign-portal/namespace/common/implementations';
import { RPCRequestParams } from '@campaign-portal/namespace/common/rpc.params';

import {
	AbstractCRUDService,
	AlarisApiService,
	AlarisLanguageService,
	AlarisToasterService,
	ErrorNotifierConfig
} from '@campaign-portal/components-library';

import { exist } from '@campaign-portal/namespace/common/id';
import { RPCResult } from '@campaign-portal/namespace/common/rpc.response';
import { EdrExport } from '@campaign-portal/namespace/entities/edr/specs';

@Injectable({
	providedIn: 'root'
})
export class EdrExportTaskService implements AbstractCRUDService {
	readonly loading$ = new BehaviorSubject<boolean>(false);
	private readonly errorNotifier = (): ErrorNotifierConfig => ({ title: this.title });

	constructor(
		private readonly api: AlarisApiService,
		private readonly alarisToaster: AlarisToasterService,
		private readonly langService: AlarisLanguageService
	) {
	}

	get entity(): string {
		return this.langService.translate('notifications.entities.edrTask');
	}

	get title(): string {
		return this.langService.translate('notifications.titles.edrTask');
	}

	create(): Observable<void> {
		return of(undefined);
	}

	delete(): Observable<undefined> {
		return of(undefined);
	}

	update(): Observable<undefined> {
		return of(undefined);
	}

	read(params?: RPCRequestParams): Observable<ReadResponse<EdrExport<exist>[]>> {
		return this.api.loader<ReadResponse<EdrExport<exist>[]>>(
			'EdrExport.Read', params, this.loading$, this.errorNotifier
		);
	}

	cancel(task: EdrExport<exist>): Observable<RPCResult<EdrExport<exist>>> {
		const params: RPCRequestParams = { Data: { Ids: [task.id] } };
		const notify = (response: RPCResult<EdrExport<exist>>): void => {
			const message = this.langService.translate('notifications.actions.cancel', { entity: this.entity });
			if ( response.Success ) {
				this.alarisToaster.success(message, this.title);
			}
		};
		return this.api.loader<RPCResult<EdrExport<exist>>>(
			'EdrExport.Cancel', params, this.loading$, this.errorNotifier, notify
		);
	}

	restart(task: EdrExport<exist>): Observable<RPCResult<EdrExport<exist>>> {
		const params: RPCRequestParams = { Data: { Ids: [task.id] } };
		const notify = (response: RPCResult<EdrExport<exist>>): void => {
			const message = this.langService.translate('notifications.actions.restart', { entity: this.entity });
			if ( response.Success ) {
				this.alarisToaster.success(message, this.title);
			}
		};
		return this.api.loader<RPCResult<EdrExport<exist>>>(
			'EdrExport.Restart', params, this.loading$, this.errorNotifier, notify
		);
	}
}
