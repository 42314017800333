import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';

import { RatesImportRequest } from '@campaign-portal/namespace/entities/rates/specs';
import { Id } from '@campaign-portal/namespace/common/id';

import { AlarisEditPanelService, EditPanelInputData } from '@campaign-portal/components-library';

@Component({
	selector: 'app-rates-import-panel',
	templateUrl: './rates-import-panel.component.html',
	styleUrls: ['./rates-import-panel.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class RatesImportPanelComponent {

	readonly id!: Id;

	constructor(
		@Inject(EditPanelInputData) private readonly inputData: EditPanelInputData,
		private readonly editPanel: AlarisEditPanelService
	) {
		this.id = this.inputData.id as Id;
	}

	close(): void {
		this.editPanel.close();
	}

	rateUpload(params: RatesImportRequest): void {
		this.editPanel.close(params);
	}
}
