import { Injectable } from '@angular/core';

import {
	AlarisTableStateService,
	filterWildcardData,
	LocalTableService,
	LocalTableUtils
} from '@campaign-portal/components-library';

import { SenderSubscription } from '@campaign-portal/namespace/entities/sender-id/specs';
import { exist } from '@campaign-portal/namespace/common/id';
import { RPCRequestParams } from '@campaign-portal/namespace/common/rpc.params';
import { ReadResponse } from '@campaign-portal/namespace/common/implementations';
import { Channel } from '@campaign-portal/namespace/entities/channels/specs';
import { EnabledDisabledStatus } from '@campaign-portal/namespace/common/enums';
import { ValueObject } from '@campaign-portal/namespace/common/valueObject';

import { SendersListService } from './senders-list.service';
import { map, Observable, of } from 'rxjs';

@Injectable()
export class SenderListTableService extends LocalTableService<SenderSubscription<exist>> {

	override readonly applySorting = SenderListTableService._applySorting;
	override readonly applyFilters = SenderListTableService._applyFilters;

	constructor(
		public override readonly service: SendersListService,
		override readonly stateService: AlarisTableStateService
	) {
		super(service, stateService);
	}

	static _applyFilters(
		result: ReadResponse<SenderSubscription<exist>[]>,
		params: RPCRequestParams
	): ReadResponse<SenderSubscription<exist>[]> {
		const filters = params.Filters;
		if ( filters === undefined ) {
			return result;
		} else {
			let data = result.Data;
			filters.forEach((filter) => {
				switch (filter.Field) {
					case 'name':
						data = filterWildcardData(filter.Value as string, data, 'name');
						break;
					case 'enabled':
						if ( filter.Value ) {
							data = data.filter((sender) => {
								return sender.enabled === (filter.Value as ValueObject).value
									|| sender.enabled === filter.Value;
							});
						}
						break;
					case 'trafficType':
						if ( filter.Value ) {
							const channels = (filter.Value as Channel[]).map(ch => ch.channelType);
							data = data.filter(sender => channels.includes(sender.trafficType));
						}
						break;
					case 'activeFrom':
					case 'activeTill':
					case 'lastUpdated':
						data = LocalTableUtils.filterDateRange(filter, data as Record<string, string>[]) as SenderSubscription<exist>[];
						break;
					case 'documents':
						data = data.filter((sender) => {
							return filter.Value
								? sender.documents && sender.documents.length > 0
								: !sender.documents || sender.documents.length === 0;
						});
						break;
					case 'partner':
						if ( filter.Value ) {
							data = LocalTableUtils.filterIdsArray(filter, data as Record<string, number>[]) as SenderSubscription<exist>[];
						}
						break;
					default:
						break;
				}
			});
			return { Success: true, Total: data.length, Data: data };
		}
	}

	static _applySorting(
		result: ReadResponse<SenderSubscription<exist>[]>,
		params: RPCRequestParams
	): ReadResponse<SenderSubscription<exist>[]> {
		const sorting = params.Sorting?.reverse();
		if ( !sorting ) {
			return result;
		} else {
			let data = result.Data;
			sorting.forEach((sort) => {
				data = data.sort((a, b) => {
					let first: any;
					let second: any;
					switch (sort.Field) {
						case 'name':
							first = a.name;
							second = b.name;
							break;
						case 'enabled':
							first = a.enabled === EnabledDisabledStatus.ENABLED ? 1 : 0;
							second = b.enabled === EnabledDisabledStatus.ENABLED ? 1 : 0;
							break;
						case 'trafficType':
							first = a.trafficType;
							second = b.trafficType;
							break;
						case 'activeFrom':
						case 'activeTill':
						case 'lastUpdated':
							first = a[sort.Field] ? new Date(a[sort.Field] as string) : a[sort.Field];
							second = b[sort.Field] ? new Date(b[sort.Field] as string) : b[sort.Field];
							break;
						case 'partner':
							// implemented in SenderSubscriptionsTableService
							break;
						case 'documents':
							first = a.documents !== undefined && a.documents.length > 0 ? 1 : 0;
							second = b.documents !== undefined && b.documents.length > 0 ? 1 : 0;
							break;
						default:
							break;
					}

					return LocalTableUtils.sortComparisonFn(sort.Dir, first, second);
				});
			});
			return { Data: data, Total: result.Total, Success: true };
		}
	}

	override read(params: RPCRequestParams): Observable<ReadResponse<SenderSubscription<exist>[]>> {
		if ( this.firstLoad ) {
			this.firstLoad = false;
			return this.service.load()
				.pipe(map(() => {
					this.list = this.service.list;
					return this.parse(this.list, params);
				}));
		}
		this.list = this.service.list;
		return of(this.parse(this.list, params));
	}
}
