@if (state === CardState.DEFAULT) {
	<div [class.type-current]="rHistory.type === RateStatus.CURRENT"
		 class="box">
		<div [class.opacity]="rHistory.type === RateStatus.CLOSED"
			 class="default-mode">
			<div class="line"></div>
			<div [class.higher]="currentPrice < rHistory.price"
				 [class.lower]="currentPrice > rHistory.price">
				{{ rHistory.price | number : '1.0-5' }} {{ currency }}
			</div>
			<div>{{ rHistory.activeFrom | date: 'd MMM y, HH:mm' }}</div>
			<div>
				{{ rHistory.activeTill ? (rHistory.activeTill | date: 'd MMM y, HH:mm') : ('gl.neverExpires' | translate) }}
			</div>
			<alaris-tag [invert]="rHistory.type === RateStatus.CURRENT"
						[mode]="rHistory.type === RateStatus.CURRENT ? 'success' : 'gray'">
				{{ 'enums.' + rHistory.type | translate }}
			</alaris-tag>
			@if (!mode.editMode() && (rHistory.type !== RateStatus.CLOSED) &&
			([AP_PERMISSIONS.V_SUBSCR_E] | alarisPBAC)) {
				<button (click)="$event.stopPropagation()"
						[alarisDropdown]="dropdown"
						[positions]="position"
						class="action-button" data-testid="actionsDropdownToggle">
					<alaris-icon name="icon-dot-vertical"></alaris-icon>
				</button>
			}
		</div>
	</div>
}

@if (state === CardState.CREATE) {
	<div [formGroup]="rHistoryForm" class="box">
		<div class="edit-mode">
			<div class="line"></div>
			<div class="form-group">
				<div class="title">{{ 'rates.addPeriod' | translate }}</div>
				<div class="in-row">
					<alaris-input-number [allowNegativeNumbers]="false"
										 [autofocus]="true"
										 [errors]="errors"
										 [label]="'gl.price' | translate"
										 [step]="0.00001"
										 data-testid="priceInputNumber"
										 formControlName="price" size="md">
						<span extra>{{ currency }}</span>
					</alaris-input-number>
					<alaris-input-date [label]="'gl.startDate' | translate"
									   [min]="Day.currentLocal()"
									   [toSystemDate]
									   [withTime]="true"
									   data-testid="activeFromInputDate"
									   formControlName="activeFrom" size="md">
					</alaris-input-date>
					<alaris-input-date [errors]="errors"
									   [label]="'gl.endDate' | translate"
									   [min]="Day.currentLocal()"
									   [placeholder]="'gl.neverExpiresShort' | translate"
									   [stateAddonEnabled]="{ reset: true, state: false }"
									   [toSystemDate]
									   [withTime]="true"
									   data-testid="activeTillInputDate"
									   formControlName="activeTill" size="md">
					</alaris-input-date>
				</div>
				@if (rHistoryForm.hasError('moreThan')) {
					<span class="error">{{ 'rates.moreThan' | translate }}</span>
				}
				<div class="in-row">
					<button (click)="save()"
							[disabled]="rHistoryForm.invalid || !rHistoryForm.dirty"
							alaris-button
							bType="primary"
							data-testid="createBtn"
							size="lg" type="button">
						{{ 'actions.create'  | translate }}
					</button>
					<button (click)="closeAddPeriodMode()"
							alaris-button bType="outline" data-testid="cancelBtn" size="lg" type="button">
						{{ 'actions.cancel' | translate }}
					</button>
				</div>
			</div>
		</div>
	</div>
}

@if (state === CardState.EDIT) {
	<div [class.type-current]="rHistory.type === RateStatus.CURRENT"
		 [formGroup]="rHistoryForm"
		 class="box">
		<div class="edit-mode">
			<div class="line"></div>
			<div class="form-group">
				<div class="in-row">
					<span class="title">{{ 'rates.editPeriod' | translate }}</span>
					<alaris-tag [invert]="rHistory.type === RateStatus.CURRENT"
								[mode]="rHistory.type === RateStatus.CURRENT?'success': 'gray'">
						{{ 'enums.' + rHistory.type | translate }}
					</alaris-tag>
				</div>
				<div class="in-row">
					<alaris-input-number [allowNegativeNumbers]="false"
										 [errors]="errors"
										 [label]="'gl.price' | translate"
										 [step]="0.00001"
										 data-testid="priceInputNumber"
										 formControlName="price"
										 id="price" size="md">
						<span extra>{{ currency }}</span>
					</alaris-input-number>
					<alaris-input-date [label]="'gl.startDate' | translate"
									   [min]="Day.currentLocal()"
									   [placeholder]="'rates.pickDate' | translate"
									   [toSystemDate]
									   [withTime]="true"
									   data-testid="activeFromInputDate"
									   formControlName="activeFrom" size="md">
					</alaris-input-date>
					<alaris-input-date [errors]="errors"
									   [label]="'gl.endDate' | translate"
									   [min]="Day.currentLocal()"
									   [placeholder]="'gl.neverExpiresShort' | translate"
									   [stateAddonEnabled]="{ reset: true, state: false }"
									   [toSystemDate]
									   [withTime]="true"
									   data-testid="activeTillInputDate"
									   formControlName="activeTill" size="md">
					</alaris-input-date>
				</div>
				@if (rHistoryForm.hasError('moreThan')) {
					<span class="error">{{ 'rates.moreThan' | translate }}</span>
				}
				<div class="in-row">
					<button (click)="save()"
							[disabled]="rHistoryForm.invalid || !rHistoryForm.dirty"
							alaris-button
							bType="primary"
							data-testid="saveBtn"
							size="lg" type="button">
						{{ 'actions.save'  | translate }}
					</button>
					<button (click)="close()"
							alaris-button bType="outline" data-testid="cancelBtn" size="lg" type="button">
						{{ 'actions.cancel' | translate }}
					</button>
					@if (rHistory.type === RateStatus.PLANNED) {
						<button (click)="delete()"
								[disabled]="rHistoryForm.invalid"
								alaris-button
								bType="outline"
								class="to-right"
								data-testid="deleteBtn"
								size="lg" type="button">
							<alaris-icon name="icon-delete"></alaris-icon>
							{{ 'actions.delete' | translate }}
						</button>
					}
				</div>
			</div>
		</div>
	</div>
}

<alaris-dropdown #dropdown>
	<alaris-dropdown-actions [actions]="actions" [attr.data-testid]="'dropdownActions/' + rHistory.id"
							 [row]="rHistory">
	</alaris-dropdown-actions>
</alaris-dropdown>
