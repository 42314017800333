import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import {
	AlarisComplexTableComponent,
	AlarisDialogService,
	AlarisEditPanelService,
	AlarisProfileService,
	AlarisTableSettingsService,
	AlarisTableStateService,
	DEFAULT_BUTTONS,
	EditPanelWidth,
	RowActionSimple,
	TableSortIndicator
} from '@campaign-portal/components-library';

import { TableTypes } from '@campaign-portal/namespace/entities/table-settings/specs';
import { exist } from '@campaign-portal/namespace/common/id';
import { Endpoint } from '@campaign-portal/namespace/entities/endpoints/specs';
import { EntityField } from '@campaign-portal/namespace/common/entityField';

import { EndpointEntity } from '@helpers/types/app.classes-interfaces';
import { EndpointsFieldsService } from './endpoints.fields.service';
import { EditEndpointComponent } from './edit-endpoint/edit-endpoint.component';
import { VendorRatesDialogType, VendorsDialogComponent } from '../vendors-dialog/vendors-dialog.component';
import { AllCountriesHintComponent } from '@helpers/components/all-countries-hint/all-countries-hint.component';
import { SortDirection } from '@campaign-portal/namespace/common/rpc.params';
import { EndpointsTableService } from './endpoints.table.service';
import { AP_PERMISSIONS } from '@helpers/types/permissions';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { CountriesNetworksService } from '@helpers/services/countries-networks.service';
import { SubscriptionGroupsService } from '../../subscriptions/subscription-groups/subscription-groups.service';

@Component({
	selector: 'app-endpoints',
	templateUrl: './endpoints.component.html',
	styleUrls: [
		// eslint-disable-next-line max-len
		'../../../../../node_modules/@campaign-portal/components-library/src/assets/templates/table-complex/complex-table.component.scss',
		'./endpoints.component.scss'
	],
	providers: [EndpointsFieldsService, EndpointsTableService],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class EndpointsComponent extends AlarisComplexTableComponent<Endpoint<exist>> implements OnInit {
	readonly AP_PERMISSIONS = AP_PERMISSIONS;
	override readonly id = 'endpoints';
	// eslint-disable-next-line
	override readonly mainActions: RowActionSimple<any>[] = this.getActions();
	override readonly ctxActions: RowActionSimple<Endpoint | Endpoint[]>[] = [...this.mainActions].map(a => ({
		...a,
		icon: ''
	}));

	override sorting: TableSortIndicator = new Map()
		.set('name', { enabled: true, value: SortDirection.ASC })
		.set('availableCountryNetList', { enabled: false, value: SortDirection.EMPTY })
		.set('groups', { enabled: false, value: SortDirection.EMPTY });

	readonly statusGroup = new FormGroup({});

	constructor(
		public readonly entityService: EndpointsTableService,
		public override readonly fieldsService: EndpointsFieldsService,
		public override readonly tableSettingsService: AlarisTableSettingsService,
		public override readonly editPanel: AlarisEditPanelService,
		public override readonly cd: ChangeDetectorRef,
		public override readonly stateService: AlarisTableStateService,
		private readonly dialog: AlarisDialogService,
		private readonly profile: AlarisProfileService,
		public readonly subGroupsService: SubscriptionGroupsService,
		public readonly countriesNetworks: CountriesNetworksService
	) {
		super(
			entityService,
			fieldsService,
			tableSettingsService,
			editPanel,
			cd,
			TableTypes.INTERNAL,
			DEFAULT_BUTTONS,
			stateService
		);
		this.entityService.stateID = this.id;
	}

	getCountryList(idList: number[] | null): string[] {
		const countries: string[] = [];
		idList?.forEach(id => {
			const mccmnc = this.countriesNetworks.map.get(id);
			if ( mccmnc ) {
				countries.push(`${mccmnc.country} - ${mccmnc.network}`);
			}
		});
		return [...new Set(countries)].sort(); // Array of Uniq Country names with alphabet sorting
	}

	getSubscriptionGroupList(idList?: number[]): string {
		const groups: string[] = [];
		idList?.slice(0, 2).forEach(id => {
			const group = this.subGroupsService.map.get(id);
			if ( group ) {
				groups.push(group.name);
			}
		});
		return groups.join(', ');
	}

	seeAllCountries(countries: string[]): void {
		this.dialog.open(AllCountriesHintComponent, {
			data: { items: countries },
			autoFocus: '__no_exist_element__'
		});
	}

	override load(): void {
		this.saveState();
		this.loading$.next(true);
		this.dataService.read(this.readParams)
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe((resp) => {
				this.tRows = resp.Data ?? [];
				this.setStateControls();
				this.selection = this.selection ? [] : this.selection;
				this.total = resp.Total ?? 0;
				this.loading$.next(false);
			});
	}

	override refresh(): void {
		this.entityService.firstLoad = true;
		this.load();
	}

	override applyHeaders(tHeaders: EntityField[]): void {
		super.applyHeaders(tHeaders);
		this.setRefBooksInFields({
			groups: this.subGroupsService
		});
	}

	openEditPanel(entity?: Endpoint): void {
		const endpoint = entity ? entity : new EndpointEntity(null);
		this.editPanel.open<unknown, { endpoint: Endpoint }, Endpoint<exist>>(
			EditEndpointComponent, EditPanelWidth.SM, { endpoint }
		)
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe(((result: Endpoint<exist>) => {
				if ( result ) {
					// eslint-disable-next-line @typescript-eslint/no-unused-expressions
					this.statusGroup.get(result.id.toString()) ?
						this.statusGroup.get(result.id.toString())?.setValue(result.isActive) :
						this.statusGroup.addControl((result.id).toString(), new FormControl(result.isActive));
					this.refresh();
				}
			}));
	}

	updateStatus(endpoint: Endpoint<exist>): void {
		this.entityService.update({
			...endpoint,
			isActive: this.statusGroup.get(endpoint.id.toString())?.value
		})
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe(() => this.refresh());
	}

	delete(endpoint?: Endpoint<exist>): void {
		this.openSpecificDialog('Delete', endpoint);
	}

	private openSpecificDialog(
		type: VendorRatesDialogType,
		endpoint?: Endpoint<exist>
	): void {
		this.dialog.open(VendorsDialogComponent, {
			data: { endpoint, type },
			autoFocus: false
		}).closed
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe((result) => {
				if ( result ) {
					this.refresh();
				}
			});
	}

	private setStateControls(): void {
		this.tRows.forEach(endpoint => {
			this.statusGroup.addControl(
				(endpoint.id ?? 0).toString(),
				new FormControl(endpoint.isActive)
			);
		});
	}

	private getActions(): RowActionSimple<Endpoint<exist>>[] {
		const edit: RowActionSimple<Endpoint<exist>> = {
			icon: 'icon-edit',
			label: 'actions.edit',
			action: this.openEditPanel.bind(this)
		};
		const details: RowActionSimple<Endpoint<exist>> = {
			icon: 'icon-password-show',
			label: 'actions.details',
			action: this.openEditPanel.bind(this)
		};
		const _delete: RowActionSimple<Endpoint<exist>> = {
			icon: 'icon-delete',
			label: 'actions.delete',
			action: this.delete.bind(this),
			highlight: true,
			separator: true
		};
		return this.profile.allowed([AP_PERMISSIONS.ENDPOINTS_E])
			? [edit, _delete]
			: [details];
	}
}
