<ng-container *ngTemplateOutlet="templates.get('period'); context: { title, cu, periodType, range }">
</ng-container>

<div #chart [alarisLoading]="loading$" [backdrop]="false" class="content">
	<div [class]="value.length ? 'char-rotate' : 'stub'"
		 [style.margin-bottom.px]="marginBottom">
		<ng-container *ngTemplateOutlet="templates.get('barChart');
			context: {
				hint, labelsX, labelsY, loading$, maxChartHeight, stubChart, value
			}">
		</ng-container>
	</div>
</div>
<ng-template #stubChart>
	<ng-container *ngTemplateOutlet="templates.get('barChartSkeleton')"></ng-container>
	<p class="stub-chart-note" style="transform: rotate(-90deg)">{{ 'dashboard.noData' | translate }}</p>
</ng-template>

<ng-template #hint let-setIndex>
	<div class="chart-tooltip">
		@for (item of value; track item; let index = $index) {
			<div class="flex">
				<div class="grid three-column">
					<div [style.background]="['#e31f14', '#60b85e', '#bbe3bb'][index]" class="dot"></div>
					<div>{{ legend[setIndex] }}</div>
					<div class="last">{{ item[setIndex] }}</div>
				</div>
			</div>
		}
	</div>
</ng-template>
