import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';

import {
	AlarisEditPanelService,
	AlarisMultiSelectDisplayWithFn,
	AlarisProfileService,
	CustomValidators,
	EditPanelInputData,
	filterWildcardData
} from '@campaign-portal/components-library';

import { exist, Id } from '@campaign-portal/namespace/common/id';
import { Channel } from '@campaign-portal/namespace/entities/channels/specs';
import { ContractCompany } from '@campaign-portal/namespace/entities/contract-company/specs';
import { Currency } from '@campaign-portal/namespace/entities/currency/specs';
import { PaymentSystem } from '@campaign-portal/namespace/entities/payment-systems/specs';

import { AP_PERMISSIONS } from '@helpers/types/permissions';
import { ChannelsService } from '@helpers/services/channels.service';
import { CurrencyService } from '@helpers/services/currency.service';
import { ContractCompaniesService } from '../contract-companies.service';
import { prepareToSave } from '@helpers/utils/form';
import { PaymentSystemsService } from '../../payment-systems/payment-systems.service';
import { CanDeactivateComponent } from '@helpers/can-deactivate/can-deactivate.component';
import { CanDeactivateGuardService } from '@helpers/can-deactivate/can-deactivate.guard';


@Component({
	selector: 'app-edit-contract-company',
	templateUrl: './edit-contract-company.component.html',
	styleUrls: ['./edit-contract-company.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditContractCompanyComponent extends CanDeactivateComponent implements OnInit, OnDestroy {
	readonly AP_PERMISSIONS = AP_PERMISSIONS;
	readonly company: ContractCompany;
	companyForm!: FormGroup;

	readonly filterCurrencyControl = new FormControl('');
	filterCurrencyList: Currency<exist>[] = [];
	readonly filterPsControl = new FormControl('');
	filterPsList: PaymentSystem<exist>[] = [];

	readonly allowedDeactivation = new BehaviorSubject<boolean>(true);

	protected readonly ngUnsubscribe: Subject<void> = new Subject<void>();

	constructor(
		@Inject(EditPanelInputData) private readonly inputData: EditPanelInputData,
		private readonly editPanel: AlarisEditPanelService,
		public readonly channelService: ChannelsService,
		public readonly currencyService: CurrencyService,
		public readonly psService: PaymentSystemsService,
		public readonly contractCompaniesService: ContractCompaniesService,
		private readonly profile: AlarisProfileService,
		private readonly guard: CanDeactivateGuardService
	) {
		super();
		this.addEditPanelGuard(editPanel, this.guard);
		this.company = this.inputData.company as ContractCompany;
	}

	ngOnInit(): void {

		this.currencyService.list$
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((list) => {
				this.filterCurrencyList = filterWildcardData(this.filterCurrencyControl.value, list, 'name');
			});

		this.psService.list$
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((list) => {
				this.filterPsList = filterWildcardData(this.filterPsControl.value, list, 'name');
			});

		this.companyForm = new FormGroup({
			name: new FormControl(this.company.name, Validators.required),
			availableImChannels: new FormControl<Id<exist>[]>(
				[...this.company.availableImChannels],
				CustomValidators.requiredArrayOrNull
			),
			availableCurrencies: new FormControl<Id<exist>[]>(
				[...this.company.availableCurrencies],
				CustomValidators.requiredArrayOrNull
			),
			availablePaySystems: new FormControl<Id<exist>[] | null>(
				[...this.company.availablePaySystems ?? []]
			)
		});

		if ( !this.profile.allowed([AP_PERMISSIONS.CC_E]) ) {
			this.companyForm.disable();
		}

		this.filterCurrencyControl.valueChanges
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((value) => {
				this.filterCurrencyList = filterWildcardData(value, this.currencyService.list, 'name');
			});

		this.filterPsControl.valueChanges
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((value) => {
				this.filterPsList = filterWildcardData(value, this.psService.list, 'name');
			});
	}

	displayPaySystem: AlarisMultiSelectDisplayWithFn<Id<exist>> = (id: Id<exist>[] | null): string => {
		if ( id === null ) {
			return this.psService.list.map(ps => ps.name).join(', ');
		}
		return id.map(i => (this.psService.map.get(i)?.name ?? '')).join(', ');
	};
	displayCurrencies: AlarisMultiSelectDisplayWithFn<Id<exist>> = (id: Id<exist>[] | null): string => {
		if ( id === null ) {
			return this.currencyService.list.map(c => c.name).join(', ');
		}
		return id.map(i => (this.currencyService.map.get(i)?.name ?? '')).join(', ');
	};
	displayImChannels: AlarisMultiSelectDisplayWithFn<Id<exist>> = (id: Id<exist>[] | null): string => {
		if ( id === null ) {
			return this.channelService.list.map(ch => ch.name).join(', ');
		}
		return id.map(i => (this.channelService.map.get(i)?.name ?? '')).join(', ');
	};

	close(): void {
		this.editPanel.close(true);
	}

	save(): void {
		const company = {
			...this.company,
			...this.companyForm.value,
			availableImChannels: prepareToSave<Channel<exist>>(
				this.companyForm.get('availableImChannels')?.value, this.channelService.list
			),
			availableCurrencies: prepareToSave<Currency<exist>>(
				this.companyForm.get('availableCurrencies')?.value, this.currencyService.list
			),
			availablePaySystems: prepareToSave<PaymentSystem<exist>>(
				this.companyForm.get('availablePaySystems')?.value, this.psService.list
			)
		};

		this.allowedDeactivation.next(false);
		this.contractCompaniesService.update(company)
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((resp) => {
				this.allowedDeactivation.next(true);
				if ( resp.Success ) {
					this.close();
				}
			});
	}

	ngOnDestroy(): void {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}

	checkSelectedType(ps: PaymentSystem): boolean {
		const value = this.companyForm.get('availablePaySystems')?.value;
		if ( !value ) {
			return false;
		}
		const selectedByType = value.filter((id: number) => this.psService.map.get(id)?.type === ps.type);
		const index = selectedByType.indexOf(ps.id);
		return selectedByType.length && index !== 0;
	}
}

