<div [alarisLoading]="paymentsService.loading$">
	<div class="edit-panel-header">
		<alaris-icon name="icon-payment"></alaris-icon>
		{{
		payment.id + ': ' + ((([AP_PERMISSIONS.PAYMENTS_E] | alarisPBAC)
			? 'payments.editPayment'
			: 'payments.details') | translate)
		}}
	</div>
	<div alarisFade="vertical"  [formGroup]="paymentForm" class="form-group edit-panel-content">
		<div class="price-zone">
			<fieldset>
				<span class="label">{{'gl.amount' | translate}}</span> <br>
				<span class="amount">{{payment.amount | currency: bs.currencyCode}}</span>
			</fieldset>
			<alaris-tag [mode]="paymentForm.get('status')?.value === PaymentStatus.CONFIRMED ? 'success': 'error'"
						[rounded]="true"
						size="xl">
				{{'enums.' + paymentForm.get('status')?.value | translate}}
			</alaris-tag>
		</div>
		<div *ngIf="this.paymentForm.get('cancelledDate')?.value" class="note">
			<alaris-icon name="icon-info"></alaris-icon>
			{{'payments.paymentCancelled' | translate}}
			{{this.paymentForm.get('cancelledDate')?.value | date: 'd MMM y, HH:mm'}}
		</div>
		<div *ngIf="payment.creationDate" class="payment-info">
			<b>{{'payments.dateOfCreation' | translate}}</b>
			{{payment.creationDate | date: 'd MMM y, HH:mm'}}
		</div>
		<div class="payment-info">
			<b>{{'payments.user' | translate}}</b>
			{{payment.user}}
		</div>
		<div class="payment-info">
			<b>{{'payments.partner' | translate}}</b>
			{{(payment.partnerId ?? 0 | partner)?.name || ''}}
		</div>
		<alaris-text-area *ngIf="paymentForm.get('comment')?.value; else noComment"
						  data-testid="commentTextarea" formControlName="comment">
			{{'fc.paymentFor' | translate}}
		</alaris-text-area>
	</div>
	<div *alarisPBAC="[AP_PERMISSIONS.PAYMENTS_E]" class="edit-panel-footer">
		<button (click)="save()"
				[disabled]="paymentForm.invalid || !paymentForm.dirty"
				alaris-button bType="primary"
				data-testid="saveBtn"
				size="lg" type="button">
			<alaris-icon class="in-primary" name="icon-success"></alaris-icon>
			{{'actions.save' | translate}}
		</button>
		<button (click)="changePaymentStatus()"
				*ngIf="paymentForm.get('status')?.value === PaymentStatus.CONFIRMED" alaris-button bType="outline"
				data-testid="cancelPaymentBtn" size="lg" type="button">
			{{ 'payments.cancelPayment' | translate }}
		</button>
	</div>
</div>

<ng-template #noComment>
	<span (click)="paymentForm.get('comment')?.setValue(' ')"
		  *alarisPBAC="[AP_PERMISSIONS.PAYMENTS_E]"
		  class="add-comment" data-testid="addCommentBtn">{{ 'payments.addComment' | translate }}</span>
</ng-template>


