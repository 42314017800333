import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { NetworkDialCode } from '@campaign-portal/namespace/entities/mccmnc/specs';

export class APCustomValidators {
	static protocolInDomainName(control: AbstractControl): ValidationErrors | null {
		const result = typeof control.value === 'string' ? control.value.includes('://') : null;
		return result ? { protocolInDomainName: true } : null;
	}

	static hasDialCodeDuplicate(map: Map<number, NetworkDialCode> | Set<number>): ValidatorFn {
		return (control: AbstractControl<number | null>): ValidationErrors | null => {
			const hasDuplicate = control.value ? map.has(control.value) : false;
			return hasDuplicate ? { duplicate: true } : null;
		};
	}
}
