import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject, takeUntil } from 'rxjs';

import { AbstractCRUDService, AlarisProfileService } from '@campaign-portal/components-library';

import { EntityField, InputComplexType, InputType } from '@campaign-portal/namespace/common/entityField';
import { ReadResponse } from '@campaign-portal/namespace/common/implementations';
import { FilterType } from '@campaign-portal/namespace/common/rpc.params';
import { EnumsMapperService } from '@helpers/services/enums.service';
import { VendorSubscriptionsService } from '@helpers/services/vendor-subscriptions.service';
import { ImportHistory } from '@campaign-portal/namespace/common/importHistory';

import { AP_PERMISSIONS } from '@helpers/types/permissions';

@Injectable()
export class RatesImportHistoryFieldsService implements AbstractCRUDService, OnDestroy {

	readonly entity = '';
	readonly title = '';
	readonly loading$ = new BehaviorSubject<boolean>(false);
	readonly file: EntityField = {
		id: 0,
		name: 'columns.fileName',
		variable: 'file',
		required: true,
		type: InputType.TEXT,
		readonly: true,
		filterType: FilterType.LIKE,
		property: 'name'
	};
	readonly status: EntityField = {
		id: 1,
		name: 'columns.status',
		variable: 'status',
		required: true,
		type: InputComplexType.MULTISELECT,
		data: Object.keys(this.enums.get('ImportStatus')),
		readonly: true,
		filterType: FilterType.IN
	};
	readonly createdBy: EntityField = {
		id: 2,
		name: 'columns.user',
		variable: 'createdBy',
		required: true,
		type: InputType.TEXT,
		readonly: true,
		filterType: FilterType.LIKE
	};
	readonly creationDate: EntityField = {
		id: 3,
		name: 'columns.creationDate',
		variable: 'creationDate',
		required: true,
		type: InputComplexType.DATE_RANGE,
		readonly: true,
		filterType: FilterType.BETWEEN
	};
	readonly subscriptionId: EntityField = {
		id: 4,
		name: 'columns.product',
		variable: 'subscriptionId',
		required: true,
		type: InputComplexType.MULTISELECT,
		data: [],
		readonly: true,
		filterType: FilterType.IN,
		property: 'id'
	};
	readonly total: EntityField = {
		id: 5,
		name: 'columns.totalRates',
		variable: 'total',
		required: true,
		type: InputComplexType.RANGE,
		readonly: true,
		filterType: FilterType.BETWEEN
	};
	readonly added: EntityField = {
		id: 6,
		name: 'columns.newRates',
		variable: 'added',
		required: true,
		type: InputComplexType.RANGE,
		readonly: true,
		filterType: FilterType.BETWEEN
	};
	readonly updated: EntityField = {
		id: 7,
		name: 'columns.updatedRates',
		variable: 'updated',
		required: true,
		type: InputComplexType.RANGE,
		readonly: true,
		filterType: FilterType.BETWEEN
	};
	readonly closed: EntityField = {
		id: 8,
		name: 'columns.closedRates',
		variable: 'closed',
		required: true,
		type: InputComplexType.RANGE,
		readonly: true,
		filterType: FilterType.BETWEEN
	};
	private readonly headers: EntityField[] = [
		this.file, this.status, this.createdBy, this.creationDate, this.subscriptionId,
		this.total, this.added, this.updated, this.closed
	];

	private readonly ngUnsubscribe = new Subject<void>();

	constructor(
		private readonly enums: EnumsMapperService,
		private readonly vsService: VendorSubscriptionsService,
		private readonly profile: AlarisProfileService
	) {
		this.vsService.list$
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(list => this.subscriptionId.data = list);
	}

	ngOnDestroy(): void {
		this.loading$.complete();
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}

	create(): Observable<void> {
		return of(undefined);
	}

	delete(): Observable<void> {
		return of(undefined);
	}

	read(): Observable<ReadResponse<EntityField[]>> {
		const headers = this.pickAvailableFields(this.headers);
		return of({
			Success: true,
			Total: headers.length,
			Data: headers
		});
	}

	update(): Observable<void> {
		return of(undefined);
	}

	private pickAvailableFields(fields: EntityField[]): EntityField[] {
		const permissions: [AP_PERMISSIONS, keyof ImportHistory][] = [
			[AP_PERMISSIONS.USERS_R, 'createdBy']
		];

		permissions.forEach(([permission, variable]) => {
			if ( !this.profile.allowed([permission]) ) {
				fields = fields.filter(field => field.variable !== variable);
			}
		});
		return fields;
	}
}

