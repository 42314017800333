import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';

import {
	AbstractCRUDService,
	AlarisApiService,
	AlarisLanguageService,
	AlarisToasterService,
	ErrorNotifierConfig
} from '@campaign-portal/components-library';

import { RPCRequestParams } from '@campaign-portal/namespace/common/rpc.params';
import { CreateResponse, ReadResponse } from '@campaign-portal/namespace/common/implementations';
import { exist } from '@campaign-portal/namespace/common/id';
import { SenderSubscription } from '@campaign-portal/namespace/entities/sender-id/specs';

@Injectable({
	providedIn: 'root'
})
export class SendersSubscriptionsService implements AbstractCRUDService {
	readonly loading$ = new BehaviorSubject<boolean>(false);
	private readonly errorNotifier = (): ErrorNotifierConfig => ({ title: this.title });

	constructor(
		private readonly api: AlarisApiService,
		private readonly alarisToaster: AlarisToasterService,
		private readonly langService: AlarisLanguageService
	) {
	}

	get entity(): string {
		return this.langService.translate('notifications.entities.senderSub');
	}

	get title(): string {
		return this.langService.translate('notifications.titles.senderSub');
	}

	read(params?: RPCRequestParams): Observable<ReadResponse<SenderSubscription<exist>[]>> {
		return this.api.loader<ReadResponse<SenderSubscription<exist>[]>>(
			'SenderSubscriptions.Read', params, this.loading$, this.errorNotifier
		);
	}

	update(sender: [SenderSubscription<exist>]): Observable<CreateResponse<SenderSubscription<exist>[]>> {
		const notify = (resp: CreateResponse<SenderSubscription<exist>[]>): void => {
			const message = this.langService.translate(
				'notifications.actions.update', {
					entity: this.entity,
					name: sender.at(0)?.name || ''
				});
			if ( resp.Success ) {
				this.alarisToaster.success(message, this.title);
			}
		};
		return this.api.loader<CreateResponse<SenderSubscription<exist>[]>>(
			'SenderSubscriptions.Update',
			{
				Data: {
					Entities: sender
				}
			},
			this.loading$,
			this.errorNotifier,
			notify
		);
	}

	create(senders: SenderSubscription[]): Observable<CreateResponse<SenderSubscription<exist>[]>> {
		const notify = (resp: CreateResponse<SenderSubscription<exist>[]>): void => {
			if ( resp.Success ) {
				resp.Data?.forEach((sub) => {
					const message = this.langService.translate(
						'notifications.actions.create',
						{
							entity: this.langService.translate('senders.senderIdSubscription'),
							name: sub.name ?? ''
						}
					);
					this.alarisToaster.success(message, this.title);
				});
			}
		};

		return this.api.loader<CreateResponse<SenderSubscription<exist>[]>>(
			'SenderSubscriptions.Create',
			{
				Data: {
					Entities: [...senders]
				}
			},
			this.loading$,
			this.errorNotifier,
			notify
		);
	}

	delete(): Observable<undefined> {
		return of(undefined);
	}
}
