<alaris-card>
	<div *ngIf="tRows.length > 0 || tRows.length === 0 && isFiltersApplied" class="table-management">
		<div class="left">
			<span class="total" data-testid="total">{{ totalTitle | translate }}: {{ total }}</span>
			<button (click)="editTableFilter()" alaris-button bType="outline" data-testid="editFilterBtn" size="lg">
				<alaris-icon class="in-outline" name="icon-filter-vertical"></alaris-icon>
				{{ enableButtons.filters.title | translate }}
				<div *ngIf="isFiltersApplied" class="indicator"></div>
			</button>
		</div>
		<div class="right">
			<button (click)="refresh()" alaris-button bType="outline" data-testid="refreshBtn" size="lg">
				<alaris-icon name="icon-refresh-clock-wise"></alaris-icon>
				{{ 'actions.refreshPage' | translate }}
			</button>
			<button (click)="openEditPanel()"
					*alarisPBAC="[AP_PERMISSIONS.SENDERS_E]"
					alaris-button bType="primary" data-testid="createBtn" size="lg">
				<alaris-icon class="in-primary" name="icon-plus"></alaris-icon>
				{{ 'senders.addSubscriptions' | translate }}
			</button>
		</div>
	</div>
	<div [alarisLoading]="loading$" class="content">
		@if (tRows.length === 0 && !isFiltersApplied) {
			<app-empty-table (newSenderSubscriptions)="openEditPanel()"
							 [disabled]="!([AP_PERMISSIONS.SENDERS_E] | alarisPBAC)"
							 type="sender-subscription">
			</app-empty-table>
		} @else {
			<alaris-table (filterChange)="_applyFilter($event)"
						  (sortingChange)="_applySorting($event)"
						  [ctxActions]="ctxActions"
						  [dblClickRowAction]="openEditPanel.bind(this)"
						  [dropDownRowActions]="dropDownRowActions"
						  [filters]="filters"
						  [sorting]="sorting"
						  [tHeaders]="tHeaders"
						  [tRows]="tRows"
						  [templates]="{name, enabled, trafficType, partner, activeFrom, activeTill, documents, lastUpdated}"
						  alarisOverflowFader>
			</alaris-table>
		}
	</div>
	<div class="footer">
		<alaris-pagination (page)="_pageChange($event)"
						   [activePage]="activePage"
						   [hidden]="total === 0"
						   [pageSizeOptions]="pageSizeOptions"
						   [pageSize]="pageSize"
						   [total]="total"
						   class="pagination">
		</alaris-pagination>
	</div>
</alaris-card>

<ng-template #name [alarisCellCtx]="tRows[0]" let-data="data" let-key="key">
	<div class="senderId-column">
		<alaris-icon [name]="cu.icon(data.trafficType)"></alaris-icon>
		{{ data.name }}
	</div>
</ng-template>

<ng-template #enabled [alarisCellCtx]="tRows[0]" let-data="data" let-key="key">
	<div [formGroup]="statusGroup">
		<alaris-toggle (change)="updateSubscriptionStatus(data, $event)"
					   [attr.data-testid]="'subscriptionStatusToggle/' + data.id"
					   [formControlName]="data.id.toString()"
					   [readonly]="!([AP_PERMISSIONS.SENDERS_E] | alarisPBAC) || statusGroup.get(data.id.toString())?.disabled || false">
		</alaris-toggle>
	</div>
</ng-template>

<ng-template #partner [alarisCellCtx]="tRows[0]" let-data="data" let-key="key">
	{{ (data.partner ?? 0 | partner)?.name | noValue }}
</ng-template>

<ng-template #activeFrom [alarisCellCtx]="tRows[0]" let-data="data" let-key="key">
	{{ data.activeFrom | date: 'd MMM y, HH:mm' }}
</ng-template>

<ng-template #activeTill [alarisCellCtx]="tRows[0]" let-data="data" let-key="key">
	{{ data.activeTill ? (data.activeTill | timeAgo:'':true) : ('gl.neverExpires' | translate) }}
</ng-template>

<ng-template #documents [alarisCellCtx]="tRows[0]" let-data="data" let-key="key">
	<alaris-icon [name]="data.documents?.length ? 'icon-success-_active':'icon-error-_active'" class="documents-column">
	</alaris-icon>
</ng-template>

<ng-template #lastUpdated [alarisCellCtx]="tRows[0]" let-data="data" let-key="key">
	<span>{{ data.lastUpdated ?? '' | timeAgo: ('gl.ago' | translate) }}</span>
</ng-template>

<ng-template #trafficType [alarisCellCtx]="tRows[0]" let-data="data" let-key="key">
	{{ cu.name(data.trafficType) | translate }}
</ng-template>
