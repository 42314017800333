import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'mccmnc'
})
export class MccmncPipe implements PipeTransform {

	transform(value: unknown): string {
		if ( typeof value === 'number' ) {
			return value.toString().padStart(3, '0');
		}
		return '';
	}
}
