import {
	ChangeDetectionStrategy,
	Component,
	DestroyRef,
	EventEmitter,
	inject,
	Input,
	OnDestroy,
	OnInit,
	Output
} from '@angular/core';
import { BehaviorSubject, switchMap } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { InputComplexType } from '@campaign-portal/namespace/common/entityField';
import { exist, Id } from '@campaign-portal/namespace/common/id';
import { AnalysisStatus } from '@campaign-portal/namespace/common/enums';
import { SortDirection } from '@campaign-portal/namespace/common/rpc.params';
import {
	RateAnalysis,
	RatesAnalysisExportResponse,
	RatesAnalysisRequest
} from '@campaign-portal/namespace/entities/rates/specs';

import {
	AlarisFilesService,
	LocalTableUtils,
	PageEvent,
	TableEntityField,
	TableFiltersIndicator,
	TableRefBooksIndicator,
	TableSortIndicator
} from '@campaign-portal/components-library';
import { EnumsMapperService } from '@helpers/services/enums.service';
import { ValueObject } from '@campaign-portal/namespace/common/valueObject';
import { RatesService } from '@helpers/services/rates.service';
import { CountriesNetworksService } from '@helpers/services/countries-networks.service';

@Component({
	selector: 'app-rates-analysis',
	templateUrl: './rates-analysis.component.html',
	styleUrls: [
		// eslint-disable-next-line max-len
		'../../../../../../node_modules/@campaign-portal/components-library/src/assets/templates/table-complex/complex-table.component.scss',
		'./rates-analysis.component.scss'
	],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class RatesAnalysisComponent implements OnInit, OnDestroy {
	@Input() predefinedSubscriptionId?: Id;
	@Input() requestParams!: RatesAnalysisRequest;
	@Output() readonly analysisDoneCorrect = new EventEmitter<boolean>();

	readonly AnalysisStatus = AnalysisStatus;
	tHeaders: TableEntityField[] = [];
	tRows: RateAnalysis<exist>[] = [];
	tRowsDefault: RateAnalysis<exist>[] = [];
	tRowsFiltered: RateAnalysis<exist>[] = [];

	activePage = 0;
	pageSize = 50;
	readonly pageSizeOptions = [50, 100, 150];

	statistics = {
		total: 0,
		added: 0,
		updated: 0,
		closed: 0

	};
	filters: TableFiltersIndicator = new Map();
	sorting: TableSortIndicator = new Map();
	isFiltersApplied = false;

	readonly loading$ = new BehaviorSubject<boolean>(true);

	private readonly destroyRef = inject(DestroyRef);

	constructor(
		private readonly ratesService: RatesService,
		private readonly fileService: AlarisFilesService,
		private readonly countriesNetworks: CountriesNetworksService,
		private readonly enums: EnumsMapperService
	) {
	}

	get start(): number {
		return this.activePage * this.pageSize;
	}

	get end(): number {
		return Math.min(
			this.start + this.pageSize,
			this.isFiltersApplied ? this.tRowsFiltered.length : this.tRowsDefault.length
		);
	}

	ngOnInit(): void {
		this.ratesService.analysisRead(this.requestParams)
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe((res) => {
				this.tRowsDefault = res.Data?.Entities ?? [];
				this.tRows = [...this.tRowsDefault];
				this.tRows = this.tRows.slice(this.start, this.end);
				this.tHeaders = LocalTableUtils.toTableFields(res.Data?.Headers ?? []);
				if ( res.Data?.Statistics ) {
					this.statistics = res.Data?.Statistics;
				}
				this.loading$.next(false);
				this.initFiltersAndSorting();
				this.analysisDoneCorrect.emit(Boolean(this.tRows.length));
			});
	}

	exportAnalysis(): void {
		this.loading$.next(true);
		this.ratesService.analysisExport({
			Data: {
				Entities: this.tRowsDefault,
				Headers: this.tHeaders
			}
		})
			.pipe(
				switchMap((response: RatesAnalysisExportResponse) =>
					this.fileService.export(response.Data[0].id, response.Data[0].name)
				),
				takeUntilDestroyed(this.destroyRef)
			)
			.subscribe(() => this.loading$.next(false));
	}

	pageChange(event: PageEvent): void {
		this.activePage = event.pageIndex;
		this.pageSize = event.pageSize;

		this.tRows = this.isFiltersApplied ?
			this.tRowsFiltered.slice(this.start, this.end) :
			this.tRowsDefault.slice(this.start, this.end);
		this.applySorting(this.sorting);
	}

	initFiltersAndSorting(): void {
		this.filters = new Map();
		this.sorting = new Map();
		this.tHeaders
			.filter(h => ['country', 'network', 'status'].includes(h.variable))
			.forEach(header =>
				this.filters.set(header.variable, {
					enabled: true,
					value: header.type === InputComplexType.MULTISELECT ? [] : null,
					filterType: header.filterType
				})
			);

		this.tHeaders.forEach(
			(header) =>
				this.sorting.set(header.variable, { enabled: true, value: SortDirection.EMPTY })
		);

		const statuses: ValueObject[] = Object
			.entries(this.enums.get('AnalysisStatus'))
			.map(([name, value]) => ({ name, value }));
		this.setRefBooksInFields({
			status: { list$: new BehaviorSubject<ValueObject[]>(statuses) },
			country: { list$: this.countriesNetworks.countriesList$, loading$: this.countriesNetworks.loading$ },
			network: { list$: this.countriesNetworks.networksList$, loading$: this.countriesNetworks.loading$ }
		});

		if ( !this.predefinedSubscriptionId ) {
			[this.filters, this.sorting].forEach(indicator => {
				indicator.set('status', { enabled: false });
			});
		}

	}

	setRefBooksInFields(refBooks: TableRefBooksIndicator): void {
		this.tHeaders.forEach(field => {
			const refBook = refBooks[field.variable];
			if ( refBook ) {
				Object.assign(field, { data$: refBook.list$, loading$: refBook.loading$ });
			}
		});
	}

	applyFilter($event: TableFiltersIndicator): void {
		this.tRowsFiltered = [...this.tRowsDefault];
		this.isFiltersApplied = this.checkFilters($event);
		$event.forEach(
			(fV, variable) => {
				const hasValue = Array.isArray(fV?.value) ? fV?.value.length : fV?.value;
				if ( fV?.enabled && hasValue ) {
					switch (variable) {
						case 'country':
						case 'network':
							const values = (fV?.value ?? []) as string[];
							this.tRowsFiltered = this.tRowsFiltered.filter(
								(el) => {
									return values.find((v) => {
										return el[variable]?.name !== undefined ?
											v === el[variable]?.name :
											false;
									});
								}
							);
							break;
						case 'status':
							if ( Array.isArray(fV.value) && fV.value.length === 0 || fV.value === null ) {
								break;
							} else if ( Array.isArray(fV.value) ) {
								this.tRowsFiltered = this.tRowsFiltered.filter((el) => {
									return (fV.value as ValueObject[]).find(f => el.status === f.value);
								});
							}
							break;
					}
				}
			}
		);
		this.pageChange({ pageIndex: 0, pageSize: this.pageSize });
	}

	applySorting($event: TableSortIndicator): void {
		$event.forEach(
			(sort, variable) => {
				if ( sort?.enabled ) {

					const tRows = [...this.tRows];
					this.tRows = tRows.sort(
						(a, b) => {
							let first: unknown = a[variable];
							let second: unknown = b[variable];

							const parseDate = (date?: string | null): Date | null => {
								return date ? new Date(date) : null;
							};
							switch (variable) {
								case 'country':
									first = a.country.name;
									second = b.country.name;
									break;
								case 'network':
									first = a.network?.name;
									second = b.network?.name;
									break;
								case 'oldPrice':
									first = a.price;
									second = b.price;
									break;
								case 'newPrice':
									first = a.changes?.price ?? a.price;
									second = b.changes?.price ?? b.price;
									break;
								case 'activeFrom':
								case 'oldActiveFrom':
									first = parseDate(a.activeFrom);
									second = parseDate(b.activeFrom);
									break;
								case 'activeTill':
								case 'oldActiveTo':
									first = parseDate(a.activeTill);
									second = parseDate(b.activeTill);
									break;
								case 'newActiveFrom':
									first = parseDate(a.changes?.activeFrom || a.activeFrom);
									second = parseDate(b.changes?.activeFrom || b.activeFrom);
									break;
								case 'newActiveTo':
									first = parseDate(a.changes?.activeTill || a.activeTill);
									second = parseDate(b.changes?.activeTill || b.activeTill);
									break;
							}

							return LocalTableUtils.sortComparisonFn(sort.value ?? SortDirection.EMPTY, first, second);
						}
					);
				}
			}
		);
	}

	checkFilters(indicators: TableFiltersIndicator): boolean {
		const filters: string[] = [];
		indicators.forEach(
			(filter, variable) => {
				if ( Array.isArray(filter?.value) ? filter?.value.length : filter?.value ) {
					filters.push(variable);
				}
			}
		);
		return !!filters.length;
	}

	ngOnDestroy(): void {
		const status = this.tHeaders.find(field => field.variable === 'status');
		status?.data$.complete();
	}
}
