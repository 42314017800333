<div [alarisLoading]="loading$" [backdrop]="false" class="grid">
	<div class="label">{{ 'dashboard.messagesByNetwork' | translate }}</div>
	<div></div>
	<div></div>
	<div></div>
	<!--	<alaris-tabs-button [formControl]="periodType" [tabs]="cu.periodTypes"></alaris-tabs-button>-->
	<!--	<alaris-input-date-range [formControl]="range"-->
	<!--							 [max]="Day.currentLocal()"-->
	<!--							 [predefinedOffset]="periodType.value"-->
	<!--							 size="md"-->
	<!--							 toNativeDate>-->
	<!--	</alaris-input-date-range>-->
	<div class="chart">
		<tui-ring-chart *ngIf="value.length && (chartsService.countries | async)?.length; else stubRingChart"
						[(activeItemIndex)]="activeItemIndex"
						[value]="value"
						size="xl">
			<div>{{ amount | number }}</div>
			<div>{{ label | translate }}</div>
		</tui-ring-chart>
	</div>
	<div [alarisFade]="'vertical'" class="legend">
		<alaris-select2 [formControl]="countryControl" [label]="'fc.country' | translate"
						[placeholder]="'fc.country' | translate" data-testid="countrySelect" size="md">
			<alaris-select-filter2 *ngIf="countries.length > 5"
								   [formControl]="filterCountriesControl" data-testid="countryFilter">
			</alaris-select-filter2>
			<alaris-options2 *ngFor="let entity of filteredCountries"
							 [attr.data-testid]="'countryOption/' + entity" [value]="entity">
				{{ entity }}
			</alaris-options2>
		</alaris-select2>
		<tui-legend-item (mouseleave)="makeNaN()"
						 (mouseover)="onHover(index, $event)"
						 *ngFor="let label of labels; let index = index"
						 [active]="isItemActive(index)"
						 [class.active]="isItemActive(index)"
						 [color]="getColor(index)"
						 [text]="label"
						 class="item"
						 size="s">
			<b>{{ value[index] | number }}</b>
		</tui-legend-item>
	</div>
</div>


<ng-template #stubRingChart>
	<div class="chart-wrapper">
		<tui-ring-chart size="l" style="margin: auto;">
			<span style="color: var(--as-TextTertiary)">n/a</span>
		</tui-ring-chart>
		<div class="stub-chart-note">{{ 'dashboard.noData' | translate }}</div>
	</div>
</ng-template>
