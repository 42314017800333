<div [alarisLoading]="loadingOnSave$">
	<div class="edit-panel-header">
		<alaris-icon name="icon-add-contact"></alaris-icon>
		{{
			(([AP_PERMISSIONS.USERS_E] | alarisPBAC)
				? (user.id ? 'users.editUserTitle' : 'users.addUserTitle')
				: 'users.details') | translate
		}}
	</div>
	<div [alarisFade]="'vertical'"
		 [alarisLoading]="partnersService.loading$" [backdrop]="false"
		 [formGroup]="userForm" class="form-group panel-content">
		<ng-container *ngIf="showForm; else partnerSuggestion">
			<div>
				<b>{{ 'users.generalInformation' | translate }}</b>
			</div>
			<div class="in-row" formGroupName="details">
				<alaris-input [label]="'fc.firstName' | translate"
							  [placeholder]="'fc.firstName' | translate"
							  data-testid="firstNameInput" formControlName="firstName">
				</alaris-input>
				<alaris-input [label]="'fc.lastName' | translate"
							  [placeholder]="'fc.lastName' | translate"
							  data-testid="lastNameInput" formControlName="lastName">
				</alaris-input>
			</div>
			<alaris-input [label]="'fc.email' | translate"
						  [placeholder]="'fc.email' | translate"
						  data-testid="emailInput" formControlName="email">
			</alaris-input>
			<alaris-select2 [displayWith]="displaySelected"
							[label]="'fc.partner' | translate"
							[placeholder]="'fc.partner' | translate"
							[readonly]="readonlyPartnerControl"
							data-testid="partnerSelect" formControlName="partnerId">
				<alaris-select-filter2 *ngIf="((partnersService.list$ | async)?.length || 0) > 5"
									   [formControl]="filterPartnersControl" data-testid="partnerFilter">
				</alaris-select-filter2>
				<alaris-options2 [value]="profile.user.partnerId"
								 class="separator" data-testid="partnerOption_colleague">
					{{ 'users.thisCompany' | translate }}
				</alaris-options2>
				<alaris-options2 *ngFor="let opt of filterPartnersList"
								 [attr.data-testid]="'partnerOption/' + opt.name" [value]="opt.id">
					{{ opt.name }}
				</alaris-options2>
			</alaris-select2>
			<div class="status-control">
				<div class="in-row">
					<b>{{ 'gl.status'| translate }}</b>
					<alaris-icon [alarisTooltip]="'users.activeNote' | translate" name="icon-info"></alaris-icon>
				</div>
				<alaris-toggle data-testid="activeToggle" formControlName="isActive">
					{{ 'gl.active' | translate }}
				</alaris-toggle>
			</div>
			<ng-container *ngIf="user.id && ([AP_PERMISSIONS.USERS_E] | alarisPBAC)">
				<div>
					<b>{{ 'users.security' | translate }}</b>
				</div>
				<div class="in-row">
					<button (click)="changePasswordByEmail(user)"
							alaris-button bType="primary"
							data-testid="resetPasswordBtn"
							size="lg" type="button">
						{{ 'users.changePasswordByEmail' | translate }}
					</button>
					<button (click)="changePasswordByHand(user)"
							alaris-button bType="primary"
							data-testid="setPasswordBtn"
							size="lg" type="button">
						{{ 'users.setNewPassword' | translate }}
					</button>
				</div>
			</ng-container>
			<div>
				<b>{{ 'users.accessPolicies' | translate }}</b> <br>
				<span class="policy-note">{{ 'users.accessPoliciesNote' | translate }}</span>
			</div>
			<alaris-select2 [displayWith]="displayUser"
							[label]="'fc.role' | translate"
							[placeholder]="'fc.role' | translate"
							[readonly]="roles.length === 1 || this.roles.length === 0"
							data-testid="roleSelect" formControlName="role">
				<alaris-options2 *ngFor="let role of roles"
								 [attr.data-testid]="'roleOption/' + role" [value]="role">
					{{ 'enums.' + role | translate }}
				</alaris-options2>
			</alaris-select2>

			<div [formGroup]="permissionsForm" class="roles">
				@if (!partnersService.map.get(userForm.controls.partnerId.value)?.isReseller &&
				([AP_PERMISSIONS.USERS_E] | alarisPBAC)) {
					<alaris-link (click)="toggleAll()" class="toggleAll" data-testid="toggleAllPermissionsLink">
						{{ (isAllSelected ? 'gl.deselectAll' : 'gl.selectAll') | translate }}
					</alaris-link>
				}
				<cdk-accordion class="groups">
					@for (group of groups; track group) {
						<cdk-accordion-item *alarisLet="getPermissions(group) as permissions"
											#accordionItem="cdkAccordionItem"
											[attr.aria-expanded]="accordionItem.expanded"
											[attr.aria-controls]="'accordion-body-' + $index"
											class="group" tabindex="0">
							<div (click)="accordionItem.toggle()"
								 class="group__header" data-testid="togglePermissionGroup" role="button">
								<alaris-icon [name]="getIcon(group)"></alaris-icon>
								<span class="name">
									{{ 'permissions.group.' + group | translate | uppercase }}
								</span>
								<span class="spacer"></span>
								<span class="counter">
									{{ countSelectedPermissions(permissions) }}
									<span class="tertiary">{{ 'gl.of' | translate }} {{ permissions.length }}</span>
								</span>
								<alaris-icon [name]="accordionItem.expanded ? 'icon-caret-up' : 'icon-caret-down'"
											 class="caret">
								</alaris-icon>
							</div>
							@if (accordionItem.expanded) {
								@for (permission of permissions; track permission) {
									<div [class.disabled]="permissionsForm.get(permission)?.disabled"
										 class="group__permission">
										<label [for]="permission" class="title">
											{{ 'permissions.name.' + permission | translate }}
											<alaris-toggle [attr.data-testid]="'permissionToggle/' + permission"
														   [formControlName]="permission"
														   [id]="permission"
														   [readonly]="(userForm.controls.partnerId.value | partner)?.isReseller || false">
											</alaris-toggle>
										</label>
										<span class="tertiary">
											{{ 'permissions.description.' + permission | translate }}
										</span>
									</div>
								}
							}
						</cdk-accordion-item>
					}
				</cdk-accordion>
			</div>
		</ng-container>
		<ng-template #partnerSuggestion>
			<app-empty-table (newPartner)="createPartner()"
							 (newUser)="createColleague()"
							 type="user-partner">
			</app-empty-table>
		</ng-template>
	</div>
	<div *ngIf="showForm" class="edit-panel-footer">
		<button (click)="save()"
				*alarisPBAC="[AP_PERMISSIONS.USERS_E]"
				[disabled]="userForm.invalid ||
				!(userForm.dirty || permissionsForm.dirty)"
				alaris-button bType="primary"
				data-testid="saveBtn"
				size="lg" type="button">
			<alaris-icon class="in-primary" name="icon-success"></alaris-icon>
			{{ (user.id ? 'actions.save' : 'actions.create') | translate }}
		</button>
		<button (click)="close()" alaris-button bType="outline" data-testid="closeBtn" size="lg" type="button">
			{{ (([AP_PERMISSIONS.USERS_E] | alarisPBAC) ? 'actions.cancel' : 'actions.close') | translate }}
		</button>
	</div>
</div>


