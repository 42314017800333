import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, map, Observable, Subject, takeUntil } from 'rxjs';

import {
	AlarisDialogService,
	AlarisProfileService,
	LocalTableUtils,
	RowActionSimple,
	TableEntityField,
	TableSortIndicator
} from '@campaign-portal/components-library';

import { Partner } from '@campaign-portal/namespace/entities/partners/specs';
import { exist, Id } from '@campaign-portal/namespace/common/id';
import { FilterType, SortDirection } from '@campaign-portal/namespace/common/rpc.params';
import { Package, Plan } from '@campaign-portal/namespace/entities/subscriptions/specs';
import { InputComplexType } from '@campaign-portal/namespace/common/entityField';
import { ContractCompanyPipe } from '@helpers/repo/repo.pipe';
import { PartnersFieldsService } from '../../../../partners/partners.fields.service';
import { PartnersService } from '../../../../partners/partners.service';
import {
	SubscriptionsDialogComponent,
	SubscriptionsDialogType
} from '../../subscriptions-dialog/subscriptions-dialog.component';
import { AP_PERMISSIONS } from '@helpers/types/permissions';

@Component({
	selector: 'app-subscribers',
	templateUrl: './subscribers.component.html',
	styleUrls: [
		// eslint-disable-next-line max-len
		'../../../../../../../node_modules/@campaign-portal/components-library/src/assets/templates/table-complex/complex-table.component.scss',
		'./subscribers.component.scss'
	],
	providers: [PartnersFieldsService, ContractCompanyPipe],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubscribersComponent implements OnDestroy, OnInit {
	@Input() subscription!: Package | Plan;
	tRows: Partner<exist>[] = [];
	tHeaders$!: Observable<TableEntityField[]>;
	readonly sorting: TableSortIndicator = new Map();

	readonly rowActions?: RowActionSimple<Partner<exist>>[] = this.getActions();
	readonly loading$ = new BehaviorSubject<boolean>(false);
	readonly ngUnsubscribe: Subject<void> = new Subject<void>();

	constructor(
		public readonly partnersService: PartnersService,
		public readonly fieldsService: PartnersFieldsService,
		public readonly ccPipe: ContractCompanyPipe,
		private readonly profile: AlarisProfileService,
		private readonly dialog: AlarisDialogService
	) {
	}

	unlink(partner?: Partner<exist>): void {
		if ( partner ) {
			this.subscription = {
				...this.subscription,
				partners: this.subscription.partners?.filter(p => p.id !== partner?.id)
			};
			this.openSpecificDialog(this.subscription, partner, 'Unlink');
		}
	}

	initFiltersAndSorting(): void {
		['name', 'ccId', 'subscriptionDate'].forEach(
			variable => {
				this.sorting.set(variable, { enabled: true, value: SortDirection.EMPTY });
			}
		);
	}

	ngOnInit(): void {
		this.tHeaders$ = this.fieldsService.read()
			.pipe(map(resp => {
				const fields = [
					...resp.Data.filter(field =>
						!['financialSettings', 'creditLimit', 'balance'].includes(field.variable)
					),
					{
						id: 6,
						name: 'columns.activationDate',
						variable: 'subscriptionDate',
						required: true,
						type: InputComplexType.DATE,
						filterType: FilterType.BETWEEN
					}];
				return LocalTableUtils.toTableFields(fields);
			}));

		this.load();
		this.initFiltersAndSorting();
	}

	load(): void {
		this.tRows = this.subscription.partners
			?.map(partner => this.partnersService.map.get(partner.id) as Partner<exist>) || [];
	}

	applySorting($event: TableSortIndicator): void {
		$event.forEach(
			(sort, variable) => {
				if ( sort?.enabled ) {
					const tRows = [...this.tRows];
					this.tRows = tRows.sort(
						(a, b) => {
							let first;
							let second;
							switch (variable) {
								case 'subscriptionDate':
									first = this.getSubscriptionDate(a.id);
									second = this.getSubscriptionDate(b.id);
									break;
								case 'ccId':
									first = this.ccPipe.transform(a[variable])?.name;
									second = this.ccPipe.transform(b[variable])?.name;
									break;
								default:
									first = a[variable];
									second = b[variable];
							}

							return LocalTableUtils.sortComparisonFn(sort.value ?? SortDirection.EMPTY, first, second);
						}
					);
				}
			}
		);
	}

	getSubscriptionDate(id: Id<exist>): Date | null {
		const subscriptionDate = this.subscription.partners?.find(p => p.id === id)?.subscriptionDate;
		return subscriptionDate ? new Date(subscriptionDate) : null;
	}

	ngOnDestroy(): void {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}

	private openSpecificDialog(
		subscription: Plan | Package,
		partner: Partner<exist>,
		type: SubscriptionsDialogType
	): void {
		this.dialog.open(SubscriptionsDialogComponent, {
			data: { subscription, partner, type },
			autoFocus: false
		}).closed
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((result) => {
				if ( result ) {
					this.load();
				}
			});
	}

	private getActions(): RowActionSimple<Partner<exist>>[] | undefined {
		const close: RowActionSimple<Partner<exist>> = {
			icon: 'icon-close',
			label: 'actions.unsubscribe',
			action: this.unlink.bind(this)
		};
		return this.profile.allowed([AP_PERMISSIONS.SUBSCR_E]) ? [close] : undefined;
	}
}
