import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { ConnectedPosition } from '@angular/cdk/overlay';

import {
	AlarisEditPanelService,
	AlarisLanguageService,
	AlarisProfileService,
	EditPanelInputData
} from '@campaign-portal/components-library';
import { MCCMNC, NetworkDialCode } from '@campaign-portal/namespace/entities/mccmnc/specs';

import { MccmncService } from '../mccmnc.service';
import { CanDeactivateComponent } from '@helpers/can-deactivate/can-deactivate.component';
import { CanDeactivateGuardService } from '@helpers/can-deactivate/can-deactivate.guard';
import { AP_PERMISSIONS } from '@helpers/types/permissions';
import { CountriesNetworksService } from '@helpers/services/countries-networks.service';

@Component({
	selector: 'app-edit-mccmnc',
	templateUrl: './edit-mccmnc.component.html',
	styleUrls: ['./edit-mccmnc.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditMccmncComponent extends CanDeactivateComponent implements OnInit, OnDestroy {
	readonly AP_PERMISSIONS = AP_PERMISSIONS;
	readonly mccmnc?: MCCMNC;
	mccmncForm!: FormGroup;

	filteredCountries: string[] = [];
	filteredNetworks: string[] = [];
	positions: ConnectedPosition[] = [
		{
			originX: 'start',
			originY: 'bottom',
			overlayX: 'start',
			overlayY: 'top',
			offsetY: 2
		}
	];

	readonly allowedDeactivation = new BehaviorSubject<boolean>(true);

	readonly errors = [
		{
			key: 'maxlength',
			value: this.lService.translate('errors.maxLength', { amount: '3' })
		}
	];

	protected readonly ngUnsubscribe: Subject<void> = new Subject<void>();

	constructor(
		@Inject(EditPanelInputData) private readonly inputData: EditPanelInputData,
		private readonly editPanel: AlarisEditPanelService,
		public readonly mccmncService: MccmncService,
		private readonly profile: AlarisProfileService,
		private readonly guard: CanDeactivateGuardService,
		private readonly lService: AlarisLanguageService,
		public countriesNetworksService: CountriesNetworksService
	) {
		super();
		this.addEditPanelGuard(editPanel, this.guard);
		this.mccmnc = this.inputData.mccmnc ? this.inputData.mccmnc as MCCMNC : undefined;
	}

	ngOnInit(): void {
		this.mccmncForm = new FormGroup({
			country: new FormControl(this.mccmnc?.country, Validators.required),
			mcc: new FormControl(this.mccmnc?.mcc, [Validators.required, Validators.maxLength(3), Validators.min(0)]),
			network: new FormControl(this.mccmnc?.network),
			mnc: new FormControl(this.mccmnc?.mnc, [Validators.maxLength(3), Validators.min(0)]),
			countryDialCode: new FormControl(this.mccmnc?.countryDialCode, Validators.required),
			networkDialCodes: new FormControl(this.mccmnc?.networkDialCodes)
		});

		if ( !this.profile.allowed([AP_PERMISSIONS.REF_BOOK_E]) ) {
			this.mccmncForm.disable();
		}

		this.mccmncForm.controls.country.valueChanges
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((value) => {
				this.filteredCountries = this.countriesNetworksService.countries.filter(
					c => c.toLowerCase().includes(value.toLowerCase())
				);
			});

		this.mccmncForm.controls.network.valueChanges
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((value) => {
				this.filteredNetworks = this.countriesNetworksService.networks.filter(
					c => c.toLowerCase().includes(value.toLowerCase())
				);
			});
	}

	close(): void {
		this.editPanel.close();
	}

	save(): void {
		const mccmnc: MCCMNC = {
			id: this.mccmnc ? this.mccmnc.id : null,
			...this.mccmnc,
			...this.mccmncForm.value
		};

		this.allowedDeactivation.next(false);
		this.mccmncService.update(mccmnc)
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((resp) => {
				this.allowedDeactivation.next(true);
				if ( resp.Success ) {
					this.editPanel.close(true);
				}
			});
	}

	updateNetworkCodes(networkCodes: NetworkDialCode[]): void {
		this.mccmncForm.get('networkDialCodes')?.setValue(networkCodes);
		this.mccmncForm.markAsDirty();
	}

	ngOnDestroy(): void {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}
}
