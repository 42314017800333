import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';

import {
	AlarisEditPanelService,
	AlarisLanguageService,
	AlarisProfileService,
	ChannelUtilsService,
	EditPanelInputData,
	filterWildcardData
} from '@campaign-portal/components-library';

import { DecisionMode, MessagePurpose, TrafficType } from '@campaign-portal/namespace/common/enums';
import { Subscription } from '@campaign-portal/namespace/entities/subscriptions/specs';
import { Currency } from '@campaign-portal/namespace/entities/currency/specs';
import { exist, Id } from '@campaign-portal/namespace/common/id';

import { AP_PERMISSIONS } from '@helpers/types/permissions';
import { ChannelsService } from '@helpers/services/channels.service';
import { CurrencyService } from '@helpers/services/currency.service';
import { EnumsMapperService } from '@helpers/services/enums.service';
import { VendorSubscriptionsService } from '@helpers/services/vendor-subscriptions.service';
import { CanDeactivateComponent } from '@helpers/can-deactivate/can-deactivate.component';
import { CanDeactivateGuardService } from '@helpers/can-deactivate/can-deactivate.guard';
import { ViberPriorityComponent } from '@helpers/components/viber-priority/viber-priority.component';
import { OwnerService } from '@helpers/services/owner.service';

@Component({
	selector: 'app-edit-product',
	templateUrl: './edit-product.component.html',
	styleUrls: ['./edit-product.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditProductComponent extends CanDeactivateComponent implements OnInit, OnDestroy {
	readonly viberPriorityComponent = ViberPriorityComponent;
	readonly TrafficType = TrafficType;
	readonly MessagePurpose = MessagePurpose;
	readonly billingModes = Object.values(this.enums.get('DecisionMode'));

	readonly product: Subscription;
	productForm!: FormGroup;

	editModeChannelControl!: FormControl;
	editModeCurrencyControl!: FormControl;
	editModeBillingModeControl!: FormControl;

	readonly filterCurrencyControl = new FormControl('');
	filterCurrencyList: Currency<exist>[] = [];

	editable = false;
	readonly allowedDeactivation = new BehaviorSubject<boolean>(true);

	protected readonly ngUnsubscribe: Subject<void> = new Subject<void>();

	constructor(
		@Inject(EditPanelInputData) private inputData: EditPanelInputData,
		private editPanel: AlarisEditPanelService,
		public vsService: VendorSubscriptionsService,
		public curService: CurrencyService,
		public chService: ChannelsService,
		public cuService: ChannelUtilsService,
		public enums: EnumsMapperService,
		private profile: AlarisProfileService,
		private isOwner: OwnerService,
		private lService: AlarisLanguageService,
		private guard: CanDeactivateGuardService
	) {
		super();
		this.addEditPanelGuard(editPanel, this.guard);
		this.product = this.inputData.product as Subscription;
	}

	ngOnInit(): void {
		this.editable = this.profile.allowed([AP_PERMISSIONS.V_SUBSCR_E]) && this.isOwner.is;

		this.curService.list$
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((list) => {
				this.filterCurrencyList = filterWildcardData(this.filterCurrencyControl.value, list, 'name');
			});

		const channel = this.lService.translate(this.cuService.purpose(
			this.product.trafficType, this.product.settings?.viberSettings.messagePurpose
		));

		this.productForm = new FormGroup({
			name: new FormControl(this.product.name, Validators.required),
			trafficType: new FormControl(this.product.id ? this.product.trafficType : null, Validators.required),
			currencyId: new FormControl(this.product.id ? this.product.currencyId : null, Validators.required),
			settings: new FormGroup({
				viberSettings: new FormGroup({
					messagePurpose: new FormControl(this.product.settings?.viberSettings.messagePurpose ||
						MessagePurpose.PROMOTION)
				})
			}),
			billingMode: new FormControl(this.product.id ? this.product.billingMode : null, Validators.required)
		});
		this.editModeChannelControl = new FormControl(channel);
		this.editModeBillingModeControl = new FormControl(this.lService.translate('enums.' + this.product.billingMode));
		this.editModeCurrencyControl = new FormControl(
			this.curService.map.get(this.product.currencyId || 0)?.name
		);

		this.filterCurrencyControl.valueChanges
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((value) => {
				this.filterCurrencyList = filterWildcardData(value, this.curService.list, 'name');
			});
	}

	close(productId?: Id): void {
		this.editPanel.close(productId ?? null);
	}

	save(): void {
		const product: Subscription = {
			...this.product,
			...this.productForm.value
		};

		if ( this.productForm.get('trafficType')?.value === TrafficType.SMS ) {
			delete product.settings;
		}

		this.allowedDeactivation.next(false);
		this.vsService.update(product)
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((resp) => {
				this.allowedDeactivation.next(true);
				if ( resp.Success ) {
					this.close(resp.Data?.[0].id);
				}
			});
	}

	displayChannelSelected = (value: TrafficType): string => {
		return this.chService.list.find(i => i.channelType === value)?.channelType ?? '';
	};

	displayCurrencySelected = (id: Id<exist>): string => {
		return this.curService.map.get(id ?? 0)?.name ?? '';
	};

	displayBillingModeSelected = (mode: DecisionMode | null): string => {
		return mode ? this.lService.translate('enums.' + mode) : '';
	};

	ngOnDestroy(): void {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}
}
