<alaris-card>
	<div *ngIf="paymentSystems.length" class="table-management">
		<div class="left">
			<span class="title" data-testid="subTitle">{{ 'payment-systems.managePayments' | translate }}</span>
		</div>
		<div class="right">
			<button (click)="editPaymentSystem()"
					*alarisPBAC="[AP_PERMISSIONS.PAYMENTS_SYSTEMS_E]"
					alaris-button bType="primary" data-testid="createBtn" size="lg">
				<alaris-icon class="in-primary" name="icon-plus"></alaris-icon>
				{{'payment-systems.add' | translate}}
			</button>
		</div>
	</div>
	<div [alarisFade]="'vertical'" [alarisLoading]="loading$" class="content">
		<app-empty-table (newPaymentSystem)="editPaymentSystem()"
						 *ngIf="!paymentSystems.length; else cards"
						 [disabled]="!([AP_PERMISSIONS.PAYMENTS_SYSTEMS_E] | alarisPBAC)"
						 type="payment-systems">
		</app-empty-table>
		<ng-template #cards>
			<app-payment-system-card (dblclick)="this.editPaymentSystem.call(this, system)"
									 (refresh)="load()"
									 *ngFor="let system of paymentSystems"
									 [actions]="actions"
									 [attr.data-testid]="'paymentSystemCard/' + system.id"
									 [paymentSystem]="system">
			</app-payment-system-card>
		</ng-template>
	</div>
</alaris-card>
