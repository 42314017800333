import { AfterViewInit, ChangeDetectionStrategy, Component, Inject, OnDestroy, ViewChild } from '@angular/core';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';

import { SubscriptionType } from '@campaign-portal/namespace/common/enums';
import { Subscription } from '@campaign-portal/namespace/entities/subscriptions/specs';
import { exist } from '@campaign-portal/namespace/common/id';

import { ModelType } from '@helpers/types/app.classes-interfaces';
import {
	PACKS_PICKER_SERVICE,
	PLANS_PICKER_SERVICE,
	SubscriptionsPickerService
} from '../subscriptions-picker.service';
import { AlarisTabsComponent } from '@campaign-portal/components-library';

export interface SubscriptionsPickerDialogData {
	type: SubscriptionType;
}

@Component({
	selector: 'app-subscriptions-picker-dialog',
	templateUrl: './subscriptions-picker-dialog.component.html',
	styleUrl: './subscriptions-picker-dialog.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubscriptionsPickerDialogComponent implements AfterViewInit, OnDestroy {
	@ViewChild('pickerTabs') readonly pickerTabs!: AlarisTabsComponent;

	readonly subscriptionType: SubscriptionType;

	readonly pickerService: SubscriptionsPickerService;
	readonly availableRowAction: (tRow: Subscription<exist>) => void;
	readonly temporaryRowAction: (tRow: Subscription<exist>) => void;

	readonly ModelType = ModelType;
	readonly SubscriptionType = SubscriptionType;

	constructor(
		@Inject(PLANS_PICKER_SERVICE) private readonly plansPickerService: SubscriptionsPickerService,
		@Inject(PACKS_PICKER_SERVICE) private readonly packsPickerService: SubscriptionsPickerService,
		@Inject(DIALOG_DATA) private readonly data: SubscriptionsPickerDialogData,
		private readonly dialogRef: DialogRef
	) {
		this.subscriptionType = this.data.type;
		this.pickerService = this.subscriptionType === SubscriptionType.PACK
			? this.packsPickerService
			: this.plansPickerService;
		this.availableRowAction = (tRow): void => this.pickerService.moveToTemporarySelected([tRow]);
		this.temporaryRowAction = (tRow): void => this.pickerService.removeFromTemporarySelected([tRow]);
	}

	close(): void {
		this.dialogRef.close(false);
	}

	confirm(): void {
		this.pickerService.applyChanges();
		this.dialogRef.close(true);
	}

	ngAfterViewInit(): void {
		this.pickerTabs.setActiveTab('available');
	}


	ngOnDestroy(): void {
		this.pickerService.clearChanges();
	}
}
