import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, filter, finalize, Subject, takeUntil } from 'rxjs';

import {
	AlarisConfigService,
	AlarisDialogService,
	AlarisEditPanelService,
	AlarisLanguageService,
	AlarisProfileService,
	EditPanelInputData,
	filterWildcardData
} from '@campaign-portal/components-library';

import { exist, Id, UUID } from '@campaign-portal/namespace/common/id';
import { Partner } from '@campaign-portal/namespace/entities/partners/specs';
import { RoleType } from '@campaign-portal/namespace/common/enums';
import { Permission, User } from '@campaign-portal/namespace/common/user';

import { UsersService } from '../users.service';
import { PartnersService } from '../../partners/partners.service';
import {
	AP_PERMISSION_GROUPS,
	AP_PERMISSIONS,
	AP_RESELLER_PERMISSION_GROUPS,
	AP_RESELLER_PERMISSIONS,
	CP_PERMISSION_GROUPS,
	CP_PERMISSIONS,
	PermissionGroup,
	ROLES
} from '@helpers/types/permissions';
import { CanDeactivateComponent } from '@helpers/can-deactivate/can-deactivate.component';
import { CanDeactivateGuardService } from '@helpers/can-deactivate/can-deactivate.guard';
import { UsersDialogComponent, UsersDialogType } from '../users-dialog/users-dialog.component';

const AP_ROLES = [
	RoleType.ADMIN, RoleType.CLIENT_MANAGER, RoleType.NOC,
	RoleType.RATE_MANAGER, RoleType.FINANCE_MANAGER
];
const CP_ROLES = [RoleType.CAMPAIGN_USER];

interface UserFormControls {
	id: FormControl<UUID | null>;
	details: FormGroup<{
		firstName: FormControl<string>;
		lastName: FormControl<string>;
	}>;
	email: FormControl<string>;
	partnerId: FormControl<exist>;
	isActive: FormControl<boolean>;
	role: FormControl<RoleType>;
}

@Component({
	selector: 'app-edit-user',
	templateUrl: './edit-user.component.html',
	styleUrls: ['./edit-user.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditUserComponent extends CanDeactivateComponent implements OnInit, OnDestroy {
	readonly AP_PERMISSIONS = AP_PERMISSIONS;
	readonly user: User;
	userForm!: FormGroup<UserFormControls>;

	permissionsForm = new FormGroup({});
	roles: RoleType[] = [];
	permissions: Permission[] = [];

	filterPartnersList: Partner<exist>[] = [];
	readonly filterPartnersControl = new FormControl('');

	readonly loadingOnSave$ = new BehaviorSubject<boolean>(false);
	readonly allowedDeactivation = new BehaviorSubject<boolean>(true);

	protected readonly ngUnsubscribe: Subject<void> = new Subject<void>();

	private readonly ownId: Id<exist>;

	constructor(
		@Inject(EditPanelInputData) private readonly inputData: EditPanelInputData,
		public readonly profile: AlarisProfileService,
		public readonly usersService: UsersService,
		public readonly partnersService: PartnersService,
		public readonly config: AlarisConfigService,
		private readonly editPanel: AlarisEditPanelService,
		private readonly cd: ChangeDetectorRef,
		private readonly lService: AlarisLanguageService,
		private readonly dialog: AlarisDialogService,
		private readonly guard: CanDeactivateGuardService
	) {
		super();
		this.addEditPanelGuard(editPanel, this.guard);
		this.user = this.inputData.user as User;
		this.ownId = this.profile.user.partnerId;
	}

	private _showForm = false;

	get showForm(): boolean {
		return this.partnersService.list.length > 0 || this._showForm || !!this.user.id;
	}

	get readonlyPartnerControl(): boolean {
		return this.partnersService.list.length === 0 || this.user.id !== null;
	}

	get isAllSelected(): boolean {
		return Object.values(this.permissionsForm.value).every(isSelected => isSelected);
	}

	get groups(): PermissionGroup[] {
		return this.userForm.controls.role.value === RoleType.CAMPAIGN_USER
			? Object.keys(CP_PERMISSION_GROUPS) as PermissionGroup[]
			: Object.keys(
				this.isReseller(this.userForm.controls.partnerId.value) ?
					AP_RESELLER_PERMISSION_GROUPS : AP_PERMISSION_GROUPS
			) as PermissionGroup[];
	}

	ngOnInit(): void {
		this.partnersService.loading$
			.pipe(
				filter(loading => !loading),
				takeUntil(this.ngUnsubscribe)
			)
			.subscribe(() => {
				this.roleTypeAndPermissionsDef(this.user.partnerId);
				this.user.permissions.forEach((permission) => {
					this.permissionsForm.get(permission)?.setValue(true);
				});

				const isUserReseller = this.partnersService.map.get(this.user.partnerId)?.isReseller;

				if ( (isUserReseller && this.user.role === RoleType.ADMIN) || (this.ownId && isUserReseller) ) {
					this.permissionsForm.disable();
				}
			});


		this.partnersService.list$
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((list) => {
				this.filterPartnersList = filterWildcardData(this.filterPartnersControl.value, list, 'name');
			});

		this.userForm = new FormGroup({
			id: new FormControl(this.user.id),
			details: new FormGroup({
				firstName: new FormControl<string>(
					(this.user.details?.firstName || '') as string,
					{ nonNullable: true, validators: [Validators.required] }
				),
				lastName: new FormControl<string>(
					(this.user.details?.lastName || '') as string,
					{ nonNullable: true }
				)
			}),
			email: new FormControl(
				this.user.email,
				{ nonNullable: true, validators: [Validators.required, Validators.email] }
			),
			partnerId: new FormControl(
				{
					value: this.user.partnerId,
					disabled: this.user.id !== null
				},
				{ nonNullable: true, validators: [Validators.required] }
			),
			isActive: new FormControl(this.user.isActive || false, {
				nonNullable: true,
				validators: [Validators.required]
			}),
			role: new FormControl({
				value: this.user.role,
				disabled: this.user.id !== null
			}, { nonNullable: true, validators: [Validators.required] })
		});

		if ( !this.profile.allowed([AP_PERMISSIONS.USERS_E]) ) {
			this.userForm.disable();
			this.permissionsForm.disable();
		}

		this.userForm.controls.partnerId.valueChanges
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((value) => {
				this.roleTypeAndPermissionsDef(value);
				this.userForm.controls.role.setValue(this.roles[0]);
				if ( this.partnersService.map.get(value)?.isReseller && this.userForm.controls.role.value == RoleType.ADMIN ) {
					this.roles = [AP_ROLES[0]];
					this.permissionsForm.disable();
				}
				this.cd.detectChanges();
			});

		this.userForm.controls.role.valueChanges
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((value) => {
				this.permissions.forEach((permission) => {
					this.permissionsForm.get(permission)?.setValue(false);
				});
				const permissions = ROLES[value].permissions;
				permissions.forEach((permission) => {
					this.permissionsForm.get(permission)?.setValue(true);
				});
			});

		this.filterPartnersControl.valueChanges
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((value) => {
				this.filterPartnersList = filterWildcardData(value, this.partnersService.list, 'name');
			});
	}

	getPermissions(group: PermissionGroup): Permission[] {
		const groups = this.userForm.controls.role.value === RoleType.CAMPAIGN_USER
			? CP_PERMISSION_GROUPS
			: this.isReseller(this.userForm.controls.partnerId.value) ?
				AP_RESELLER_PERMISSION_GROUPS :
				AP_PERMISSION_GROUPS;
		return (group in groups) ? groups[group as keyof typeof groups] : [];
	}

	close(): void {
		this.editPanel.close(true);
	}

	save(): void {
		const user: User = {
			...this.user,
			...this.userForm.value,
			permissions: this.preparePermissionsList()
		};
		this.loadingOnSave$.next(true);
		this.allowedDeactivation.next(false);
		this.usersService.update(user)
			.pipe(
				takeUntil(this.ngUnsubscribe),
				finalize(() => {
					this.loadingOnSave$.next(false);
				})
			)
			.subscribe((resp) => {
				this.allowedDeactivation.next(true);
				if ( resp.Success ) {
					this.close();
				}
			});
	}

	displaySelected = (id: Id<exist>): string => {
		if ( id === this.ownId ) {
			return this.lService.translate('users.thisCompany');
		}
		return this.partnersService.list.find(i => i.id === id)?.name ?? '';
	};

	getIcon(group: PermissionGroup): string {
		const iconMap: { [key in PermissionGroup]: string } = {
			administration: 'icon-key',
			billing: 'icon-billing',
			finance: 'icon-billing',
			campaign: 'icon-paper-plane-right',
			rates: 'icon-rates',
			routing: 'icon-arrows-left-right',
			partners: 'icon-partner-line',
			contacts: 'icon-users',
			statistics: 'icon-statistics'
		};
		return iconMap[group];
	}

	countSelectedPermissions(permissions: Permission[]): number {
		return permissions.filter(permission => {
			return this.permissionsForm.get(permission)?.value;
		}).length;
	}

	preparePermissionsList(): string[] {
		return Object.entries(this.permissionsForm.value)
			.filter(([_, value]) => value)
			.map(([permission, _]) => permission);
	}

	displayUser = (type: RoleType): string => {
		return this.lService.translate(type ? 'enums.' + type : '');
	};

	ngOnDestroy(): void {
		this.loadingOnSave$.complete();
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}

	toggleAll(): void {
		const value = !this.isAllSelected;
		this.permissions.forEach((permission) => {
			this.permissionsForm.get(permission)?.setValue(value);
			this.permissionsForm.markAsDirty();
		});
		this.cd.detectChanges();
	}

	createPartner(): void {
		this.editPanel.close(null);
	}

	createColleague(): void {
		this._showForm = true;
		this.userForm.controls.partnerId.setValue(this.ownId);
	}

	changePasswordByEmail(user: User): void {
		this.openSpecificDialog('ChangePasswordByEmail', user);
	}

	changePasswordByHand(user: User): void {
		this.openSpecificDialog('ChangePasswordByHand', user);
	}

	private isReseller(id: Id<exist>): boolean {
		return this.partnersService.map.get(id)?.isReseller || !this.profile.user.details?.isOwner;
	}

	private roleTypeAndPermissionsDef(partnerId: Id<exist>): void {
		const partner = this.partnersService.map.get(partnerId);
		const ap = partnerId === this.ownId || partner?.isReseller;
		if ( ap ) {
			this.roles = AP_ROLES;
			// To exclude 'endpoints_read', 'endpoints_edit', 'ref_book_edit' for reseller
			this.permissions = Object.values(
				this.isReseller(partnerId) ? AP_RESELLER_PERMISSIONS : AP_PERMISSIONS
			);
		} else {
			this.roles = CP_ROLES;
			this.permissions = Object.values(CP_PERMISSIONS);
		}
		this.permissionsForm = new FormGroup({});
		this.permissions.forEach((permission) => {
			this.permissionsForm.setControl(
				permission,
				new FormControl<boolean>(!this.user.id, { nonNullable: true })
			);
		});
	}

	private openSpecificDialog(
		type: UsersDialogType,
		user: User
	): void {
		this.dialog.open(UsersDialogComponent, {
			data: { user, type },
			autoFocus: false
		}).closed
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe();
	}
}
