import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';

import { AbstractCRUDService, AlarisProfileService } from '@campaign-portal/components-library';

import { EntityField, InputComplexType, InputType } from '@campaign-portal/namespace/common/entityField';
import { ReadResponse } from '@campaign-portal/namespace/common/implementations';
import { FilterType } from '@campaign-portal/namespace/common/rpc.params';
import { TransactionHistory } from '@campaign-portal/namespace/entities/transaction-history/specs';

import { AP_PERMISSIONS } from '@helpers/types/permissions';

@Injectable()
export class TransactionFieldsService implements AbstractCRUDService, OnDestroy {

	readonly entity = '';
	readonly title = '';
	readonly loading$ = new BehaviorSubject<boolean>(false);

	private readonly id: EntityField = {
		id: 0,
		name: 'columns.id',
		variable: 'id',
		required: true,
		type: InputType.NUMBER,
		readonly: true,
		filterType: FilterType.EXACT
	};
	private readonly amount: EntityField = {
		id: 1,
		name: 'columns.amount',
		variable: 'amount',
		required: true,
		type: InputComplexType.RANGE,
		data: [],
		readonly: true,
		filterType: FilterType.BETWEEN
	};
	private readonly msgFromPack: EntityField = {
		id: 2,
		name: 'columns.msgFromPack',
		variable: 'msgFromPack',
		required: true,
		type: InputType.NUMBER,
		filterType: FilterType.BETWEEN,
		readonly: true
	};
	private readonly creationDate: EntityField = {
		id: 3,
		name: 'columns.date',
		variable: 'creationDate',
		required: true,
		type: InputComplexType.DATE_RANGE,
		readonly: true,
		filterType: FilterType.BETWEEN
	};
	private readonly operationType: EntityField = {
		id: 4,
		name: 'columns.operationType',
		variable: 'operationType',
		required: true,
		readonly: true,
		type: InputComplexType.MULTISELECT,
		data: [],
		filterType: FilterType.IN
	};
	private readonly partnerId: EntityField = {
		id: 5,
		name: 'columns.partner',
		variable: 'partnerId',
		required: true,
		type: InputComplexType.MULTISELECT,
		readonly: true,
		data: [],
		filterType: FilterType.IN,
		property: 'id'
	};
	private readonly createdBy: EntityField = {
		id: 6,
		name: 'columns.initiator',
		variable: 'initiator',
		required: true,
		type: InputType.TEXT,
		readonly: true,
		filterType: FilterType.LIKE
	};
	private readonly description: EntityField = {
		id: 7,
		name: 'columns.description',
		variable: 'description',
		required: true,
		type: InputType.TEXT,
		readonly: true,
		filterType: FilterType.LIKE
	};


	private readonly headers: EntityField[] = [
		this.id,
		this.amount,
		this.msgFromPack,
		this.creationDate,
		this.operationType,
		this.partnerId,
		this.createdBy,
		this.description
	];

	private readonly ngUnsubscribe = new Subject<void>();


	constructor(
		private readonly profile: AlarisProfileService
	) {
	}

	ngOnDestroy(): void {
		this.loading$.complete();
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}

	create(): Observable<void> {
		return of(undefined);
	}

	delete(): Observable<void> {
		return of(undefined);
	}

	read(): Observable<ReadResponse<EntityField[]>> {
		const headers = this.pickAvailableFields(this.headers);
		return of({
			Success: true,
			Total: headers.length,
			Data: headers
		});
	}

	update(): Observable<void> {
		return of(undefined);
	}

	private pickAvailableFields(fields: EntityField[]): EntityField[] {
		const permissions: [AP_PERMISSIONS, keyof TransactionHistory][] = [
			[AP_PERMISSIONS.USERS_R, 'initiator']
		];

		permissions.forEach(([permission, variable]) => {
			if ( !this.profile.allowed([permission]) ) {
				fields = fields.filter(field => field.variable !== variable);
			}
		});
		return fields;
	}
}
