import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import {
	AbstractCRUDService,
	AlarisApiService,
	AlarisLanguageService,
	AlarisToasterService,
	ErrorNotifierConfig
} from '@campaign-portal/components-library';

import {
	CreateResponse,
	DeleteRequest,
	DeleteResponse,
	ReadResponse
} from '@campaign-portal/namespace/common/implementations';
import { RPCRequestParams } from '@campaign-portal/namespace/common/rpc.params';
import { Id, UUID } from '@campaign-portal/namespace/common/id';
import { UsersResetPasswordRequest, UsersResetPasswordResponse } from '@campaign-portal/namespace/entities/users/specs';
import { ResetPasswordRequest, ResetPasswordResponse } from '@campaign-portal/namespace/entities/auth/specs';
import { User } from '@campaign-portal/namespace/common/user';

@Injectable({
	providedIn: 'root'
})
export class UsersService implements AbstractCRUDService {
	readonly loading$ = new BehaviorSubject<boolean>(false);

	readonly create = this.update;
	private readonly errorNotifier = (): ErrorNotifierConfig => ({ title: this.title });

	constructor(
		private readonly api: AlarisApiService,
		private readonly alarisToaster: AlarisToasterService,
		private readonly langService: AlarisLanguageService
	) {
	}

	get entity(): string {
		return this.langService.translate('notifications.entities.user');
	}

	get title(): string {
		return this.langService.translate('notifications.titles.user');
	}

	read(params?: RPCRequestParams): Observable<ReadResponse<User<UUID>[]>> {
		return this.api.loader<ReadResponse<User<UUID>[]>>('Users.Read', params, this.loading$, this.errorNotifier);
	}

	update(user: User): Observable<CreateResponse<User>> {
		const notify = (response: CreateResponse<User>): void => {
			if ( response.Success ) {
				const message = this.langService.translate(
					user.id ? 'notifications.actions.update' : 'notifications.actions.create', {
						entity: this.entity,
						name: (user.details.firstName ?? '') as string
					});
				this.alarisToaster.success(message, this.title);
			}
		};
		return this.api.loader<CreateResponse<User>>(
			`Users.${user.id ? 'Update' : 'Create'}`,
			{ Data: { Entities: [user] } }, this.loading$, this.errorNotifier, notify
		);
	}

	delete(id: Id<UUID>): Observable<DeleteResponse<User>> {
		const params: DeleteRequest<User<UUID>> = { Data: { Ids: [id] } };
		const notify = (response: DeleteResponse<User>): void => {
			if ( response.Success ) {
				const message = this.langService.translate('notifications.actions.delete', { entity: this.entity });
				this.alarisToaster.success(message, this.title);
			}
		};
		return this.api.loader<DeleteResponse<User>>(
			'Users.Delete', params, this.loading$, this.errorNotifier, notify
		);
	}

	passwordResetByHand(params: UsersResetPasswordRequest): Observable<UsersResetPasswordResponse> {
		const notify = (response: UsersResetPasswordResponse): void => {
			if ( response.Success ) {
				const message = this.langService.translate('notifications.actions.reset');
				this.alarisToaster.success(message, this.title);
			}
		};
		return this.api.loader<UsersResetPasswordResponse>(
			'Users.ResetPassword', params, this.loading$, this.errorNotifier, notify
		);
	}

	passwordResetByEmail(params: ResetPasswordRequest): Observable<ResetPasswordResponse> {
		const notify = (response: ResetPasswordResponse): void => {
			if ( response.Success ) {
				const message = this.langService.translate('notifications.actions.resetByEmail');
				this.alarisToaster.success(message, this.title);
			}
		};
		return this.api.loader<ResetPasswordResponse>(
			'Auth.ResetPassword', params, this.loading$, this.errorNotifier, notify
		);
	}
}
