<alaris-dialog [closeAction]="close.bind(this)"
			   [confirmAction]="confirm.bind(this)"
			   [confirmButtonText]="getConfirmText"
			   [disabled]="disabled"
			   [loading$]="loading$"
			   closeButtonText="actions.cancel">
	<h3 [ngSwitch]="data.type" slot="header">
		<ng-container *ngSwitchCase="'Delete'">{{ "endpoints.deleteEndpoint" | translate }}</ng-container>
		<ng-container *ngSwitchCase="'Price'">{{ "rates.changePrice" | translate }}</ng-container>
		<ng-container *ngSwitchCase="'Close'">{{ "rates.closeRate" | translate }}</ng-container>
		<ng-container *ngSwitchCase="'DeletePeriod'">{{ "rates.deletePeriod" | translate }}</ng-container>
		<ng-container *ngSwitchCase="'DeleteProduct'">{{ "products.deleteProduct" | translate }}</ng-container>
	</h3>
	<div slot="content">
		<div [ngSwitch]="data.type">
			<ng-container *ngSwitchCase="'DeletePeriod'">
				<p>{{ 'rates.deletePeriodNote' | translate }}</p>
			</ng-container>
			<ng-container *ngSwitchCase="'DeleteProduct'">
				<p>{{ "products.deleteNote" | translate }} <b>{{product.name}}?</b></p>
			</ng-container>
			<ng-container *ngSwitchCase="'Delete'">
				<p>{{ 'endpoints.deleteNote' | translate }} <b>{{endpoint.name}}?</b></p>
			</ng-container>
			<ng-container *ngSwitchCase="'Price'">
				<alaris-input-number [allowNegativeNumbers]="false"
									 [formControl]="dialogControl" [label]="'fc.price' | translate"
									 [placeholder]="'fc.price' | translate" data-testid="priceInputNumber">
				</alaris-input-number>
			</ng-container>
			<ng-container *ngSwitchCase="'Close'">
				<p>{{'rates.closeRateNote' | translate}}</p>
				<alaris-input-date [autofocus]="true"
								   [formControl]="dialogControl"
								   [label]="'fc.endDate' | translate"
								   [min]="Day.currentLocal()"
								   [toSystemDate]
								   [withTime]="true"
								   data-testid="endInputDate" style="width: 16rem">
				</alaris-input-date>
			</ng-container>
		</div>
	</div>
</alaris-dialog>
