<alaris-card *alarisLet="(isFiltersApplied || !!resellerFilter.value) as hasFilter">
	<div *ngIf="tRows.length > 0 || tRows.length === 0 && hasFilter" class="table-management">
		<div class="left">
			<span class="total" data-testid="total">{{ totalTitle | translate }}: {{ total }}</span>
			<alaris-tabs-button [formControl]="resellerFilter" [tabs]="tabs"
								data-testid="partnerTypeTabs">
			</alaris-tabs-button>
			<button (click)="editTableFilter()" alaris-button bType="outline" data-testid="editFilterBtn" size="lg">
				<alaris-icon class="in-outline" name="icon-filter-vertical"></alaris-icon>
				{{ enableButtons.filters.title | translate }}
				<div *ngIf="isFiltersApplied" class="indicator"></div>
			</button>
		</div>
		<div *alarisPBAC="[AP_PERMISSIONS.PARTNERS_E, AP_PERMISSIONS.PAYMENTS_E]" class="right">
			<button (click)="openEditPanel()" alaris-button bType="primary" data-testid="createBtn" size="lg">
				<alaris-icon class="in-primary" name="icon-plus"></alaris-icon>
				{{ 'partners.addEntry' | translate }}
			</button>
		</div>
	</div>
	<div [alarisLoading]="loading$" class="content">
		@if (tRows.length === 0 && !hasFilter) {
			<app-empty-table (newPartner)="openEditPanel()"
							 [disabled]="!([AP_PERMISSIONS.PARTNERS_E] | alarisPBAC)"
							 type="partner">
			</app-empty-table>
		} @else {
			<alaris-table (filterChange)="_applyFilter($event)"
						  (sortingChange)="_applySorting($event)"
						  [ctxActions]="ctxActions"
						  [dblClickRowAction]="openDetailsPanel.bind(this)"
						  [filters]="filters"
						  [rowActions]="mainActions"
						  [sorting]="sorting"
						  [tHeaders]="tHeaders"
						  [tRows]="tRows"
						  [templates]="{name, ccId, balance, creditLimit, financialSettings}"
						  alarisOverflowFader>
			</alaris-table>
		}
	</div>
	<div class="footer">
		<alaris-pagination (page)="_pageChange($event)"
						   [activePage]="activePage"
						   [hidden]="total === 0"
						   [pageSizeOptions]="pageSizeOptions"
						   [pageSize]="pageSize"
						   [total]="total"
						   class="pagination">
		</alaris-pagination>
	</div>
</alaris-card>

<ng-template #name [alarisCellCtx]="tRows[0]" let-data="data">
	<div class="name-column">
		{{ data.name }}
		<alaris-tag *ngIf="data.isReseller" mode="success">{{ 'partners.reseller' | translate }}</alaris-tag>
		<alaris-tag *ngIf="data.isActive" mode="info">{{ 'partners.active' | translate }}</alaris-tag>
	</div>
</ng-template>

<ng-template #ccId [alarisCellCtx]="tRows[0]" let-data="data">
	<div class="ccId-column">
		{{ (data.ccId | cCompany)?.name }}
	</div>
</ng-template>

<ng-template #balance [alarisCellCtx]="tRows[0]" let-data="data">
	{{ data.balance | currency: bs.currencyCode }}
</ng-template>

<ng-template #creditLimit [alarisCellCtx]="tRows[0]" let-data="data">
	{{ data.financialSettings.creditLimit | currency: bs.currencyCode }}
</ng-template>

<ng-template #financialSettings [alarisCellCtx]="tRows[0]" let-data="data">
	{{ 'enums.' + data.financialSettings.creditType | translate }}
</ng-template>
