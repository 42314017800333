import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';

import {
	AlarisComplexTableComponent,
	AlarisDialogService,
	AlarisEditPanelService,
	AlarisProfileService,
	AlarisTableSettingsService,
	AlarisTableStateService,
	DEFAULT_BUTTONS,
	EditPanelWidth,
	RowActionSimple,
	TabButtonEntity,
	TableSortIndicator
} from '@campaign-portal/components-library';
import { TableFiltersIndicator } from '@campaign-portal/components-library/lib/table';

import { exist, Id, UUID } from '@campaign-portal/namespace/common/id';
import { TableTypes } from '@campaign-portal/namespace/entities/table-settings/specs';
import { EntityField } from '@campaign-portal/namespace/common/entityField';


import { AP_PERMISSIONS } from '@helpers/types/permissions';
import { PartnerEntity, UserEntity } from '@helpers/types/app.classes-interfaces';
import { UsersFieldsService } from './users.fields.service';
import { PartnersService } from '../partners/partners.service';
import { UsersDialogComponent, UsersDialogType } from './users-dialog/users-dialog.component';
import { EditUserComponent } from './edit-user/edit-user.component';
import { EnumsMapperService } from '@helpers/services/enums.service';
import { FormControl } from '@angular/forms';
import { FilterType, SortDirection } from '@campaign-portal/namespace/common/rpc.params';
import { Partner } from '@campaign-portal/namespace/entities/partners/specs';
import { EditPartnerComponent } from '../partners/edit-partner/edit-partner.component';
import { ContractCompaniesService } from '../settings/contract-companies/contract-companies.service';
import { CurrencyService } from '@helpers/services/currency.service';
import { UsersTableService } from './users.table.service';
import { User } from '@campaign-portal/namespace/common/user';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
	selector: 'app-users',
	templateUrl: './users.component.html',
	styleUrls: [
		// eslint-disable-next-line max-len
		'../../../../node_modules/@campaign-portal/components-library/src/assets/templates/table-complex/complex-table.component.scss',
		'./users.component.scss'],
	providers: [UsersFieldsService, UsersTableService],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class UsersComponent extends AlarisComplexTableComponent<User<UUID>> implements OnInit {
	readonly AP_PERMISSIONS = AP_PERMISSIONS;

	readonly tabsFilter: TabButtonEntity<Id<exist> | string | null>[] = [
		{ label: 'tb.all', value: null },
		{ label: 'tb.yoursUsers', value: this.profile.user?.partnerId || 0 }
	];
	readonly selectedTabFilter = new FormControl<Id<exist> | string | null>(null);
	// @ts-expect-error: ignore some specific function signature
	readonly mainActions: RowActionSimple<UserObject<UUID>>[] = this.getActions();
	// @ts-expect-error: ignore some specific function signature
	readonly ctxActions: RowActionSimple<UserObject<UUID> | UserObject<UUID>[]>[] = [...this.mainActions].map(a => ({
		...a,
		icon: ''
	}));

	override sorting: TableSortIndicator = new Map()
		.set('partnerId', { enabled: true, value: SortDirection.ASC })
		.set('email', { enabled: true, value: SortDirection.ASC });

	override readonly id = 'users-table';

	constructor(
		public readonly profile: AlarisProfileService,
		public readonly entityService: UsersTableService,
		public override readonly fieldsService: UsersFieldsService,
		public override readonly tableSettingsService: AlarisTableSettingsService,
		public override readonly editPanel: AlarisEditPanelService,
		public override readonly cd: ChangeDetectorRef,
		private readonly dialog: AlarisDialogService,
		private readonly partnersService: PartnersService,
		private readonly enums: EnumsMapperService,
		private readonly ccService: ContractCompaniesService,
		private readonly curService: CurrencyService,
		stateService: AlarisTableStateService
	) {
		super(
			entityService,
			fieldsService,
			tableSettingsService,
			editPanel,
			cd,
			TableTypes.INTERNAL,
			DEFAULT_BUTTONS,
			stateService
		);
		this.entityService.stateID = this.id;
	}

	override ngOnInit(): void {
		super.ngOnInit();

		this.selectedTabFilter.valueChanges
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe((value) => {
				const partnerIdFilter = this.filters.get('partnerId');
				if ( partnerIdFilter ) {
					partnerIdFilter.enabled = !value;
				}
				this._applyFilter(this.filters);
			});
	}

	override refresh(): void {
		this.entityService.firstLoad = true;
		this.load();
	}

	openEditPanel(user?: User<UUID>): void {
		const entity = user
			? user
			: new UserEntity(null, this.profile.user.partnerId);
		this.editPanel.open(EditUserComponent, EditPanelWidth.SM, {
			user: entity
		})
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe(result => {
				if ( result ) {
					this.refresh();
				} else if ( result === null ) {
					this.createPartner();
				}
			});
	}

	delete(user?: User<UUID>): void {
		this.openSpecificDialog('Delete', user);
	}

	override applyHeaders(tHeaders: EntityField[]): void {
		super.applyHeaders(tHeaders);
		this.setRefBooksInFields({
			partnerId: this.partnersService
		})
	}

	override applyFilter($event: TableFiltersIndicator): void {
		super.applyFilter($event);
		this.readParams.Filters?.forEach(
			f => f.Field === 'role' ? f.Value = (f.Value as string[]).map(i => this.enums.get('RoleType')[i]) : f
		);
		this.readParams.Filters?.forEach(
			f => f.Field === 'partnerId' ? f.Value = (f.Value as Partner[]).map(i => i.id) : f
		);
		const isActiveFilter = this.filters.get('isActive');
		if ( isActiveFilter?.enabled && isActiveFilter.value !== null ) {
			// due to complexTable element super.applyFilter function
			// it is required to manually pass 'boolean false' filter
			const isActiveFilterParam = this.readParams.Filters?.find(f => f.Field === 'isActive');
			if ( isActiveFilterParam ) {
				isActiveFilterParam.Value = isActiveFilter.value;
			} else {
				this.readParams.Filters?.push({
					Field: 'isActive',
					Type: FilterType.EXACT,
					Value: isActiveFilter.value
				});
			}
		}

		if ( this.selectedTabFilter.value ) {
			const partnerIdFilter = {
				Field: 'partnerId', Type: FilterType.EXACT, Value: this.selectedTabFilter.value
			};
			if ( (this.readParams.Filters ?? []).find(f => f.Field === 'partnerId') ) {
				this.readParams.Filters = (this.readParams.Filters ?? [])
					.map(f => f.Field === 'partnerId' ? partnerIdFilter : f);
			} else {
				(this.readParams.Filters ?? []).push(partnerIdFilter);
			}
		}
	}

	override restoreState(): void {
		super.restoreState();

		const colleagues = this.readParams.Filters?.find((f) => {
			return f.Field === 'partnerId' && f.Value === this.profile.user.partnerId || 0;
		});

		if ( colleagues ) {
			this.selectedTabFilter.setValue(colleagues.Value as string | number | null);
		}
	}

	private createPartner(): void {
		const defaultCCId = this.ccService.list[0]?.id ?? 0;
		const defaultCurrencyId = this.curService.list[0].id ?? 0;
		const partner = new PartnerEntity(null, defaultCCId, defaultCurrencyId);
		// timeout is required to swap editpanels subscribers. Very dirty
		setTimeout(() => {
			this.editPanel.open(EditPartnerComponent, EditPanelWidth.SM, { partner })
				.pipe(takeUntilDestroyed(this.destroyRef))
				.subscribe((result) => {
					if ( !result ) {
						return;
					}

					this.partnersService.load().pipe(takeUntilDestroyed(this.destroyRef)).subscribe();
					setTimeout(() => {
						this.openEditPanel();
					});
				});
		});
	}

	private openSpecificDialog(
		type: UsersDialogType,
		user?: User<UUID>
	): void {
		this.dialog.open(UsersDialogComponent, {
			data: { user, type },
			autoFocus: false
		}).closed
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe((result) => {
				if ( result ) {
					this.refresh();
				}
			});
	}

	private getActions(): RowActionSimple<User<UUID>>[] {
		const details: RowActionSimple<User<UUID>> = {
			icon: 'icon-password-show',
			label: 'actions.details',
			action: this.openEditPanel.bind(this)
		};
		const edit: RowActionSimple<User<UUID>> = {
			icon: 'icon-edit',
			label: 'actions.edit',
			action: this.openEditPanel.bind(this)
		};
		const _delete: RowActionSimple<User<UUID>> = {
			icon: 'icon-delete',
			label: 'actions.delete',
			action: this.delete.bind(this),
			highlight: true,
			separator: true
		};
		return this.profile.allowed([AP_PERMISSIONS.USERS_E])
			? [edit, _delete]
			: [details];
	}
}
