import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { EMPTY, finalize, switchMap } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import {
	AlarisBalanceService,
	AlarisComplexTableComponent,
	AlarisDialogService,
	AlarisEditPanelService,
	AlarisFilesService,
	AlarisLanguageService,
	AlarisProfileService,
	AlarisTableSettingsService,
	AlarisTableStateService,
	ChannelUtilsService,
	DEFAULT_BUTTONS,
	EditPanelWidth,
	RowActionSimple,
	TableSortIndicator
} from '@campaign-portal/components-library';
import { TableFiltersIndicator } from '@campaign-portal/components-library/lib/table';

import { exist } from '@campaign-portal/namespace/common/id';
import { TableSettingsEntries, TableTypes } from '@campaign-portal/namespace/entities/table-settings/specs';
import { Rate, RatesExportRequest, RatesExportResponse } from '@campaign-portal/namespace/entities/rates/specs';
import { RateStatus, SubscriptionType } from '@campaign-portal/namespace/common/enums';
import { Filter, FilterType, SortDirection } from '@campaign-portal/namespace/common/rpc.params';

import { OwnerService } from '@helpers/services/owner.service';
import { VendorSubscriptionsService } from '@helpers/services/vendor-subscriptions.service';
import { RatesService } from '@helpers/services/rates.service';
import { RatesFieldsService } from '@helpers/services/rates.fields.service';
import { AP_PERMISSIONS } from '@helpers/types/permissions';
import { RatesExportPanelComponent } from './rates-export-panel/rates-export-panel.component';
import { EditRateComponent } from './edit-rate/edit-rate.component';
import { VendorRatesDialogType, VendorsDialogComponent } from '../vendors-dialog/vendors-dialog.component';

@Component({
	selector: 'app-rates',
	templateUrl: './rates.component.html',
	styleUrls: [
		// eslint-disable-next-line max-len
		'../../../../../node_modules/@campaign-portal/components-library/src/assets/templates/table-complex/complex-table.component.scss',
		'./rates.component.scss'
	],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class RatesComponent extends AlarisComplexTableComponent<Rate<exist>> implements OnInit {
	@Output() readonly initImportMode = new EventEmitter();
	readonly RateStatus = RateStatus;
	readonly AP_PERMISSIONS = AP_PERMISSIONS;

	override readonly id = 'rates';
	// tslint:disable-next-line:no-any
	override readonly rowActions: RowActionSimple<any>[] = this.getRowActions();
	override readonly mainActions: RowActionSimple<Rate<exist> | Rate<exist>[]>[] = this.getMainActions();
	override readonly additionalActions: RowActionSimple<Rate<exist> | Rate<exist>[]>[] =
		this.profile.allowed([AP_PERMISSIONS.V_SUBSCR_E])
			? [
				{
					icon: 'icon-rejected',
					label: 'actions.close',
					action: this.close.bind(this)
				}
			]
			: [];

	override sorting: TableSortIndicator = new Map()
		.set('country', { enabled: true, value: SortDirection.ASC });

	// Todo - ctxActions doesn't work
	override readonly ctxActions: RowActionSimple<Rate<exist> | Rate<exist>[]>[] = [...this.rowActions].map(a => ({
		...a,
		icon: ''
	}));
	readonly activeRatesControl = new FormControl<boolean>(false);

	readonly tSettingsModified: TableSettingsEntries = [
		['country', { visible: true }],
		['mcc', { visible: false }],
		['network', { visible: true }],
		['mnc', { visible: false }]
	];

	constructor(
		public readonly entityService: RatesService,
		public override readonly fieldsService: RatesFieldsService,
		public override readonly tableSettingsService: AlarisTableSettingsService,
		public override readonly editPanel: AlarisEditPanelService,
		public override readonly cd: ChangeDetectorRef,
		public override readonly stateService: AlarisTableStateService,
		public readonly channelUtilsService: ChannelUtilsService,
		public readonly bs: AlarisBalanceService,
		public readonly vsService: VendorSubscriptionsService,
		public readonly isOwner: OwnerService,
		private readonly profile: AlarisProfileService,
		private readonly fileService: AlarisFilesService,
		private readonly lService: AlarisLanguageService,
		private readonly dialog: AlarisDialogService
	) {
		super(
			entityService,
			fieldsService,
			tableSettingsService,
			editPanel,
			cd,
			TableTypes.INTERNAL,
			DEFAULT_BUTTONS,
			stateService
		);
	}

	mock = (): void => {
	};

	override ngOnInit(): void {
		super.ngOnInit();

		this.activeRatesControl.valueChanges
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe(() => {
				this._applyFilter(this.filters);
			});
	}

	override applyFilter($event: TableFiltersIndicator): void {
		if ( $event.get('activeFrom')?.value || $event.get('activeTill')?.value ) {
			this.activeRatesControl.setValue(false, { emitEvent: false });
		}
		this.filters = new Map($event);
		this.isFiltersApplied = this.checkFilters($event);
		const filters: Filter[] = [];
		this.activePage = 0; // Reset pagination to first page
		$event.forEach(
			(fV, variable) => {
				const header = this.tHeaders.find(h => h.variable === variable);
				const hasValue = Array.isArray(fV?.value) ? fV?.value.length : fV?.value;
				if ( hasValue ) {
					const filter: Filter = {
						Field: variable,
						Type: fV?.filterType ?? FilterType.EXACT,
						Value: fV?.value
					};
					if ( header?.property ) {
						filter.Property = header.property;
					}

					switch (variable) {
						case 'mcc':
							filter.Field = 'country';
							filter.Property = variable;
							break;
						case 'mnc':
							filter.Field = 'network';
							filter.Property = variable;
							break;
						case 'channel':
							filter.Field = 'subscriptionId';
							filter.Property = 'channelType';
							break;
						case 'country':
							filter.Value = fV && Array.isArray(fV.value) ? fV.value.map((country) => {
								return { name: country };
							}) : null;
							break;
						case 'network':
							filter.Value = fV && Array.isArray(fV.value) ? fV.value.map((network) => {
								return { name: network };
							}).flat() : null;
					}

					filters.push(filter);
				}
			}
		);
		// Add only active rates filter
		if ( this.activeRatesControl.value ) {
			const activeFromIndex = filters.findIndex(f => f.Field === 'activeFrom');
			const activeToIndex = filters.findIndex(f => f.Field === 'activeTill');

			if ( activeFromIndex !== -1 ) {
				filters.splice(activeFromIndex, 1);
			}

			filters.push({
				Field: 'activeFrom',
				Type: FilterType.LESS,
				Value: new Date().toISOString()
			});

			if ( activeToIndex !== -1 ) {
				filters.splice(activeToIndex, 1);
			}

			filters.push({
				Field: 'activeTill',
				Type: FilterType.MORE,
				Value: new Date().toISOString()
			});
		}
		this.readParams = {
			...this.readParams,
			Paging: {
				Skip: this.activePage,
				Take: this.pageSize
			},
			Filters: filters
		};
	}

	openEditPanel(rate?: Rate<exist>): void {
		this.editPanel.open(EditRateComponent, EditPanelWidth.MD, { rate })
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe(result => {
				if ( result ) {
					this.load();
				}
			});
	}

	override import(): void {
		this.initImportMode.emit();
	}

	selectFields(): void {
		this.editPanel.open(RatesExportPanelComponent, EditPanelWidth.SM, {
			tHeads: this.tHeaders,
			total: this.total
		})
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe(fields => {
				if ( fields ) {
					this.export(null, fields as string[]);
				}
			});
	}

	export(rates: Rate<exist>[] | null, fields?: string[]): void {
		let params: RatesExportRequest = {
			Data: {
				Ids: rates ? rates.map(rate => rate.id) : null,
				fields: fields ? fields : this.tHeaders.map(field => field.variable)
			}
		};

		if ( rates === null ) {
			params = {
				...params,
				Filters: [
					...this.readParams.Filters ?? [],
					{
						Field: 'subscriptionId',
						Type: FilterType.EXACT,
						Value: SubscriptionType.VENDOR,
						Property: 'type'
					}
				]
			};
		}

		this.loading$.next(true);
		this.entityService.export(params)
			.pipe(
				takeUntilDestroyed(this.destroyRef),
				switchMap(
					(response: RatesExportResponse) => {
						return response.Success
							? this.fileService.export(response.Data[0]?.id, response.Data[0]?.name)
							: EMPTY;
					}
				),
				finalize(() => {
					this.loading$.next(false);
				})
			)
			.subscribe();
	}

	changePrice(): void {
		this.openSpecificDialog('Price', this.selection);
	}

	close(): void {
		this.openSpecificDialog('Close', this.selection);
	}

	private getRowActions(): RowActionSimple<Rate<exist>>[] {
		const edit: RowActionSimple<Rate<exist>> = {
			icon: 'icon-edit',
			label: 'actions.edit',
			action: this.openEditPanel.bind(this)
		};
		const details: RowActionSimple<Rate<exist>> = {
			icon: 'icon-password-show',
			label: 'actions.details',
			action: this.openEditPanel.bind(this)
		};
		return this.profile.allowed([AP_PERMISSIONS.V_SUBSCR_E])
			? [edit]
			: [details];
	}

	private getMainActions(): RowActionSimple<Rate<exist> | Rate<exist>[]>[] {
		const _export: RowActionSimple<Rate<exist> | Rate<exist>[]> = {
			icon: 'icon-export-1',
			label: this.lService.translate('actions.export'),
			action: (): void => {
				this.export(this.selection);
			}
		};
		const changePrice: RowActionSimple<Rate<exist> | Rate<exist>[]> = {
			icon: 'icon-money-2',
			label: 'actions.changePrice',
			action: this.changePrice.bind(this)
		};
		return this.profile.allowed([AP_PERMISSIONS.V_SUBSCR_E])
			? [_export, changePrice]
			: [_export];
	}

	private openSpecificDialog(type: VendorRatesDialogType, rates: Rate<exist>[] | null = null): void {
		this.dialog.open(VendorsDialogComponent, {
			data: { type, rates },
			autoFocus: false
		}).closed
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe((result) => {
				if ( result ) {
					this.refresh();
				}
			});
	}
}
