@if (type === ModelType.TEMPORARY || type === ModelType.AVAILABLE) {
	<header class="header">
		<div class="total">
			<b>{{ (type === ModelType.AVAILABLE ? 'gl.available' : 'gl.total') | translate }}</b>
			<span class="size">{{ tDefaultRows.length }}</span>
		</div>
		@if (tRows.length) {
			<alaris-link (click)="_bulkAction()"
						 [underline]="false"
						 data-testid="toggleAllLink" type="secondary">
				{{
					(type === ModelType.AVAILABLE
							? isFiltersApplied ? 'gl.selectFiltered' : 'gl.selectAll'
							: isFiltersApplied ? 'gl.deselectFiltered' : 'gl.deselectAll'
					) | translate : {amount: tRows.length}
				}}
			</alaris-link>
		}
	</header>
}
<alaris-table (filterChange)="_applyFilter($event)"
			  (sortingChange)="_applySorting($event)"
			  [filters]="filters"
			  [fixFirstColumn]="false"
			  [resizable]="false"
			  [sorting]="sorting"
			  [tHeaders]="tHeaders"
			  [tRows]="tRows"
			  [templates]="{ name, billingMode, currencyId, packSettings }"
			  alarisOverflowFader
			  class="alaris-table">
</alaris-table>

<ng-template #name [alarisCellCtx]="tRows[0]" let-data="data">
	@if (isDivider(data)) {
		<div data-role="divider">
			<span class="dots"></span>
			<span class="note">{{ 'subscriptions.groups.addedEarlier' | translate }}</span>
		</div>
	} @else {
		<div class="name-column">
			<div [alarisTooltip]="data.name"
				 [maxWidth]="210" [overflow]="true" [offsetY]="6" [pointer]="false" class="name">
				{{ data.name }}
			</div>
			<div class="trafficType">
				@if (data.trafficType === TrafficType.VIBER) {
					{{ cu.purpose(data.trafficType, data.settings?.viberSettings?.messagePurpose) | translate }}
				} @else {
					{{ cu.name(data.trafficType) | translate }}
				}
			</div>
		</div>
	}
</ng-template>

<ng-template #billingMode [alarisCellCtx]="tRows[0]" let-data="data">
	@if (isDivider(data)) {
		<ng-container *ngTemplateOutlet="divider"></ng-container>
	} @else {
		{{ 'enums.' + data.billingMode | translate }}
	}
</ng-template>

<ng-template #currencyId [alarisCellCtx]="tRows[0]" let-data="data">
	@if (isDivider(data)) {
		<ng-container *ngTemplateOutlet="divider"></ng-container>
	} @else {
		<div class="currency-column">
			{{ (data.currencyId ?? 0 | alarisCurrency)?.name ?? '' }}
			<ng-container *ngTemplateOutlet="selectionToggle; context: { $implicit: data }"></ng-container>
		</div>
	}
</ng-template>

<ng-template #packSettings let-data="data">
	@if (isDivider(data)) {
		<ng-container *ngTemplateOutlet="divider"></ng-container>
	} @else {
		<div class="price-column">
			<span class="price"
				  [attr.int]="data.packSettings.packPrice | currency: bs.currencyCode: 'symbol' :'1.0-0'"
				  [attr.float]="fractional(data.packSettings.packPrice)">
			</span>
			<ng-container *ngTemplateOutlet="selectionToggle; context: { $implicit: data }"></ng-container>
		</div>
	}
</ng-template>

<ng-template #selectionToggle let-data>
	@if (isDivider(data)) {
		<ng-container *ngTemplateOutlet="divider"></ng-container>
	} @else {
		<alaris-icon (click)="rowAction(data)"
					 *alarisPBAC="[AP_PERMISSIONS.SUBSCR_E]"
					 [name]="type === ModelType.AVAILABLE ? 'icon-checkmark' : 'icon-close'"
					 data-role="row-action">
		</alaris-icon>
	}
</ng-template>

<ng-template #divider>
	<div data-role="divider">
		<span class="dots"></span>
	</div>
</ng-template>
