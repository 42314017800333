import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnInit,
	Output
} from '@angular/core';

import {
	AlarisComplexTableComponent,
	AlarisEditPanelService,
	AlarisFilesService,
	AlarisFilterManagerComponent,
	AlarisTableSettingsService,
	AlarisTableStateService,
	DEFAULT_BUTTONS,
	EditPanelWidth,
	RowActionSimple,
	TableFiltersIndicator,
	TableSortIndicator
} from '@campaign-portal/components-library';

import { TableTypes } from '@campaign-portal/namespace/entities/table-settings/specs';
import { FilterType, SortDirection } from '@campaign-portal/namespace/common/rpc.params';
import { EntityField } from '@campaign-portal/namespace/common/entityField';
import { RatesImportHistory } from '@campaign-portal/namespace/entities/rates-import-history/specs';
import { exist, Id } from '@campaign-portal/namespace/common/id';
import { ImportStatus, SubscriptionType } from '@campaign-portal/namespace/common/enums';

import { RatesImportHistoryFieldsService } from './rates-import-history.fields.service';
import { EnumsMapperService } from '@helpers/services/enums.service';
import { VendorSubscriptionsService } from '@helpers/services/vendor-subscriptions.service';
import { RatesService } from '@helpers/services/rates.service';
import { TagMode } from '@campaign-portal/components-library/lib/tag/src/base';
import { RateHistoryTableService } from '@helpers/components/import-history/rate-history.table.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
	selector: 'app-import-history',
	templateUrl: './import-history.component.html',
	styleUrls: [
		// eslint-disable-next-line max-len
		'../../../../../node_modules/@campaign-portal/components-library/src/assets/templates/table-complex/complex-table.component.scss',
		'./import-history.component.scss'
	],
	providers: [RatesImportHistoryFieldsService, RateHistoryTableService],
	changeDetection: ChangeDetectionStrategy.OnPush
})

export class ImportHistoryComponent extends AlarisComplexTableComponent<RatesImportHistory>
	implements OnChanges, OnInit {

	@Input() subscriptionId!: Id<exist>;
	@Input() vendorRates = false;
	@Input() withImport = true;

	@Output() readonly initImportMode = new EventEmitter();
	@Output() readonly importHistoryChange = new EventEmitter<RatesImportHistory[]>();

	override readonly id = 'import-history';
	override sorting: TableSortIndicator = new Map()
		.set('creationDate', { enabled: true, value: SortDirection.DESC });

	// eslint-disable-next-line
	override readonly rowActions: RowActionSimple<any>[] = [
		{
			icon: 'icon-import',
			label: 'actions.download',
			action: this.download.bind(this)
		}
	];
	override readonly ctxActions: RowActionSimple<RatesImportHistory | RatesImportHistory[]>[] = [...this.rowActions].map(a => ({
		...a,
		icon: ''
	}));

	constructor(
		public readonly entityService: RateHistoryTableService,
		public override readonly fieldsService: RatesImportHistoryFieldsService,
		public override readonly tableSettingsService: AlarisTableSettingsService,
		public override readonly editPanel: AlarisEditPanelService,
		public override readonly cd: ChangeDetectorRef,
		public override readonly stateService: AlarisTableStateService,
		public readonly vsService: VendorSubscriptionsService,
		private readonly filesService: AlarisFilesService,
		private readonly enums: EnumsMapperService,
		private readonly ratesService: RatesService
	) {
		super(
			entityService,
			fieldsService,
			tableSettingsService,
			editPanel,
			cd,
			TableTypes.INTERNAL,
			DEFAULT_BUTTONS,
			stateService
		);
		this.entityService.stateID = this.id;
	}

	ngOnChanges(): void {
		this.entityService.vendorRates = this.vendorRates;
		this.entityService.subscriptionId = this.subscriptionId;
	}

	override ngOnInit(): void {

		this.entityService.loaded$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
			this.importHistoryChange.emit(this.entityService.list);
		});

		this.ratesService.importComplete$
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe(() => this.refresh());

		if ( this.subscriptionId ) {
			this.readParams = {
				Filters: [{
					Field: 'subscriptionId',
					Type: FilterType.EXACT,
					Value: this.subscriptionId
				}]
			};
		}
		if ( this.vendorRates ) {
			this.readParams = {
				Filters: [{
					Field: 'subscriptionId',
					Type: FilterType.EXACT,
					Value: SubscriptionType.VENDOR,
					Property: 'type'
				}]
			};
		}
		super.ngOnInit();
	}

	download(importHistory?: RatesImportHistory): void {
		if ( importHistory ) {
			this.loading$.next(true);
			this.filesService.export(importHistory.file.id, importHistory.file.name)
				.pipe(takeUntilDestroyed(this.destroyRef))
				.subscribe(() => {
					this.loading$.next(false);
				});
		}
	}

	statusMode(status: ImportStatus): TagMode {
		const map: { [key in ImportStatus]: TagMode } = {
			[ImportStatus.NEW]: 'info',
			[ImportStatus.SUCCESS]: 'success',
			[ImportStatus.PENDING]: 'gray',
			[ImportStatus.ERROR]: 'error'
		};
		return map[status];
	}

	override refresh(): void {
		this.entityService.firstLoad = true;
		this.load();
	}

	override import(): void {
		this.initImportMode.emit();
	}

	override applyHeaders(tHeaders: EntityField[]): void {
		const tH = this.subscriptionId ? tHeaders.filter(i => i.variable !== 'subscriptionId') : tHeaders;
		super.applyHeaders(tH);
	}


	override applyFilter($event: TableFiltersIndicator): void {
		super.applyFilter($event);
		if ( this.subscriptionId ) {
			this.readParams.Filters?.push({
				Field: 'subscriptionId',
				Type: FilterType.EXACT,
				Value: this.subscriptionId
			});
		}
		if ( this.vendorRates ) {
			this.readParams.Filters?.push({
				Field: 'subscriptionId',
				Type: FilterType.EXACT,
				Value: SubscriptionType.VENDOR,
				Property: 'type'
			});
		}
		this.readParams.Filters?.forEach(
			f => {
				if ( f.Field === 'status' ) {
					f.Value = (f.Value as string[]).map(i => this.enums.get('ImportStatus')[i]);
				}
				return f;
			}
		);
	}

	override editTableFilter(): void {
		this.editPanel.open(AlarisFilterManagerComponent, EditPanelWidth.SM, {
			tHeaders: this.tHeaders,
			filters: this.filters,
			filtersChange: this.filtersChange
		});
	}

}
