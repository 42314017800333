import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';

import {
	AlarisDialogService,
	AlarisEditPanelService,
	AlarisFilesService,
	AlarisLanguageService,
	AlarisProfileService,
	ChannelUtilsService,
	CustomValidators,
	Day,
	EditPanelInputData
} from '@campaign-portal/components-library';

import { SenderSubscription } from '@campaign-portal/namespace/entities/sender-id/specs';
import { FileInfo } from '@campaign-portal/namespace/common/fileInfo';
import { EnabledDisabledStatus, TrafficType } from '@campaign-portal/namespace/common/enums';
import { exist } from '@campaign-portal/namespace/common/id';
import { FilterType } from '@campaign-portal/namespace/common/rpc.params';

import { ChannelsService } from '@helpers/services/channels.service';
import { SendersListService } from '../senders-list.service';
import { SubscriptionManageService } from '../../subscription-manage.service';
import { SendersSubscriptionsService } from '../../senders-subscriptions/senders-subscriptions.service';
import { OwnerService } from '@helpers/services/owner.service';
import { SendersDialogComponent } from '../../senders-dialog/senders-dialog.component';
import { CanDeactivateComponent } from '@helpers/can-deactivate/can-deactivate.component';
import { CanDeactivateGuardService } from '@helpers/can-deactivate/can-deactivate.guard';
import { AP_PERMISSIONS } from '@helpers/types/permissions';

@Component({
	selector: 'app-edit-sender',
	templateUrl: './edit-sender.component.html',
	styleUrls: ['./edit-sender.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditSenderComponent extends CanDeactivateComponent implements OnInit, OnDestroy {
	readonly Day = Day;
	readonly AP_PERMISSIONS = AP_PERMISSIONS;
	readonly senderId: SenderSubscription;
	subscriptions: SenderSubscription<exist>[] = [];

	readonly senderIdForm: FormGroup;
	readonly unsubscribe: (sender: SenderSubscription<exist>) => void;
	readonly delete: (sender: SenderSubscription<exist>) => void;

	readonly allowedDeactivation = new BehaviorSubject<boolean>(true);

	protected readonly ngUnsubscribe: Subject<void> = new Subject<void>();

	constructor(
		public readonly isOwner: OwnerService,
		public readonly channelService: ChannelsService,
		public readonly cu: ChannelUtilsService,
		public readonly sendersListService: SendersListService,
		public readonly subscriptionsService: SendersSubscriptionsService,
		@Inject(EditPanelInputData) private readonly inputData: EditPanelInputData,
		private readonly cd: ChangeDetectorRef,
		private readonly subscriptionManageService: SubscriptionManageService,
		private readonly profile: AlarisProfileService,
		private readonly editPanel: AlarisEditPanelService,
		private readonly filesService: AlarisFilesService,
		private readonly lService: AlarisLanguageService,
		private readonly dialog: AlarisDialogService,
		private readonly guard: CanDeactivateGuardService
	) {
		super();
		this.addEditPanelGuard(editPanel, this.guard);
		this.senderId = this.inputData.senderId as SenderSubscription;
		this.unsubscribe = this.inputData.unsubscribe as (sender: SenderSubscription<exist>) => void;
		this.delete = this.inputData.delete as (sender: SenderSubscription<exist>) => void;

		this.senderIdForm = new FormGroup({
			name: new FormControl(this.senderId.name, Validators.required),
			trafficType: new FormControl(this.senderId.trafficType, Validators.required),
			enabled: new FormControl(this.senderId.enabled === EnabledDisabledStatus.ENABLED, Validators.required),
			activeFrom: new FormControl({
				value: (this.senderId.activeFrom ? new Date(this.senderId.activeFrom) : null),
				disabled: !!this.senderId.activeFrom &&
					this.senderId.activeFrom < Day.currentLocal().toISONativeDate()
			}, Validators.required),
			activeTill: new FormControl(this.senderId.activeTill ? new Date(this.senderId.activeTill) : null),
			comment: new FormControl(this.senderId.comment),
			documents: new FormControl(this.senderId.documents || [])
		}, [CustomValidators.moreThan('activeFrom', 'activeTill')]);
	}

	get minEndDate(): Day {
		const currentDay = Day.currentLocal();
		const startDay = Day.fromSystemDate(this.senderIdForm.controls.activeFrom.value) ?? Day.currentLocal();
		return new Date(currentDay.toSystemDate() ?? '').getTime() > new Date(startDay?.toSystemDate() ?? '').getTime()
			? currentDay
			: startDay;
	}

	ngOnInit(): void {
		if ( !this.profile.allowed([AP_PERMISSIONS.SENDERS_E]) ) {
			this.senderIdForm.disable();
		}

		this.subscriptionsService.read({
			Filters: [{
				Field: 'id',
				Type: FilterType.IN,
				Value: this.senderId.subscribers
			}]
		})
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((response) => {
				this.subscriptions = response.Data;
				this.cd.markForCheck();
			});
	}

	displayChannel = (value: TrafficType): string => {
		return this.lService.translate(this.cu.name(value));
	};

	upload(data: FileInfo | FileInfo[]): void {
		const files = Array.isArray(data) ? data.map(item => item.id) : [data.id];
		const fileName = Array.isArray(data) ? '' : data.name;
		this.filesService.export(files, fileName)
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe();
	}

	updateDocuments(files: (FileInfo | undefined)[]): void {
		this.senderIdForm.get('documents')?.setValue(files, { emitValue: false });
		this.senderIdForm.markAsDirty();
	}

	close(): void {
		this.editPanel.close();
	}

	onUnsubscribe(): void {
		this.unsubscribe(this.senderId as SenderSubscription<exist>);
	}

	onDelete(): void {
		this.delete(this.senderId as SenderSubscription<exist>);
	}

	save(): void {
		const activeFrom = this.senderIdForm.controls.activeFrom.value === Day.fromISONativeDate(this.senderId.activeFrom)?.toISONativeDate() ?
			this.senderId.activeFrom : this.senderIdForm.controls.activeFrom.value;
		const activeTill = this.senderIdForm.controls.activeTill.value === Day.fromISONativeDate(this.senderId.activeTill)?.toISONativeDate() ?
			this.senderId.activeTill : this.senderIdForm.controls.activeTill.value;

		const sender = {
			...this.senderId,
			...this.senderIdForm.value,
			name: this.senderIdForm.controls.name.value.trim(),
			enabled: this.senderIdForm.get('enabled')?.value ?
				EnabledDisabledStatus.ENABLED : EnabledDisabledStatus.DISABLED,
			activeFrom,
			activeTill
		};
		this.allowedDeactivation.next(false);
		this.sendersListService.update(sender)
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((resp) => {
				this.allowedDeactivation.next(true);
				if ( resp.Success ) {
					this.editPanel.close(resp);
				}
			});
	}

	warn($event: MouseEvent, sender: SenderSubscription): void {
		$event.preventDefault();
		this.dialog.open(SendersDialogComponent, {
			data: { subscription: sender, type: 'Sender Disable' },
			autoFocus: false
		}).closed
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((result) => {
				if ( result ) {
					this.senderIdForm.controls.enabled.setValue(false);
					this.senderIdForm.controls.enabled.markAsDirty();
					this.cd.markForCheck();
				}
			});
	}

	navigate(subscription: SenderSubscription<exist>): void {
		this.close();
		this.subscriptionManageService.tabsComponent.setActiveTab('senderIdSubscriptions');
		this.subscriptionManageService.setSubscriptionToUpdate(subscription);
	}

	ngOnDestroy(): void {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}

	toggleEnabledState($event: MouseEvent): void {
		if ( this.senderId.id ) {
			return this.senderIdForm.get('enabled')?.value ? this.warn($event, this.senderId) : undefined;
		}
	}
}
