import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BehaviorSubject, combineLatest, filter, map, Observable, Subject, switchMap, takeUntil } from 'rxjs';

import {
	AlarisBalanceService,
	ChartUtilsService,
	Day,
	filterWildcardFn,
	SharedTemplatesService
} from '@campaign-portal/components-library';

import {
	CubeTypeENUM,
	FilterInterval,
	FilterType,
	RPCRequestParams
} from '@campaign-portal/namespace/common/rpc.params';
import { RingChart } from '@campaign-portal/namespace/entities/charts/specs';

import { sumElementsInArray } from '@helpers/utils/math';
import { ChartsService } from '../charts.service';

@Component({
	selector: 'app-messages-by-net',
	templateUrl: './message-by-net.component.html',
	styleUrls: ['./message-by-net.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class MessageByNetComponent implements OnInit, OnDestroy {

	@Input() periodType!: FormControl<CubeTypeENUM | string>;
	@Input() range!: FormControl;
	@Input() byCountryLoading$!: BehaviorSubject<boolean>;

	readonly Day = Day;
	activeItemIndex = NaN;
	labels: string[] = [];
	value: number[] = [];
	totalCost = 0;
	totalMessageValue = 0;


	readonly countryControl = new FormControl<string | null>(null);
	readonly filterCountriesControl = new FormControl();
	countries: string[] = [];
	filteredCountries: string[] = [];


	readParams: RPCRequestParams = {};

	readonly _loading$ = new BehaviorSubject<boolean>(true);
	loading$!: Observable<boolean>;
	private readonly ngUnsubscribe: Subject<void> = new Subject<void>();

	constructor(
		public readonly bs: AlarisBalanceService,
		public readonly chartsService: ChartsService,
		private readonly cd: ChangeDetectorRef,
		public readonly cu: ChartUtilsService,
		public readonly templates: SharedTemplatesService
	) {
	}


	get amount(): number {
		return isNaN(this.activeItemIndex) ? this.totalMessageValue : this.value[this.activeItemIndex];
	}

	get label(): string {
		return isNaN(this.activeItemIndex) ? 'gl.total' : this.labels[this.activeItemIndex];
	}

	isItemActive(index: number): boolean {
		return this.activeItemIndex === index;
	}

	onHover(index: number, hovered: MouseEvent): void {
		this.activeItemIndex = hovered ? index : 0;
	}

	getColor(index: number): string {
		return `var(--tui-chart-${index})`;
	}

	ngOnInit(): void {
		this.loading$ = combineLatest([this._loading$, this.byCountryLoading$])
			.pipe(
				takeUntil(this.ngUnsubscribe),
				map(([byCountry, loading]) => {
					return byCountry || loading;
				})
			);

		this.chartsService.countries
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((countries) => {
				this.countries = countries ?? [];
				this.filteredCountries = this.countries.filter((coutry) => {
					return filterWildcardFn(coutry, this.filterCountriesControl.value ?? '');
				});
				this.countryControl.setValue(countries && countries[0]);
			});

		combineLatest([this.range.valueChanges, this.countryControl.valueChanges])
			.pipe(
				// skip(1),
				filter(([_, country]) => !!country),
				switchMap(([range, country]) => {
					this.constructFilters(range, country as string);
					this._loading$.next(true);
					return this.chartsService.messagesPerNetChart(this.readParams);
				}),
				takeUntil(this.ngUnsubscribe))
			.subscribe(({ Data }) => {
				this.updateChartData(Data);
				this._loading$.next(false);
				this.cd.markForCheck();
			});


		this.filterCountriesControl.valueChanges
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((value) => {
				this.filteredCountries = this.countries.filter((country) => {
					return filterWildcardFn(country, value ?? '');
				});
			});
	}

	updateChartData(data: RingChart): void {
		this.labels = data.labels;
		this.value = data.values;
		this.totalMessageValue = sumElementsInArray(this.value);
	}

	constructFilters(range: FilterInterval<string>, country: string): void {
		this.readParams = {
			...this.readParams,
			Grouping: {
				CubeType: this.cu.periodCubeTypeReducer(this.periodType.value, false),
				Groups: [], isDetailed: false, isTotal: false
			},
			Filters: [
				{ Field: 'country', Type: FilterType.EXACT, Value: country },
				{ Field: 'groupingDate', Type: FilterType.BETWEEN, Value: range }
			]
		};
	}

	makeNaN(): void {
		this.activeItemIndex = NaN;
	}

	ngOnDestroy(): void {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
		this._loading$.next(false);
		this._loading$.complete();
	}
}
