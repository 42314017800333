<alaris-dialog [closeAction]="close.bind(this)"
			   [confirmAction]="confirm.bind(this)"
			   [loading$]="loading$"
			   [disabled]="disabled">
	<h3 slot="header" [ngSwitch]="data.type">
		<ng-container *ngSwitchCase="'Delete'">{{ "partners.deleteEntry" | translate }}</ng-container>
	</h3>
	<div slot="content" [ngSwitch]="data.type">
		<ng-container *ngSwitchCase="'Delete'">
			{{ 'partners.deleteEntryNote' | translate }}
			<b>{{partner.name}}</b>?
		</ng-container>
	</div>
</alaris-dialog>


