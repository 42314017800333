import { inject, InjectionToken, signal } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';

import { exist, Id } from '@campaign-portal/namespace/common/id';
import { SubscriptionGroup } from '@campaign-portal/namespace/entities/subscription-groups/specs';
import { Subscription } from '@campaign-portal/namespace/entities/subscriptions/specs';
import { SubscriptionType } from '@campaign-portal/namespace/common/enums';

import { SubscriptionsService } from '../../subscriptions-list/subscriptions.service';

const subscriptionsPickerFactory = (type: SubscriptionType): SubscriptionsPickerService => {
	const subscriptionsService = inject(SubscriptionsService);
	return new SubscriptionsPickerService(type, subscriptionsService);
};

export const PLANS_PICKER_SERVICE = new InjectionToken<SubscriptionsPickerService>(
	'SubscriptionsPlansPickerService',
	{
		providedIn: 'root',
		factory: (): SubscriptionsPickerService => subscriptionsPickerFactory(SubscriptionType.PLAN)
	}
);

export const PACKS_PICKER_SERVICE = new InjectionToken<SubscriptionsPickerService>(
	'SubscriptionsPacksPickerService',
	{
		providedIn: 'root',
		factory: (): SubscriptionsPickerService => subscriptionsPickerFactory(SubscriptionType.PACK)
	}
);

export class SubscriptionsPickerService {
	readonly newTemporarySelectedCounter = signal(0);

	readonly temporarySelectedModel = new SelectionModel<Subscription<exist>>(true, []);
	readonly selectedModel = new SelectionModel<Subscription<exist>>(true, []);
	readonly availableModel = new SelectionModel<Subscription<exist>>(true, []);

	constructor(
		private readonly type: SubscriptionType,
		private readonly subscriptionsService: SubscriptionsService
	) {
	}

	getSelectedSubscriptionsIds(): Id<exist>[] {
		return this.selectedModel.selected.map(sub => sub.id);
	}

	removeFromTemporarySelected(subscriptions: Subscription<exist>[]): void {
		this.temporarySelectedModel.deselect(...subscriptions);
		this.availableModel.select(...subscriptions);
		this.countNewTemporarySelected();
	}

	moveToTemporarySelected(subscriptions: Subscription<exist>[]): void {
		this.temporarySelectedModel.select(...subscriptions);
		this.availableModel.deselect(...subscriptions);
		this.countNewTemporarySelected();
	}

	removeFromSelected(subscriptions: Subscription<exist>[]): void {
		this.selectedModel.deselect(...subscriptions);
		this.temporarySelectedModel.deselect(...subscriptions);
		this.availableModel.select(...subscriptions);
	}

	clearChanges(): void {
		this.availableModel.select(...this.temporarySelectedModel.selected);
		this.availableModel.deselect(...this.selectedModel.selected);
		this.temporarySelectedModel.setSelection(...this.selectedModel.selected);
		this.newTemporarySelectedCounter.set(0);
	}

	applyChanges(): void {
		const candidates = this.temporarySelectedModel.selected;
		this.selectedModel.setSelection(...candidates);
		this.availableModel.deselect(...candidates);
		this.newTemporarySelectedCounter.set(0);
	}

	initModels(group: SubscriptionGroup): void {
		this.clearAll();

		const [subscriptionsMap, groupSubscriptionIds] = this.type === SubscriptionType.PACK
			? [this.subscriptionsService.packsMap, group.packs]
			: [this.subscriptionsService.plansMap, group.plans];

		const subsWithoutGroups = [...subscriptionsMap.values()].filter(sub => !sub.groupId);
		this.availableModel.select(...subsWithoutGroups);
		groupSubscriptionIds.forEach(id => {
			const subscription = subscriptionsMap.get(id);
			if ( subscription ) {
				this.selectedModel.select(subscription);
				this.temporarySelectedModel.select(subscription);
			}
		});
	}

	clearAll(): void {
		this.newTemporarySelectedCounter.set(0);
		this.selectedModel.clear();
		this.availableModel.clear();
		this.temporarySelectedModel.clear();
	}

	private countNewTemporarySelected(): void {
		const candidates = this.temporarySelectedModel.selected;
		const total = candidates.reduce((newSelectedCounter, candidate) => {
			if ( !this.selectedModel.isSelected(candidate) ) {
				newSelectedCounter++;
			}
			return newSelectedCounter;
		}, 0);
		this.newTemporarySelectedCounter.set(total);
	}
}
