<alaris-dialog [closeAction]="confirm.bind(this)"
			   [confirmAction]="close.bind(this)"
			   closeButtonText="gl.leaveWithoutSaving"
			   confirmButtonText="gl.stayOnPage">
	<ng-container slot="header">
		<div class="header">
			<alaris-icon class="icon" name="icon-warning-circle"></alaris-icon>
			<h3 class="title">{{ "gl.leaveWithoutSaving" | translate }}</h3>
		</div>
	</ng-container>
	<ng-container slot="content">
		<div class="content">
			{{ 'gl.changesBeLost2' | translate }}
		</div>
	</ng-container>
</alaris-dialog>



