<alaris-dialog [closeAction]="close.bind(this)"
			   [closeButtonText]="'actions.cancel'"
			   [confirmAction]="confirm.bind(this)"
			   [confirmButtonIcon]="'icon-success'"
			   [confirmButtonText]="'actions.save'"
			   [size]="'auto'"
			   class="alaris-dialog">
	<ng-container slot="header">
		<div class="header">
			<alaris-icon name="icon-subscription-group"></alaris-icon>
			<h3 class="title">
				{{
					(subscriptionType === SubscriptionType.PACK
						? 'subscriptions.addPacks'
						: 'subscriptions.addPlans') | translate
				}}
			</h3>
		</div>
	</ng-container>
	<ng-container slot="content">
		<alaris-tabs #pickerTabs class="alaris-tabs">
			<alaris-tab [badge]="pickerService.newTemporarySelectedCounter()"
						[label]="'gl.selected' | translate" tabId="selected">
				<div class="tab-content">
					@if (pickerService.temporarySelectedModel.isEmpty()) {
						<ng-container *ngTemplateOutlet="stub; context: {$implicit: ModelType.TEMPORARY}">
						</ng-container>
					} @else {
						<app-subscriptions-picker-table
							[rowAction]="temporaryRowAction"
							[bulkAction]="pickerService.removeFromTemporarySelected.bind(pickerService)"
							[selectionModel]="pickerService.temporarySelectedModel"
							[subscriptionType]="subscriptionType"
							[selectedModel]="pickerService.selectedModel"
							[type]="ModelType.TEMPORARY"
							class="picker-table">
						</app-subscriptions-picker-table>
					}
				</div>
			</alaris-tab>

			<alaris-tab [label]="'gl.available' | translate" tabId="available">
				<div class="tab-content">
					@if (pickerService.availableModel.isEmpty()) {
						<ng-container *ngTemplateOutlet="stub; context: {$implicit: ModelType.AVAILABLE}">
						</ng-container>
					} @else {
						<app-subscriptions-picker-table
							[rowAction]="availableRowAction"
							[bulkAction]="pickerService.moveToTemporarySelected.bind(pickerService)"
							[selectionModel]="pickerService.availableModel"
							[subscriptionType]="subscriptionType"
							[type]="ModelType.AVAILABLE"
							class="picker-table">
						</app-subscriptions-picker-table>
					}
				</div>
			</alaris-tab>
		</alaris-tabs>
	</ng-container>
</alaris-dialog>

<ng-template #stub let-type>
	<div class="stub">
		@switch (type) {
			@case (ModelType.TEMPORARY) {
				<h5 class="title">{{ 'subscriptions.notFound' | translate }}</h5>
				<div class="note">{{ 'subscriptions.groups.goToAvailableTab' | translate }}</div>
			}
			@case (ModelType.AVAILABLE) {
				<h5 class="title">{{ 'subscriptions.groups.noAvailableSubs' | translate }}</h5>
				<div class="note">
					{{ 'subscriptions.groups.allAvailableAdded' | translate }}<br>
					{{ 'subscriptions.groups.goToSubscriptionsChanges' | translate }}
				</div>
			}
		}
	</div>
</ng-template>
