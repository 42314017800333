<div [alarisLoading]="partnersService.loading$">
	<div class="edit-panel-header">
		<alaris-icon name="icon-partner-_glass"></alaris-icon>
		{{ (partner.id ? 'partners.editEntry' : 'partners.addEntry') | translate }}
	</div>
	<div [formGroup]="partnerForm" alarisFade="vertical" class="form-group edit-panel-content">
		<ng-container *ngIf="(ccService.map$ | async)?.size; else noCC">
			<div class="section-title">{{ 'partners.core' | translate }}</div>
			<alaris-input [label]="'fc.name' | translate"
						  [placeholder]="'fc.name' | translate"
						  data-testid="nameInput" formControlName="name">
			</alaris-input>
			<alaris-select2 [displayWith]="displayCompanySelected"
							[label]="'fc.cc' | translate"
							[placeholder]="'fc.cc' | translate"
							[readonly]="partner.id !== null"
							data-testid="ccIdSelect" formControlName="ccId">
				<alaris-select-filter2 *ngIf="((ccService.list$ | async)?.length || 0) > 5"
									   [formControl]="filterCcControl" data-testid="ccIdFilter">
				</alaris-select-filter2>
				<alaris-options2 *ngFor="let opt of filterCCList"
								 [attr.data-testid]="'ccIdOption/' + opt.name" [value]="opt.id">
					{{ opt.name }}
				</alaris-options2>
			</alaris-select2>
			<alaris-toggle data-testid="isActiveToggle" direction="right" formControlName="isActive">
				{{ 'partners.enable' | translate }}
			</alaris-toggle>
			<alaris-toggle [readonly]="partner.id !== null"
						   data-testid="isResellerToggle"
						   direction="right" formControlName="isReseller">
				{{ 'partners.reseller'  | translate | titlecase }}
			</alaris-toggle>

			<div class="section-title">{{ 'partners.finance' | translate }}</div>
			<ng-container formGroupName="financialSettings">
				<alaris-select2 [displayWith]="displayCurrencySelected"
								[label]="'fc.currency' | translate"
								[placeholder]="'fc.currency' | translate"
								[readonly]="partner.id !== null"
								data-testid="currencySelect" formControlName="currencyId">
					<alaris-select-filter2 *ngIf="((currencyService.list$ | async)?.length || 0) > 5"
										   [formControl]="filterCurrencyControl" data-testid="currencyFilter">
					</alaris-select-filter2>
					<alaris-options2 *ngFor="let opt of filterCurrencyList"
									 [attr.data-testid]="'currencyOption/' + opt.name" [value]="opt.id">
						{{ opt.name }}
					</alaris-options2>
				</alaris-select2>
				<div class="in-row align-start">
					<alaris-select2 [displayWith]="displayCreditType"
									[label]="'fc.credit' | translate"
									[placeholder]="'fc.credit' | translate"
									[readonly]="!([AP_PERMISSIONS.PAYMENTS_E] | alarisPBAC)"
									data-testid="creditTypeSelect" formControlName="creditType">
						<alaris-options2 *ngFor="let opt of creditTypeList"
										 [attr.data-testid]="'creditTypeOption/' + opt" [value]="opt">
							{{ 'enums.' + opt | translate }}
						</alaris-options2>
					</alaris-select2>
					<alaris-input-number
						*ngIf="partnerForm.get('financialSettings.creditType')?.value === CreditType.POSTPAID"
						[allowNegativeNumbers]="false"
						[errors]="errors" [label]="'fc.creditLimit' | translate"
						[placeholder]="'fc.creditLimit' | translate"
						[readonly]="!([AP_PERMISSIONS.PAYMENTS_E] | alarisPBAC)"
						data-testid="creditLimitInput" formControlName="creditLimit">
					</alaris-input-number>
				</div>


				<alaris-toggle data-testid="sendAlertsToggle" direction="right"
							   formControlName="sendAlerts">
					{{ 'gl.sendAlerts'| translate }}
				</alaris-toggle>
			</ng-container>

			<ng-container *alarisPBAC="[AP_PERMISSIONS.SUBSCR_R, AP_PERMISSIONS.SUBSCR_E]">
				<div class="subscriptions-title">
					<strong>
						{{ 'partners.subscriptions' | translate }}
						<ng-container *ngIf="partnerForm.controls.plans.dirty || partnerForm.controls.packs.dirty">
							({{ 'gl.changed' | translate }})
						</ng-container>
					</strong>
					<ng-container *ngIf="tRows.length">
						<span class="label">{{ tRows.length }}</span>
						<button (click)="addSubscriptions()" class="plus" data-testid="addSubscriptionBtn">
							<alaris-icon name="icon-plus"></alaris-icon>
						</button>
					</ng-container>
				</div>
				<app-virtual-scroll-table *ngIf="tRows.length; else stub"
										  [action]="action"
										  [tHeaders]="tHeaders"
										  [tRows]="tRows"
										  [templates]="{currencyId, name, type, packSettings}">
				</app-virtual-scroll-table>
			</ng-container>

		</ng-container>
	</div>
	<div class="edit-panel-footer">
		<button (click)="save()"
				[disabled]="partnerForm.invalid || !partnerForm.dirty"
				alaris-button bType="primary"
				data-testid="saveBtn"
				size="lg" type="button">
			<alaris-icon class="in-primary" name="icon-success"></alaris-icon>
			{{ (partner.id ? 'actions.save' : 'actions.create') | translate }}
		</button>
		<button (click)="close(false)" alaris-button bType="outline" data-testid="closeBtn" size="lg" type="button">
			{{ 'actions.cancel' | translate }}
		</button>
	</div>
</div>

<ng-template [alarisCellCtx]="tRows[0]" #currencyId let-data="data">
	{{ (data.currencyId ?? 0 | alarisCurrency)?.name | noValue }}
</ng-template>
<ng-template [alarisCellCtx]="tRows[0]" #type let-data="data">
	{{ 'enums.' + data.type | translate }}
</ng-template>
<ng-template [alarisCellCtx]="tRows[0]" #name let-data="data">
	<div class="name-column">
		{{ data.name }}
	</div>
</ng-template>
<ng-template #packSettings let-data="data">
	<ng-container *ngIf="data.packSettings; else empty">
		<div class="cost">
			<div>{{ data.packSettings.packPrice | currency: bs.currencyCode }}</div>
			<div>( {{ data.messagesTotal }} )</div>
		</div>

	</ng-container>
	<ng-template #empty> -</ng-template>
</ng-template>

<ng-template #stub>
	<div class="add-new">
		{{
			(partnerForm.controls.isReseller.value ?
				'partners.selectResellerSubscriptions' : 'partners.selectSubscriptions') | translate
		}}
		<button (click)="addSubscriptions()"
				[disabled]="!ccAndCurrency"
				alaris-button
				bType="secondary"
				data-testid="addSubscriptionBtn" size="lg">
			{{ 'gl.addEntry' | translate }}
		</button>
	</div>
</ng-template>

<ng-template #noCC>
	<alaris-card>
		<app-empty-table (newContractCompany)="goToCC()" class="stretch" type="portals"></app-empty-table>
	</alaris-card>
</ng-template>
