import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { AlarisTableStateService } from '@campaign-portal/components-library';

@Component({
	selector: 'app-statistics',
	templateUrl: './statistics.component.html',
	styleUrls: ['./statistics.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class StatisticsComponent implements OnDestroy {
	constructor(
		public readonly cd: ChangeDetectorRef,
		private readonly stateService: AlarisTableStateService
	) {
	}

	ngOnDestroy(): void {
		this.stateService.clear();
	}
}
