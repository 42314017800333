<alaris-tabs #ratesTabs (tabChanged)="cd.detectChanges()" [underline]="false" id="vendorsTabs">

	<alaris-tab *alarisPBAC="[AP_PERMISSIONS.V_SUBSCR_R]"
				[label]="'tabs.products' | translate" tabId="products">
		<app-products *ngIf="ratesTabs.activeTab?.tabId === 'products'"></app-products>
	</alaris-tab>

	<ng-container *ngIf="isOwner.is && ([AP_PERMISSIONS.ENDPOINTS_R] | alarisPBAC)">
		<alaris-tab [label]="'tabs.endpoints' | translate" tabId="endpoints">
			<app-endpoints *ngIf="ratesTabs.activeTab?.tabId === 'endpoints'"></app-endpoints>
		</alaris-tab>
	</ng-container>

	<alaris-tab *alarisPBAC="[AP_PERMISSIONS.V_SUBSCR_R]"
				[label]="'tabs.rates' | translate" tabId="rates">
		<app-rates (initImportMode)="initiateImport()"
				   *ngIf="ratesTabs.activeTab?.tabId === 'rates'">
		</app-rates>
	</alaris-tab>

	<ng-container *ngIf="isOwner.is && ([AP_PERMISSIONS.V_SUBSCR_R] | alarisPBAC)">
		<alaris-tab [label]="'tabs.importHistory' | translate" tabId="importHistory">
			<app-import-history (initImportMode)="initiateImport()"
								*ngIf="ratesTabs.activeTab?.tabId === 'importHistory'"
								[vendorRates]="true"
								[withImport]="[AP_PERMISSIONS.V_SUBSCR_E] | alarisPBAC">
			</app-import-history>
		</alaris-tab>
	</ng-container>

</alaris-tabs>
