import { Component, Input } from '@angular/core';
import { RowActionSimple, TableEntityField } from '@campaign-portal/components-library';
import { TableCellTemplates } from '@campaign-portal/components-library/lib/table/src/base';

@Component({
	selector: 'app-virtual-scroll-table',
	templateUrl: './virtual-scroll-table.component.html',
	styleUrls: [
		'./virtual-scroll-table.component.scss'
	]
})
export class VirtualScrollTableComponent<T> {
	@Input() tHeaders: TableEntityField[] = [];
	@Input() tRows: T[] = [];
	// eslint-disable-next-line
	@Input() templates: TableCellTemplates<T> = {};
	@Input() action!: RowActionSimple<T>;
	@Input() maxViewPortSize = 33.125;


	constructor() {
	}

	calculateViewPort(): string {
		return this.tRows.length > 10 ? this.maxViewPortSize + 'rem' : (this.tRows.length * 3 + 3.25) + 'rem';
	}
}
