import { Injectable } from '@angular/core';
import {
	AlarisLanguageService,
	AlarisTableStateService,
	filterWildcardData,
	LocalTableService,
	LocalTableUtils
} from '@campaign-portal/components-library';
import { Partner } from '@campaign-portal/namespace/entities/partners/specs';
import { PartnersService } from './partners.service';
import { exist } from '@campaign-portal/namespace/common/id';
import { FilterInterval, RPCRequestParams } from '@campaign-portal/namespace/common/rpc.params';
import { ReadResponse } from '@campaign-portal/namespace/common/implementations';
import { ContractCompany } from '@campaign-portal/namespace/entities/contract-company/specs';
import { ContractCompaniesService } from '../settings/contract-companies/contract-companies.service';
import { map, Observable, of } from 'rxjs';

@Injectable()
export class PartnersTableService extends LocalTableService<Partner<exist>> {

	constructor(
		public readonly partnersService: PartnersService,
		private readonly ccService: ContractCompaniesService,
		private readonly ls: AlarisLanguageService,
		override readonly stateService: AlarisTableStateService
	) {
		super(partnersService, stateService);
	}

	override read(params: RPCRequestParams): Observable<ReadResponse<Partner<exist>[]>> {
		if ( this.firstLoad ) {
			this.firstLoad = false;
			return this.partnersService.load()
				.pipe(map(() => {
					this.list = this.partnersService.list;
					return this.parse(this.list, params);
				}));
		}
		this.list = this.partnersService.list;
		return of(this.parse(this.list, params));
	}

	override applyFilters(result: ReadResponse<Partner<exist>[]>, params: RPCRequestParams): ReadResponse<Partner<exist>[]> {
		const filters = params.Filters;
		if ( filters === undefined ) {
			return result;
		} else {
			let data: Partner<exist>[] = result.Data;
			filters.forEach((filter) => {
				switch (filter.Field) {
					case 'name':
						data = filterWildcardData(filter.Value as string, data, 'name');
						break;
					case 'ccId':
						const cc = new Set(
							(filter.Value as ContractCompany<exist>[]).map(company => company.id)
						);
						data = data.filter(partner => {
							return cc.has(partner.ccId);
						});
						break;
					case 'balance':
						data = data.filter(partner => {
							if ( filter.Value ) {
								const start = (filter.Value as FilterInterval<number | null>).Start;
								const end = (filter.Value as FilterInterval<number | null>).End;
								const more = start !== null ? partner.balance >= start : true;
								const less = end !== null ? partner.balance <= end : true;
								return more && less;
							}
							return true;
						});
						break;
					case 'creditLimit':
						data = data.filter(partner => {
							if (
								filter.Value
								&& (
									partner.financialSettings.creditLimit !== null
									&& partner.financialSettings.creditLimit !== undefined
								)
							) {
								const start = (filter.Value as FilterInterval<number | null>).Start;
								const end = (filter.Value as FilterInterval<number | null>).End;
								const more = start !== null ? partner.financialSettings.creditLimit >= start : true;
								const less = end !== null ? partner.financialSettings.creditLimit <= end : true;
								return more && less;
							}
							if ( filter.Value && (filter.Value as FilterInterval<number | null>).End !== null ) {
								return !!partner.financialSettings.creditLimit;
							}
							return true;
						});
						break;
					case 'financialSettings':
						data = data.filter(partner => {
							return filter.Value === null
								? true
								: (filter.Value as string[]).includes(partner.financialSettings.creditType);
						});
						break;
					case 'isReseller':
						data = data.filter(partner => partner.isReseller);
						break;
					default:
						break;
				}
			});
			return { Success: true, Total: data.length, Data: data };
		}
	}

	override applySorting(result: ReadResponse<Partner<exist>[]>, params: RPCRequestParams): ReadResponse<Partner<exist>[]> {
		const sorting = params.Sorting?.reverse();
		if ( !sorting ) {
			return result;
		} else {
			let data = result.Data;
			sorting.forEach((sort) => {
				data = data.sort((a, b) => {
					let first: unknown;
					let second: unknown;
					switch (sort.Field) {
						case 'ccId':
							first = this.ccService.map.get(a.ccId)?.name ?? '';
							second = this.ccService.map.get(b.ccId)?.name ?? '';
							break;
						case 'creditLimit':
							first = a.financialSettings.creditLimit;
							second = b.financialSettings.creditLimit;
							break;
						case 'financialSettings':
							first = this.ls.translate('enums.' + a.financialSettings.creditType);
							second = this.ls.translate('enums.' + b.financialSettings.creditType);
							break;
						case 'isReseller':
						case 'balance':
						default:
							first = a[sort.Field];
							second = b[sort.Field];
							break;
					}

					return LocalTableUtils.sortComparisonFn(sort.Dir, first, second);
				});
			});
			return { Data: data, Total: result.Total, Success: true };
		}
	}
}
