import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { SubscriptionsService } from './subscriptions-list/subscriptions.service';
import { OwnerService } from '@helpers/services/owner.service';

@Component({
	selector: 'app-subscriptions',
	templateUrl: './subscriptions.component.html',
	styleUrl: './subscriptions.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubscriptionsComponent implements OnDestroy {
	constructor(
		public readonly cd: ChangeDetectorRef,
		private readonly entityService: SubscriptionsService,
		public readonly isOwner: OwnerService
	) {
	}

	ngOnDestroy(): void {
		this.entityService.clearFilters();
	}
}
