import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	HostBinding,
	HostListener,
	Input,
	ViewChild
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

import { CubeTypeENUM } from '@campaign-portal/namespace/common/rpc.params';
import { BarChart } from '@campaign-portal/namespace/entities/charts/specs';

import { ChartUtilsService, Day, SharedTemplatesService } from '@campaign-portal/components-library';

import { TotalProgressChart } from '@helpers/types/app.classes-interfaces';
import { calculateBarChartMax, generateAxisYLabels } from '@helpers/utils/math';
import { ChartsService } from '../charts.service';

@Component({
	selector: 'app-horizontal-chart',
	templateUrl: './horizontal-chart.component.html',
	styleUrls: ['./horizontal-chart.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class HorizontalChartComponent implements AfterViewInit {
	// for chart offset calculation
	@ViewChild('chart') readonly chart?: ElementRef<HTMLDivElement>;
	marginBottom = 0;

	@HostBinding('style.--tui-chart-0')
	@Input()
		barColor = 'var(--as-ChartGreen-500)';
	title = '';
	readonly periodType = new FormControl<CubeTypeENUM | string>(this.cu.periodTypes[2].value, { nonNullable: true });
	readonly range = new FormControl();
	readonly Day = Day;

	// Chart
	value: number[][] = [];
	labelsX: string[] = [];
	labelsY: string[] = [];
	hintX: string[] = [];
	legend: string[] = [];
	maxChartHeight = 0;
	totalMessages = 0;
	totalCost = 0;
	totals: TotalProgressChart[] = [];

	// Ring Chart
	activeItemIndex = NaN;
	totalStatusValues: number[] = [];

	readonly loading$ = new BehaviorSubject<boolean>(false);

	constructor(
		public readonly cu: ChartUtilsService,
		public readonly templates: SharedTemplatesService,
		public readonly chartsService: ChartsService,
		public readonly cd: ChangeDetectorRef
	) {
	}

	@HostBinding('style.--chartHeight')
	get chartHeight(): string {
		const offset = 32;
		return (this.chart?.nativeElement.clientWidth ?? 0) - offset + 'px';
	}

	@HostListener('window:resize')
	onResize(): void {
		this.cd.markForCheck();
	}
	
	get amount(): number {
		return isNaN(this.activeItemIndex) ? this.totalMessages : this.totalStatusValues[this.activeItemIndex];
	}

	get label(): string {
		return isNaN(this.activeItemIndex) ? 'Total' : this.legend[this.activeItemIndex];
	}

	ngAfterViewInit(): void {
		// for stubChart rendering
		this.cd.markForCheck();
	}

	updateChartData(data: BarChart): void {
		if ( data.values.at(0) ) {
			[data.values[0], data.labels] = this.cu.sortData(data.values[0], data.labels);
		}

		// bar chart
		this.value = data.values;
		this.maxChartHeight = calculateBarChartMax(this.value, true);
		this.labelsX = data.labels;
		this.hintX = data.labels;
		this.labelsY = generateAxisYLabels(0, this.maxChartHeight, 5);
		this.legend = data.labels;

		// chart offset
		if ( this.labelsX.length ) {
			const labelsYHeightInPx = 50;
			const labelXHeightInPx = 64;
			const height = labelXHeightInPx * this.labelsX.length + labelsYHeightInPx;
			this.marginBottom = height - (this.chart?.nativeElement.clientWidth ?? 0);
		} else {
			this.marginBottom = 0;
		}
	}

	// Ring Chart
	isItemActive(index: number): boolean {
		return this.activeItemIndex === index;
	}

	onHover(index: number, hovered: MouseEvent): void {
		this.activeItemIndex = hovered ? index : 0;
	}

	makeNaN(): void {
		this.activeItemIndex = NaN;
	}
}
