import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject, takeUntil } from 'rxjs';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';

import { exist } from '@campaign-portal/namespace/common/id';
import { RPCResult } from '@campaign-portal/namespace/common/rpc.response';
import { ContractCompany } from '@campaign-portal/namespace/entities/contract-company/specs';

import { ContractCompaniesService } from '../contract-companies.service';
import { CanDeactivateComponent } from '@helpers/can-deactivate/can-deactivate.component';
import { CanDeactivateGuardService } from '@helpers/can-deactivate/can-deactivate.guard';
import { AlarisDialogService } from '@campaign-portal/components-library';

export type CompanyDialogType = 'Delete';

export interface CompanyDialogData {
	type: CompanyDialogType;
	company: ContractCompany<exist>;
}

@Component({
	selector: 'app-contract-company-dialog',
	templateUrl: './contract-company-dialog.component.html',
	styleUrls: ['./contract-company-dialog.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContractCompanyDialogComponent extends CanDeactivateComponent implements OnDestroy {

	readonly company!: ContractCompany<exist>;

	readonly loading$ = new BehaviorSubject<boolean>(false);
	readonly allowedDeactivation = new BehaviorSubject<boolean>(true);
	protected readonly ngUnsubscribe = new Subject<void>();

	constructor(
		@Inject(DIALOG_DATA) public readonly data: CompanyDialogData,
		private readonly dialogRef: DialogRef<unknown>,
		private readonly cd: ChangeDetectorRef,
		private readonly contractCompaniesService: ContractCompaniesService,
		private readonly guard: CanDeactivateGuardService,
		private readonly dialog: AlarisDialogService
	) {
		super();
		this.addDialogGuard(this.dialog, dialogRef, this.guard);
		this.company = data.company;
	}

	get disabled(): boolean {
		return this.loading$.getValue();
	}

	close(): void {
		this.dialogRef.close(false);
	}

	confirm(): void {
		this.loading$.next(true);
		let caller: Observable<RPCResult<unknown>> = of({ Success: false });

		switch (this.data.type) {
			case 'Delete':
				caller = this.contractCompaniesService.delete(this.company.id);
				break;
			default:
				break;

		}

		this.allowedDeactivation.next(false);
		caller.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
			(resp) => {
				this.allowedDeactivation.next(true);
				this.loading$.next(false);
				this.dialogRef.close(resp.Success);
				this.cd.markForCheck();
			}
		);
	}

	ngOnDestroy(): void {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
		this.loading$.complete();
	}
}
