import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';

import {
	CancelRequest,
	CancelResponse,
	CreateResponse,
	ReadResponse
} from '@campaign-portal/namespace/common/implementations';
import { RPCRequestParams } from '@campaign-portal/namespace/common/rpc.params';
import { exist, Id } from '@campaign-portal/namespace/common/id';
import { Payments } from '@campaign-portal/namespace/entities/payments/specs';

import {
	AbstractCRUDService,
	AlarisApiService,
	AlarisLanguageService,
	AlarisToasterService,
	ErrorNotifierConfig
} from '@campaign-portal/components-library';

@Injectable({
	providedIn: 'root'
})
export class PaymentsService implements AbstractCRUDService {
	readonly loading$ = new BehaviorSubject<boolean>(false);

	readonly create = this.update;
	private readonly errorNotifier = (): ErrorNotifierConfig => ({ title: this.title });

	constructor(
		private readonly api: AlarisApiService,
		private readonly alarisToaster: AlarisToasterService,
		private readonly langService: AlarisLanguageService
	) {
	}

	get entity(): string {
		return this.langService.translate('notifications.entities.payment');
	}

	get title(): string {
		return this.langService.translate('notifications.titles.payment');
	}

	delete(): Observable<undefined> {
		return of(undefined);
	}

	update(payment: Payments): Observable<CreateResponse<Payments>> {
		const notify = (response: CreateResponse<Payments>): void => {
			if ( response.Success ) {
				const message = this.langService.translate(
					payment.id ? 'notifications.actions.update' : 'notifications.actions.create', {
						entity: this.entity,
						name: ''
					});
				this.alarisToaster.success(message, this.title);
			}
		};
		return this.api.loader<CreateResponse<Payments>>(
			`Payments.${payment.id ? 'Update' : 'Create'}`,
			{ Data: { Entities: [payment] } },
			this.loading$, this.errorNotifier, notify
		);
	}

	cancel(id: Id<exist>): Observable<CancelResponse<Payments>> {
		const params: CancelRequest<Payments<exist>> = { Data: { Ids: [id] } };
		const notify = (response: CancelResponse<Payments>): void => {
			if ( response.Success ) {
				const message = this.langService.translate('notifications.actions.cancel', { entity: this.entity });
				this.alarisToaster.success(message, this.title);
			}
		};
		return this.api.loader<CancelResponse<Payments>>(
			'Payments.Cancel', params, this.loading$, this.errorNotifier, notify
		);
	}

	read(params?: RPCRequestParams): Observable<ReadResponse<Payments[]>> {
		return this.api.loader<ReadResponse<Payments[]>>('Payments.Read', params, this.loading$, this.errorNotifier);
	}
}
