import { Injectable } from '@angular/core';
import { EndpointsService } from './endpoints.service';
import { FilterInterval, RPCRequestParams } from '@campaign-portal/namespace/common/rpc.params';
import { Endpoint } from '@campaign-portal/namespace/entities/endpoints/specs';
import { exist } from '@campaign-portal/namespace/common/id';
import { ReadResponse } from '@campaign-portal/namespace/common/implementations';
import {
	AlarisTableStateService,
	filterWildcardData,
	LocalTableService,
	LocalTableUtils
} from '@campaign-portal/components-library';
import { ValueObject } from '@campaign-portal/namespace/common/valueObject';
import { Subscription } from '@campaign-portal/namespace/entities/subscriptions/specs';
import { VendorSubscriptionsService } from '@helpers/services/vendor-subscriptions.service';
import { SubscriptionGroup } from '@campaign-portal/namespace/entities/subscription-groups/specs';


@Injectable()
export class EndpointsTableService extends LocalTableService<Endpoint<exist>> {

	constructor(
		override readonly service: EndpointsService,
		override readonly stateService: AlarisTableStateService,
		private readonly vsService: VendorSubscriptionsService
	) {
		super(service, stateService);
	}

	override applyFilters(
		result: ReadResponse<Endpoint<exist>[]>,
		params: RPCRequestParams
	): ReadResponse<Endpoint<exist>[]> {
		const filters = params.Filters;
		if ( filters === undefined ) {
			return result;
		} else {
			let data: Endpoint<exist>[] = result.Data;
			filters.forEach((filter) => {
				switch (filter.Field) {
					case 'name':
						data = filterWildcardData(filter.Value as string, data, 'name');
						break;
					case 'isActive':
						if ( filter.Value !== undefined || filter.Value !== null ) {
							data = data.filter(item => item.isActive === (filter.Value as ValueObject).value);
						}
						break;
					case 'isOnline':
						if ( filter.Value !== undefined || filter.Value !== null ) {
							data = data.filter(item => item.isOnline === (filter.Value as ValueObject).value);
						}
						break;
					case 'endpointProtocol':
						if ( filter.Value ) {
							data = data.filter(item => item.endpointProtocol === filter.Value);
						}
						break;
					case 'priority':
						data = data.filter(endpoint => {
							if ( filter.Value ) {
								const start = (filter.Value as FilterInterval<number | null>).Start;
								const end = (filter.Value as FilterInterval<number | null>).End;
								const more = start !== null ? endpoint.priority >= start : true;
								const less = end !== null ? endpoint.priority <= end : true;
								return more && less;
							}
							return true;
						});
						break;
					case 'vendorSubscription':
						if ( filter.Value ) {
							const vendors = (filter.Value as Subscription<exist>[]).map(subsription => subsription.id);
							data = data.filter(endpoint => vendors.includes(endpoint.vendorSubscription));
						}
						break;
					case 'availableCountryNetList':
						if ( filter.Value ) {
							const countries = (filter.Value as ValueObject[]).map(v => v.value);
							data = data.filter((endpoint) => {
								return endpoint.availableCountryNetList === null
									? true
									: endpoint.availableCountryNetList.find(c => countries.includes(c));
							});
						}
						break;
					case 'groups':
						if ( filter.Value ) {
							const groups = (filter.Value as SubscriptionGroup<exist>[]).map(group => group.id);
							data = data.filter(endpoint => {
								return endpoint.groups
									? endpoint.groups.find(group => groups.includes(group))
									: false;
							});
						}
						break;
					default:
						break;
				}
			});
			return { Success: true, Total: data.length, Data: data };
		}
	}

	override applySorting(result: ReadResponse<Endpoint<exist>[]>, params: RPCRequestParams): ReadResponse<Endpoint<exist>[]> {
		const sorting = params.Sorting?.reverse();
		if ( !sorting ) {
			return result;
		} else {
			let data = result.Data;
			sorting.forEach((sort) => {
				data = data.sort((a, b) => {
					let first: any;
					let second: any;
					switch (sort.Field) {
						case 'isActive':
							first = a.isActive ? 1 : 0;
							second = b.isActive ? 1 : 0;
							break;
						case 'isOnline':
							first = a.isOnline ? 1 : 0;
							second = b.isOnline ? 1 : 0;
							break;
						case 'vendorSubscription':
							first = this.vsService.map.get(a.vendorSubscription)?.name;
							second = this.vsService.map.get(b.vendorSubscription)?.name;
							break;
						case 'endpointProtocol':
						case 'priority':
						default:
							first = (a as Record<string, any>)[sort.Field];
							second = (b as Record<string, any>)[sort.Field];
							break;
					}

					return LocalTableUtils.sortComparisonFn(sort.Dir, first, second);
				});
			});
			return { Data: data, Total: result.Total, Success: true };
		}
	}


}
