import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DialogRef } from '@angular/cdk/dialog';


@Component({
	selector: 'app-lost-data-dialog',
	templateUrl: './lost-data-dialog.component.html',
	styleUrls: ['./lost-data-dialog.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class LostDataDialogComponent {

	constructor(
		private readonly dialogRef: DialogRef<unknown>
	) {
	}

	close(): void {
		this.dialogRef.close(false);
	}

	confirm(): void {
		this.dialogRef.close(true);
	}
}
