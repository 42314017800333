import { Injectable } from '@angular/core';
import { VendorSubscriptionsService } from '@helpers/services/vendor-subscriptions.service';
import { RPCRequestParams } from '@campaign-portal/namespace/common/rpc.params';
import { ReadResponse } from '@campaign-portal/namespace/common/implementations';
import { Subscription } from '@campaign-portal/namespace/entities/subscriptions/specs';
import { exist } from '@campaign-portal/namespace/common/id';
import { SubscriptionsFilter } from '../../subscriptions/subscriptions-list/subscriptions.table.service';
import {
	AlarisTableStateService,
	ChannelUtilsService,
	LocalTableService,
	LocalTableUtils
} from '@campaign-portal/components-library';

@Injectable()
export class ProductsTableService extends LocalTableService<Subscription<exist>> {

	override readonly applyFilters = SubscriptionsFilter.applyFilters;

	constructor(
		vendorSubscriptionService: VendorSubscriptionsService,
		stateService: AlarisTableStateService,
		private readonly cu: ChannelUtilsService
	) {
		super(vendorSubscriptionService, stateService);
	}

	override applySorting(result: ReadResponse<Subscription<exist>[]>, params: RPCRequestParams): ReadResponse<Subscription<exist>[]> {
		const sorting = params.Sorting?.reverse();
		if ( !sorting ) {
			return result;
		} else {
			let data = result.Data;
			sorting.forEach((sort) => {
				data = data.sort((a, b) => {
					if ( sort.Field === 'trafficType' ) {
						const tta = this.cu.purpose(a.trafficType, a.settings?.viberSettings?.messagePurpose);
						const ttb = this.cu.purpose(b.trafficType, b.settings?.viberSettings?.messagePurpose);
						return LocalTableUtils.sortComparisonFn(sort.Dir, tta, ttb);
					}
					return LocalTableUtils.sortComparisonFn(sort.Dir, a[sort.Field], b[sort.Field]);
				});
			});
			return { Data: data, Total: result.Total, Success: true };
		}
	}

}
