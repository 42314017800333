import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';

import { AbstractCRUDService, AlarisLanguageService } from '@campaign-portal/components-library';

import { EntityField, InputComplexType, InputType } from '@campaign-portal/namespace/common/entityField';
import { ReadResponse } from '@campaign-portal/namespace/common/implementations';
import { FilterType } from '@campaign-portal/namespace/common/rpc.params';
import { EdrExportStatus } from '@campaign-portal/namespace/common/enums';

@Injectable()
export class EdrExportTaskFieldsService implements AbstractCRUDService, OnDestroy {

	readonly entity = '';
	readonly title = '';
	readonly loading$ = new BehaviorSubject<boolean>(false);

	private readonly headers: EntityField[] = [
		{
			id: 0,
			name: 'columns.task',
			variable: 'name',
			required: true,
			type: InputType.TEXT,
			readonly: true,
			filterType: FilterType.LIKE
		},
		{
			id: 1,
			name: 'columns.status',
			variable: 'status',
			required: true,
			type: InputComplexType.SELECT,
			data: Object.keys(EdrExportStatus).map((item) => {
				return { value: item, name: this.lService.translate('enums.' + item) };
			}),
			readonly: true,
			filterType: FilterType.EXACT
		},
		{
			id: 2,
			name: 'columns.user',
			variable: 'createdBy',
			required: true,
			type: InputType.TEXT,
			readonly: true,
			filterType: FilterType.LIKE
		},
		{
			id: 3,
			name: 'columns.creationDate',
			variable: 'creationDate',
			required: true,
			type: InputComplexType.DATE_RANGE,
			readonly: true,
			filterType: FilterType.BETWEEN
		},
		{
			id: 4,
			name: 'columns.size',
			variable: 'size',
			required: true,
			readonly: true,
			type: InputType.TEXT,
			filterType: FilterType.LIKE
		},
		{
			id: 5,
			name: 'columns.file',
			variable: 'fileId',
			required: true,
			type: InputType.TEXT,
			readonly: true,
			filterType: FilterType.LIKE
		}
	];

	constructor(
		private readonly lService: AlarisLanguageService
	) {
	}

	ngOnDestroy(): void {
		this.loading$.complete();
	}

	create(): Observable<void> {
		return of(undefined);
	}

	delete(): Observable<void> {
		return of(undefined);
	}

	read(): Observable<ReadResponse<EntityField[]>> {
		return of({
			Success: true,
			Total: this.headers.length,
			Data: this.headers
		});
	}

	update(): Observable<void> {
		return of(undefined);
	}
}
