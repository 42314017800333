<div class="edit-panel-header">
	<alaris-icon name="icon-import-contact"></alaris-icon>
	{{'rates.import.importRates'| translate}}
</div>
<div alarisFade="vertical" class="edit-panel-content">
	<app-rates-import [predefinedSubscriptionId]="id"
					  [withBanner]="false"
					  [bordered]="true"
					  (rateUpload)="rateUpload($event)">
	</app-rates-import>
</div>
<div class="edit-panel-footer">
	<button (click)="close()" alaris-button bType="outline" data-testid="closeBtn" size="lg" type="button">
		{{ 'actions.cancel' | translate }}
	</button>
</div>
