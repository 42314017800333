import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject, takeUntil } from 'rxjs';

import { AbstractCRUDService } from '@campaign-portal/components-library';

import { EntityField, InputComplexType, InputType } from '@campaign-portal/namespace/common/entityField';
import { ReadResponse } from '@campaign-portal/namespace/common/implementations';
import { FilterType } from '@campaign-portal/namespace/common/rpc.params';
import { EnumsMapperService } from '@helpers/services/enums.service';
import { ContractCompaniesService } from '../settings/contract-companies/contract-companies.service';

@Injectable()
export class PartnersFieldsService implements AbstractCRUDService, OnDestroy {

	readonly entity = '';
	readonly title = '';
	readonly loading$ = new BehaviorSubject<boolean>(false);

	private readonly name: EntityField = {
		id: 0,
		name: 'columns.name',
		variable: 'name',
		required: true,
		type: InputType.TEXT,
		readonly: true,
		filterType: FilterType.LIKE
	};
	private readonly ccId: EntityField = {
		id: 1,
		name: 'columns.cc',
		variable: 'ccId',
		required: true,
		type: InputComplexType.MULTISELECT,
		data: [],
		readonly: true,
		filterType: FilterType.IN,
		property: 'id'
	};
	private readonly balance: EntityField = {
		id: 2,
		name: 'columns.balance',
		variable: 'balance',
		required: true,
		type: InputComplexType.RANGE,
		readonly: true,
		filterType: FilterType.EXACT
	};
	private readonly creditLimit: EntityField = {
		id: 3,
		name: 'columns.creditLimit',
		variable: 'creditLimit',
		required: true,
		readonly: true,
		type: InputComplexType.RANGE,
		filterType: FilterType.EXACT
	};
	private readonly financialSettings: EntityField = {
		id: 4,
		name: 'columns.creditType',
		variable: 'financialSettings',
		required: true,
		readonly: true,
		data: Object.keys(this.enums.get('CreditType')),
		type: InputComplexType.MULTISELECT,
		filterType: FilterType.IN,
		property: 'creditType'
	};

	private readonly headers: EntityField[] = [
		this.name,
		this.ccId,
		this.balance,
		this.creditLimit,
		this.financialSettings
	];

	private readonly ngUnsubscribe = new Subject<void>();

	constructor(
		private readonly enums: EnumsMapperService,
		private readonly ccService: ContractCompaniesService
	) {
		this.ccService.list$
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((list) => {
				this.ccId.data = list;
			});
	}

	ngOnDestroy(): void {
		this.loading$.complete();
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}

	create(): Observable<void> {
		return of(undefined);
	}

	delete(): Observable<void> {
		return of(undefined);
	}

	read(): Observable<ReadResponse<EntityField[]>> {
		return of({
			Success: true,
			Total: this.headers.length,
			Data: this.headers
		});
	}

	update(): Observable<void> {
		return of(undefined);
	}
}

