import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';

import {
	AbstractCRUDService,
	AlarisApiService,
	AlarisLanguageService,
	ErrorNotifierConfig
} from '@campaign-portal/components-library';

import { ReadResponse } from '@campaign-portal/namespace/common/implementations';
import { RPCRequestParams } from '@campaign-portal/namespace/common/rpc.params';
import { exist } from '@campaign-portal/namespace/common/id';
import { Rate } from '@campaign-portal/namespace/entities/rates/specs';

@Injectable()
export class SubscriptionRatesService implements AbstractCRUDService, OnDestroy {

	readonly entity = '';

	readonly create = this.update;
	private readonly errorNotifier = (): ErrorNotifierConfig => ({ title: this.title });

	readonly loading$ = new BehaviorSubject<boolean>(false);

	constructor(
		private readonly api: AlarisApiService,
		private readonly langService: AlarisLanguageService
	) {
	}

	get title(): string {
		return this.langService.translate('notifications.titles.rate');
	}
	
	ngOnDestroy(): void {
		this.loading$.complete();
	}

	read(params?: RPCRequestParams): Observable<ReadResponse<Rate<exist>[]>> {
		return this.api.loader<ReadResponse<Rate<exist>[]>>(
			'Rates.Read', params, this.loading$, this.errorNotifier
		);
	}

	update(): Observable<undefined> {
		return of(undefined);
	}

	delete(): Observable<undefined> {
		return of(undefined);
	}
}
