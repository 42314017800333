<form class="form">
	<div class="custom-fields">
		<div class="reset-password">
			<alaris-link (click)="generate()"
						 data-testid="generateLink" type="primary">
				{{ 'signUp.generatePassword' | translate }}
			</alaris-link>
			<alaris-input [alarisLoading]="loading$"
						  [errors]="errors"
						  [formControl]="newPassword"
						  autocomplete="new-password"
						  class="custom-field"
						  data-testid="newPasswordInput"
						  type="password">
				{{ 'signUp.reset.newPassword' | translate }}
			</alaris-input>
		</div>
	</div>
</form>
