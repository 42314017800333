<div [alarisLoading]="contractCompaniesService.loading$">
	<div class="edit-panel-header">
		<alaris-icon name="icon-contract-company"></alaris-icon>
		{{
			(([AP_PERMISSIONS.CC_E] | alarisPBAC)
				? (company.id ? 'contract-companies.editEntry' : 'contract-companies.createEntry')
				: 'contract-companies.details') | translate
		}}
	</div>
	<div [formGroup]="companyForm" alarisFade="vertical" class="form-group edit-panel-content">
		<alaris-input [label]="'fc.name' | translate"
					  [placeholder]="'fc.name' | translate"
					  data-testid="nameInput" formControlName="name">
		</alaris-input>
		<alaris-multi-select2 [displayWith]="displayImChannels"
							  [label]="'fc.deliveryChannels' | translate"
							  [optionsCount]="(channelService.list$ | async)?.length || 0"
							  [placeholder]="'fc.deliveryChannels' | translate"
							  data-testid="channelsMultiSelect" formControlName="availableImChannels">
			<alaris-multi-options2 *ngFor="let channel of channelService.list$ | async"
								   [attr.data-testid]="'channelsOption/' + channel.name" [value]="channel.id">
				{{ channel.name }}
			</alaris-multi-options2>
		</alaris-multi-select2>
		<alaris-multi-select2 [displayWith]="displayCurrencies"
							  [label]="'fc.currency' | translate"
							  [optionsCount]="(currencyService.list$ | async)?.length || 0"
							  [placeholder]="'fc.currency' | translate"
							  data-testid="currenciesMultiSelect" formControlName="availableCurrencies">
			<alaris-select-filter2 *ngIf="((currencyService.list$ | async)?.length || 0) > 5"
								   [formControl]="filterCurrencyControl" data-testid="currenciesFilter">
			</alaris-select-filter2>
			<alaris-multi-options2 *ngFor="let currency of filterCurrencyList"
								   [attr.data-testid]="'currenciesOption/' + currency.name" [value]="currency.id">
				{{ currency.name }}
			</alaris-multi-options2>
		</alaris-multi-select2>
		<alaris-multi-select2 [displayWith]="displayPaySystem"
							  [label]="('fc.paymentSystems' | translate)"
							  [optionsCount]="(psService.list$ | async)?.length || 0"
							  [placeholder]="'fc.paymentSystems' | translate"
							  data-testid="paySystemsMultiSelect" formControlName="availablePaySystems">
			<alaris-select-filter2 *ngIf="((psService.list$ | async)?.length || 0) > 5"
								   [formControl]="filterPsControl" data-testid="paySystemsFilter">
			</alaris-select-filter2>
			<alaris-multi-options2 *ngFor="let paySystem of filterPsList"
								   [attr.data-testid]="'paySystemsOption/' + paySystem.name"
								   [disabled]="checkSelectedType(paySystem)" [value]="paySystem.id">
				{{ paySystem.name }}
			</alaris-multi-options2>
		</alaris-multi-select2>
	</div>
	<div class="edit-panel-footer">
		<button (click)="save()"
				*alarisPBAC="[AP_PERMISSIONS.CC_E]"
				[disabled]="companyForm.invalid || !companyForm.dirty"
				alaris-button bType="primary"
				data-testid="saveBtn"
				size="lg" type="button">
			<alaris-icon class="in-primary" name="icon-success"></alaris-icon>
			{{ (company.id ? 'actions.save' : 'actions.create') | translate }}
		</button>
		<button (click)="close()" alaris-button bType="outline" data-testid="closeBtn" size="lg" type="button">
			{{ (([AP_PERMISSIONS.CC_E] | alarisPBAC) ? 'actions.cancel' : 'actions.close') | translate }}
		</button>
	</div>
</div>


