import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { map, switchMap } from 'rxjs';

import {
	AlarisComplexTableComponent,
	AlarisEditPanelService,
	AlarisProfileService,
	AlarisTableSettingsService,
	AlarisTableStateService,
	ChannelUtilsService,
	DEFAULT_BUTTONS,
	EditPanelWidth,
	RowActionSimple
} from '@campaign-portal/components-library';

import { TableTypes } from '@campaign-portal/namespace/entities/table-settings/specs';
import { exist } from '@campaign-portal/namespace/common/id';
import { SenderSubscription } from '@campaign-portal/namespace/entities/sender-id/specs';
import { EnabledDisabledStatus } from '@campaign-portal/namespace/common/enums';
import { RPCResult } from '@campaign-portal/namespace/common/rpc.response';

import { AP_PERMISSIONS } from '@helpers/types/permissions';
import { ChannelsService } from '@helpers/services/channels.service';
import { PartnersService } from '../../partners/partners.service';
import { SenderSubscriptionEntity } from '@helpers/types/app.classes-interfaces';
import { SendersSubscriptionsFieldsService } from './senders-subscriptions.fields.service';
import { SubscriptionManageService } from '../subscription-manage.service';
import { EditSenderSubscriptionComponent } from './edit-sendor-subscription/edit-sender-subscription.component';
import { OwnerService } from '@helpers/services/owner.service';
import { SendersListService } from '../senders-list/senders-list.service';
import { SenderSubscriptionsTableService } from './sender-subscriptions.table.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
	selector: 'app-senders-subscriptions',
	templateUrl: './senders-subscriptions.component.html',
	styleUrls: [
		// eslint-disable-next-line max-len
		'../../../../../node_modules/@campaign-portal/components-library/src/assets/templates/table-complex/complex-table.component.scss',
		'./senders-subscriptions.component.scss'
	],
	providers: [SendersSubscriptionsFieldsService, SenderSubscriptionsTableService],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SendersSubscriptionsComponent extends AlarisComplexTableComponent<SenderSubscription<exist>>
	implements OnInit {

	readonly AP_PERMISSIONS = AP_PERMISSIONS;
	readonly statusGroup = new FormGroup({});

	// eslint-disable-next-line
	override readonly dropDownRowActions: RowActionSimple<any>[] = this.getActions();
	override readonly ctxActions: RowActionSimple<SenderSubscription | SenderSubscription[]>[] = this.dropDownRowActions;

	override readonly id = 'sender-subscriptions';

	constructor(
		public readonly isOwner: OwnerService,
		public readonly entityService: SenderSubscriptionsTableService,
		public override readonly fieldsService: SendersSubscriptionsFieldsService,
		public override readonly tableSettingsService: AlarisTableSettingsService,
		public override readonly editPanel: AlarisEditPanelService,
		public override readonly cd: ChangeDetectorRef,
		public readonly channelService: ChannelsService,
		public readonly partnersService: PartnersService,
		public readonly cu: ChannelUtilsService,
		private readonly subscriptionManageService: SubscriptionManageService,
		private readonly profile: AlarisProfileService,
		private readonly sendersService: SendersListService,
		public override readonly stateService: AlarisTableStateService
	) {
		super(
			entityService,
			fieldsService,
			tableSettingsService,
			editPanel,
			cd,
			TableTypes.INTERNAL,
			DEFAULT_BUTTONS,
			stateService
		);
		this.entityService.stateID = this.id;
	}

	override ngOnInit(): void {
		super.ngOnInit();

		// for automatically opening from sender-list interface action
		this.subscriptionManageService.activeSubscription.asObservable()
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe(value => {
				if ( value ) {
					this.openEditPanel(value);
					this.subscriptionManageService.activeSubscription.next(undefined);
				}
			});
	}

	override load(): void {
		this.saveState();
		this.loading$.next(true);
		this.sendersService.load()
			.pipe(
				takeUntilDestroyed(this.destroyRef),
				switchMap(() => {
					return this.dataService.read(this.readParams);
				})
			)
			.subscribe((resp) => {
				this.tRows = resp.Data ?? [];
				this.setStateControls();
				this.total = resp.Total ?? 0;
				this.loading$.next(false);
			});
	}

	override refresh(): void {
		this.entityService.firstLoad = true;
		this.load();
	}

	openEditPanel(senderSubscription?: SenderSubscription): void {
		const subscription = senderSubscription ? senderSubscription : new SenderSubscriptionEntity(null);
		this.editPanel.open<EditSenderSubscriptionComponent, {
			subscription: SenderSubscription;
		}, RPCResult<SenderSubscription<exist>[]> | undefined>(
			EditSenderSubscriptionComponent, EditPanelWidth.SM,
			{
				subscription
			}
		)
			.pipe(
				map((response: RPCResult<SenderSubscription<exist>[]> | undefined) => {
					response?.Data?.forEach(sub => {
						const id = sub.id.toString();
						const isSubEnabled = sub.enabled === EnabledDisabledStatus.ENABLED;
						this.statusGroup.get(id) ?
							this.statusGroup.get(id)?.setValue(isSubEnabled) :
							this.statusGroup.addControl(id, new FormControl(isSubEnabled));
					});
					return response;
				}),
				takeUntilDestroyed(this.destroyRef)
			)
			.subscribe((result) => {
				if ( result ) {
					this.refresh();
					this.sendersService.load();
				}
			});
	}

	updateSubscriptionStatus(subscription: SenderSubscription<exist>, $event: Event): void {
		$event.stopPropagation();
		$event.preventDefault();

		if ( this.statusGroup.get(subscription.id.toString())?.disabled ) {
			return;
		}

		this.loading$.next(true);
		this.entityService.update([{
			...subscription,
			enabled: subscription.enabled === EnabledDisabledStatus.ENABLED ?
				EnabledDisabledStatus.DISABLED : EnabledDisabledStatus.ENABLED
		}])
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe((resp) => {
				this.loading$.next(false);
				if ( resp.Success ) {
					this.refresh();
				}
			});
	}

	private setStateControls(): void {
		this.tRows.forEach(sender => {
			this.statusGroup.setControl(
				(sender.id ?? 0).toString(),
				new FormControl({
					value: sender.enabled === EnabledDisabledStatus.ENABLED,
					disabled: (sender.activeTill ? new Date() > new Date(sender.activeTill) : false)
						|| !this.profile.allowed([AP_PERMISSIONS.SENDERS_E])
				})
			);
		});
	}

	private getActions(): RowActionSimple<SenderSubscription<exist>>[] {
		const edit: RowActionSimple<SenderSubscription<exist>> = {
			icon: '',
			label: 'actions.edit',
			action: this.openEditPanel.bind(this)
		};
		const details: RowActionSimple<SenderSubscription<exist>> = {
			...edit,
			label: 'actions.details'
		};
		return this.profile.allowed([AP_PERMISSIONS.SENDERS_E]) ? [edit] : [details];
	}
}
