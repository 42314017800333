import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { interval } from 'rxjs';

import {
	AlarisComplexTableComponent,
	AlarisEditPanelService,
	AlarisProfileService,
	AlarisTableSettingsService,
	EditPanelWidth,
	RowActionSimple,
	SharedTemplatesService,
	TableSortIndicator
} from '@campaign-portal/components-library';

import { TableTypes } from '@campaign-portal/namespace/entities/table-settings/specs';
import { FilterType, SortDirection } from '@campaign-portal/namespace/common/rpc.params';
import { Payments } from '@campaign-portal/namespace/entities/payments/specs';
import { PaymentSource, PaymentStatus } from '@campaign-portal/namespace/common/enums';

import { PaymentsEntity } from '@helpers/types/app.classes-interfaces';
import { PaymentsFieldsService } from './payments-fields.service';
import { PaymentsService } from './payments.service';
import { UpdatePaymentComponent } from './update-payment/update-payment.component';
import { AddPaymentComponent } from './add-payment/add-payment.component';
import { TableFiltersIndicator } from '@campaign-portal/components-library/lib/table';
import { AP_PERMISSIONS } from '@helpers/types/permissions';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
	selector: 'app-payments',
	templateUrl: './payments.component.html',
	styleUrls: [
		// eslint-disable-next-line max-len
		'../../../../../node_modules/@campaign-portal/components-library/src/assets/templates/table-complex/complex-table.component.scss',
		'./payments.component.scss'
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [PaymentsFieldsService]
})
export class PaymentsComponent extends AlarisComplexTableComponent<Payments> implements OnInit {

	readonly AP_PERMISSIONS = AP_PERMISSIONS;
	readonly PaymentSource = PaymentSource;
	readonly PaymentStatus = PaymentStatus;

	readonly tabsFilter = [
		{
			label: 'tb.allPayments',
			value: 0
		},
		{
			label: 'enums.INCOMING',
			value: 1
		},
		{
			label: 'enums.OUTGOING',
			value: -1
		}
	];

	readonly selectedTabFilter = new FormControl<number>(this.tabsFilter[0].value);

	override sorting: TableSortIndicator = new Map()
		.set('id', { enabled: true, value: SortDirection.DESC });

	// eslint-disable-next-line
	override readonly rowActions: RowActionSimple<any>[] = this.getActions();
	override readonly ctxActions: RowActionSimple<Payments | Payments[]>[] = [...this.rowActions].map(a => ({
		...a,
		icon: ''
	}));
	override readonly id = 'payments';

	constructor(
		public readonly paymentsService: PaymentsService,
		public override readonly fieldsService: PaymentsFieldsService,
		public override readonly tableSettingsService: AlarisTableSettingsService,
		public override readonly editPanel: AlarisEditPanelService,
		public override readonly cd: ChangeDetectorRef,
		public readonly sharedTemplates: SharedTemplatesService,
		private readonly profile: AlarisProfileService
	) {
		super(
			paymentsService,
			fieldsService,
			tableSettingsService,
			editPanel,
			cd,
			TableTypes.INTERNAL
		);
	}

	override ngOnInit(): void {
		super.ngOnInit();
		this.selectedTabFilter.valueChanges
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe(() => {
				this.filters.set('amount', { enabled: true });
				this._applyFilter(this.filters);
			});

		interval(30000).pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
			this.load(false);
		});
	}

	override load(showLoading = true): void {
		if ( showLoading ) {
			this.loading$.next(true);
		}
		this.dataService.read(this.readParams)
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe((resp) => {
				this.tRows = resp.Data;
				this.total = resp.Total;
				if ( showLoading ) {
					this.loading$.next(false);
				}
				this.cd.markForCheck();
			});
		this.cd.markForCheck();
	}

	override applyFilter($event: TableFiltersIndicator): void {
		super.applyFilter($event);
		if ( this.selectedTabFilter.value ) {
			const amountFilter = this.readParams.Filters?.find(filter => filter.Field === 'amount');
			if ( !amountFilter ) {
				this.readParams.Filters?.push({
					Field: 'amount',
					Type: this.selectedTabFilter.value > 0 ? FilterType.MORE : FilterType.LESS,
					Value: 0
				});
			} else {
				amountFilter.Type = this.selectedTabFilter.value > 0 ? FilterType.MORE : FilterType.LESS;
			}
		}
	}

	openUpdatePaymentPanel(payment?: Payments): void {
		if ( (payment?.amount ?? 0) < 0 ) {
			return;
		}
		this.editPanel.open(UpdatePaymentComponent, EditPanelWidth.SM, {
			payment: payment ? payment : null
		})
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe(result => {
				if ( result ) {
					this.load();
				}
			});
	}

	openAddPaymentPanel(): void {
		const paymentEntity = new PaymentsEntity(null);
		this.editPanel.open(AddPaymentComponent, EditPanelWidth.SM, {
			payment: paymentEntity
		})
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe(result => {
				if ( result ) {
					this.load();
				}
			});
	}

	private getActions(): RowActionSimple<Payments>[] {
		const edit: RowActionSimple<Payments> = {
			icon: 'icon-edit',
			label: 'actions.edit',
			action: this.openUpdatePaymentPanel.bind(this),
			shown: (payment?: Payments): boolean => (payment?.amount ?? 0) > 0
		};
		const details: RowActionSimple<Payments> = {
			...edit,
			icon: 'icon-password-show',
			label: 'actions.details'
		};
		return this.profile.allowed([AP_PERMISSIONS.PAYMENTS_E]) ? [edit] : [details];
	}
}
