import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { SelectionModel } from '@angular/cdk/collections';
import { Subject } from 'rxjs';

import { AlarisEditPanelService, EditPanelInputData } from '@campaign-portal/components-library';

import { EntityField } from '@campaign-portal/namespace/common/entityField';

@Component({
	selector: 'app-rates-export-panel',
	templateUrl: './rates-export-panel.component.html',
	styleUrls: ['./rates-export-panel.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class RatesExportPanelComponent implements OnInit, OnDestroy {
	total = 0;
	readonly tHeads: EntityField[] = [];
	readonly selection = new SelectionModel<EntityField>(true, []);
	// For correct checkbox work
	readonly fakeGroup = new FormGroup({});
	readonly fakeControl = new FormControl();

	private readonly ngUnsubscribe: Subject<void> = new Subject<void>();

	constructor(
		@Inject(EditPanelInputData) private readonly inputData: EditPanelInputData,
		private readonly editPanel: AlarisEditPanelService
	) {
		this.total = this.inputData.total as number;
		this.tHeads = this.inputData.tHeads as EntityField[];
	}

	ngOnInit(): void {
		// For correct checkbox work
		this.tHeads.forEach(field => {
			this.fakeGroup.addControl(field.variable, new FormControl());
		});
	}

	close(fields?: string[]): void {
		this.editPanel.close(fields);
	}

	export(): void {
		const fields = this.selection.selected.map(field => field.variable);
		this.close(fields);
	}

	isAllSelected(): boolean {
		const numSelected = this.selection.selected.length;
		const numRows = this.tHeads.length;
		return numSelected === numRows;
	}

	toggleAllRows(): void {
		if ( this.isAllSelected() ) {
			this.selection.clear();
			return;
		}

		this.selection.select(...this.tHeads);
	}

	ngOnDestroy(): void {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}
}
