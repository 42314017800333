<div class="edit-panel-header">
	<alaris-icon [name]="cu.icon((edr.channelId | channel)?.channelType)"></alaris-icon>
	{{ 'statistics.edrDetails' | translate }}
</div>
<div alarisFade="vertical" class="edit-panel-content">
	<div class="card-group">
			<span class="edrEventTime">
				{{ 'columns.eventTime' | translate }}:
				{{ edr.edrDate | date: 'd MMM y, HH:mm' }}
			</span>

		<section class="card">
			<h5 class="header">{{ 'statistics.core' | translate }}</h5>
			<div class="grid">
				<b>{{ 'columns.messageID' | translate }}:</b>
				{{ edr.messageId | noValue }}
				<b>{{ 'columns.status' | translate }}:</b>
				{{ 'enums.' + edr.edrStatus | translate }}
				<b>{{ 'columns.destinationNumber' | translate }}:</b>
				{{ edr.destinationNumber | noValue }}
				<b>{{ 'columns.partAmount' | translate }}:</b>
				{{ edr.partAmount | noValue }}
				<b>{{ 'columns.transactionID' | translate }}:</b>
				{{ edr.transactionId | noValue }}
				<b>{{ 'columns.country' | translate }}:</b>
				{{ edr.country | noValue }}
				<b>{{ 'columns.network' | translate }}:</b>
				{{ edr.net | noValue }}

				<ng-container *ngIf="edr.errMsg">
					<b>{{ 'columns.errorMessage' | translate }}:</b>
					{{ edr.errMsg }}
				</ng-container>

			</div>
		</section>
		<section class="card">
			<h5 class="header">{{ 'statistics.additional' | translate }}</h5>
			<div class="grid">
				<ng-container *ngIf="isOwner.is">
					<b>{{ 'columns.mcc' | translate }}:</b>
					{{ edr.mcc | mccmnc }}
					<b>{{ 'columns.mnc' | translate }}:</b>
					<span>{{ edr.mnc | mccmnc }}</span>
					<b>{{ 'columns.vendorChannel' | translate }}:</b>
					{{ edr.vendorChannel | noValue }}
					<b>{{ 'columns.vendor' | translate }}:</b>
					{{ edr.vendor | noValue }}
					<ng-container *ngIf="isOwner.is">
						<b>{{ 'columns.endpointId' | translate }}:</b>
						{{ edr.endpointName | noValue }}
					</ng-container>
				</ng-container>

				<b>{{ 'columns.vendorSubscription' | translate }}:</b>
				{{ edr.vendorSubscription | noValue }}
				<b>{{ 'columns.client' | translate }}:</b>
				{{ edr.client | noValue }}
				<b>{{ 'columns.clientSubscription' | translate }}:</b>
				{{ edr.clientSubscription | noValue }}
				<b>{{ 'columns.revenue' | translate }}:</b>
				{{ edr.revenue | currency: bs.currencyCode: 'symbol' : '1.2-6' | noValue }}
			</div>
		</section>
		<section class="card">
			<h5 class="header">{{ 'statistics.campaign' | translate }}</h5>
			<div class="grid">
				<b>{{ 'columns.name' | translate }}:</b>
				@if (edr.labelAPI) {
					<div class="label-api">
						<alaris-tag mode="gray-light">{{ 'api.request' | translate }}</alaris-tag>
					</div>
				} @else {
					{{ edr.campaignName | noValue }}
				}

				@if (edr.labelAPI) {
					<b>{{ 'columns.labelAPI' | translate }}:</b>
					{{ edr.labelAPI | noValue }}
				}
				<b>{{ 'columns.senderID' | translate }}:</b>
				{{ edr.senderName | noValue }}
				<b>{{ 'columns.message' | translate }}:</b>
				{{ edr.message | noValue }}
				<b>{{ 'columns.channel' | translate }}:</b>
				{{ (edr.channelId | channel)?.name | noValue }}
				<b>{{ 'columns.cost' | translate }}:</b>
				{{ (edr.cost | currency: bs.currencyCode: 'symbol': '1.2-5') | noValue }}

				<ng-container *ngIf="(edr.channelId | channel)?.channelType !== TrafficType.SMS">
					<b>{{ 'columns.messagePurpose' | translate }}:</b>
					{{ edr.messagePurpose ? ('enums.' + edr.messagePurpose | translate) : '-' }}
					<b>{{ 'columns.buttonActionUrl' | translate }}:</b>
					<span class="edrUrl">{{ edr.buttonActionUrl | noValue }}</span>
					<b>{{ 'columns.buttonCaption' | translate }}:</b>
					{{ edr.buttonCaption | noValue }}
					<b>{{ 'columns.imageUrl' | translate }}:</b>
					<span class="edrUrl">{{ edr.imageUrl | noValue }}</span>
				</ng-container>
			</div>
		</section>
	</div>
</div>
<div class="edit-panel-footer">
	<button (click)="close()" alaris-button bType="outline" data-testid="closeBtn" size="lg">
		{{ 'actions.close' | translate }}
	</button>
</div>
