import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

import {
	AlarisComplexTableComponent,
	AlarisDialogService,
	AlarisEditPanelService,
	AlarisProfileService,
	AlarisTableSettingsService,
	AlarisTableStateService,
	ChannelUtilsService,
	DEFAULT_BUTTONS,
	EditPanelWidth,
	PROFILE_SERVICE_INJECTOR,
	RowActionSimple,
	TableSortIndicator
} from '@campaign-portal/components-library';
import { TableFiltersIndicator } from '@campaign-portal/components-library/lib/table';

import { TableTypes } from '@campaign-portal/namespace/entities/table-settings/specs';
import { SenderSubscriptionEvent } from '@campaign-portal/namespace/entities/sender-id/specs';
import { SenderSubscriptionEventStatus } from '@campaign-portal/namespace/common/enums';
import { FilterType, SortDirection } from '@campaign-portal/namespace/common/rpc.params';
import { exist, Id } from '@campaign-portal/namespace/common/id';

import { EventsFieldsService } from './events.fields.service';
import { EventsDetailsComponent } from './events-details/events-details.component';
import { ActionsFactory } from './actions-factory';
import { SendersDialogComponent, SendersDialogType } from '../senders-dialog/senders-dialog.component';
import { OwnerService } from '@helpers/services/owner.service';
import { EventsTableService } from './events.table.service';
import { SendersListService } from '../senders-list/senders-list.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
	selector: 'app-events',
	templateUrl: './events.component.html',
	styleUrls: [
		// eslint-disable-next-line max-len
		'../../../../../node_modules/@campaign-portal/components-library/src/assets/templates/table-complex/complex-table.component.scss',
		'./events.component.scss'
	],
	providers: [EventsFieldsService, EventsTableService],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class EventsComponent extends AlarisComplexTableComponent<SenderSubscriptionEvent> implements OnInit {
	readonly EventStatus = SenderSubscriptionEventStatus;
	readonly tabs = [
		{
			label: 'tb.all',
			value: null
		},
		{
			label: 'tb.myEvents',
			value: FilterType.EXACT
		},
		{
			label: 'tb.partnerEvents',
			value: FilterType.NOT_EXACT
		}
	];
	readonly eventsFilter = new FormControl();

	override readonly id = 'events';
	override sorting: TableSortIndicator = new Map()
		.set('lastUpdated', { enabled: true, value: SortDirection.DESC });

	override readonly dropDownRowActions: RowActionSimple<any>[];
	override readonly ctxActions: RowActionSimple<SenderSubscriptionEvent | SenderSubscriptionEvent[]>[];
	readonly profileId: Id<exist>;

	constructor(
		public readonly eventsService: EventsTableService,
		private readonly sendersListService: SendersListService,
		public override readonly fieldsService: EventsFieldsService,
		public override readonly tableSettingsService: AlarisTableSettingsService,
		public override readonly editPanel: AlarisEditPanelService,
		public override readonly cd: ChangeDetectorRef,
		public override readonly stateService: AlarisTableStateService,
		public readonly cu: ChannelUtilsService,
		private readonly dialog: AlarisDialogService,
		public readonly isOwner: OwnerService,
		@Inject(PROFILE_SERVICE_INJECTOR) private readonly profile: AlarisProfileService
	) {
		super(
			eventsService,
			fieldsService,
			tableSettingsService,
			editPanel,
			cd,
			TableTypes.INTERNAL,
			DEFAULT_BUTTONS,
			stateService
		);
		this.eventsService.stateID = this.id;
		this.profileId = this.profile.user.partnerId;

		this.dropDownRowActions = new ActionsFactory(this).getActions(this.isOwner.is);
		this.ctxActions = this.dropDownRowActions;
	}

	override ngOnInit(): void {
		super.ngOnInit();

		this.eventsFilter.valueChanges
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe(() => {
				this._applyFilter(this.filters);
			});
	}

	override applyFilter($event: TableFiltersIndicator): void {
		super.applyFilter($event);
		if ( this.eventsFilter.value ) {
			this.readParams.Filters?.push({
				Field: 'sender',
				Type: this.eventsFilter.value,
				Value: this.profileId,
				Property: 'partner'
			});
		}
	}

	override refresh(): void {
		// todo: remove after websocket
		// owner can confirm sender, but the reseller's refbook
		// will not be updated
		this.sendersListService.refresh$.next();

		this.eventsService.firstLoad = true;
		this.load();
	}

	openDetailsPanel(event?: SenderSubscriptionEvent): void {
		this.editPanel.open(EventsDetailsComponent, EditPanelWidth.SM, {
			event,
			actions: this.dropDownRowActions
		})
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe((result) => {
				if ( result ) {
					this.refresh();
				}
			});
	}

	confirm(event?: SenderSubscriptionEvent): void {
		if ( event ) {
			this.openSpecificDialog('Confirm', event);
		}
	}

	decline(event?: SenderSubscriptionEvent): void {
		if ( event ) {
			this.openSpecificDialog('Decline', event);
		}
	}

	cancel(event: SenderSubscriptionEvent): void {
		this.eventsService.cancel(event)
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe(() => this.refresh());
	}

	putInProgress(event: SenderSubscriptionEvent): void {
		this.eventsService.inProgress(event)
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe(() => this.refresh());
	}

	override restoreState(): void {
		super.restoreState();

		const tabFilter = this.readParams.Filters?.find((f) => {
			return f.Field === 'sender' && f.Property === 'partner' && f.Value === this.profileId;
		});
		if ( this.isOwner.is && tabFilter ) {
			this.eventsFilter.setValue(tabFilter.Type);
		}
	}

	private openSpecificDialog(
		type: SendersDialogType,
		event?: SenderSubscriptionEvent
	): void {
		this.dialog.open(SendersDialogComponent, {
			data: { event, type },
			autoFocus: false
		}).closed
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe((result) => {
				if ( result ) {
					this.refresh();
				}
			});
	}
}

