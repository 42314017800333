import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	OnDestroy,
	OnInit,
	ViewChild
} from '@angular/core';
import { map, Observable, Subject, takeUntil } from 'rxjs';

import { AlarisTableStateService, AlarisTabsComponent } from '@campaign-portal/components-library';

import { SenderSubscriptionEvent } from '@campaign-portal/namespace/entities/sender-id/specs';
import { exist } from '@campaign-portal/namespace/common/id';

import { SubscriptionManageService } from './subscription-manage.service';
import { EventsService } from './events/events.service';
import { SenderSubscriptionEventStatus } from '@campaign-portal/namespace/common/enums';
import { AP_PERMISSIONS } from '@helpers/types/permissions';

@Component({
	selector: 'app-senders',
	templateUrl: './senders.component.html',
	styleUrls: ['./senders.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SendersComponent implements OnInit, AfterViewInit, OnDestroy {
	@ViewChild('sendersTabs') readonly sendersTabs!: AlarisTabsComponent;
	readonly events$: Observable<SenderSubscriptionEvent<exist>[]>;
	readonly AP_PERMISSIONS = AP_PERMISSIONS;

	private readonly ngUnsubscribe = new Subject<void>();

	constructor(
		public readonly cd: ChangeDetectorRef,
		public readonly subscriptionManageService: SubscriptionManageService,
		private readonly eventsService: EventsService,
		private readonly stateService: AlarisTableStateService
	) {
		this.events$ = this.eventsService.list$
			.pipe(
				map(list => list.filter(e => e.status === SenderSubscriptionEventStatus.NEW))
			);
	}

	ngOnInit(): void {
		this.eventsService.read().pipe(takeUntil(this.ngUnsubscribe)).subscribe();
	}

	ngAfterViewInit(): void {
		this.subscriptionManageService.setTabsManager(this.sendersTabs);
	}

	ngOnDestroy(): void {
		this.stateService.clear();
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}

}
