<tui-root>
	<div class="dashboard-grid">
		<alaris-card class="by-status" size="auto">
			<app-message-by-status-chart></app-message-by-status-chart>
		</alaris-card>
		<alaris-card class="by-channel" size="auto">
			<app-message-by-channel-chart></app-message-by-channel-chart>
		</alaris-card>
		<alaris-card class="by-country" size="auto">
			<app-messages-by-country #byCountry></app-messages-by-country>
		</alaris-card>
		<alaris-card class="by-net" size="auto">
			<app-messages-by-net [byCountryLoading$]="byCountry.loading$"
								 [periodType]="byCountry.periodType"
								 [range]="byCountry.range">
			</app-messages-by-net>
		</alaris-card>
		<ng-container *alarisLet="[AP_PERMISSIONS.SENDERS_R] | alarisPBAC as bySender">
			<alaris-card [class.expanded]="!bySender"
						 class="by-client" size="auto">
				<app-messages-by-client></app-messages-by-client>
			</alaris-card>
			@if (bySender) {
				<alaris-card class="by-sender" size="auto">
					<app-messages-by-sender></app-messages-by-sender>
				</alaris-card>
			}
		</ng-container>
		<alaris-card class="analytics" size="auto">
			<app-analytics-by-client></app-analytics-by-client>
		</alaris-card>
	</div>
</tui-root>
<app-chart-templates></app-chart-templates>

