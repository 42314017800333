import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy } from '@angular/core';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { BehaviorSubject, finalize, Observable, of, Subject, takeUntil } from 'rxjs';

import { exist } from '@campaign-portal/namespace/common/id';
import { RPCResult } from '@campaign-portal/namespace/common/rpc.response';
import { Subscription } from '@campaign-portal/namespace/entities/subscriptions/specs';

import { SubscriptionsService } from '../subscriptions.service';
import { Partner } from '@campaign-portal/namespace/entities/partners/specs';
import { FormControl } from '@angular/forms';
import { CanDeactivateComponent } from '@helpers/can-deactivate/can-deactivate.component';
import { CanDeactivateGuardService } from '@helpers/can-deactivate/can-deactivate.guard';
import { AlarisDialogService } from '@campaign-portal/components-library';

export type SubscriptionsDialogType = 'Delete' | 'Unlink';

export interface SubscriptionsDialogData {
	subscription: Subscription<exist>;
	partner?: Partner<exist>;
	type: SubscriptionsDialogType;
}

@Component({
	selector: 'app-subscriptions-dialog',
	templateUrl: './subscriptions-dialog.component.html',
	styleUrls: ['./subscriptions-dialog.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubscriptionsDialogComponent extends CanDeactivateComponent implements OnDestroy {

	readonly subscription: Subscription<exist>;
	readonly partner: Partner<exist> | undefined;
	readonly comment = new FormControl('', { nonNullable: true });

	readonly loading$ = new BehaviorSubject<boolean>(false);
	readonly allowedDeactivation = new BehaviorSubject<boolean>(true);
	protected readonly ngUnsubscribe = new Subject<void>();

	constructor(
		@Inject(DIALOG_DATA) public readonly data: SubscriptionsDialogData,
		private readonly dialogRef: DialogRef<unknown>,
		private readonly cd: ChangeDetectorRef,
		private readonly subService: SubscriptionsService,
		private readonly guard: CanDeactivateGuardService,
		private readonly dialog: AlarisDialogService
	) {
		super();
		this.addDialogGuard(this.dialog, dialogRef, this.guard);
		this.subscription = data.subscription;
		this.partner = data.partner;
	}

	get disabled(): boolean {
		return this.loading$.getValue();
	}

	close(): void {
		this.dialogRef.close(false);
	}

	confirm(): void {
		this.loading$.next(true);
		let caller: Observable<RPCResult<unknown>> = of({ Success: false });

		switch (this.data.type) {
			case 'Delete':
				caller = this.subService.delete(this.subscription.id);
				break;
			case 'Unlink':
				caller = this.subService.unsubscribe(
					{
						Data: {
							Ids: [this.subscription.id],
							comment: this.comment.value,
							partnerId: this.partner?.id
						}
					}
				);
				break;
			default:
				break;

		}

		this.allowedDeactivation.next(false);
		caller
			.pipe(
				finalize(() => {
					this.loading$.next(false);
				}),
				takeUntil(this.ngUnsubscribe))
			.subscribe(
				(resp) => {
					this.allowedDeactivation.next(true);
					this.dialogRef.close(resp.Success);
					this.cd.markForCheck();
				}
			);
	}

	ngOnDestroy(): void {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
		this.loading$.complete();
	}
}
