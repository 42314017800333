import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { catchError, forkJoin, map, Observable, of } from 'rxjs';

import { AlarisProfileService, AlarisTableSettingsService } from '@campaign-portal/components-library';
import { PortalPair, PortalType } from '@campaign-portal/namespace/entities/portals/specs';
import { TableSettingsEntries, TableTypes } from '@campaign-portal/namespace/entities/table-settings/specs';
import { exist } from '@campaign-portal/namespace/common/id';

import { AP_PERMISSIONS } from '@helpers/types/permissions';
import { PortalsService } from './portals.service';

@Component({
	selector: 'app-portals',
	templateUrl: './portals.component.html',
	styleUrls: ['./portals.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PortalsComponent implements OnInit {

	readonly AP_PERMISSIONS = AP_PERMISSIONS;
	readonly PortalType = PortalType;
	portals$!: Observable<PortalPair<exist>[]>;

	constructor(
		public readonly portalsService: PortalsService,
		public readonly profile: AlarisProfileService,
		private readonly tableSettingsService: AlarisTableSettingsService,
		private readonly router: Router
	) {
	}

	ngOnInit(): void {
		this.portals$ = forkJoin([this.portalsService.read(), this.tableSettingsService.read(TableTypes.PORTALS)])
			.pipe(
				map(([portals, settings]) => {
					const sortingArray = settings.Data.map(([ccName, _]) => ccName);
					return portals.sort((a, b) => sortingArray.indexOf(a.ccName) - sortingArray.indexOf(b.ccName));
				}),
				catchError((_) => of([]))
			);
	}

	drop(event: CdkDragDrop<PortalPair<exist>>, array: PortalPair<exist>[]): void {
		moveItemInArray(array, event.previousIndex, event.currentIndex);
		const tSettings: TableSettingsEntries = array.map(pp => ([pp.ccName, { visible: true }]));
		this.tableSettingsService.update(tSettings, TableTypes.PORTALS);
	}

	navigate(portalPair: PortalPair<exist>, type: PortalType = PortalType.ADMIN_PORTAL): void {
		const titleType = this.profile.allowed([AP_PERMISSIONS.PORTALS_E])
			? portalPair[type]?.id
				? 'update'
				: 'create'
			: 'details';
		this.router.navigateByUrl('edit-portals', {
			state: {
				portalPair,
				type,
				title: 'portals.' + titleType + type
			}
		});
	}

	goToCC(): void {
		this.router.navigateByUrl('settings/contract-companies');
	}
}
