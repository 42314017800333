import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';

import {
	AlarisEditPanelService,
	AlarisLanguageService,
	AlarisMultiSelectDisplayWithFn,
	ChannelUtilsService,
	CustomValidators
} from '@campaign-portal/components-library';

import { EnabledDisabledStatus, TrafficType } from '@campaign-portal/namespace/common/enums';
import { FileInfo } from '@campaign-portal/namespace/common/fileInfo';
import { SenderSubscription } from '@campaign-portal/namespace/entities/sender-id/specs';

import { SendersListService } from '../senders-list.service';
import { ChannelsService } from '@helpers/services/channels.service';

@Component({
	selector: 'app-request-sender',
	templateUrl: './request-sender.component.html',
	styleUrls: ['./request-sender.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class RequestSenderComponent implements OnInit, OnDestroy {
	senderIdForm!: FormGroup;

	private readonly ngUnsubscribe: Subject<void> = new Subject<void>();

	constructor(
		private readonly editPanel: AlarisEditPanelService,
		public readonly sendersService: SendersListService,
		public readonly channelService: ChannelsService,
		public readonly cu: ChannelUtilsService,
		private readonly lService: AlarisLanguageService
	) {
	}

	displayChannel: AlarisMultiSelectDisplayWithFn<TrafficType> = (values: TrafficType[] | null): string => {
		if ( values === null ) {
			return this.lService.translate('gl.all');
		}
		return values.map(ch => this.lService.translate(this.cu.name(ch))).join(', ');
	};

	close(): void {
		this.editPanel.close();
	}

	ngOnInit(): void {
		this.senderIdForm = new FormGroup({
			name: new FormControl<string>('', [Validators.required]),
			channels: new FormControl<null | TrafficType[]>([], [CustomValidators.requiredArrayOrNull]),
			comment: new FormControl<string>(''),
			documents: new FormControl<FileInfo[]>([], [Validators.required])
		});
	}

	save(): void {
		const value = this.senderIdForm.value;
		const senderIds: SenderSubscription | SenderSubscription[] = value.channels === null
			? this.channelService.list.map(({ channelType }) => this.mapChannels(channelType))
			: value.channels.map(this.mapChannels.bind(this));

		this.sendersService.requestSenderId(senderIds)
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((result) => {
				if ( result.Success ) {
					this.editPanel.close();
				}
			});
	}

	ngOnDestroy(): void {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}

	private mapChannels(trafficType: TrafficType): SenderSubscription {
		const sender: SenderSubscription = {
			id: null,
			...this.senderIdForm.value,
			trafficType,
			enabled: EnabledDisabledStatus.DISABLED,
			subscribers: []
		};
		delete sender.channels;
		return sender;
	}
}
