<div [alarisLoading]="sendersListService.loading$">
	<div class="edit-panel-header">
		<alaris-icon name="icon-sender"></alaris-icon>
		<span>{{ (senderId.id ? 'senders.senderIdDetails' : 'senders.addSenderID') | translate }}</span>
	</div>
	<div alarisFade="vertical" class="edit-panel-content">
		@if (senderId.id) {
			<alaris-tabs #senderDetailTabs [underline]="true">
				<alaris-tab [label]="'tabs.main' | translate" tabId="main">
					<ng-container [ngTemplateOutlet]=" isOwner.is ? mainOwner : mainReseller"></ng-container>
				</alaris-tab>
				<alaris-tab [label]="'tabs.subscriptionsWithAmount' | translate : {length: subscriptions.length }"
							tabId="subscriptions">
					@if (subscriptions.length) {
						<div class="subscriptions">
							@for (subscription of subscriptions; track $index) {
								<div class="subscription">
									<div>
										<b>{{ subscription.partner ? (subscription.partner | partner)?.name : '' }}</b>
										<div class="label">
											{{ subscription.activeFrom | date:'d MMM y, HH:mm' }} -
											{{
												subscription.activeTill ?
													(subscription.activeTill | date:'d MMM y, HH:mm') :
													('gl.neverExpires' | translate)
											}}
										</div>
									</div>
									<alaris-icon (click)="navigate(subscription)"
												 [attr.data-testid]="'toSubscriptionBtn/' + subscription.id"
												 class="pointer" name="icon-caret-right">
									</alaris-icon>
								</div>
							}
						</div>
					} @else {
						<div class="label stub">{{ 'senders.noSubscription' | translate }}</div>
					}
				</alaris-tab>
			</alaris-tabs>
		} @else {
			<ng-container [ngTemplateOutlet]="mainOwner"></ng-container>
		}
	</div>
	<div class="edit-panel-footer">
		<button (click)="save()"
				*alarisPBAC="[AP_PERMISSIONS.SENDERS_E]"
				[disabled]="senderIdForm.invalid || !senderIdForm.dirty"
				alaris-button
				bType="primary"
				data-testid="saveBtn"
				size="lg"
				type="button">
			<alaris-icon class="in-primary" name="icon-success"></alaris-icon>
			{{ (senderId.id ? 'actions.save' : 'actions.create') | translate }}
		</button>
		<button (click)="close()" alaris-button bType="outline" data-testid="closeBtn" size="lg" type="button">
			{{ (([AP_PERMISSIONS.SENDERS_E] | alarisPBAC) ? 'actions.cancel' : 'actions.close') | translate }}
		</button>
		@if (senderId.id && !isOwner.is && ([AP_PERMISSIONS.SENDERS_E] | alarisPBAC)) {
			<button (click)="onUnsubscribe()"
					[disabled]="!senderId.subscribers.length"
					alaris-button
					bType="outline"
					data-testid="unsubscribeBtn"
					size="lg"
					style="margin-left: auto"
					type="button">
				<alaris-icon class="in-outline" name="icon-delete"></alaris-icon>
				{{ 'senders.unsubscribe' | translate }}
			</button>
		}
		@if (senderId.id && isOwner.is && ([AP_PERMISSIONS.SENDERS_E] | alarisPBAC)) {
			<button (click)="onDelete()"
					[disabled]="senderId.subscribers.length !== 0"
					alaris-button
					bType="outline"
					data-testid="deleteBtn"
					size="lg"
					style="margin-left: auto"
					type="button">
				<alaris-icon class="in-outline" name="icon-delete"></alaris-icon>
				{{ 'actions.delete' | translate }}
			</button>
		}
	</div>
</div>

<ng-template #noComment>
	<span (click)="senderIdForm.get('comment')?.setValue(' ')"
		  *alarisPBAC="[AP_PERMISSIONS.SENDERS_E]"
		  class="add-comment">
		{{ 'gl.addComment' | translate }}
	</span>
</ng-template>

<ng-template #mainOwner>
	<div [formGroup]="senderIdForm" class="form-group">
		@if (senderId.lastUpdated) {
			<div class="label">
				{{ 'gl.lastUpdated' | translate }}
				{{ senderId.lastUpdated | date: 'd MMM y, HH:mm' }}
			</div>
		}
		<alaris-input [label]="('fc.sender'|translate)"
					  [placeholder]="'fc.sender'|translate"
					  [readonly]="!!senderId.subscribers.length"
					  data-testid="nameInput" formControlName="name">
		</alaris-input>
		@if (!senderId.subscribers.length) {
			<alaris-select2 [displayWith]="displayChannel"
							[label]="'fc.channel' | translate"
							[placeholder]="'fc.channel' | translate"
							data-testid="trafficTypeSelect" formControlName="trafficType">
				@for (opt of channelService.list$ | async; track $index) {
					<alaris-options2 [attr.data-testid]="'trafficTypeOption/' + opt.channelType"
									 [value]="opt.channelType">
						{{ cu.name(opt.channelType) | translate }}
					</alaris-options2>
				}
			</alaris-select2>
		} @else {
			<alaris-input [label]="'fc.channel' | translate"
						  [placeholder]="'fc.channel' | translate"
						  [readonly]="true"
						  data-testid="trafficTypeInput" formControlName="trafficType">
			</alaris-input>
		}
		<alaris-toggle (click)="toggleEnabledState($event)" data-testid="activeToggle" formControlName="enabled">
			{{ 'gl.enabled' | translate }}
		</alaris-toggle>
		<b>{{ 'senders.periodOfValidity' | translate }}</b>
		<div class="in-row">
			<alaris-input-date [label]="'fc.startDate' | translate"
							   [min]="Day.currentLocal()"
							   [toSystemDate]
							   data-testid="activeFromInputDate" formControlName="activeFrom">
			</alaris-input-date>
			<alaris-input-date [label]="'fc.endDate' | translate"
							   [min]="minEndDate"
							   [stateAddonEnabled]="{ reset: true, state: false }"
							   [toSystemDate]
							   data-testid="activeTillInputDate" formControlName="activeTill">
			</alaris-input-date>
		</div>
		@if (senderIdForm.hasError('moreThan')) {
			<span class="error">{{ 'errors.moreThan' | translate }}</span>
		}
		<div class="content-info">
			<b>{{ 'gl.documents' | translate }}</b>
			@if (senderId.documents?.length! > 1) {
				<span (click)="senderId.documents ? upload(senderId.documents): null" class="label">
					{{ 'gl.downloadAll' | translate }}
				</span>
			}
		</div>
		<alaris-file-loader (fileListChanges)="updateDocuments($event)"
							[exportInternally]="true"
							[files]="senderId.documents || []"
							[readonly]="!([AP_PERMISSIONS.SENDERS_E] | alarisPBAC)"
							data-testid="documentFile" fileTypes=".pdf">
		</alaris-file-loader>
		@if (senderIdForm.get('comment')?.value) {
			<alaris-text-area data-testid="commentTextarea" formControlName="comment">
				{{ 'fc.comment' | translate }}
			</alaris-text-area>
		} @else {
			<ng-container [ngTemplateOutlet]="noComment"></ng-container>
		}
	</div>
</ng-template>

<ng-template #mainReseller>
	<div [formGroup]="senderIdForm" class="form-group grid">
		<b>{{ 'gl.state' | translate }}</b>
		<alaris-tag [mode]="senderId.enabled ? 'success' :'error'" [rounded]="true" size="lg">
			{{ 'enums.' + senderId.enabled | translate }}
		</alaris-tag>
		<b>{{ 'gl.channel' | translate }}</b>
		{{ cu.name(senderId.trafficType) | translate }}
		<b>{{ 'senders.periodOfValidity' | translate }}</b>
		<span>
			{{ senderId.activeFrom | date:'d MMM y, HH:mm' }} -
			{{
				senderId.activeTill ?
					(senderId.activeTill |date:'d MMM y, HH:mm') :
					('gl.neverExpires' | translate)
			}}
		</span>
		@if (senderId.documents?.length) {
			<b>{{ 'gl.documents' | translate }}</b>
			<div>
				<alaris-file-loader [exportInternally]="true"
									[files]="senderId.documents ?? []"
									[readonly]="true" data-testid="documentFile">
				</alaris-file-loader>
			</div>
		}
		<b>{{ 'gl.comment' | translate }}</b>
		@if (senderIdForm.get('comment')?.value) {
			<alaris-text-area data-testid="commentTextarea" formControlName="comment">
				{{ 'fc.comment' | translate }}
			</alaris-text-area>
		} @else {
			<ng-container [ngTemplateOutlet]="noComment"></ng-container>
		}
	</div>
</ng-template>

