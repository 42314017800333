import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';

import { AbstractCRUDService } from '@campaign-portal/components-library';

import { EntityField, InputComplexType, InputType } from '@campaign-portal/namespace/common/entityField';
import { ReadResponse } from '@campaign-portal/namespace/common/implementations';
import { FilterType } from '@campaign-portal/namespace/common/rpc.params';
import { EnumsMapperService } from '@helpers/services/enums.service';

@Injectable()
export class UsersFieldsService implements AbstractCRUDService, OnDestroy {

	readonly entity = '';
	readonly title = '';
	readonly loading$ = new BehaviorSubject<boolean>(false);

	private readonly email: EntityField = {
		id: 0,
		name: 'columns.user',
		variable: 'email',
		required: true,
		type: InputType.TEXT,
		readonly: true,
		filterType: FilterType.LIKE
	};
	private readonly isActive: EntityField = {
		id: 1,
		name: 'columns.status',
		variable: 'isActive',
		required: true,
		type: InputComplexType.CHECKBOX,
		readonly: true,
		filterType: FilterType.EXACT
	};
	private readonly role: EntityField = {
		id: 2,
		name: 'columns.role',
		variable: 'role',
		required: true,
		type: InputComplexType.MULTISELECT,
		data: Object.keys(this.enums.get('RoleType')),
		readonly: true,
		filterType: FilterType.IN
	};
	private readonly partner: EntityField = {
		id: 3,
		name: 'columns.partner',
		variable: 'partnerId',
		required: true,
		readonly: true,
		type: InputComplexType.MULTISELECT,
		data: [],
		filterType: FilterType.IN
	};
	private readonly lastLoginDate: EntityField = {
		id: 4,
		name: 'columns.lastLogin',
		variable: 'lastLoginDate',
		required: true,
		type: InputComplexType.DATE_RANGE,
		readonly: true,
		filterType: FilterType.BETWEEN
	};

	private readonly headers: EntityField[] = [
		this.email,
		this.isActive,
		this.role,
		this.partner,
		this.lastLoginDate
	];


	private readonly ngUnsubscribe = new Subject<void>();

	constructor(
		private readonly enums: EnumsMapperService
	) {
	}

	ngOnDestroy(): void {
		this.loading$.complete();
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}

	create(): Observable<void> {
		return of(undefined);
	}

	delete(): Observable<void> {
		return of(undefined);
	}

	read(): Observable<ReadResponse<EntityField[]>> {
		return of({
			Success: true,
			Total: this.headers.length,
			Data: this.headers
		});
	}

	update(): Observable<void> {
		return of(undefined);
	}
}

