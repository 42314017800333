<alaris-card>
	@if (tRows.length > 0 || tRows.length === 0 && (isFiltersApplied || eventsFilter.value)) {
		<div class="table-management">
			<div class="left">
				<span class="total" data-testid="total">{{ totalTitle | translate }}: {{ total }}</span>
				@if (!this.isOwner.is) {
					<alaris-tabs-button [formControl]="eventsFilter" [tabs]="tabs" data-testid="eventsTypeTabs">
					</alaris-tabs-button>
				}
				<button (click)="editTableFilter()" alaris-button bType="outline" data-testid="editFilterBtn" size="lg">
					<alaris-icon class="in-outline" name="icon-filter-vertical"></alaris-icon>
					{{ enableButtons.filters.title | translate }}
					@if (isFiltersApplied) {
						<div class="indicator"></div>
					}
				</button>
			</div>
			<div class="right">
				<button (click)="refresh()" alaris-button bType="outline" data-testid="refreshBtn" size="lg">
					<alaris-icon name="icon-refresh-clock-wise"></alaris-icon>
					{{ 'actions.refreshPage' | translate }}
				</button>
			</div>
		</div>
	}
	<div [alarisLoading]="loading$" class="content">
		@if (tRows.length === 0 && (!isFiltersApplied && !eventsFilter.value)) {
			<app-empty-table [type]="isOwner.is ? 'sender-events-owner' : 'sender-events-reseller'">
			</app-empty-table>
		} @else {
			<alaris-table (filterChange)="_applyFilter($event)"
						  (sortingChange)="_applySorting($event)"
						  [colSpan]="false"
						  [ctxActions]="ctxActions"
						  [dblClickRowAction]="openDetailsPanel.bind(this)"
						  [dropDownRowActions]="dropDownRowActions"
						  [expandable]="true"
						  [expandedRowTemplate]="expandedRowTemplate"
						  [filters]="filters"
						  [sorting]="sorting"
						  [tHeaders]="tHeaders"
						  [tRows]="tRows"
						  [templates]="{name, status, trafficType, partner, documents, lastUpdated}"
						  alarisOverflowFader>
			</alaris-table>
		}
	</div>
	<div class="footer">
		<alaris-pagination (page)="_pageChange($event)"
						   [activePage]="activePage"
						   [hidden]="total === 0"
						   [pageSizeOptions]="pageSizeOptions"
						   [pageSize]="pageSize"
						   [total]="total"
						   class="pagination">
		</alaris-pagination>
	</div>
</alaris-card>

<ng-template #name [alarisCellCtx]="tRows[0]" let-data="data" let-key="key">
	<div class="senderId-column">
		@if (data.status === EventStatus.IN_PROGRESS) {
			<alaris-spinner class="alaris-icon" size="sm"></alaris-spinner>
		} @else {
			<alaris-icon [name]="cu.icon(data.sender.trafficType)"></alaris-icon>
		}
		{{ data.sender.name }}
		@if (data.history.length) {
			<div class="arrow">
				<alaris-icon [attr.data-testid]="'expandRowBtn/' + data.id"
							 aria-expanded="true" class="pointer" name="icon-caret-right">
				</alaris-icon>
			</div>
		}
	</div>
</ng-template>

<ng-template #status [alarisCellCtx]="tRows[0]" let-data="data" let-key="key">
	<div class="status-column">
		<div [style.background]="eventsService.getStatusColor(data.status)" class="dot"></div>
		{{ 'enums.' + data.status | translate }}
	</div>
</ng-template>

<ng-template #trafficType [alarisCellCtx]="tRows[0]" let-data="data" let-key="key">
	{{ cu.name(data.sender.trafficType) | translate }}
</ng-template>

<ng-template #partner [alarisCellCtx]="tRows[0]" let-data="data" let-key="key">
	@if (data.sender.partner === profileId) {
		<alaris-tag [rounded]="true" class="no-wrap" size="lg">{{ 'senders.myEvent' | translate }}</alaris-tag>
	} @else {
		{{ (data.sender.partner ?? 0 | partner)?.name | noValue }}
	}
</ng-template>

<ng-template #documents [alarisCellCtx]="tRows[0]" let-data="data" let-key="key">
	<alaris-icon [name]="data.sender.documents?.length ? 'icon-success-_active':'icon-error-_active'"
				 class="documents-column">
	</alaris-icon>
</ng-template>

<ng-template #lastUpdated [alarisCellCtx]="tRows[0]" let-data="data" let-key="key">
	{{ data.lastUpdated | date: 'd MMM y, HH:mm' }}
</ng-template>

<ng-template #expandedRowTemplate [alarisExpandedCellCtx]="tRows[0]" let-element let-extra=extra>
	@for (history of element.history; track history; let last = $last) {
		<tr [class.last]="last"
			class="expanded-row">
			<td></td>
			<td class="status-column">
				<div [style.background]="eventsService.getStatusColor(history.status)" class="dot"></div>
				{{ 'enums.' + history.status | translate }}
			</td>
			<td></td>
			<td></td>
			<td></td>
			<td>{{ history.lastUpdated | date: 'd MMM y, HH:mm' }}</td>
		</tr>
	}

</ng-template>
