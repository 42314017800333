<div [alarisLoading]="paymentSystemsService.loading$">
	<div class="edit-panel-header">
		<alaris-icon name="icon-payment"></alaris-icon>
		{{
		(([AP_PERMISSIONS.PAYMENTS_SYSTEMS_E] | alarisPBAC)
			? (paymentSystem.id ? 'payment-systems.editEntry' : 'payment-systems.createEntry')
			: 'payment-systems.details') | translate
		}}
	</div>
	<div alarisFade="vertical" class="form-group edit-panel-content" [formGroup]="paymentSystemForm">
		<span class="title-note">{{'payment-systems.connectYourPaymentSystem' | translate}}</span>
		<alaris-input [label]="'fc.name' | translate"
					  [placeholder]="'fc.name' | translate"
					  data-testid="nameInput" formControlName="name">
		</alaris-input>
		<alaris-select2 [label]="'fc.type' | translate"
						[placeholder]="'fc.type' | translate"
						data-testid="typeSelect" formControlName="type">
			<alaris-options2 *ngFor="let opt of availablePaySystem"
							 [attr.data-testid]="'typeOption/' + opt" [value]="opt">
				{{ opt }}
			</alaris-options2>
		</alaris-select2>
		<ng-container *ngIf="paymentSystemForm.get('type')?.value as paymentSystem" formGroupName="settings">
			<ng-container *ngIf="paymentSystem === PaymentSystemName.PAYPAL">
				<alaris-input [label]="'fc.clientID' | translate"
							  [placeholder]="'fc.clientID' | translate"
							  data-testid="clientIdInput" formControlName="clientId">
				</alaris-input>
				<alaris-input [label]="'fc.clientSecret' | translate"
							  [placeholder]="'fc.clientSecret' | translate"
							  data-testid="clientSecretInput" formControlName="clientSecret">
				</alaris-input>
			</ng-container>
			<ng-container *ngIf="paymentSystem === PaymentSystemName.STRIPE">
				<alaris-input [label]="'fc.publicKey' | translate"
							  [placeholder]="'fc.publicKey' | translate"
							  data-testid="publicKeyInput" formControlName="publicKey">
				</alaris-input>
				<alaris-input [label]="'fc.secretKey' | translate"
							  [placeholder]="'fc.secretKey' | translate"
							  data-testid="secretKeyInput" formControlName="secretKey">
				</alaris-input>
			</ng-container>
			<fieldset class="fee-control">
				<legend>{{'payment-systems.fee' | translate}}</legend>
				<label for="customer">
					{{'payment-systems.customer' | translate}}
					<alaris-radio [value]="true" data-testid="feeRadio/customer"
								  formControlName="fee" id="customer" name="fee">
					</alaris-radio>
				</label>
				<label for="owner">
					{{'payment-systems.owner' | translate}}
					<alaris-radio [value]="false" data-testid="feeRadio/owner"
								  formControlName="fee" id="owner" name="fee">
					</alaris-radio>
				</label>
			</fieldset>
		</ng-container>
		<div *ngIf="paymentSystemForm.get('type')?.value" class="status-control">
			<b>{{'gl.status'| translate}}</b>
			<alaris-toggle data-testid="activeToggle" formControlName="isActive">
				<span [class.inactive]="!paymentSystemForm.get('isActive')?.value">
					{{'gl.active' | translate}}
				</span>
			</alaris-toggle>
		</div>
	</div>
	<div class="edit-panel-footer">
		<button (click)="save()"
				*alarisPBAC="[AP_PERMISSIONS.PAYMENTS_SYSTEMS_E]"
				[disabled]="paymentSystemForm.invalid || !paymentSystemForm.dirty"
				alaris-button bType="primary"
				data-testid="saveBtn"
				size="lg" type="button">
			<alaris-icon class="in-primary" name="icon-success"></alaris-icon>
			{{(paymentSystem.id ? 'actions.save' : 'actions.create') | translate}}
		</button>
		<button (click)="close()" alaris-button bType="outline" data-testid="closeBtn" size="lg" type="button">
			{{(([AP_PERMISSIONS.PAYMENTS_SYSTEMS_E] | alarisPBAC) ? 'actions.cancel' : 'actions.close') | translate }}
		</button>
	</div>
</div>


