<alaris-dialog [closeAction]="close.bind(this)"
			   [confirmAction]="confirm.bind(this)"
			   [loading$]="loading$"
			   [disabled]="disabled">
	<h3 slot="header" [ngSwitch]="data.type">
		<ng-container *ngSwitchCase="'Delete'">{{ "subscriptions.deleteEntry" | translate }}</ng-container>
		<ng-container *ngSwitchCase="'Unlink'">{{ "subscriptions.unlink" | translate }}</ng-container>
	</h3>
	<div slot="content" class="content">
		<div [ngSwitch]="data.type">
			<ng-container *ngSwitchCase="'Delete'">
				{{ 'subscriptions.deleteEntryNote' | translate: {name: subscription.name} }}
			</ng-container>
			<ng-container *ngSwitchCase="'Unlink'">
				{{ 'subscriptions.unlinkEntryNote' | translate : {name: partner?.name ?? ''} }}
				<p>{{ "subscriptions.leaveComment" | translate }}</p>
				<alaris-text-area [formControl]="comment" data-testid="commentTextarea">
					{{ 'fc.comment' | translate}}
				</alaris-text-area>
			</ng-container>
		</div>
	</div>
</alaris-dialog>

