import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';

import {
	AlarisDialogService,
	AlarisEditPanelService,
	AlarisLanguageService,
	EditPanelInputData
} from '@campaign-portal/components-library';
import { SubscriptionGroup } from '@campaign-portal/namespace/entities/subscription-groups/specs';
import { exist, Id } from '@campaign-portal/namespace/common/id';

import { CanDeactivateComponent } from '@helpers/can-deactivate/can-deactivate.component';
import { CanDeactivateGuardService } from '@helpers/can-deactivate/can-deactivate.guard';
import { SubscriptionGroupsService } from '../subscription-groups.service';
import { SubscriptionsSelection } from '../subscriptions-picker/subscriptions-picker.component';
import { LostSubscriptionGroupDataDialogComponent } from './lost-subscription-group-data-dialog/lost-subscription-group-data-dialog.component';
import { AP_PERMISSIONS } from '@helpers/types/permissions';
import {
	SubscriptionGroupDialogComponent,
	SubscriptionGroupDialogType
} from '../subscription-group-dialog/subscription-group-dialog.component';

interface SubscriptionGroupControls {
	name: FormControl<string>;
	description: FormControl<string>;
	packs: FormControl<Id<exist>[]>;
	plans: FormControl<Id<exist>[]>;
}

@Component({
	selector: 'app-edit-subscription-group',
	templateUrl: './edit-subscription-group.component.html',
	styleUrl: './edit-subscription-group.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditSubscriptionGroupComponent extends CanDeactivateComponent implements OnInit, OnDestroy {
	form!: FormGroup<SubscriptionGroupControls>;
	readonly group: SubscriptionGroup;

	readonly AP_PERMISSIONS = AP_PERMISSIONS;
	readonly allowedDeactivation = new BehaviorSubject<boolean>(true);
	readonly _maxNameLength = 40;
	readonly errors = [
		{
			key: 'maxlength',
			value: this.lService.translate('errors.maxLength', { amount: `${this._maxNameLength}` })
		}
	];
	protected readonly ngUnsubscribe = new Subject<void>();

	constructor(
		readonly subGroupsService: SubscriptionGroupsService,
		@Inject(EditPanelInputData) private readonly inputData: EditPanelInputData,
		private readonly editPanel: AlarisEditPanelService,
		private readonly guard: CanDeactivateGuardService,
		private readonly router: Router,
		private readonly lService: AlarisLanguageService,
		private readonly dialog: AlarisDialogService
	) {
		super();
		this.addEditPanelGuard(editPanel, this.guard, LostSubscriptionGroupDataDialogComponent);
		this.group = this.inputData.group as SubscriptionGroup;
	}

	private get cantBeClosed(): boolean {
		return !this.allowedDeactivation.value;
	}

	ngOnInit(): void {
		this.form = new FormGroup({
			name: new FormControl<string>(this.group.name, {
				nonNullable: true,
				validators: [Validators.required, Validators.maxLength(this._maxNameLength)]
			}),
			description: new FormControl<string>(this.group.description ?? '', { nonNullable: true }),
			packs: new FormControl<Id<exist>[]>([...this.group.packs], { nonNullable: true }),
			plans: new FormControl<Id<exist>[]>([...this.group.plans], { nonNullable: true })
		});

		this.form.valueChanges
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(() => {
				this.allowedDeactivation.next(this.form.invalid);
			});
	}

	packsPlansUpdate(selection: SubscriptionsSelection): void {
		this.form.patchValue(selection);
		this.form.markAsTouched();
	}

	save(): void {
		if ( this.form.invalid ) {
			return;
		}
		const group: SubscriptionGroup = {
			...this.group,
			...this.form.value
		};

		this.subGroupsService.update(group)
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((resp) => {
				if ( resp.Success ) {
					this.close();
				}
			});
	}

	cancel(): void {
		if ( this.cantBeClosed ) {
			this.editPanel.closingComponent$.emit(true);
		} else {
			this.close();
		}
	}

	close(): void {
		this.allowedDeactivation.next(true);
		this.editPanel.close();
	}

	ngOnDestroy(): void {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}

	goToSubscriptions(): void {
		if ( !this.cantBeClosed ) {
			this.close();
			this.router.navigate(['subscriptions', 'subscription']).then();
			return;
		}

		const group: SubscriptionGroup = {
			...this.group,
			...this.form.value
		};
		this.openSpecificDialog('GoToSubscription', group);
	}

	private openSpecificDialog(
		type: SubscriptionGroupDialogType,
		group: SubscriptionGroup
	): void {
		this.dialog.open(SubscriptionGroupDialogComponent, {
			data: { group, type },
			autoFocus: false
		}).closed
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((result) => {
				if ( type === 'GoToSubscription' && result ) {
					this.close();
					this.router.navigate(['subscriptions', 'subscription']).then();
				}
			});
	}
}
