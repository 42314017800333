<div [alarisLoading]="portalsService.loading$" class="content">
	<ng-container #scrollable [formGroup]="portalForm">
		<alaris-card [size]="'auto'">
			<div class="title">{{ 'gl.general' | translate }}</div>
			<div class="content half-width">
				<alaris-input [label]="(type === 'ADMIN_PORTAL' ? 'fc.panelName' : 'fc.portalName') | translate"
							  [placeholder]="( type ==='ADMIN_PORTAL' ? 'fc.panelName' : 'fc.portalName') | translate"
							  data-testid="nameInput" formControlName="name">
				</alaris-input>
				<alaris-input [errors]="errors"
							  [label]="(type === 'ADMIN_PORTAL' ?
							  		'fc.panelDomain' : 'fc.portalDomain') | translate"
							  [placeholder]="(type === 'ADMIN_PORTAL' ?
							  		'fc.panelDomain' : 'fc.portalDomain') | translate"
							  data-testid="hostInput" formControlName="host">
				</alaris-input>

				<div>
					<span class="subTitle">{{ 'portals.supportEmail' | translate }}</span>
					<alaris-icon [alarisTooltip]="'portals.supportEmailNote' | translate" class="tooltip"
								 name="icon-info">
					</alaris-icon>
				</div>
				<alaris-input [label]="'portals.supportEmail' | translate"
							  [placeholder]="'portals.supportEmail' | translate"
							  data-testid="supportEmailInput" formControlName="supportEmail">
				</alaris-input>

				<span class="subTitle">{{ 'portals.sslCertificate' | translate }}</span>
				<alaris-document-loader (fileListChanges)="update('sslSettings.certFile', $event)"
										[exportInternally]="true"
										[files]="portalForm.get('sslSettings.certFile')?.value ?
											[portalForm.get('sslSettings.certFile')?.value] : []"
										[readonly]="!([AP_PERMISSIONS.PORTALS_E] | alarisPBAC)"
										[required]="true"
										data-testid="certificateLoader" fileTypes=".crt,.pem"
										purpose="portals.certificate">
				</alaris-document-loader>
				<alaris-document-loader (fileListChanges)="update('sslSettings.privateKeyFile', $event)"
										[exportInternally]="true"
										[files]="portalForm.get('sslSettings.privateKeyFile')?.value ?
											[portalForm.get('sslSettings.privateKeyFile')?.value] : []"
										[readonly]="!([AP_PERMISSIONS.PORTALS_E] | alarisPBAC)"
										[required]="true"
										data-testid="privateKeyLoader" fileTypes=".key"
										purpose="portals.privateKey">
				</alaris-document-loader>
				<div>
					<span class="subTitle">{{ 'profile.termsOfService' | translate }}</span>
					<alaris-icon [alarisTooltip]="'portals.termsTooltip' | translate" class="tooltip" name="icon-info">
					</alaris-icon>
				</div>
				<alaris-document-loader (fileListChanges)="update('termsUrl', $event)"
										[exportInternally]="true"
										[files]="portalForm.get('termsUrl')?.value ?
											[portalForm.get('termsUrl')?.value] : []"
										[readonly]="!([AP_PERMISSIONS.PORTALS_E] | alarisPBAC)"
										data-testid="termsOfServiceLoader" fileTypes=".pdf,.html"
										purpose="profile.termsOfService">
				</alaris-document-loader>
				<div>
					<span class="subTitle">{{ 'portals.privacy' | translate }}</span>
					<alaris-icon [alarisTooltip]="'portals.termsTooltip' | translate" class="tooltip" name="icon-info">
					</alaris-icon>
				</div>
				<alaris-document-loader (fileListChanges)="update('privacyUrl', $event)"
										[exportInternally]="true"
										[files]="portalForm.get('privacyUrl')?.value ?
											[portalForm.get('privacyUrl')?.value] : []"
										[readonly]="!([AP_PERMISSIONS.PORTALS_E] | alarisPBAC)"
										data-testid="privacyLoader" fileTypes=".pdf,.html"
										purpose="portals.privacy">
				</alaris-document-loader>
			</div>
		</alaris-card>
		<alaris-card [size]="'auto'" formGroupName="customSettings">
			<div class="title">{{ 'gl.customization' | translate }}</div>
			<div class="content">
				<span class="subTitle">{{ 'portals.customLogo' | translate }}</span>
				<span class="note">{{ 'portals.customLogoNote' | translate }}</span>
				<div class="in-row">
					<div>
						<span>{{ 'portals.lightLogo' | translate }}</span>
						<p></p>
						<alaris-image-loader (fileListChanges)="update('customSettings.logoLight', $event)"
											 [preview]="url('logoLight')"
											 [readonly]="!([AP_PERMISSIONS.PORTALS_E] | alarisPBAC)"
											 data-testid="lightLogoLoader" fileTypes=".png">
							{{ 'gl.uploadFile' | translate }}
						</alaris-image-loader>
					</div>
					<div>
						<span>{{ 'portals.darkLogo' | translate }}</span>
						<p></p>
						<alaris-image-loader (fileListChanges)="update('customSettings.logoDark', $event)"
											 [preview]="url('logoDark')"
											 [readonly]="!([AP_PERMISSIONS.PORTALS_E] | alarisPBAC)"
											 data-testid="darkLogoLoader" fileTypes=".png"
											 mode="dark">
							{{ 'gl.uploadFile' | translate }}
						</alaris-image-loader>
					</div>
				</div>
				<hr class="divider">
				<span class="subTitle">{{ 'portals.favicon' | translate }}</span>
				<span class="note">{{ 'portals.faviconNote' | translate }}</span>
				<alaris-image-loader (fileListChanges)="update('customSettings.favicon', $event)"
									 [preview]="url('favicon')"
									 [readonly]="!([AP_PERMISSIONS.PORTALS_E] | alarisPBAC)"
									 data-testid="faviconLoader" fileTypes=".ico"
									 size="md">
					<alaris-icon name="icon-image-square"></alaris-icon>
				</alaris-image-loader>
				<hr class="divider">
				<span class="subTitle">{{ 'portals.brandColor' | translate }}</span>
				<span class="note">{{ 'portals.brandColorNote' | translate }}</span>
				<app-color-select [colors]="colors"
								  data-testid="colorSelect" formControlName="theme">
				</app-color-select>
				<hr class="divider">
				<span class="subTitle">{{ 'portals.theme' | translate }}</span>
				<span class="note">
					{{ (type === 'ADMIN_PORTAL' ? 'portals.themeNotePanel' : 'portals.themeNotePortal') | translate }}
				</span>
				<div class="in-row icon-portal">
					<div *ngFor="let theme of ThemeTypes; let last=last">
						<div (click)="selectTheme(theme)"
							 [attr.data-testid]="'selectTheme/' + theme"
							 [class.bordered]="portalForm.get('customSettings.themeType')?.value === theme"
							 [class.disabled]="portalForm.get('customSettings.themeType')?.disabled"
							 [class.theme]="last"
							 class="portal">
							<alaris-icon name="icon-themed-portal"></alaris-icon>
						</div>
						{{ 'portals.' + theme | translate }}
					</div>
				</div>
			</div>
		</alaris-card>
		<alaris-card *ngIf="type === PortalType.CAMPAIGN_PORTAL" [size]="'auto'" formGroupName="campaignPortalDefaults">
			<div class="title">{{ 'portals.settings' | translate }}</div>
			<div class="content">
				<div class="in-row stretch align-start">
					<alaris-select2 [displayWith]="displayCreditType"
									[label]="'fc.credit' | translate"
									[placeholder]="'fc.credit' | translate"
									data-testid="creditTypeSelect" formControlName="creditType">
						<alaris-options2 *ngFor="let opt of CreditTypes"
										 [attr.data-testid]="'creditTypeOption/' + opt" [value]="opt">
							{{ 'enums.' + opt | translate }}
						</alaris-options2>
					</alaris-select2>
					<alaris-input-number
						*ngIf="portalForm.get('campaignPortalDefaults.creditType')?.value === CreditType.POSTPAID"
						[allowNegativeNumbers]="false"
						[errors]="errors"
						[label]="'fc.creditLimit' | translate"
						[placeholder]="'fc.creditLimit' | translate"
						data-testid="creditLimitInputNumber" formControlName="creditLimit">
					</alaris-input-number>

					<alaris-input-number [allowNegativeNumbers]="false"
										 [label]="'fc.giftPayment' | translate"
										 [placeholder]="'fc.giftPayment' | translate"
										 data-testid="giftPaymentInputNumber"
										 formControlName="giftPaymentAmount">
					</alaris-input-number>
					<alaris-input-number [allowNegativeNumbers]="false"
										 [label]="'fc.minPayment' | translate"
										 [placeholder]="'fc.minPayment' | translate"
										 data-testid="minimumPaymentInputNumber"
										 formControlName="minimumPaymentAmount">
					</alaris-input-number>
				</div>
				<span class="subTitle">{{ 'portals.settingsNote' | translate }}</span>

				<div class="in-row stretch">
					<alaris-select2 [displayWith]="displayPacks"
									[label]="'fc.selectPack' |translate"
									[placeholder]="'fc.selectPack' |translate"
									[stateAddonEnabled]="{reset: true, state: false}"
									data-testid="defaultPackSelect" formControlName="defaultPackage">
						<alaris-select-filter2 *ngIf="promoPacksList.length > 5"
											   [formControl]="filterPromoPacksControl" data-testid="defaultPackFilter">
						</alaris-select-filter2>
						<alaris-options2 *ngFor="let opt of filterPromoPacksList"
										 [attr.data-testid]="'defaultPackOption/' + opt.name" [value]="opt.id">
							{{ opt.name }}
						</alaris-options2>
					</alaris-select2>
					<alaris-select2 [displayWith]="displayPlans"
									[label]="'fc.selectPlan' | translate"
									[placeholder]="'fc.selectPlan' | translate"
									[stateAddonEnabled]="{reset: true, state: false}"
									data-testid="defaultPlanSelect" formControlName="defaultPlan">
						<alaris-select-filter2 *ngIf="subService.plansList.length > 5"
											   [formControl]="filterPlansControl" data-testid="defaultPlanFilter">
						</alaris-select-filter2>
						<alaris-options2 *ngFor="let opt of filterPlansList"
										 [attr.data-testid]="'defaultPlanOption/' + opt.name" [value]="opt.id">
							{{ opt.name }}
						</alaris-options2>
					</alaris-select2>
				</div>
				<alaris-toggle data-testid="allowRegistrationToggle" direction="right"
							   formControlName="allowRegistration">
					{{ 'portals.allowRegistration' | translate }}
				</alaris-toggle>
			</div>
		</alaris-card>
		<alaris-card *ngIf="portal?.id && ([AP_PERMISSIONS.PORTALS_E] | alarisPBAC)" [size]="'auto'">
			<div class="title">
				{{ (type === 'ADMIN_PORTAL' ? 'portals.deletePanel' : 'portals.deletePortal') | translate }}
			</div>
			<div class="content">
				{{ (type === 'ADMIN_PORTAL' ? 'portals.deletePanelNote' : 'portals.deletePortalNote') | translate }}
				<button (click)="delete()" alaris-button bType="outline" data-testid="deleteBtn" size="lg"
						style="width: 13rem">
					{{ (type === 'ADMIN_PORTAL' ? 'portals.deletePanel' : 'portals.deletePortal') | translate }}
				</button>
			</div>
		</alaris-card>
	</ng-container>
</div>


<div *alarisPBAC="[AP_PERMISSIONS.PORTALS_E]" class="edit-panel-footer">
	<button (click)="save()"
			[disabled]="portalForm.invalid || !portalForm.dirty || !!(portalsService.loading$ | async)"
			alaris-button bType="primary" data-testid="saveBtn"
			size="lg" type="button">
		<alaris-icon class="in-primary" name="icon-success"></alaris-icon>
		{{ (portal?.id ? 'actions.save' : 'actions.create') | translate }}
	</button>
</div>
