import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';

import { AlarisBalanceService, AlarisEditPanelService, EditPanelInputData } from '@campaign-portal/components-library';

import { Id } from '@campaign-portal/namespace/common/id';
import { Payments } from '@campaign-portal/namespace/entities/payments/specs';
import { PaymentStatus } from '@campaign-portal/namespace/common/enums';

import { PaymentsService } from '../payments.service';
import { CanDeactivateComponent } from '@helpers/can-deactivate/can-deactivate.component';
import { CanDeactivateGuardService } from '@helpers/can-deactivate/can-deactivate.guard';
import { AP_PERMISSIONS } from '@helpers/types/permissions';

@Component({
	selector: 'app-update-payment',
	templateUrl: './update-payment.component.html',
	styleUrls: ['./update-payment.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class UpdatePaymentComponent extends CanDeactivateComponent implements OnInit, OnDestroy {
	readonly PaymentStatus = PaymentStatus;
	readonly AP_PERMISSIONS = AP_PERMISSIONS;
	readonly payment!: Payments;
	paymentForm!: FormGroup;

	readonly allowedDeactivation = new BehaviorSubject<boolean>(true);

	protected readonly ngUnsubscribe: Subject<void> = new Subject<void>();

	constructor(
		@Inject(EditPanelInputData) private readonly inputData: EditPanelInputData,
		public readonly bs: AlarisBalanceService,
		public readonly paymentsService: PaymentsService,
		private readonly editPanel: AlarisEditPanelService,
		private readonly guard: CanDeactivateGuardService
	) {
		super();
		this.addEditPanelGuard(editPanel, this.guard);
		this.payment = this.inputData.payment as Payments;
	}

	ngOnInit(): void {
		this.paymentForm = new FormGroup({
			amount: new FormControl(this.payment.amount, Validators.required),
			partnerId: new FormControl<Id>(this.payment.partnerId, Validators.required),
			comment: new FormControl<string>(this.payment.comment || ''),
			status: new FormControl<PaymentStatus>(this.payment.status),
			cancelledDate: new FormControl<string>(this.payment.cancelledDate || '')
		});
	}

	close(): void {
		this.editPanel.close(true);
	}

	save(): void {
		this.allowedDeactivation.next(false);
		this.paymentsService.update({ ...this.payment, ...this.paymentForm.value })
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((resp) => {
				this.allowedDeactivation.next(true);
				if ( resp.Success ) {
					this.close();
				}
			});
	}

	changePaymentStatus(): void {
		if ( !this.payment.id ) {
			return;
		}
		this.paymentsService.cancel(this.payment.id)
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((resp) => {
				if ( resp.Success ) {
					this.paymentForm.get('status')?.setValue(PaymentStatus.CANCELLED);
					this.paymentForm.get('cancelledDate')?.setValue(new Date().toISOString());
					this.close();
				}
			});
	}

	ngOnDestroy(): void {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}
}
