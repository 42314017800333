import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { forkJoin, map, Observable, Subject } from 'rxjs';

import {
	AlarisBalanceService,
	AlarisEditPanelService,
	AlarisProfileService,
	ChannelUtilsService,
	EditPanelInputData,
	LocalTableUtils,
	TableEntityField
} from '@campaign-portal/components-library';

import { EntityField } from '@campaign-portal/namespace/common/entityField';
import { exist } from '@campaign-portal/namespace/common/id';
import {
	TransactionHistory,
	TransactionHistoryCampaignDetail
} from '@campaign-portal/namespace/entities/transaction-history/specs';

import { TransactionDetailsFieldsService } from './transaction-details-fields.service';
import { TransactionsService } from '../transactions.service';

@Component({
	selector: 'app-transaction-details',
	templateUrl: './transaction-details.component.html',
	styleUrls: ['./transaction-details.component.scss'],
	providers: [TransactionDetailsFieldsService],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TransactionDetailsComponent implements OnInit, OnDestroy {
	readonly transaction!: TransactionHistory<exist>;
	tableData$!: Observable<[TransactionHistoryCampaignDetail[], TableEntityField[]]>;

	readonly tHeaders: EntityField[] = [];

	private readonly ngUnsubscribe: Subject<void> = new Subject<void>();

	constructor(
		@Inject(EditPanelInputData) private readonly inputData: EditPanelInputData,
		public readonly bs: AlarisBalanceService,
		public readonly cu: ChannelUtilsService,
		public readonly fieldsService: TransactionDetailsFieldsService,
		public readonly transactionHistoryService: TransactionsService,
		public readonly profile: AlarisProfileService,
		private readonly editPanel: AlarisEditPanelService
	) {
		this.transaction = this.inputData.transactionHistory as TransactionHistory<exist>;
	}

	ngOnInit(): void {
		this.tableData$ = forkJoin(
			[this.transactionHistoryService.details(this.transaction), this.fieldsService.read()]
		)
			.pipe(map(([a, b]) => {
				return [a.Data, LocalTableUtils.toTableFields(b.Data)];
			}));
	}

	export(): void {
		this.editPanel.close();
	}

	ngOnDestroy(): void {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}
}
