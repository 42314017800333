import { ChangeDetectionStrategy, Component, HostBinding, HostListener, Input } from '@angular/core';
import { ConnectedPosition } from '@angular/cdk/overlay';

import { AlarisBalanceService, ChannelUtilsService, RowActionSimple } from '@campaign-portal/components-library';

import { Package, Subscription } from '@campaign-portal/namespace/entities/subscriptions/specs';
import { exist } from '@campaign-portal/namespace/common/id';

@Component({
	selector: 'app-subscription-card',
	templateUrl: './subscription-card.component.html',
	styleUrls: ['./subscription-card.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubscriptionCardComponent {

	@Input() actions: RowActionSimple<Subscription<exist>>[] = [];
	@Input() subscription!: Subscription<exist>;

	isActive = false;
	readonly position: ConnectedPosition[] = [
		{
			originX: 'end',
			originY: 'bottom',
			overlayX: 'end',
			overlayY: 'top',
			offsetY: 0,
			offsetX: 0
		}
	];

	constructor(
		public readonly chUtil: ChannelUtilsService,
		public readonly bs: AlarisBalanceService
	) {
	}

	@HostBinding('class.opacity')
	get state(): boolean {
		return !this.subscription?.isActive ?? false;
	}

	get price(): number {
		return (this.subscription as Package<exist>)?.packSettings?.packPrice;
	}

	get isPromo(): boolean {
		return (this.subscription as Package<exist>)?.packSettings?.isPromo;
	}

	@HostListener('click')
	edit(): void {
		this.actions[0].action(this.subscription);
	}

}
