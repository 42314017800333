import { Injectable } from '@angular/core';
import { AlarisTableStateService, filterWildcardData, LocalTableService } from '@campaign-portal/components-library';
import { SubscriptionsService } from './subscriptions.service';
import { ReadResponse } from '@campaign-portal/namespace/common/implementations';
import { Package, Subscription } from '@campaign-portal/namespace/entities/subscriptions/specs';
import { exist } from '@campaign-portal/namespace/common/id';
import { FilterInterval, RPCRequestParams } from '@campaign-portal/namespace/common/rpc.params';
import { ValueObject } from '@campaign-portal/namespace/common/valueObject';
import { IdObject } from '@campaign-portal/namespace/common/idObject';
import { SubscriptionType } from '@campaign-portal/namespace/common/enums';
import { SubscriptionGroup } from '@campaign-portal/namespace/entities/subscription-groups/specs';

@Injectable()
export class SubscriptionsTableService extends LocalTableService<Subscription<exist>> {

	override readonly applyFilters = SubscriptionsFilter.applyFilters;
	readonly savedFilters = this.subscriptionsService.savedFilters;

	constructor(
		private readonly subscriptionsService: SubscriptionsService,
		override readonly stateService: AlarisTableStateService
	) {
		super(subscriptionsService, stateService);
	}

	override applySorting(
		result: ReadResponse<Subscription<exist>[]>,
		_params: RPCRequestParams
	): ReadResponse<Subscription<exist>[]> {
		const data = result.Data.sort((a, b) => {
			return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
		});
		return { Success: result.Success, Data: data, Total: data.length };
	}

}

export class SubscriptionsFilter {
	static applyFilters(
		result: ReadResponse<Subscription<exist>[]>,
		params: RPCRequestParams
	): ReadResponse<Subscription<exist>[]> {
		const filters = params.Filters;
		if ( filters === undefined ) {
			return result;
		} else {
			let data: Subscription<exist>[] = result.Data;
			filters.forEach((filter) => {
				switch (filter.Field) {
					case 'name':
						data = filterWildcardData(filter.Value as string, data, 'name');
						break;
					case 'isActive':
						if ( filter.Value !== undefined || filter.Value !== null ) {
							data = data.filter(item => item.isActive === filter.Value);
						}
						break;
					case 'trafficType':
						if ( filter.Value ) {
							data = data.filter((item) => {
								return (filter.Value as ValueObject[])
									.map(v => v.value)
									.includes(item.trafficType);
							});
						}
						break;
					case 'currencyId':
						if ( filter.Value ) {
							data = data.filter((item) => {
								return (filter.Value as IdObject[])
									.map(i => i.id)
									.includes(item.currencyId!);
							});
						}
						break;
					case 'contractCompanies':
						if ( filter.Value ) {
							const ccIds = (filter.Value as IdObject<exist>[]).map(i => i.id);
							data = data.filter((item) => {
								return !!ccIds.find(id => item.contractCompanies?.includes(id));
							});
						}
						break;
					case 'partners':
						if ( filter.Value ) {
							const partnersIds = (filter.Value as IdObject<exist>[]).map(i => i.id);
							data = data.filter((item) => {
								return !!partnersIds.find(id => item.partners?.map(i => i.id)?.includes(id));
							});
						}
						break;
					case 'billingMode':
						if ( filter.Value ) {
							data = data.filter(item => (filter.Value as string[]).includes(item.billingMode));
						}
						break;
					case 'type':
						if ( filter.Value ) {
							data = data.filter(item => item.type === filter.Value);
						}
						break;
					case 'personalFor':
						if ( filter.Value ) {
							const partnersIds = (filter.Value as IdObject<exist>[]).map(i => i.id);
							data = data.filter((item) => {
								return !!partnersIds.find(id => item.personalFor?.includes(id));
							});
						}
						break;
					case 'isPromo':
						if ( filter.Value !== undefined || filter.Value !== null ) {
							data = data.filter((item) => {
								return item.type === SubscriptionType.PACK
									&& (item as Package<exist>).packSettings.isPromo === filter.Value;
							});
						}
						break;
					case 'groupId':
						if ( Array.isArray(filter.Value) ) {
							data = data.filter((item) => {
								return (filter.Value as SubscriptionGroup<exist>[])
									.map(group => group.id)
									.includes(item.groupId ?? -1);
							});
						}
						break;
					case 'messagesTotal':
						if ( filter.Value ) {
							const filterFn = (sub: Subscription): boolean => {
								const messages = (sub as Package).messagesTotal || 0;
								const fV = filter.Value as FilterInterval<number | null>;
								const min = fV.Start !== null ? messages >= fV.Start : true;
								const max = fV.End !== null ? messages <= fV.End : true;
								return min && max;
							};
							data = data.filter(filterFn);
						}
						break;
					case 'packSettings':
						if ( filter.Value ) {
							const filterFn = (sub: Subscription): boolean => {
								const price = (sub as Package).packSettings?.packPrice || 0;
								const fV = filter.Value as FilterInterval<number | null>;
								const min = fV.Start !== null ? price >= fV.Start : true;
								const max = fV.End !== null ? price <= fV.End : true;
								return min && max;
							};
							data = data.filter(filterFn);
						}
						break;
					default:
						break;
				}
			});
			return { Success: true, Total: data.length, Data: data };
		}
	}
}
