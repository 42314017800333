<div [alarisLoading]="partnersService.loading$">
	<div class="edit-panel-header">
		<alaris-icon name="icon-partner-_glass"></alaris-icon>
		{{ partner.name }}
	</div>
	<div alarisFade="vertical" class="form-group edit-panel-content">
		<fieldset class="card">
			<h5>{{ 'partners.core' | translate }}</h5>
			<div class="grid">
				<b>{{ 'partners.partnerName' | translate }}:</b>
				{{ partner.name }}
				<b>{{ 'partners.contractCompany' | translate }}:</b>
				{{ (partner.ccId | cCompany)?.name }}
				<b>{{ 'partners.status' | translate }}:</b>
				{{ (partner.isActive ? 'gl.active' : 'gl.inactive') | translate }}
				<b>{{ 'partners.reseller' | translate | titlecase }}:</b>
				{{ (partner.isReseller ? 'gl.yes' : 'gl.no') | translate }}
			</div>
		</fieldset>
		<fieldset *alarisPBAC="[AP_PERMISSIONS.PAYMENTS_R]" class="card">
			<h5>{{ 'partners.finance' | translate }}</h5>
			<div class="grid">
				<b>{{ 'partners.balance' | translate }}:</b>
				{{ partner.balance | currency: bs.currencyCode }}
				@if (partner.financialSettings.creditType !== CreditType.PREPAID) {
					<b>{{ 'partners.creditLimit' | translate }}:</b>
					{{ partner.financialSettings.creditLimit ?? ('partners.unlimited' | translate) }}
				}
				<b>{{ 'partners.currency' | translate }}</b>
				{{ (partner.financialSettings.currencyId | alarisCurrency)?.name }}
				<b>{{ 'gl.sendAlerts' | translate }}:</b>
				{{ (partner.financialSettings.sendAlerts ? 'gl.yes' : 'gl.no') | translate }}
			</div>
		</fieldset>
		@if (partner.statistics) {
			<fieldset class="card">
				<h5>{{ 'partners.statistics' | translate }}</h5>
				<div class="grid">
					<b>{{ 'partners.campaignLaunched' | translate }}:</b>
					{{ partner.statistics.launched }}
					<b>{{ 'partners.moneyEarned' | translate }}:</b>
					{{ partner.statistics.moneyEarned | currency: bs.currencyCode }}
					<b>{{ 'partners.messagesSent' | translate }}:</b>
					{{ partner.statistics.messagesSent }}
				</div>
			</fieldset>
		}

		<fieldset *alarisPBAC="[AP_PERMISSIONS.SUBSCR_R]" class="card">
			<div class="header-with-action">
				<h5>{{ 'partners.subscriptions' | translate }}</h5>
				@if (showSubscribeBtn) {
					<button (click)="subscribe()"
							alaris-button bType="primary"
							data-testid="saveSubscriptionBtn"
							size="md" type="button">
						<alaris-icon name="icon-save"></alaris-icon>
						{{ 'actions.save' | translate }}
					</button>
				}
			</div>
			<div class="grid">
				<b>{{ 'partners.plans' | translate }}:</b>
				<div (click)="subscriptions()" class="in-row pointer" data-testid="addSubscriptionBtn">
					<alaris-tag *ngIf="subService.plansMap.get(partnersSubscriptions.plans[0])?.name as name">
						{{ name }}
					</alaris-tag>
					@if ((partnersSubscriptions.plans.length - 1) > 0) {
						<alaris-tag [rounded]="true"> + {{ partnersSubscriptions.plans.length - 1 }}</alaris-tag>
					}
					<ng-container *ngTemplateOutlet="link"></ng-container>
				</div>
				@if (!partner.isReseller) {
					<b>{{ 'partners.packs' | translate }}:</b>
					<div (click)="subscriptions()" class="in-row pointer" data-testid="addSubscriptionBtn">
						<alaris-tag *ngIf="subService.packsMap.get(partnersSubscriptions.packs[0])?.name as name">
							{{ name }}
						</alaris-tag>
						@if ((partnersSubscriptions.packs.length - 1) > 0) {
							<alaris-tag [rounded]="true"> + {{ partnersSubscriptions.packs.length - 1 }}</alaris-tag>
						}

						<ng-container *ngTemplateOutlet="link"></ng-container>
					</div>
				}
			</div>
		</fieldset>
	</div>
	<div class="edit-panel-footer">
		<button (click)="close(true)"
				*alarisPBAC="[AP_PERMISSIONS.PARTNERS_E]"
				alaris-button bType="primary"
				data-testid="editBtn"
				size="lg" type="button">
			<alaris-icon name="icon-edit"></alaris-icon>
			{{ 'actions.edit' | translate }}
		</button>
		<button (click)="close()"
				alaris-button bType="outline"
				data-testid="closeBtn"
				size="lg" type="button">
			{{ 'gl.close' | translate }}
		</button>
	</div>
</div>

<ng-template #link>
	<alaris-link data-testid="subscriptionLink">
		{{
			(([AP_PERMISSIONS.PARTNERS_E, AP_PERMISSIONS.SUBSCR_E] | alarisPBAC)
				? 'actions.add'
				: 'actions.details') | translate
		}}
	</alaris-link>
</ng-template>
