<cdk-virtual-scroll-viewport [itemSize]="5" [style.height]="calculateViewPort()">

	<alaris-table [fixFirstColumn]="false"
				  [resizable]="true"
				  [rowActions]="[action]"
				  [tHeaders]="tHeaders"
				  [tRows]="tRows" [templates]="templates">
	</alaris-table>

</cdk-virtual-scroll-viewport>

<ng-template #name let-data="data" let-key="key">
	<div class="name">
		{{ data[key]}}
	</div>
</ng-template>
