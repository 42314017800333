import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';

import {
	AlarisComplexTableComponent,
	AlarisDialogService,
	AlarisEditPanelService,
	AlarisProfileService,
	AlarisTableSettingsService,
	AlarisTableStateService,
	DEFAULT_BUTTONS,
	EditPanelWidth,
	RowActionSimple,
	TableSortIndicator
} from '@campaign-portal/components-library';

import { exist } from '@campaign-portal/namespace/common/id';
import { TableTypes } from '@campaign-portal/namespace/entities/table-settings/specs';
import { MCCMNC } from '@campaign-portal/namespace/entities/mccmnc/specs';
import { SortDirection } from '@campaign-portal/namespace/common/rpc.params';

import { MccmncFieldsService } from './mccmnc.fields.service';
import { EditMccmncComponent } from './edit-mccmnc/edit-mccmnc.component';
import { MccmncDialogComponent, MCCMNCDialogType } from './mccmnc-dialog/mccmnc-dialog.component';
import { MccmncTableService } from './mccmnc.table.service';
import { AP_PERMISSIONS } from '@helpers/types/permissions';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
	selector: 'app-mccmnc',
	templateUrl: './mccmnc.component.html',
	styleUrls: [
		// eslint-disable-next-line max-len
		'../../../../../node_modules/@campaign-portal/components-library/src/assets/templates/table-complex/complex-table.component.scss',
		'./mccmnc.component.scss'],
	providers: [MccmncFieldsService, MccmncTableService],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class MccmncComponent extends AlarisComplexTableComponent<MCCMNC<exist>> {
	readonly AP_PERMISSIONS = AP_PERMISSIONS;
	// tslint:disable-next-line:no-any
	override readonly mainActions: RowActionSimple<any>[] = this.getActions();

	override readonly ctxActions: RowActionSimple<MCCMNC<exist> | MCCMNC<exist>[]>[] = [...this.mainActions].map(a => ({
		...a,
		icon: ''
	}));

	override sorting: TableSortIndicator = new Map()
		.set('country', { enabled: true, value: SortDirection.ASC })
		.set('network', { enabled: true, value: SortDirection.ASC });

	override readonly id = 'mccmnc-table';

	constructor(
		public readonly entityService: MccmncTableService,
		public override readonly fieldsService: MccmncFieldsService,
		public override readonly tableSettingsService: AlarisTableSettingsService,
		public override readonly editPanel: AlarisEditPanelService,
		public override readonly cd: ChangeDetectorRef,
		public override readonly stateService: AlarisTableStateService,
		private readonly profile: AlarisProfileService,
		private readonly dialog: AlarisDialogService
	) {
		super(
			entityService,
			fieldsService,
			tableSettingsService,
			editPanel,
			cd,
			TableTypes.INTERNAL,
			DEFAULT_BUTTONS,
			stateService
		);
		this.entityService.stateID = this.id;
	}

	override refresh(): void {
		this.entityService.firstLoad = true;
		this.load();
	}

	// Row actions
	openEditPanel(mccmnc?: MCCMNC<exist>): void {
		this.editPanel.open(EditMccmncComponent, EditPanelWidth.SM, {
			mccmnc
		})
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe(result => {
				if ( result ) {
					this.refresh();
				}
			});
	}

	copy(mccmnc?: MCCMNC<exist>): void {
		this.editPanel.open(EditMccmncComponent, EditPanelWidth.SM, {
			mccmnc: { ...mccmnc, id: null }
		});
	}

	delete(mccmnc?: MCCMNC<exist>): void {
		this.openSpecificDialog('Delete', mccmnc);
	}

	private openSpecificDialog(
		type: MCCMNCDialogType,
		mccmnc?: MCCMNC<exist>
	): void {
		this.dialog.open(MccmncDialogComponent, {
			data: { mccmnc, type },
			autoFocus: false
		}).closed
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe((result) => {
				if ( result ) {
					this.refresh();
				}
			});
	}

	private getActions(): RowActionSimple<MCCMNC<exist>>[] {
		const details: RowActionSimple<MCCMNC<exist>> = {
			icon: 'icon-password-show',
			label: 'actions.details',
			action: this.openEditPanel.bind(this)
		};
		const edit: RowActionSimple<MCCMNC<exist>> = {
			icon: 'icon-edit',
			label: 'actions.edit',
			action: this.openEditPanel.bind(this)
		};
		const copy: RowActionSimple<MCCMNC<exist>> = {
			icon: 'icon-copy',
			label: 'actions.duplicate',
			action: this.copy.bind(this)
		};
		const _delete: RowActionSimple<MCCMNC<exist>> = {
			icon: 'icon-delete',
			label: 'actions.delete',
			action: this.delete.bind(this),
			highlight: true,
			separator: true
		};
		return this.profile.allowed([AP_PERMISSIONS.REF_BOOK_E])
			? [edit, copy, _delete]
			: [details];
	}
}
