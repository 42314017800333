import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, switchMap, takeUntil } from 'rxjs';

import { FilterType, RPCRequestParams } from '@campaign-portal/namespace/common/rpc.params';
import { ChartUtilsService, SharedTemplatesService } from '@campaign-portal/components-library';

import { HorizontalChartComponent } from '../horizontal-chart/horizontal-chart.component';
import { ChartsService } from '../charts.service';

@Component({
	selector: 'app-messages-by-sender',
	templateUrl: '../horizontal-chart/horizontal-chart.component.html',
	styleUrls: ['../horizontal-chart/horizontal-chart.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class MessageBySenderComponent extends HorizontalChartComponent implements OnInit, OnDestroy {

	override readonly title = 'dashboard.messagesBySender';
	override readonly barColor = 'linear-gradient(#314755, #26a0da)';

	readParams: RPCRequestParams = {};

	private readonly ngUnsubscribe: Subject<void> = new Subject<void>();

	constructor(
		public override readonly cu: ChartUtilsService,
		public override readonly templates: SharedTemplatesService,
		public override readonly chartsService: ChartsService,
		public override readonly cd: ChangeDetectorRef
	) {
		super(cu, templates, chartsService, cd);
	}

	ngOnInit(): void {
		this.range.valueChanges
			.pipe(
				switchMap(range => {
					this.loading$.next(false); // Avoid set more than one portal
					this.readParams = {
						...this.readParams,
						Grouping: {
							CubeType: this.cu.periodCubeTypeReducer(this.periodType.value, false),
							Groups: [], isDetailed: false, isTotal: false
						},
						Filters: [{ Field: 'groupingDate', Type: FilterType.BETWEEN, Value: range }]
					};
					this.loading$.next(true);
					return this.chartsService.messagesBySenderChart(this.readParams);
				}),
				takeUntil(this.ngUnsubscribe))
			.subscribe(({ Data }) => {
				this.updateChartData(Data);
				// rerender
				this.loading$.next(false);
				this.cd.markForCheck();
			});
	}

	ngOnDestroy(): void {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}
}
