import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';

import {
	AlarisDialogService,
	AlarisEditPanelService,
	AlarisProfileService,
	EditPanelWidth,
	RowActionSimple
} from '@campaign-portal/components-library';

import { exist } from '@campaign-portal/namespace/common/id';
import { PaymentSystem } from '@campaign-portal/namespace/entities/payment-systems/specs';

import { PaymentSystemsService } from './payment-systems.service';
import { UpdatePaymentSystemComponent } from './update-payment-system/update-payment-system.component';
import {
	PaymentSystemDialogComponent,
	PaymentSystemsDialogType
} from './payment-system-dialog/payment-system-dialog.component';
import { PaymentSystemEntity } from '@helpers/types/app.classes-interfaces';
import { AP_PERMISSIONS } from '@helpers/types/permissions';

@Component({
	selector: 'app-payment-systems',
	templateUrl: './payment-systems.component.html',
	styleUrls: [
		// eslint-disable-next-line max-len
		'../../../../../node_modules/@campaign-portal/components-library/src/assets/templates/table-complex/complex-table.component.scss',
		'./payment-systems.component.scss'
	],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaymentSystemsComponent implements OnInit, OnDestroy {
	readonly AP_PERMISSIONS = AP_PERMISSIONS;

	readonly actions: RowActionSimple<PaymentSystem<exist>>[] = this.getActions();
	paymentSystems: PaymentSystem<exist>[] = [];
	readonly loading$ = new BehaviorSubject<boolean>(false);

	private readonly ngUnsubscribe: Subject<void> = new Subject<void>();

	constructor(
		public readonly editPanel: AlarisEditPanelService,
		private readonly paymentSystemsService: PaymentSystemsService,
		private readonly profile: AlarisProfileService,
		private readonly dialog: AlarisDialogService
	) {
	}

	ngOnInit(): void {
		this.load();
	}

	load(): void {
		this.loading$.next(true);
		this.paymentSystemsService.read()
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(response => {
				this.paymentSystems = response.Data;
				this.loading$.next(false);
			});
	}

	delete(paymentSystem?: PaymentSystem<exist>): void {
		this.openSpecificDialog('Delete', paymentSystem);
	}

	editPaymentSystem(paymentSystem?: PaymentSystem<exist>): void {
		const entity = paymentSystem ? paymentSystem : new PaymentSystemEntity(null);
		this.editPanel.open(UpdatePaymentSystemComponent, EditPanelWidth.SM, {
			paymentSystem: entity
		})
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(result => {
				if ( result ) {
					this.load();
				}
			});
	}

	ngOnDestroy(): void {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
		this.loading$.complete();
	}

	private openSpecificDialog(
		type: PaymentSystemsDialogType,
		paymentSystem?: PaymentSystem<exist>
	): void {
		this.dialog.open(PaymentSystemDialogComponent, {
			data: { paymentSystem, type },
			autoFocus: false
		}).closed
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((result) => {
				if ( result ) {
					this.load();
				}
			});
	}

	private getActions(): RowActionSimple<PaymentSystem<exist>>[] {
		const edit: RowActionSimple<PaymentSystem<exist>> = {
			icon: '',
			label: 'actions.edit',
			action: this.editPaymentSystem.bind(this)
		};
		const details: RowActionSimple<PaymentSystem<exist>> = {
			...edit,
			label: 'actions.details'
		};
		const _delete: RowActionSimple<PaymentSystem<exist>> = {
			icon: '',
			label: 'actions.delete',
			action: this.delete.bind(this),
			highlight: true,
			separator: true
		};
		return this.profile.allowed([AP_PERMISSIONS.PAYMENTS_SYSTEMS_E])
			? [edit, _delete]
			: [details];
	}
}

