import { Injectable } from '@angular/core';

import {
	AlarisTableStateService,
	filterWildcardFn,
	LocalTableService,
	LocalTableUtils
} from '@campaign-portal/components-library';
import { exist } from '@campaign-portal/namespace/common/id';
import { FilterType, RPCRequestParams } from '@campaign-portal/namespace/common/rpc.params';
import { ReadResponse } from '@campaign-portal/namespace/common/implementations';

import { UsersService } from './users.service';
import { PartnersService } from '../partners/partners.service';
import { User } from '@campaign-portal/namespace/common/user';

@Injectable()
export class UsersTableService extends LocalTableService<User<exist>> {

	constructor(
		service: UsersService,
		stateService: AlarisTableStateService,
		private readonly partnersService: PartnersService
	) {
		super(service, stateService);
	}

	applyFilters(result: ReadResponse<User<exist>[]>, params: RPCRequestParams): ReadResponse<User<exist>[]> {
		const filters = params.Filters;
		if ( filters === undefined ) {
			return result;
		} else {
			let data = result.Data;
			filters.forEach((filter) => {
				switch (filter.Field) {
					case 'email':
						data = data.filter((item) => {
							return filterWildcardFn(item.email, filter.Value as string);
						});
						break;
					case 'isActive':
						data = data.filter((item) => {
							// @ts-ignore todo fix specs
							return item.isActive === filter.Value;
						});
						break;
					case 'role':
						if ( filter.Value ) {
							data = data.filter((item) => {
								// @ts-ignore todo fix specs
								return (filter.Value as string[]).includes(item.role);
							});
						}
						break;
					case 'partnerId':
						if ( filter.Value ) {
							if ( filter.Type === FilterType.EXACT ) {
								data = data.filter(item => item.partnerId === filter.Value);
							} else {
								data = data.filter(item => (filter.Value as exist[]).includes(item.partnerId));
							}
						}
						break;
					case 'lastLoginDate':
						data = (LocalTableUtils
							.filterDateRange(filter, data as unknown as Record<string, string>[]) as unknown as User<exist>[])
							.filter(i => !!i.lastLoginDate);
						break;
					default:
						break;
				}
			});
			return { Success: true, Total: data.length, Data: data };
		}
	}

	applySorting(result: ReadResponse<User<exist>[]>, params: RPCRequestParams): ReadResponse<User<exist>[]> {
		const sorting = params.Sorting?.reverse();
		if ( !sorting ) {
			return result;
		} else {
			let data = result.Data;
			sorting.forEach((sort) => {
				data = data.sort((a, b) => {
					let first: any;
					let second: any;
					switch (sort.Field) {
						case 'isActive':
							first = a.isActive ? 1 : 0;
							second = b.isActive ? 1 : 0;
							break;
						case 'email':
							first = a.email || '';
							second = b.email || '';
							break;
						case 'role':
							first = a.role;
							second = b.role;
							break;
						case 'partnerId':
							first = this.partnersService.map.get(a.partnerId)?.name;
							second = this.partnersService.map.get(b.partnerId)?.name;
							break;
						case 'lastLoginDate':
						default:
							first = (a as Record<string, any>)[sort.Field]
								? new Date((a as Record<string, any>)[sort.Field]) : 0;
							second = (b as Record<string, any>)[sort.Field]
								? new Date((b as Record<string, any>)[sort.Field]) : 0;
							break;
					}

					return LocalTableUtils.sortComparisonFn(sort.Dir, first, second);
				});
			});
			return { Data: data, Total: result.Total, Success: true };
		}
	}

}


