import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { SelectionModel } from '@angular/cdk/collections';
import { BehaviorSubject, map, Observable, Subject, takeUntil, tap } from 'rxjs';

import {
	filterWildcardFn,
	LocalTableUtils,
	TableEntityField,
	TableFiltersIndicator,
	TableSortIndicator
} from '@campaign-portal/components-library';

import { CountryNetworkRef } from '@campaign-portal/namespace/entities/mccmnc/specs';
import { exist } from '@campaign-portal/namespace/common/id';
import { EntityField } from '@campaign-portal/namespace/common/entityField';
import { FilterType, SortDirection } from '@campaign-portal/namespace/common/rpc.params';

import { CountryNetworkFieldsService } from '@helpers/components/country-network/country-network.fields.service';
import { CountryNetworkSelectionService } from '@helpers/components/country-network/country-network-selection.service';
import { ModelType } from '@helpers/types/app.classes-interfaces';
import { CountriesNetworksService } from '@helpers/services/countries-networks.service';

@Component({
	selector: 'app-country-network-table',
	templateUrl: './country-network-table.component.html',
	styleUrls: [
		'../../../../../assets/templates/table.component.scss',
		'./country-network-table.component.scss'
	],
	providers: [CountryNetworkFieldsService],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class CountryNetworkTableComponent implements OnInit, OnDestroy {
	@ViewChild(CdkVirtualScrollViewport, { static: true })
	public readonly viewPort!: CdkVirtualScrollViewport;

	@Input() selectionModel!: SelectionModel<CountryNetworkRef<exist>>;
	@Input() action!: null | ((row: CountryNetworkRef<exist>) => void);
	@Input() type = ModelType.SELECTED;

	readonly ModelType = ModelType;
	readonly SortDirection = SortDirection;

	tHeaders$!: Observable<TableEntityField[]>;
	tRows: CountryNetworkRef<exist>[] = [];
	sorting: TableSortIndicator = new Map();
	filters: TableFiltersIndicator = new Map();
	isFiltersApplied = false;

	readonly loading$ = new BehaviorSubject<boolean>(false);

	private readonly ngUnsubscribe: Subject<void> = new Subject<void>();

	constructor(
		public readonly countriesNetworks: CountriesNetworksService,
		public readonly fieldsService: CountryNetworkFieldsService,
		public readonly selectionService: CountryNetworkSelectionService,
		private readonly media: MediaMatcher
	) {
	}

	// https://github.com/angular/components/issues/14833
	// https://stackblitz.com/edit/components-issue-t3xvyz?file=app%2Fapp.component.scss
	public get inverseOfTranslation(): string {
		// eslint-disable-next-line @typescript-eslint/dot-notation
		if ( !this.viewPort || !this.viewPort['_renderedContentOffset'] ) {
			return '-0px';
		}
		// eslint-disable-next-line @typescript-eslint/dot-notation
		const offset = this.viewPort['_renderedContentOffset'];
		return `-${offset}px`;
	}

	ngOnInit(): void {
		this.tHeaders$ = this.fieldsService.read()
			.pipe(
				map(resp => LocalTableUtils.toTableFields(resp.Data)),
				tap(() => {
					this.viewPort.checkViewportSize();
				})
			);
		this.tRows = this.countriesNetworks.list;
		this.initFiltersAndSorting();
		this.initSelection(this.tRows);

		this.selectionModel.changed
			.pipe(
				tap(() => this.loading$.next(true)),
				takeUntil(this.ngUnsubscribe))
			.subscribe(() => {
				this.tRows = this.selectionModel.selected;
				this.applyFilter(this.filters);
				this.applySorting(this.sorting);
				this.loading$.next(false);
			});
	}

	initFiltersAndSorting(): void {
		['mccmnc', 'country', 'network'].forEach(
			(variable) => {
				this.sorting.set(variable, { enabled: true, value: SortDirection.EMPTY });
				this.filters.set(variable, { enabled: true, value: null, filterType: FilterType.LIKE });
			}
		);
	}

	initSelection(refBook: CountryNetworkRef<exist>[]): void {
		switch (this.type) {
			case ModelType.AVAILABLE:
				// Doesn't work this.selectionModel.deselected()!!!
				const diff = refBook.filter(i => !this.selectionService.selectedModel.selected
					.find(f => f.id === i.id));
				this.selectionModel.clear();
				this.selectionModel.select(...diff);
				this.tRows = [...diff];
				break;
			case ModelType.SELECTED:
				this.tRows = [...this.selectionModel.selected];
				break;
			case ModelType.TEMPORARY:
				this.selectionModel.clear();
				this.selectionModel.select(...this.selectionService.selectedModel.selected);
				this.tRows = [...this.selectionModel.selected];
				break;
			default:
				this.tRows = [...this.selectionModel.selected];
		}
	}

	applyFilter($event: TableFiltersIndicator): void {
		this.tRows = [...this.selectionModel.selected];
		this.isFiltersApplied = this.checkFilters($event);
		$event.forEach(
			(filter, variable) => {
				if ( filter?.enabled && filter.value ) {
					switch (variable) {
						case 'network':
						case 'country':
							this.tRows = this.tRows.filter(
								el => variable === 'network'
									? filterWildcardFn(el.network ?? '', filter.value as string)
									: filterWildcardFn(el.country, filter.value as string)
							);
							break;
						case 'mccmnc':
							this.tRows = this.tRows.filter(
								el =>
									filterWildcardFn(
										el.mcc + '' + el.mnc,
										(filter.value as string).replace(/\s/g, '')
									)
							);
							break;
						default:
					}
				}
			}
		);
	}

	checkFilters(indicators: TableFiltersIndicator): boolean {
		const filters: string[] = [];
		indicators.forEach(
			(filter, variable) => {
				if ( Array.isArray(filter?.value) ? filter?.value.length : filter?.value ) {
					filters.push(variable);
				}
			}
		);
		return !!filters.length;
	}

	applySorting($event: TableSortIndicator): void {
		$event.forEach(
			(sort, variable) => {
				if ( sort?.enabled ) {
					const tRows = [...this.tRows];
					this.tRows = tRows.sort(
						(a, b) => {
							let first: unknown;
							let second: unknown;
							switch (variable) {
								case 'country':
									first = a.country;
									second = b.country;
									break;
								case 'network':
									first = a.network ?? '';
									second = b.network ?? '';
									break;
								case 'mccmnc': {
									const mccSort = a.mcc - b.mcc;
									const mncSort = (a.mnc || 0) - (b.mnc || 0);
									switch (sort?.value) {
										case SortDirection.ASC:
											if ( a.mcc !== b.mcc ) {
												return mccSort > 0 ? 1 : -1; // Sort by mcc first
											}
											return mncSort > 0 ? 1 : -1; // Then by mnc
										case SortDirection.DESC:
											if ( a.mcc !== b.mcc ) {
												return mccSort > 0 ? -1 : 1; // Sort by mcc first
											}
											return mncSort > 0 ? -1 : 1; // Then by mnc
										case SortDirection.EMPTY:
										default:
											return 1;
									}
								}
							}

							return LocalTableUtils.sortComparisonFn(sort.value ?? SortDirection.EMPTY, first, second);
						}
					);
				}
			}
		);
	}

	changeSortDirection(head: EntityField): void {
		const srcDirection = this.sorting.get(head.variable)?.value ?? SortDirection.EMPTY;
		let direction;
		switch (srcDirection) {
			case SortDirection.EMPTY:
				direction = SortDirection.DESC;
				break;
			case SortDirection.DESC:
				direction = SortDirection.ASC;
				break;
			case SortDirection.ASC:
				direction = SortDirection.EMPTY;
				break;
			default:
				direction = SortDirection.EMPTY;
		}
		this.sorting.delete(head.variable);
		this.sorting = new Map([[head.variable, { enabled: true, value: direction }], ...this.sorting]);
		this.applySorting(this.sorting);
	}

	changeFilter($event: TableFiltersIndicator): void {
		this.filters = new Map([...this.filters, ...$event]);
		this.applyFilter(this.filters);
	}

	calculateViewPort(): string {
		const showRowQueries: Map<number, string> = new Map();
		const rowHeight = 3;
		let defaultMaxRows = 10;
		showRowQueries
			.set(10, '(min-height: 800px)')
			.set(7, '(min-height: 700px) and (max-height: 800px)')
			.set(5, '(max-height: 700px)');
		for ( const [maxRows, query] of showRowQueries.entries() ) {
			if ( this.media.matchMedia(query).matches ) {
				defaultMaxRows = maxRows;
				break;
			}
		}
		return (this.tRows.length > defaultMaxRows ? defaultMaxRows : this.tRows.length) * rowHeight + 3.125 + 'rem';
	}

	ngOnDestroy(): void {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}
}
