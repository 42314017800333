import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';

import { ReadResponse } from '@campaign-portal/namespace/common/implementations';
import { RPCRequestParams } from '@campaign-portal/namespace/common/rpc.params';
import {
	BarChart,
	ChartCostMessagePerChannels,
	ChartMessagesByStatus,
	RingChart
} from '@campaign-portal/namespace/entities/charts/specs';

import {
	AlarisApiService,
	AlarisLanguageService,
	ChartUtilsService,
	ErrorNotifierConfig
} from '@campaign-portal/components-library';

@Injectable({
	providedIn: 'root'
})
export class ChartsService {
	readonly loading$ = new BehaviorSubject(false);
	private readonly availableCountries$ = new BehaviorSubject<string[] | null>(null);

	private readonly errorNotifier = (): ErrorNotifierConfig => ({ title: this.title });

	constructor(
		private readonly api: AlarisApiService,
		private readonly cu: ChartUtilsService,
		private readonly langService: AlarisLanguageService
	) {
	}

	get title(): string {
		return this.langService.translate('notifications.titles.chart');
	}
	
	get countries(): Observable<string[] | null> {
		return this.availableCountries$.asObservable();
	}

	messagesPerNetChart(params?: RPCRequestParams): Observable<ReadResponse<RingChart>> {
		return this.api.loader<ReadResponse<RingChart>>(
			'Charts.MessagesPerNet', params, this.loading$, this.errorNotifier
		).pipe(map((resp) => {
			[resp.Data.values, resp.Data.labels] = this.cu.sortData(resp.Data.values, resp.Data.labels);
			return resp;
		}));
	}

	messagesByStatusChart(params?: RPCRequestParams): Observable<ReadResponse<ChartMessagesByStatus>> {
		return this.api.loader<ReadResponse<ChartMessagesByStatus>>(
			'Charts.MessagesByStatus', params, this.loading$, this.errorNotifier
		);
	}

	messagesByClientChart(params?: RPCRequestParams): Observable<ReadResponse<BarChart>> {
		return this.api.loader<ReadResponse<BarChart>>(
			'Charts.MessagesByClient', params, this.loading$, this.errorNotifier
		);
	}

	messagesBySenderChart(params?: RPCRequestParams): Observable<ReadResponse<BarChart>> {
		return this.api.loader<ReadResponse<BarChart>>(
			'Charts.MessagesBySender', params, this.loading$, this.errorNotifier
		);
	}

	messagesByCountryChart(params?: RPCRequestParams): Observable<ReadResponse<BarChart>> {
		return this.api.loader<ReadResponse<BarChart>>(
			'Charts.MessagesByCountry', params, this.loading$, this.errorNotifier
		).pipe(
			map((resp) => {
				if ( resp.Data.values[0] === undefined ) {
					resp.Data.values[0] = [];
				}
				[resp.Data.values[0], resp.Data.labels] = this.cu.sortData(resp.Data.values[0], resp.Data.labels);
				this.availableCountries$.next(resp.Data.labels);
				return resp;
			})
		);
	}

	costMessagePerChannelsChart(params?: RPCRequestParams): Observable<ReadResponse<ChartCostMessagePerChannels>> {
		return this.api.loader<ReadResponse<ChartCostMessagePerChannels>>(
			'Charts.CostMessagePerChannels', params, this.loading$, this.errorNotifier
		);
	}

}
