import { Injectable } from '@angular/core';

import { AlarisLanguageService, LocalTableUtils, TableEntityField } from '@campaign-portal/components-library';

import { SendersListFieldsService } from '../senders-list/senders-list.fields.service';
import { ChannelsService } from '@helpers/services/channels.service';
import { PartnersService } from '../../partners/partners.service';

@Injectable()
export class EventsFieldsService extends SendersListFieldsService {

	public override readonly headers: TableEntityField[] = LocalTableUtils.toTableFields([
		this.name, this.status, this.trafficType, this.partner, this.documents, this.lastUpdated
	]);

	constructor(
		lsService: AlarisLanguageService,
		channelService: ChannelsService,
		partnersService: PartnersService
	) {
		super(lsService, channelService, partnersService);
	}

}
