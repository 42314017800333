<alaris-card size="lg">
	<div [alarisLoading]="loading$" style="width: 100%;border-radius: 0.5rem 0.5rem 0 0;">
		<div class="head">
			<alaris-icon (click)="close()" class="close" name="icon-close"></alaris-icon>
			<h3>{{ task.name }}</h3>
		</div>
		<div class="content">
			@if (task.description) {
				<alaris-alert type="error">{{ task.description }}</alaris-alert>
			}
			<div class="grid vertical-margin">
				<b>{{ 'gl.status' | translate }}</b>
				<div class="status">
					<div [style.background]="getColor(task.status)" class="dot"></div>
					{{ ('enums.' + task.status) | translate }}
				</div>
				<b>{{ 'gl.user' | translate }}</b>
				<div class="column-with-icon">
					<alaris-icon name="icon-user"></alaris-icon>
					{{ task.createdBy }}
				</div>
				<b>{{ 'columns.creationDate' | translate }}</b>
				<div class="column-with-icon">
					<alaris-icon name="icon-data"></alaris-icon>
					{{ (task.creationDate  | date: 'd MMM y, HH:mm') | noValue }}
				</div>
				<b>{{ 'gl.fields' | translate }}</b>
				<div>
					<div [class.hidden]="showFields">
						<div #fields class="flex-container">
							@for (field of task.fields; track $index) {
								<alaris-tag>{{ edrFields.map.get(field)?.name ?? '' | translate }}</alaris-tag>
							}
						</div>
					</div>
					@if (isHiddenFields()) {
						<button class="show-more"
								(click)="showFields=!showFields"
								data-testid="showHiddenFields">
							{{ 'statistics.showMore' | translate : {amount: task.fields.length - getOverflowIndex()} }}
							<alaris-icon [class.rotate]="!showFields" name="deprecated/arrow"></alaris-icon>
						</button>
					}
				</div>
				@if (task.emails) {
					<b>{{ 'statistics.recipientEmail' | translate }}</b>
					{{ task.emails }}
				}
			</div>
			<div class="parameters">
				<div (click)="showParameters=!showParameters" class="title flex-container">
					<b>{{ 'statistics.parameters' | translate }}</b>
					<span class="secondary">{{ task.filters.length - 1 }}</span>
					<alaris-icon [name]="showParameters ? 'icon-caret-up' : 'icon-caret-down'"></alaris-icon>
				</div>
				@if (showParameters) {
					<div class="flex-container secondary vertical-margin">
						<alaris-icon name="icon-info"></alaris-icon>
						{{ 'statistics.parametersNote' | translate }}
					</div>
					<div alarisFade="vertical" class="grid">
						@for (filter of task.filters; track $index) {
							@switch (filter.Field) {
								@case ('edrDate') {
									<ng-container *ngTemplateOutlet="edrDate; context: {filter}"></ng-container>
								}
								@case ('edrStatus') {
									<ng-container *ngTemplateOutlet="multiEnumValue; context:{filter, dot: true}">
									</ng-container>
								}
								@case ('messagePurpose') {
									<ng-container *ngTemplateOutlet="multiEnumValue; context:{filter}"></ng-container>
								}
								@case ('senderName') {
									<ng-container *ngTemplateOutlet="multiValue; context:{
										title: filter.Field, list: filter.Value | as: 'recordsArray'}">
									</ng-container>
								}
								@case ('endpointName') {
									<ng-container *ngTemplateOutlet="multiValue; context:{
										title: filter.Field, list: filter.Value | as: 'recordsArray', property: 'name'}">
									</ng-container>
								}
								@case ('country') {
									<ng-container *ngTemplateOutlet="multiValue; context:{
										title: filter.Field, list: filter.Value}">
									</ng-container>
								}
								@case ('net') {
									<ng-container *ngTemplateOutlet="multiValue; context:{
										title: filter.Field, list: filter.Value}">
									</ng-container>
								}
								@case ('revenue') {
									<ng-container *ngTemplateOutlet="currency; context: {filter}"></ng-container>
								}
								@case ('cost') {
									<ng-container *ngTemplateOutlet="currency; context: {filter}"></ng-container>
								}
								@case ('channelId') {
									<ng-container *ngTemplateOutlet="channelId; context: {filter}"></ng-container>
								}
								@case ('partNumber') {
									<ng-container *ngTemplateOutlet="range; context: {filter}"></ng-container>
								}
								@case ('partnerId') {
								}
								@default {
									<ng-container *ngTemplateOutlet="standard; context: {filter}"></ng-container>
								}
							}
						}
					</div>
				}
			</div>
		</div>
		<div class="footer">
			<button (click)="confirm()"
					[bType]="task.status === EdrExportStatus.READY || task.status === EdrExportStatus.SENT ?
									'primary' : 'outline'"
					[disabled]="disabled"
					alaris-button
					data-testid="actionBtn" size="lg">
				@switch (task.status) {
					@case (EdrExportStatus.WAITING) {
						<alaris-icon name="icon-error"></alaris-icon>
						{{ 'statistics.cancelTask' | translate }}
					}
					@case (EdrExportStatus.FAILED) {
						<alaris-icon name="icon-arrow-clock-wise"></alaris-icon>
						{{ 'statistics.restartTask' | translate }}
					}
					@case (EdrExportStatus.CANCELLED) {
						<alaris-icon name="icon-arrow-clock-wise"></alaris-icon>
						{{ 'statistics.restartTask' | translate }}
					}
					@default {
						<alaris-icon name="icon-import"></alaris-icon>
						{{ 'actions.download' | translate }}
					}
				}
			</button>
		</div>
	</div>
</alaris-card>

<!--Templates for construct filters applied in edr export-->

<ng-template #standard let-filter=filter>
	<b>{{ ('columns.' + filter.Field) | translate }}</b>
	{{ filter.Value }}
</ng-template>

<ng-template #edrDate let-filter=filter>
	<b>{{ 'columns.eventTime' | translate }}</b>
	@if (filter.Value.Start === null && filter.Value.End === null) {
		{{ 'gl.allPeriod' | translate }}
	} @else {
		{{ (filter.Value.Start | date: 'd MMM y') ?? ('calendar.before' | translate) }}
		-
		{{ (filter.Value.End | date: 'd MMM y') ?? ('calendar.after' | translate) }}
	}
</ng-template>

<ng-template #multiEnumValue let-dot="dot" let-filter="filter">
	<b>{{ ('columns.' + filter.Field) | translate }}</b>
	<div class="flex-container">
		@for (value of filter.Value; track $index) {
			<alaris-tag>
				@if (dot) {
					<div [style.background]="chartUtilsService.chartHintColorMapper(
				'enums.' + value, alarisConfig.themeType)" class="dot-2">
					</div>
				}
				{{ ('enums.' + value) | translate }}
			</alaris-tag>
		}
	</div>
</ng-template>

<ng-template #multiValue let-list="list" let-property=property let-title=title>
	<b>{{ ('columns.' + title) | translate }}</b>
	<div class="flex-container">
		@for (value of list; track $index) {
			<alaris-tag>{{ property ? value[property] : property }}</alaris-tag>
		}
	</div>
</ng-template>

<ng-template #currency let-filter="filter">
	<b>{{ ('columns.' + filter.Field) | translate }}</b>
	{{ filter.Value[0] | currency: bs.currencyCode : 'symbol' : '1.2-5' }}
	-
	{{ filter.Value[1] | currency: '':'':'1.2-5' }}
</ng-template>

<ng-template #channelId let-filter="filter">
	<b>{{ 'columns.channelId' | translate }}</b>
	<div class="flex-container">
		@for (value of filter.Value; track $index) {
			<alaris-tag>{{ cu.name(value.channelType) | translate }}</alaris-tag>
		}
	</div>
</ng-template>

<ng-template #range let-filter="filter">
	<b>{{ ('columns.' + filter.Field) | translate }}</b>
	{{ filter.Value[0] ?? ('calendar.before' | translate) }}
	-
	{{ filter.Value[1] ?? ('calendar.after' | translate) }}
</ng-template>
