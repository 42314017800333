<alaris-dialog [closeIconAction]="close.bind(this)"
			   [closeAction]="closeAction.bind(this)"
			   [closeButtonText]="closeButtonText"
			   [confirmAction]="confirm.bind(this)"
			   [confirmButtonIcon]="confirmButtonIcon"
			   [confirmButtonText]="confirmButtonText"
			   [loading$]="loading$">
	<ng-container slot="header">
		<div class="header">
			<alaris-icon class="icon" name="icon-warning-circle"></alaris-icon>
			@switch (data.type) {
				@case ('Delete') {
					<h3 class="title">{{ "subscriptions.groups.deleteGroup" | translate }}</h3>
				}
				@case ('GoToSubscription') {
					<h3 class="title">{{ "subscriptions.groups.goToSubscriptions" | translate }}</h3>
				}
			}

		</div>
	</ng-container>
	<ng-container slot="content">
		<div class="content">
			@switch (data.type) {
				@case ('Delete') {
					@if (hasLinkedEndpoints()) {
						<span>
							{{ 'subscriptions.groups.unlinkEndpoint' | translate }}
							<b>{{ linkedEndpointNames }}</b>
						</span>
						{{ 'subscriptions.groups.goToVendorsNote' | translate }}
					} @else {
						<span>
							{{ 'subscriptions.groups.deleteNote' | translate }}
							<b>{{ group.name }}</b>?
						</span>
						<b>{{ 'subscriptions.groups.actionUndone' | translate }}</b>
					}
				}
				@case ('GoToSubscription') {
					<div>{{ 'subscriptions.groups.goToSubscriptionsSection' | translate }}</div>
					<div><b>{{ 'subscriptions.groups.unsavedData' | translate }}</b></div>
				}
			}
		</div>
	</ng-container>
</alaris-dialog>



