<div [alarisLoading]="subscriptionsService.loading$">
	<div class="edit-panel-header">
		<alaris-icon name="icon-sender"></alaris-icon>
		<span>{{ (subscription.id ? 'senders.senderIdSubscription' : 'senders.addSubscriptions') | translate }}</span>
	</div>
	<div [formGroup]="subscriptionForm" alarisFade="vertical" class="edit-panel-content form-group">
		<ng-container *ngIf="subscription.id !== null">
			<ng-container *ngTemplateOutlet="form"></ng-container>
		</ng-container>
		<ng-container *ngIf="subscription.id === null && activeSendersList.length > 0">
			<ng-container *ngTemplateOutlet="form"></ng-container>
		</ng-container>
		<ng-container *ngIf="subscription.id === null && activeSendersList.length === 0">
			<ng-container *ngTemplateOutlet="noActiveSenders"></ng-container>
		</ng-container>


		<ng-template #noComment>
			<div (click)="subscriptionForm.get('comment')?.setValue(' ')"
				 *alarisPBAC="[AP_PERMISSIONS.SENDERS_E]"
				 class="add-comment" data-testid="addCommentBtn">
				{{ 'gl.addComment' | translate }}
			</div>
		</ng-template>

		<ng-template #form>
			<div *ngIf="subscription.lastUpdated" class="label">
				<br>
				{{ 'gl.lastUpdated' | translate }}
				{{ subscription.lastUpdated | date: 'd MMM y, HH:mm' }}
			</div>
			<alaris-select2 [label]="'fc.sender' | translate"
							[placeholder]="'fc.sender' | translate"
							[readonly]="!!subscription.id"
							data-testid="senderSelect"
							formControlName="name" selectedLabel="name">
				<alaris-select-filter2 *ngIf="activeSendersList.length > 5"
									   [formControl]="filterSendersControl" data-testid="senderFilter">
				</alaris-select-filter2>
				<alaris-options2 *ngFor="let opt of filterSendersList"
								 [attr.data-testid]="'senderOption/' + opt.name" [value]="opt">
					{{ opt.name }} ({{ cu.name(opt.trafficType) | translate }})
				</alaris-options2>
			</alaris-select2>
			<alaris-select2 [displayWith]="displayChannel"
							[label]="'fc.channel' | translate"
							[placeholder]="'fc.channel' | translate"
							[readonly]="true"
							data-testid="trafficTypeSelect" formControlName="trafficType">
				<alaris-options2 *ngFor="let opt of channelService.list$ | async"
								 [attr.data-testid]="'trafficTypeOption/' + opt.channelType" [value]="opt.channelType">
					{{ cu.name(opt.channelType) | translate }}
				</alaris-options2>
			</alaris-select2>
			<alaris-multi-select2 [displayWith]="displayPartners"
								  [label]="'fc.partners' | translate"
								  [optionsCount]="(partnersService.list$ | async)?.length || 0"
								  [placeholder]="'fc.partners' | translate"
								  [readonly]="!!subscription.id"
								  data-testid="partnersMultiSelect" formControlName="partner">
				<alaris-select-filter2 *ngIf="((partnersService.list$ | async)?.length || 0) > 5"
									   [formControl]="filterPartnersControl" data-testid="partnersFilter">
				</alaris-select-filter2>
				<alaris-multi-options2 *ngFor="let partner of filterPartnersList"
									   [attr.data-testid]="'partnersOption/' + partner.name" [value]="partner.id">
					{{ partner.name }}
				</alaris-multi-options2>
			</alaris-multi-select2>
			<alaris-toggle data-testid="enabledToggle" formControlName="enabled">
				{{ 'gl.enabled' | translate }}
			</alaris-toggle>
			<b>{{ 'senders.periodOfValidity' | translate }}</b>
			<div class="in-row">
				<alaris-input-date [label]="'fc.startDate' | translate"
								   [min]="Day.currentLocal()"
								   [toSystemDate]
								   data-testid="activeFromInputDate" formControlName="activeFrom">
				</alaris-input-date>
				<alaris-input-date [label]="'fc.endDate' | translate"
								   [min]="minEndDate"
								   [stateAddonEnabled]="{ reset: true, state: false }"
								   [toSystemDate]
								   data-testid="activeTillInputDate" formControlName="activeTill">
				</alaris-input-date>
			</div>
			<span *ngIf="subscriptionForm.hasError('moreThan')" class="error">{{ 'errors.moreThan' | translate }}</span>
			<alaris-text-area *ngIf="subscriptionForm.get('comment')?.value; else noComment"
							  data-testid="commentTextarea" formControlName="comment">
				{{ 'fc.comment' | translate }}
			</alaris-text-area>
		</ng-template>

		<ng-template #noActiveSenders>
			<app-empty-table type="no-senders-to-subscribe"></app-empty-table>
		</ng-template>
	</div>
	<div class="edit-panel-footer">
		<button (click)="save()"
				*alarisPBAC="[AP_PERMISSIONS.SENDERS_E]"
				[disabled]="subscriptionForm.invalid || !subscriptionForm.dirty"
				alaris-button bType="primary"
				data-testid="saveBtn"
				size="lg" type="button">
			<alaris-icon class="in-primary" name="icon-success"></alaris-icon>
			{{ (subscription.id ? 'actions.save' : 'actions.create') | translate }}
		</button>
		<button (click)="close()" alaris-button bType="outline" data-testid="closeBtn" size="lg" type="button">
			{{ (([AP_PERMISSIONS.SENDERS_E] | alarisPBAC) ? 'actions.cancel' : 'actions.close') | translate }}
		</button>
		<!--		<button (click)="onUnsubscribe()"-->
		<!--				*ngIf="subscription.id"-->
		<!--				[disabled]="unsubscribeDisabled" alaris-button-->
		<!--				bType="outline" size="lg" style="margin-left: auto" type="button">-->
		<!--			<alaris-icon class="in-outline" name="icon-delete"></alaris-icon>-->
		<!--			{{ 'actions.unsubscribe' | translate }}-->
		<!--		</button>-->
	</div>
</div>

