import { RowActionSimple } from '@campaign-portal/components-library';
import { SenderSubscriptionEventStatus } from '@campaign-portal/namespace/common/enums';
import { SenderSubscriptionEvent } from '@campaign-portal/namespace/entities/sender-id/specs';
import { exist } from '@campaign-portal/namespace/common/id';

import { EventsComponent } from './events.component';

export class ActionsFactory {
	private readonly ownerActions: RowActionSimple<SenderSubscriptionEvent<exist>>[] = [
		{
			icon: '',
			label: 'actions.view',
			action: this.component.openDetailsPanel.bind(this.component)
		},
		{
			icon: '',
			label: 'actions.confirm',
			action: this.component.confirm.bind(this.component),
			disabled: (row: SenderSubscriptionEvent): boolean =>
				!(row.status === SenderSubscriptionEventStatus.NEW ||
					row.status === SenderSubscriptionEventStatus.IN_PROGRESS)
		},
		{
			icon: '',
			label: 'actions.progress',
			action: this.component.putInProgress.bind(this.component),
			disabled: (row: SenderSubscriptionEvent): boolean => row.status !== SenderSubscriptionEventStatus.NEW
		},
		{
			icon: '',
			label: 'actions.decline',
			action: this.component.decline.bind(this.component),
			disabled: (row: SenderSubscriptionEvent): boolean =>
				!(row.status === SenderSubscriptionEventStatus.NEW ||
					row.status === SenderSubscriptionEventStatus.IN_PROGRESS)
		}
	];

	private readonly resellerActions: RowActionSimple<SenderSubscriptionEvent<exist>>[] = [
		{
			icon: '',
			label: 'actions.view',
			action: this.component.openDetailsPanel.bind(this.component)
		},
		{
			icon: '',
			label: 'actions.confirm',
			action: this.component.confirm.bind(this.component),
			disabled: (row: SenderSubscriptionEvent): boolean =>
				row.sender.partner === this.component.profileId || row.status !== SenderSubscriptionEventStatus.NEW
		},
		{
			icon: '',
			label: 'actions.decline',
			action: this.component.decline.bind(this.component),
			disabled: (row: SenderSubscriptionEvent): boolean =>
				(row.sender.partner === this.component.profileId || (row.status !== SenderSubscriptionEventStatus.NEW &&
					row.status !== SenderSubscriptionEventStatus.IN_PROGRESS))
		},
		{
			icon: '',
			label: 'actions.cancel',
			action: this.component.cancel.bind(this.component),
			disabled: (row: SenderSubscriptionEvent): boolean =>
				!(row.sender.partner === this.component.profileId && row.status === SenderSubscriptionEventStatus.NEW)
		}
	];

	constructor(public readonly component: EventsComponent) {
	}

	getActions(isOwner: boolean): RowActionSimple<SenderSubscriptionEvent<exist>>[] {
		return isOwner ? this.ownerActions : this.resellerActions;
	}
}
