<alaris-card>
	<div *ngIf="tRows.length > 0 || tRows.length === 0 && isFiltersApplied" class="table-management">
		<div class="left">
			<span class="total" data-testid="total">{{ totalTitle | translate }}: {{ total }}</span>
			<button (click)="editTableFilter()" *ngIf="enableButtons.filters.enabled" alaris-button bType="outline"
					data-testid="editFilterBtn" size="lg">
				<alaris-icon name="icon-filter-vertical"></alaris-icon>
				{{ enableButtons.filters.title | translate }}
				<div *ngIf="isFiltersApplied" class="indicator"></div>
			</button>
		</div>
		<div class="right">
			<button (click)="openEditPanel()"
					*ngIf="isOwner.is && ([AP_PERMISSIONS.V_SUBSCR_E] | alarisPBAC)"
					alaris-button bType="primary" data-testid="createBtn" size="lg">
				<alaris-icon class="in-primary" name="icon-plus"></alaris-icon>
				{{ 'products.addEntry' | translate }}
			</button>
		</div>
	</div>
	<div [alarisLoading]="loading$" class="content">
		@if (tRows.length === 0 && !isFiltersApplied) {
			<app-empty-table (newProduct)="openEditPanel()"
							 [disabled]="!([AP_PERMISSIONS.V_SUBSCR_E] | alarisPBAC)"
							 type="products">
			</app-empty-table>
		} @else {
			<alaris-table (filterChange)="_applyFilter($event)"
						  (sortingChange)="_applySorting($event)"
						  [ctxActions]="ctxActions"
						  [dblClickRowAction]="isOwner.is? openEditPanel.bind(this): mock"
						  [filters]="filters"
						  [rowActions]="isOwner.is ? rowActions : undefined"
						  [sorting]="sorting"
						  [tHeaders]="tHeaders"
						  [tRows]="tRows"
						  [templates]="{name, trafficType, currencyId, billingMode}"
						  alarisOverflowFader>
			</alaris-table>
		}
	</div>
	<div class="footer">
		<alaris-pagination (page)="_pageChange($event)"
						   [activePage]="activePage"
						   [hidden]="total === 0"
						   [pageSizeOptions]="pageSizeOptions"
						   [pageSize]="pageSize"
						   [total]="total"
						   class="pagination">
		</alaris-pagination>
	</div>
</alaris-card>

<ng-template #name [alarisCellCtx]="tRows[0]" let-data="data">
	{{ data.name }}
</ng-template>

<ng-template #trafficType [alarisCellCtx]="tRows[0]" let-data="data">
	{{ channelUtilsService.purpose(data.trafficType, data.settings?.viberSettings?.messagePurpose) | translate }}
</ng-template>

<ng-template #currencyId [alarisCellCtx]="tRows[0]" let-data="data">
	{{ (data.currencyId ?? 0 | alarisCurrency)?.name | noValue }}
</ng-template>

<ng-template #billingMode [alarisCellCtx]="tRows[0]" let-data="data">
	{{ 'enums.' + data.billingMode | translate }}
</ng-template>
