import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BehaviorSubject, merge, Subject, takeUntil } from 'rxjs';

import { AlarisBalanceService, AlarisEditPanelService, EditPanelInputData } from '@campaign-portal/components-library';

import { exist, Id } from '@campaign-portal/namespace/common/id';
import { Partner } from '@campaign-portal/namespace/entities/partners/specs';
import { CreditType } from '@campaign-portal/namespace/common/enums';
import { CurrencyService } from '@helpers/services/currency.service';
import { SubscriptionsService } from '../../subscriptions/subscriptions-list/subscriptions.service';
import { PartnersService } from '../partners.service';
import { CanDeactivateGuardService } from '@helpers/can-deactivate/can-deactivate.guard';
import { AP_PERMISSIONS } from '@helpers/types/permissions';

@Component({
	selector: 'app-partners-details',
	templateUrl: './partners-details.component.html',
	styleUrls: ['./partners-details.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PartnersDetailsComponent implements OnInit, OnDestroy {

	readonly AP_PERMISSIONS = AP_PERMISSIONS;
	readonly CreditType = CreditType;
	readonly partner!: Partner<exist>;
	isActive!: FormControl;

	showSubscribeBtn = false;
	partnersSubscriptions: { plans: Id<exist>[]; packs: Id<exist>[] } = { plans: [], packs: [] };

	readonly allowedDeactivation = new BehaviorSubject(true);
	private readonly ngUnsubscribe = new Subject<void>();

	constructor(
		public readonly partnersService: PartnersService,
		public readonly currencyService: CurrencyService,
		public readonly subService: SubscriptionsService,
		public readonly bs: AlarisBalanceService,
		private readonly editPanel: AlarisEditPanelService,
		private readonly cd: ChangeDetectorRef,
		private readonly guard: CanDeactivateGuardService,
		@Inject(EditPanelInputData) private readonly inputData: EditPanelInputData
	) {
		this.partner = this.inputData.partner as Partner<exist>;
		this.partnersSubscriptions = { plans: this.partner.plans, packs: this.partner.packs };
	}

	ngOnInit(): void {
		merge(
			this.editPanel.closingComponent$,
			this.editPanel.escKeyInComponent$,
			this.editPanel.backdropClickComponent$
		)
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(() => {
				if ( !this.editPanel.canBeClosed ) {
					this.guard.lostDataDialog()
						.pipe(takeUntil(this.ngUnsubscribe))
						.subscribe(result => {
							this.allowedDeactivation.next(result);
							this.editPanel.close();
						});
				}
			});

		this.isActive = new FormControl(this.partner.isActive);
		this.editPanel.canBeClosed$ = this.allowedDeactivation;
	}

	close(result?: boolean): void {
		this.editPanel.closingComponent$.emit(true);
		this.editPanel.close(result);
	}

	subscribe(): void {
		this.allowedDeactivation.next(false);
		this.partnersService.update({
			...this.partner,
			plans: this.partnersSubscriptions.plans,
			packs: this.partnersSubscriptions.packs
		})
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(() => {
				this.allowedDeactivation.next(true);
			});
	}

	subscriptions(): void {
		this.partnersService.subscriptionsDialog(this.partner)
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((result) => {
				if ( result ) {
					this.showSubscribeBtn = true;
					const [newPlans, newPacks] = this.partnersService
						.prepareSubscriptions(
							result,
							this.partner.plans,
							this.partner.packs
						);
					this.partnersSubscriptions = {
						plans: newPlans,
						packs: newPacks
					};
					this.cd.detectChanges();
				}
			});
	}

	ngOnDestroy(): void {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}
}
