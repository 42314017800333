<alaris-dialog [closeAction]="close.bind(this)"
			   [confirmAction]="confirm.bind(this)"
			   [disabled]="disabled"
			   [loading$]="loading$">
	<h3 [ngSwitch]="data.type" slot="header">
		<ng-container *ngSwitchCase="'Delete'">{{ "users.deleteEntry" | translate }}</ng-container>
		<ng-container *ngSwitchCase="'ChangePasswordByHand'">{{ "users.setNewPassword" | translate }}</ng-container>
		<ng-container *ngSwitchCase="'ChangePasswordByEmail'">{{ "users.resetPassword" | translate }}</ng-container>
	</h3>
	<div class="content" slot="content">
		<div [ngSwitch]="data.type">
			<ng-container *ngSwitchCase="'Delete'">
				{{ 'users.deleteEntryNote' | translate }}
				<b>{{user.details.firstName + ' ' + (user.details.lastName || '')}}</b>?
			</ng-container>
			<ng-container *ngSwitchCase="'ChangePasswordByHand'">
				<app-change-password-by-hand (passwordChange)="password=$event"></app-change-password-by-hand>
			</ng-container>
			<ng-container *ngSwitchCase="'ChangePasswordByEmail'">
				<alaris-captcha (key)="captcha.controls.key.setValue($event)"
								[forceUpdate$]="updateCaptcha$"
								[formControl]="captcha.controls.answer" data-testid="captcha">
					{{ 'gl.captcha' | translate }}
				</alaris-captcha>
			</ng-container>
		</div>
	</div>
</alaris-dialog>
