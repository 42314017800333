import { Injectable } from '@angular/core';

import { AlarisLanguageService } from '@campaign-portal/components-library';
import { SendersListFieldsService } from '../senders-list/senders-list.fields.service';
import { ChannelsService } from '@helpers/services/channels.service';
import { PartnersService } from '../../partners/partners.service';

@Injectable()
export class SendersSubscriptionsFieldsService extends SendersListFieldsService {


	override readonly headers = [
		this.name, this.enabled, this.trafficType, this.partner, this.activeFrom, this.activeTill, this.lastUpdated
	];

	constructor(
		lsService: AlarisLanguageService,
		channelService: ChannelsService,
		partnersService: PartnersService
	) {
		super(lsService, channelService, partnersService);
	}

}
