<alaris-dialog [closeAction]="close.bind(this)"
			   [confirmAction]="confirm.bind(this)"
			   [disabled]="disabled"
			   [loading$]="loading$">
	<h3 slot="header">
		@switch (data.type) {
			@case ('Confirm') {
				{{ "senders.confirmSenderID" | translate }}
			}
			@case ('Delete') {
				{{ "actions.delete" | translate }} {{ "senders.senderId" | translate }}
			}
			@case ('Decline') {
				{{ "senders.declineSenderID" | translate }}
			}
			@case ('Unsubscribe') {
				{{ "senders.unsubscribeSenderID" | translate }}
			}
			@case ('Sender Disable') {
				{{ "senders.disableSenderID" | translate }}
			}
			@case ('Sender Expired') {
				{{ 'senders.activateSender' | translate }}
			}
		}
	</h3>
	<div class="content" slot="content">
		<div>
			@switch (data.type) {
				@case ('Confirm') {
					<p>{{
							(withPeriod ? 'senders.confirmNoteWithPeriod' : 'senders.confirmNote') | translate: {
								senderId: event?.sender?.name,
								partner: (event?.sender?.partner ?? 0 | partner)?.name
							}
						}}
					</p>
					@if (withPeriod) {
						<ng-container *ngTemplateOutlet="period"></ng-container>
					}
				}
				@case ('Decline') {
					<p>{{ 'senders.declineNote' | translate }} <b>{{ event?.sender?.name }}</b>?</p>
					<p>{{
							'senders.declineCommentNote' | translate: {
								partner: (event?.sender?.partner ?? 0 | partner)?.name
							}
						}}</p>
					<alaris-text-area [formControl]="comment" data-testid="commentTextarea">
						{{ 'fc.comment' | translate }}
					</alaris-text-area>
				}
				@case ('Unsubscribe') {
					<p>{{ 'senders.unsubscribeNote' | translate : {action: 'unsubscribe'} }}
						<b>{{ subscription?.name }}</b>?
					</p>
					<p>{{ 'senders.unsubscribeCommentNote' | translate:{action: 'unsubscribe'} }}</p>
				}
				@case ('Delete') {
					<p>{{ 'senders.deleteNote' | translate }} <b>{{ subscription?.name }}</b>?</p>
					<p>{{ 'senders.deleteCommentNote' | translate:{action: 'unsubscribe'} }}</p>
				}
				@case ('Sender Disable') {
					<p>{{ 'senders.disableNote' | translate }}</p>
				}
				@case ('Sender Expired') {
					<p>{{ 'senders.expiredNote' | translate }}</p>
					<ng-container *ngTemplateOutlet="period"></ng-container>
				}
			}
		</div>
	</div>
</alaris-dialog>

<ng-template #period>
	<div [formGroup]="periodForm" class="in-row">
		<alaris-input-date [label]="'gl.startDate' | translate"
						   [min]="Day.currentLocal()"
						   [toSystemDate]
						   data-testid="activeFromInputDate" formControlName="activeFrom">
		</alaris-input-date>
		<alaris-input-date [label]="'gl.endDate' | translate"
						   [max]="maxDate"
						   [min]="minEndDate"
						   [toSystemDate]
						   data-testid="activeTillInputDate" formControlName="activeTill">
		</alaris-input-date>
	</div>
	@if (periodForm.hasError('moreThan')) {
		<div class="error">{{ 'errors.moreThan' | translate }}</div>
	}
</ng-template>
