import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	DestroyRef,
	EventEmitter,
	inject,
	Inject,
	Input,
	OnDestroy,
	OnInit,
	Output
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { SubscriptionGroup } from '@campaign-portal/namespace/entities/subscription-groups/specs';
import { exist, Id } from '@campaign-portal/namespace/common/id';
import { AlarisDialogService } from '@campaign-portal/components-library';
import { Subscription } from '@campaign-portal/namespace/entities/subscriptions/specs';
import { SubscriptionType } from '@campaign-portal/namespace/common/enums';

import {
	SubscriptionsPickerDialogComponent,
	SubscriptionsPickerDialogData
} from './subscriptions-picker-dialog/subscriptions-picker-dialog.component';
import { PACKS_PICKER_SERVICE, PLANS_PICKER_SERVICE, SubscriptionsPickerService } from './subscriptions-picker.service';
import { SubscriptionsService } from '../../subscriptions-list/subscriptions.service';
import { ModelType } from '@helpers/types/app.classes-interfaces';
import { AP_PERMISSIONS } from '@helpers/types/permissions';

export interface SubscriptionsSelection {
	packs: Id<exist>[];
	plans: Id<exist>[];
}

@Component({
	selector: 'app-subscriptions-picker',
	templateUrl: './subscriptions-picker.component.html',
	styleUrl: './subscriptions-picker.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubscriptionsPickerComponent implements OnInit, OnDestroy {
	@Input({ required: true }) group!: SubscriptionGroup;
	@Output() readonly selectionChange = new EventEmitter<SubscriptionsSelection>();
	@Output() readonly goToSubscriptions = new EventEmitter<void>();

	readonly AP_PERMISSIONS = AP_PERMISSIONS;
	readonly SubscriptionType = SubscriptionType;
	readonly ModelType = ModelType;
	readonly destroyRef = inject(DestroyRef);

	constructor(
		public readonly subsService: SubscriptionsService,
		@Inject(PLANS_PICKER_SERVICE) public readonly plansPickerService: SubscriptionsPickerService,
		@Inject(PACKS_PICKER_SERVICE) public readonly packsPickerService: SubscriptionsPickerService,
		private readonly cd: ChangeDetectorRef,
		private readonly dialog: AlarisDialogService
	) {
	}

	get hasSubscriptions(): boolean {
		return this.groupWithPlans
			|| this.groupWithPacks
			|| this.hasAvailablePacks
			|| this.hasAvailablePlans;
	}

	get groupWithPlans(): boolean {
		return this.plansPickerService.selectedModel.hasValue();
	}

	get groupWithPacks(): boolean {
		return this.packsPickerService.selectedModel.hasValue();
	}

	get hasAvailablePlans(): boolean {
		return this.plansPickerService.availableModel.hasValue();
	}

	get hasAvailablePacks(): boolean {
		return this.packsPickerService.availableModel.hasValue();
	}

	deletePlan(subscription: Subscription<exist>): void {
		this.plansPickerService.removeFromSelected([subscription]);
		this.emitSelectionChange();
	}

	deletePack(subscription: Subscription<exist>): void {
		this.packsPickerService.removeFromSelected([subscription]);
		this.emitSelectionChange();
	}

	openDialog(type: SubscriptionType): void {
		this.dialog.open<SubscriptionsPickerDialogComponent, SubscriptionsPickerDialogData>(
			SubscriptionsPickerDialogComponent, {
				data: { type },
				autoFocus: false
			})
			.closed
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe((result) => {
				if ( result ) {
					this.cd.markForCheck();
					this.emitSelectionChange();
				}
			});
	}

	ngOnDestroy(): void {
		this.packsPickerService.clearAll();
		this.plansPickerService.clearAll();
	}

	private emitSelectionChange(): void {
		const packs = this.packsPickerService.getSelectedSubscriptionsIds();
		const plans = this.plansPickerService.getSelectedSubscriptionsIds();
		this.selectionChange.emit({ packs, plans });
	}

	ngOnInit(): void {
		this.subsService.load()
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe(() => {
				this.plansPickerService.initModels(this.group);
				this.packsPickerService.initModels(this.group);
			});
	}
}
