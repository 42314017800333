import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';

import {
	AlarisComplexTableComponent,
	AlarisDialogService,
	AlarisEditPanelService,
	AlarisLanguageService,
	AlarisProfileService,
	AlarisTableSettingsService,
	AlarisTableStateService,
	ChannelUtilsService,
	DEFAULT_BUTTONS,
	EditPanelWidth,
	RowActionSimple,
	TableFiltersIndicator,
	TableSortIndicator
} from '@campaign-portal/components-library';

import { exist } from '@campaign-portal/namespace/common/id';
import { TableTypes } from '@campaign-portal/namespace/entities/table-settings/specs';
import { DecisionMode } from '@campaign-portal/namespace/common/enums';
import { Subscription } from '@campaign-portal/namespace/entities/subscriptions/specs';
import { SortDirection } from '@campaign-portal/namespace/common/rpc.params';

import { AP_PERMISSIONS } from '@helpers/types/permissions';
import { ProductEntity } from '@helpers/types/app.classes-interfaces';
import { ProductsFieldsService } from './products.fields.service';
import { CurrencyService } from '@helpers/services/currency.service';
import { EditProductComponent } from './edit-product/edit-product.component';
import { OwnerService } from '@helpers/services/owner.service';
import { ProductsTableService } from './products.table.service';
import { VendorRatesDialogType, VendorsDialogComponent } from '../vendors-dialog/vendors-dialog.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { EntityField } from '@campaign-portal/namespace/common/entityField';
import { ChannelsService } from '@helpers/services/channels.service';

@Component({
	selector: 'app-products',
	templateUrl: './products.component.html',
	styleUrls: [
		// eslint-disable-next-line max-len
		'../../../../../node_modules/@campaign-portal/components-library/src/assets/templates/table-complex/complex-table.component.scss',
		'./products.component.scss'
	],
	providers: [ProductsFieldsService, ProductsTableService],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductsComponent extends AlarisComplexTableComponent<Subscription<exist>> implements OnInit {
	readonly AP_PERMISSIONS = AP_PERMISSIONS;

	override readonly id = 'products';
	// eslint-disable-next-line
	override readonly rowActions: RowActionSimple<any>[] = this.getActions();
	override readonly ctxActions: RowActionSimple<Subscription<exist> | Subscription<exist>[]>[] = [...this.rowActions].map(a => ({
		...a,
		icon: ''
	}));

	override sorting: TableSortIndicator = new Map()
		.set('name', { enabled: true, value: SortDirection.ASC });

	constructor(
		public readonly entityService: ProductsTableService,
		public override readonly fieldsService: ProductsFieldsService,
		public override readonly tableSettingsService: AlarisTableSettingsService,
		public override readonly editPanel: AlarisEditPanelService,
		public override readonly cd: ChangeDetectorRef,
		public override readonly stateService: AlarisTableStateService,
		public readonly currencyService: CurrencyService,
		public readonly channelUtilsService: ChannelUtilsService,
		private readonly channelsService: ChannelsService,
		public readonly isOwner: OwnerService,
		private readonly dialog: AlarisDialogService,
		private readonly profile: AlarisProfileService,
		private readonly lService: AlarisLanguageService
	) {
		super(
			entityService,
			fieldsService,
			tableSettingsService,
			editPanel,
			cd,
			TableTypes.INTERNAL,
			DEFAULT_BUTTONS,
			stateService
		);
		this.entityService.stateID = this.id;
	}

	override applyHeaders(tHeaders: EntityField[]): void {
		super.applyHeaders(tHeaders);
		this.setRefBooksInFields({
			currencyId: this.currencyService,
			trafficType: {
				list$: this.fieldsService.trafficTypeList$,
				loading$: this.channelsService.loading$
			}
		});
	}

	override applyFilter($event: TableFiltersIndicator): void {
		super.applyFilter($event);
		this.readParams.Filters?.map(
			f => f.Field === 'billingMode' ?
				(f.Value as DecisionMode[])[0] === this.lService.translate(`enums.${DecisionMode.BY_DELIVERED}`) ?
					f.Value = [DecisionMode.BY_DELIVERED] :
					f.Value = [DecisionMode.BY_SUBMITTED] :
				f
		);
	}

	override refresh(): void {
		this.entityService.firstLoad = true;
		this.load();
	}

	openEditPanel(product?: Subscription<exist>): void {
		const entity = product ? product : new ProductEntity(null, this.currencyService.list[0].id);

		this.editPanel.open(EditProductComponent, EditPanelWidth.SM, {
			product: entity
		})
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe((result) => {
				if ( result ) {
					this.refresh();
				}
			});
	}

	delete(product: Subscription<exist>): void {
		this.openSpecificDialog('DeleteProduct', product);
	}

	mock(): void {
	}

	private openSpecificDialog(type: VendorRatesDialogType, product: Subscription<exist>): void {
		this.dialog.open(VendorsDialogComponent, {
			data: { type, product }
		}).closed
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe((result) => {
				if ( result ) {
					this.refresh();
				}
			});
	}

	private getActions(): RowActionSimple<Subscription<exist>>[] {
		const edit: RowActionSimple<Subscription<exist>> = {
			icon: 'icon-edit',
			label: 'actions.edit',
			action: this.openEditPanel.bind(this)
		};
		const details: RowActionSimple<Subscription<exist>> = {
			icon: 'icon-password-show',
			label: 'actions.details',
			action: this.openEditPanel.bind(this)
		};
		const _delete: RowActionSimple<Subscription<exist>> = {
			icon: 'icon-delete',
			label: 'actions.delete',
			// @ts-expect-error: function arg type inconsistency - no need for undefined
			action: this.delete.bind(this),
			highlight: true,
			separator: true
		};
		return this.isOwner && this.profile.allowed([AP_PERMISSIONS.V_SUBSCR_E])
			? [edit, _delete]
			: [details];
	}
}
