<alaris-card>
	<div *ngIf="tRows.length > 0 || tRows.length === 0 && isFiltersApplied" class="table-management">
		<div class="left">
			<span class="total" data-testid="total">{{ totalTitle | translate }}: {{ total }}</span>
			<button (click)="editTableFilter()" alaris-button bType="outline" data-testid="editFilterBtn" size="lg">
				<alaris-icon class="in-outline" name="icon-filter-vertical"></alaris-icon>
				{{ enableButtons.filters.title | translate }}
				<div *ngIf="isFiltersApplied" class="indicator"></div>
			</button>
		</div>
		<div class="right">
			<button (click)="openEditPanel()"
					*alarisPBAC="[AP_PERMISSIONS.REF_BOOK_E]"
					alaris-button bType="primary" data-testid="createBtn" size="lg">
				<alaris-icon class="in-primary" name="icon-plus"></alaris-icon>
				{{ 'mccmnc.addEntry' | translate }}
			</button>
		</div>
	</div>
	<div [alarisLoading]="loading$" class="content">
		@if (tRows.length === 0 && !isFiltersApplied) {
			<app-empty-table (newMCCMNC)="openEditPanel()"
							 [disabled]="!([AP_PERMISSIONS.REF_BOOK_E] | alarisPBAC)"
							 type="mccmnc">
			</app-empty-table>
		} @else {
			<alaris-table (filterChange)="_applyFilter($event)"
						  (sortingChange)="_applySorting($event)"
						  [ctxActions]="ctxActions"
						  [dblClickRowAction]="openEditPanel.bind(this)"
						  [filters]="filters"
						  [rowActions]="mainActions"
						  [sorting]="sorting"
						  [tHeaders]="tHeaders"
						  [tRows]="tRows"
						  [templates]="{mcc, country, mnc, network, lastUpdated}"
						  alarisOverflowFader>
			</alaris-table>
		}
	</div>
	<div class="footer">
		<alaris-pagination (page)="_pageChange($event)"
						   [activePage]="activePage"
						   [hidden]="total === 0"
						   [pageSizeOptions]="pageSizeOptions"
						   [pageSize]="pageSize"
						   [total]="total"
						   class="pagination">
		</alaris-pagination>
	</div>
</alaris-card>

<ng-template #mcc [alarisCellCtx]="tRows[0]" let-data="data">
	{{ data.mcc | mccmnc }}
</ng-template>
<ng-template #country [alarisCellCtx]="tRows[0]" let-data="data">
	{{ data.country }}
</ng-template>
<ng-template #mnc [alarisCellCtx]="tRows[0]" let-data="data">
	{{ data.mnc | mccmnc }}
</ng-template>
<ng-template #network [alarisCellCtx]="tRows[0]" let-data="data">
	{{ data.network || ((data.mnc || data.mnc === 0) ? '' : ('mccmnc.allNetworks' | translate)) }}
</ng-template>
<ng-template #lastUpdated [alarisCellCtx]="tRows[0]" let-data="data">
	{{ data.lastUpdated | date: 'd MMM y, HH:mm' }}
</ng-template>
