<alaris-card [cdkContextMenuTriggerFor]="ctxMenu" class="host" size="auto">

	<alaris-icon [name]="chUtil.icon(subscription.trafficType)" class="i-channel"></alaris-icon>
	<div class="name">
		<div class="highlight">{{ subscription.name }}</div>
		<div class="in-row">
			<alaris-tag>
			<span [style.background]="subscription.isActive  ? 'var(--as-BgPositive)':  'var(--as-BgNegative)'"
				  class="dot">
			</span>
				{{ (subscription.isActive ? 'gl.active' : 'gl.inactive') | translate | lowercase }}
			</alaris-tag>

			<span class="info">
			<span>{{ 'columns.id' | translate }}-{{ subscription.id }}</span>
			<span class="small-dot">&bull;</span>
			<span>{{ 'gl.totalSubscribers' | translate : {amount: subscription.partners?.length ?? ''} }}</span>
				@if (isPromo) {
					<span class="small-dot">&bull;</span>
					<span>{{ 'subscriptions.promo' | translate }}</span>
				}
				@if (subscription.groupId) {
					<span class="small-dot">&bull;</span>
					<alaris-tag mode="gray-light">
						{{ (subscription.groupId | subGroup)?.name ?? '' }}
					</alaris-tag>
				}
		</span>
		</div>
	</div>

	<div class="type">
		<div class="label">{{ 'gl.type' | translate }}</div>
		{{ 'enums.' + subscription.type | translate }}
	</div>

	<div class="channel">
		<div class="label">{{ 'gl.channel' | translate }}</div>
		{{ chUtil.purpose(subscription.trafficType, subscription.settings?.viberSettings?.messagePurpose) | translate }}
	</div>

	<div class="billingMode">
		<div class="label">{{ 'fc.billingMode' | translate }}</div>
		{{ 'enums.' + subscription.billingMode | translate }}
	</div>

	<div *ngIf="subscription.currencyId" class="currency">
		<div class="label">{{ 'gl.currency' | translate }}</div>
		{{ (subscription.currencyId | alarisCurrency)?.name }}
	</div>

	<div *ngIf="price" class="price">
		<div class="label">{{ 'gl.price' | translate }}</div>
		{{ price | currency: bs.currencyCode }}
	</div>

	<div class="cc">
		<div class="label">{{ 'gl.cc' | translate }}</div>
		<div class="in-row">
			<alaris-tag *ngFor="let cc of subscription.contractCompanies?.slice(0, 1)" size="lg">
				{{ (cc | cCompany)?.name }}
			</alaris-tag>
			<span *ngIf="(subscription.contractCompanies?.length ?? 0) >= 2">
			 {{ ' +' + ((subscription.contractCompanies?.length ?? 0) - 1) }}
		</span>
		</div>
	</div>

	<div *ngIf="subscription.personalFor?.length" class="personal-for">
		<div class="label">{{ 'subscriptions.personalFor' | translate }}</div>
		<div class="in-row">
			<alaris-tag *ngFor="let partner of subscription.personalFor?.slice(0,1)" size="lg">
				{{ (partner | partner)?.name }}
			</alaris-tag>
			<span *ngIf="(subscription.personalFor?.length ?? 0) >= 2">
			{{ ' +' + ((subscription.personalFor?.length ?? 0) - 1) }}
		</span>
		</div>
	</div>

	<div class="actions in-row">
	<span *ngIf="subscription?.lastUpdated" class="label">
		{{ ('gl.updated'| translate) + ' ' + (subscription.lastUpdated ?? '' | timeAgo: ('gl.ago' | translate)) }}
	</span>
		<button (click)="$event.stopPropagation()"
				(contextmenu)="$event.stopPropagation()"
				[attr.data-testid]="'actionsDropdownToggle/' + subscription.id"
				[cdkMenuTriggerFor]="ctxMenu"
				class="action__button">
			<alaris-icon name="icon-dot-vertical"></alaris-icon>
		</button>
	</div>
</alaris-card>

<ng-template #ctxMenu>
	<alaris-ctx-action [actions]="actions"
					   [attr.data-testid]="'dropdownActions/' + subscription.id"
					   [row]="subscription"
					   class="ctxMenu">
	</alaris-ctx-action>
</ng-template>
