<div class="edit-panel-header">
	<alaris-icon name="icon-sender"></alaris-icon>
	<div>
		<span>{{ event.sender.name }}</span><br>
		<span class="note">{{ 'gl.lastUpdated' | translate }}: {{ event.lastUpdated | date: 'd MMM y, HH:mm' }}
			</span>
	</div>
</div>
<div alarisFade="vertical" class="edit-panel-content">
	<div class="content">
		<b>{{ 'gl.status' | translate }}</b>
		<span class="dots"></span>
		<alaris-select2 [displayWith]="displayLabel"
						[formControl]="selectedAction"
						[placeholder]="('enums.' + event.status) | translate"
						data-testid="statusSelect" size="md">
			@for (opt of actions | slice:1; track $index) {
				<alaris-options2 [attr.data-testid]="'statusOption/' + opt.label"
								 [disabled]="opt?.disabled(event) ?? false" [value]="opt">
					{{ opt.label | translate }}
				</alaris-options2>
			}
		</alaris-select2>

		<b>{{ 'gl.event' | translate }}</b>
		<span class="dots"></span>
		{{ 'enums.' + event.type | translate }}

		<b>{{ 'gl.channel' | translate }}</b>
		<span class="dots"></span>
		{{ cu.purpose(event.sender.trafficType) | translate }}

		<b>{{ 'gl.partner' | translate }}</b>
		<span class="dots"></span>
		@if (event.sender.partner === profile.user.partnerId) {
			<alaris-tag [rounded]="true" class="no-wrap" size="lg">{{ 'senders.myEvent' | translate }}</alaris-tag>
		} @else {
			{{ (event.sender.partner ?? 0 | partner)?.name | noValue }}
		}

		<b>{{ 'gl.comment' | translate }}</b>
		<span class="dots"></span>
		{{ event.comment || '-' }}
	</div>

	@if (event.sender.documents?.length) {
		<div class="content documents">
			<b>{{ 'gl.documents' | translate }}</b>
			<div class="file-loader">
				<alaris-file-loader [exportInternally]="true"
									[files]="event.sender.documents ?? []"
									[readonly]="true" data-testid="documentFile">
				</alaris-file-loader>
			</div>
		</div>
	}
	@if (event.history.length) {
		<div class="content history">
			<b>{{ 'gl.history' | translate }}</b>
			<alaris-table [tHeaders]="[eventFieldsService.headers[1], eventFieldsService.headers[5]]"
						  [tRows]="tRows"
						  [templates]="{status, lastUpdated}" class="history-table">
			</alaris-table>
		</div>
	}
</div>

<div class="edit-panel-footer">
	<button (click)="save()"
			[disabled]="!selectedAction.value"
			alaris-button bType="primary"
			data-testid="saveBtn"
			size="lg" type="button">
		<alaris-icon class="in-primary" name="icon-success"></alaris-icon>
		{{ 'actions.save' | translate }}
	</button>
	<button (click)="close()" alaris-button bType="outline" data-testid="closeBtn" size="lg" type="button">
		{{ 'actions.cancel' | translate }}
	</button>
</div>


<ng-template #status [alarisCellCtx]="tRows[0]" let-data="data" let-key="key">
	<div class="status-column">
		<div [style.background]="eventsService.getStatusColor(data.status)" class="dot"></div>
		{{ 'enums.' + data.status | translate }}
	</div>
</ng-template>

<ng-template #lastUpdated [alarisCellCtx]="tRows[0]" let-data="data" let-key="key">
	{{ data.lastUpdated | date: 'd MMM y, HH:mm' }}
</ng-template>
