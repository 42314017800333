import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable, of } from 'rxjs';

import { ReadResponse } from '@campaign-portal/namespace/common/implementations';
import { RPCRequestParams } from '@campaign-portal/namespace/common/rpc.params';
import { RatesImportHistory } from '@campaign-portal/namespace/entities/rates-import-history/specs';

import {
	AbstractCRUDService,
	AlarisApiService,
	AlarisLanguageService,
	ErrorNotifierConfig
} from '@campaign-portal/components-library';


@Injectable({
	providedIn: 'root'
})
export class RatesImportHistoryService implements AbstractCRUDService {

	readonly entity = '';

	public rateHistoryList: RatesImportHistory[] = [];

	readonly loading$ = new BehaviorSubject<boolean>(false);
	private readonly errorNotifier = (): ErrorNotifierConfig => ({ title: this.title });

	constructor(
		private readonly api: AlarisApiService,
		private readonly langService: AlarisLanguageService
	) {
	}

	get title(): string {
		return this.langService.translate('notifications.titles.rate');
	}
	
	import(): void {
	}

	read(params?: RPCRequestParams): Observable<ReadResponse<RatesImportHistory[]>> {
		return this.api.loader<ReadResponse<RatesImportHistory[]>>(
			'RatesImportHistory.Read', params, this.loading$, this.errorNotifier
		)
			.pipe(
				map(
					resp => {
						this.rateHistoryList = resp.Data;
						return resp;
					}
				)
			);
	}

	create(): Observable<void> {
		return of(undefined);
	}

	delete(): Observable<void> {
		return of(undefined);
	}

	update(): Observable<void> {
		return of(undefined);
	}

}
