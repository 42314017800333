import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject, takeUntil } from 'rxjs';

import { AbstractCRUDService, AlarisLanguageService, AlarisProfileService } from '@campaign-portal/components-library';

import { EntityField, InputComplexType, InputType } from '@campaign-portal/namespace/common/entityField';
import { ReadResponse } from '@campaign-portal/namespace/common/implementations';
import { FilterType } from '@campaign-portal/namespace/common/rpc.params';
import { EnumsMapperService } from '@helpers/services/enums.service';
import { PartnersService } from '../../partners/partners.service';
import { OwnerService } from '@helpers/services/owner.service';
import { Payments } from '@campaign-portal/namespace/entities/payments/specs';

import { AP_PERMISSIONS } from '@helpers/types/permissions';

@Injectable()
export class PaymentsFieldsService implements AbstractCRUDService, OnDestroy {

	readonly entity = '';
	readonly title = '';
	readonly loading$ = new BehaviorSubject<boolean>(false);

	private readonly id: EntityField = {
		id: 0,
		name: 'columns.id',
		variable: 'id',
		required: true,
		type: InputType.NUMBER,
		readonly: true,
		filterType: FilterType.EXACT
	};
	private readonly amount: EntityField = {
		id: 1,
		name: 'columns.amount',
		variable: 'amount',
		required: true,
		type: InputType.NUMBER,
		data: [],
		readonly: true,
		filterType: FilterType.MORE
	};
	private readonly status: EntityField = {
		id: 2,
		name: 'columns.status',
		variable: 'status',
		required: true,
		type: InputComplexType.MULTISELECT,
		data: Object.keys(this.enums.get('PaymentStatus')),
		readonly: true,
		filterType: FilterType.IN
	};
	private readonly partnerId: EntityField = {
		id: 3,
		name: 'columns.partner',
		variable: 'partnerId',
		required: true,
		type: InputComplexType.MULTISELECT,
		data: [],
		readonly: true,
		filterType: FilterType.IN,
		property: 'id'
	};
	private readonly comment: EntityField = {
		id: 4,
		name: 'columns.comment',
		variable: 'comment',
		required: true,
		type: InputType.TEXT,
		readonly: true,
		filterType: FilterType.LIKE
	};
	private readonly creationDate: EntityField = {
		id: 5,
		name: 'columns.date',
		variable: 'creationDate',
		required: true,
		type: InputComplexType.DATE_RANGE,
		readonly: true,
		filterType: FilterType.BETWEEN
	};
	private readonly user: EntityField = {
		id: 6,
		name: 'columns.user',
		variable: 'user',
		required: true,
		type: InputType.TEXT,
		readonly: true,
		filterType: FilterType.LIKE
	};

	private readonly headers: EntityField[] = [
		this.id,
		this.amount,
		this.status,
		this.partnerId,
		this.comment,
		this.creationDate,
		this.user
	];

	private readonly ngUnsubscribe = new Subject<void>();

	constructor(
		private readonly enums: EnumsMapperService,
		private readonly partnersService: PartnersService,
		private readonly profile: AlarisProfileService,
		private readonly isOwner: OwnerService,
		private readonly ls: AlarisLanguageService
	) {
		this.partnersService.list$
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((partners) => {
				const resellerPartner = {
					id: this.profile.user.partnerId,
					name: this.ls.translate('finance.myPartner')
				};
				this.partnerId.data = this.isOwner.is ? partners : [resellerPartner, ...partners];
			});
	}

	ngOnDestroy(): void {
		this.loading$.complete();
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}

	create(): Observable<void> {
		return of(undefined);
	}

	delete(): Observable<void> {
		return of(undefined);
	}

	read(): Observable<ReadResponse<EntityField[]>> {
		const headers = this.pickAvailableFields(this.headers);
		return of({
			Success: true,
			Total: headers.length,
			Data: headers
		});
	}

	update(): Observable<void> {
		return of(undefined);
	}

	private pickAvailableFields(fields: EntityField[]): EntityField[] {
		const permissions: [AP_PERMISSIONS, keyof Payments][] = [
			[AP_PERMISSIONS.USERS_R, 'user']
		];

		permissions.forEach(([permission, variable]) => {
			if ( !this.profile.allowed([permission]) ) {
				fields = fields.filter(field => field.variable !== variable);
			}
		});
		return fields;
	}
}
