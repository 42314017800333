import { Injectable } from '@angular/core';
import {
	AlarisTableStateService,
	filterWildcardFn,
	LocalTableService,
	LocalTableUtils
} from '@campaign-portal/components-library';
import { EdrExport } from '@campaign-portal/namespace/entities/edr/specs';
import { exist } from '@campaign-portal/namespace/common/id';
import { EdrExportTaskService } from './edr-export-task.service';
import { RPCRequestParams, SortDirection } from '@campaign-portal/namespace/common/rpc.params';
import { ReadResponse } from '@campaign-portal/namespace/common/implementations';
import { map, Observable, of } from 'rxjs';

@Injectable()
export class ExportTableService extends LocalTableService<EdrExport<exist>> {

	readonly restart = this.service.restart.bind(this.service);
	readonly cancel = this.service.cancel.bind(this.service);

	constructor(
		public override readonly service: EdrExportTaskService,
		stateService: AlarisTableStateService
	) {
		super(service, stateService);
	}

	override read(params: RPCRequestParams): Observable<ReadResponse<EdrExport<exist>[]>> {
		if ( this.firstLoad ) {
			this.firstLoad = false;
			const state = this.stateService.store.get(this.stateID || '');
			const _params = state
				? {
					...LocalTableUtils.removeState(state, params),
					Paging: { Skip: 0, Take: 1000 },
					Sorting: [...(params.Sorting || []), { Field: 'id', Dir: SortDirection.DESC }]
				}
				: {
					...params,
					Paging: { Skip: 0, Take: 1000 },
					Sorting: [...(params.Sorting || []), { Field: 'id', Dir: SortDirection.DESC }]
				};

			return this.service.read(_params)
				.pipe(map((response) => {
					this.list = response.Data;
					return this.parse(this.list, params);
				}));
		}
		return of(this.parse(this.list, params));
	}

	applyFilters(result: ReadResponse<EdrExport<exist>[]>, params: RPCRequestParams): ReadResponse<EdrExport<exist>[]> {
		const filters = params.Filters;
		if ( filters === undefined ) {
			return result;
		} else {
			let data = result.Data;
			filters.forEach((filter) => {
				switch (filter.Field) {
					case 'name':
						data = data.filter((task) => {
							return filterWildcardFn(task.name, filter.Value as string);
						});
						break;
					case 'status':
						data = LocalTableUtils.filterValueObject(filter, data);
						break;
					case 'createdBy':
						data = data.filter(task => {
							return filterWildcardFn(task.createdBy as string, filter.Value as string);
						});
						break;
					case 'creationDate':
						data = LocalTableUtils.filterDateRange(filter, data as unknown as Record<string, string>[]) as unknown as EdrExport<exist>[];
						break;
					case 'size':
					case 'fileId':
					default:
						break;
				}
			});
			return { Success: true, Total: data.length, Data: data };
		}
	}

	applySorting(result: ReadResponse<EdrExport<exist>[]>, params: RPCRequestParams): ReadResponse<EdrExport<exist>[]> {
		const sorting = params.Sorting?.reverse();
		if ( !sorting ) {
			return result;
		} else {
			let data = result.Data;
			sorting.forEach((sort) => {
				data = data.sort((a, b) => {
					let first: any;
					let second: any;
					switch (sort.Field) {
						case 'name':
							first = a.name;
							second = b.name;
							break;
						case 'status':
						case 'createdBy':
							first = a[sort.Field];
							second = b[sort.Field];
							break;
						case 'size':
							first = a.file?.size;
							second = b.file?.size;
							break;
						case 'creationDate':
							first = a.creationDate ? new Date(a.creationDate) : a.creationDate;
							second = b.creationDate ? new Date(b.creationDate) : b.creationDate;
							break;
						case 'fileId':
						default:
							break;
					}

					return LocalTableUtils.sortComparisonFn(sort.Dir, first, second);
				});
			});
			return { Data: data, Total: result.Total, Success: true };
		}
	}
}
