import { Injectable } from '@angular/core';

import { AlarisLanguageService, ExtendableEnumsMapperService } from '@campaign-portal/components-library';
import {
	AnalysisStatus,
	CreditType,
	DecisionMode,
	EdrStatus,
	ImportStatus,
	MessagePurpose,
	PaymentStatus,
	RoleType,
	SubscriptionType
} from '@campaign-portal/namespace/common/enums';

type Enums =
	'EdrStatus'
	| 'MessagePurpose'
	| 'CreditType'
	| 'RoleType'
	| 'DecisionMode'
	| 'SubscriptionType'
	| 'ImportStatus'
	| 'AnalysisStatus'
	| 'PaymentStatus';

@Injectable({
	providedIn: 'root'
})
export class EnumsMapperService extends ExtendableEnumsMapperService<Enums> {
	constructor(
		public override readonly langService: AlarisLanguageService
	) {
		super(langService);
	}

	override init(): void {
		this.set('EdrStatus', this.transform(EdrStatus));
		this.set('MessagePurpose', this.transform(MessagePurpose));
		this.set('CreditType', this.transform(CreditType));
		this.set('RoleType', this.transform(RoleType));
		this.set('DecisionMode', this.transform(DecisionMode));
		this.set('SubscriptionType', this.transform(SubscriptionType));
		this.set('ImportStatus', this.transform(ImportStatus));
		this.set('AnalysisStatus', this.transform(AnalysisStatus));
		this.set('PaymentStatus', this.transform(PaymentStatus));
	}

}
