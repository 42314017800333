import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { filter, map } from 'rxjs';

import {
	AlarisComplexTableComponent,
	AlarisDialogService,
	AlarisEditPanelService,
	AlarisProfileService,
	AlarisTableSettingsService,
	AlarisTableStateService,
	ChannelUtilsService,
	DEFAULT_BUTTONS,
	EditPanelWidth,
	RowActionSimple,
	TableSortIndicator
} from '@campaign-portal/components-library';

import { TableTypes } from '@campaign-portal/namespace/entities/table-settings/specs';
import { exist } from '@campaign-portal/namespace/common/id';
import { SenderSubscription } from '@campaign-portal/namespace/entities/sender-id/specs';
import { EnabledDisabledStatus, SenderSubscriptionEventStatus } from '@campaign-portal/namespace/common/enums';
import { SortDirection } from '@campaign-portal/namespace/common/rpc.params';
import { RPCResult } from '@campaign-portal/namespace/common/rpc.response';

import { AP_PERMISSIONS } from '@helpers/types/permissions';
import { PartnersService } from '../../partners/partners.service';
import { ChannelsService } from '@helpers/services/channels.service';
import { SendersDialogComponent, SendersDialogType } from '../senders-dialog/senders-dialog.component';
import { SendersListFieldsService } from './senders-list.fields.service';
import { ActionsFactory } from './actions-factory';
import { SenderSubscriptionEntity } from '@helpers/types/app.classes-interfaces';
import { EditSenderComponent } from './edit-sender/edit-sender.component';
import { OwnerService } from '@helpers/services/owner.service';
import { RequestSenderComponent } from './request-sender/request-sender.component';
import { SenderListTableService } from './sender-list.table.service';
import { SendersListService } from './senders-list.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
	selector: 'app-senders-list',
	templateUrl: './senders-list.component.html',
	styleUrls: [
		// eslint-disable-next-line max-len
		'../../../../../node_modules/@campaign-portal/components-library/src/assets/templates/table-complex/complex-table.component.scss',
		'./senders-list.component.scss'
	],
	providers: [SendersListFieldsService, SenderListTableService],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SendersListComponent extends AlarisComplexTableComponent<SenderSubscription<exist>> implements OnInit {

	readonly AP_PERMISSIONS = AP_PERMISSIONS;
	readonly statusGroup = new FormGroup({});

	override sorting: TableSortIndicator = new Map()
		.set('name', { enabled: true, value: SortDirection.ASC });

	// eslint-disable-next-line
	override dropDownRowActions: RowActionSimple<any>[] = [];
	override ctxActions: RowActionSimple<SenderSubscription | SenderSubscription[]>[] = [];
	// tabsFilter: TabButtonEntity<EnabledDisabledStatus | null>[] = [
	// 	{ label: 'tb.all', value: null },
	// 	{ label: 'tb.active', value: EnabledDisabledStatus.ENABLED },
	// 	{ label: 'tb.inactive', value: EnabledDisabledStatus.DISABLED }
	// ];
	// selectedTabFilter = new FormControl<EnabledDisabledStatus | null>(this.tabsFilter[0].value);
	override readonly id = 'sender-list';
	protected readonly SenderSubscriptionEventStatus = SenderSubscriptionEventStatus;

	constructor(
		public readonly isOwner: OwnerService,
		public override readonly dataService: SenderListTableService,
		public readonly sendersListService: SendersListService,
		public readonly sendersListFieldsService: SendersListFieldsService,
		public override readonly tableSettingsService: AlarisTableSettingsService,
		public override readonly editPanel: AlarisEditPanelService,
		public override readonly cd: ChangeDetectorRef,
		public readonly channelService: ChannelsService,
		public readonly partnersService: PartnersService,
		public readonly cu: ChannelUtilsService,
		private readonly profile: AlarisProfileService,
		private readonly dialog: AlarisDialogService,
		public override readonly stateService: AlarisTableStateService
	) {
		super(
			dataService,
			sendersListFieldsService,
			tableSettingsService,
			editPanel,
			cd,
			TableTypes.INTERNAL,
			DEFAULT_BUTTONS,
			stateService
		);
		this.dataService.stateID = this.id;
	}

	override ngOnInit(): void {
		super.ngOnInit();
		this.dropDownRowActions = new ActionsFactory(this).getActions(
			this.isOwner.is,
			this.profile.allowed([AP_PERMISSIONS.SENDERS_E])
		);
		this.ctxActions = this.dropDownRowActions;

		this.sendersListService.refresh$
			.pipe(
				filter(Boolean),
				takeUntilDestroyed(this.destroyRef)
			)
			.subscribe(() => this.load());
	}

	override load(): void {
		this.saveState();
		this.loading$.next(true);
		this.dataService.read(this.readParams)
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe((resp) => {
				this.tRows = resp.Data ?? [];
				this.updateRows();
				this.total = resp.Total ?? 0;
				this.loading$.next(false);
			});
	}

	override refresh(): void {
		this.dataService.firstLoad = true;
		this.load();
	}

	openEditPanel(sender?: SenderSubscription): void {
		const senderId = sender ? sender : new SenderSubscriptionEntity(null);
		// eslint-disable-next-line max-len
		this.editPanel.open<
			EditSenderComponent,
			{
				senderId: SenderSubscription;
				unsubscribe: (sender?: SenderSubscription<exist>) => void;
				delete: (sender?: SenderSubscription<exist>) => void
					},
			RPCResult<SenderSubscription<exist>[]> | undefined>(EditSenderComponent, EditPanelWidth.SM,
					{ senderId, unsubscribe: this.unsubscribe.bind(this), delete: this.delete.bind(this) }
					)
			.pipe(
				map((response: RPCResult<SenderSubscription<exist>[]> | undefined) => {
					response?.Data?.forEach((sub) => {
						const id = sub.id.toString();
						const isEnabled = sub.enabled === EnabledDisabledStatus.ENABLED;
						return this.statusGroup.get(id)
							? this.statusGroup.get(id)?.setValue(isEnabled)
							: this.statusGroup.setControl(id, new FormControl(isEnabled));
					});
					return response;
				}),
				takeUntilDestroyed(this.destroyRef))
			.subscribe();
	}

	openRequestPanel(): void {
		this.editPanel.open(RequestSenderComponent, EditPanelWidth.SM, {});
	}

	updateSenderStatus(sender: SenderSubscription<exist>): void {
		this.sendersListService.update({
			...sender,
			enabled: sender.enabled === EnabledDisabledStatus.ENABLED ?
				EnabledDisabledStatus.DISABLED : EnabledDisabledStatus.ENABLED
		})
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe(res => {
				if ( !res.Success ) {
					const isEnabled = sender.enabled === EnabledDisabledStatus.ENABLED;
					this.statusGroup.get(sender.id.toString())?.setValue(isEnabled);
				}
			});
	}

	unsubscribe(sender?: SenderSubscription<exist>): void {
		this.openSpecificDialog('Unsubscribe', sender);
	}

	delete(sender?: SenderSubscription<exist>): void {
		this.openSpecificDialog('Delete', sender);
	}

	toggleStatus($event: MouseEvent, sender: SenderSubscription<exist>): void {
		const status = this.statusGroup.get(sender.id.toString());
		if ( status?.disabled || !this.profile.allowed([AP_PERMISSIONS.SENDERS_E]) ) {
			return;
		}
		if ( status?.value ) {
			return this.warn($event, sender);
		}
		if ( this.isOwner.is && sender.activeTill && (new Date(sender.activeTill) < new Date()) ) {
			$event.preventDefault();
			return this.openSpecificDialog('Sender Expired', sender);
		}
	}

	warn($event: MouseEvent, sender: SenderSubscription<exist>): void {
		$event.preventDefault();
		this.dialog.open(SendersDialogComponent, {
			data: { subscription: sender, type: 'Sender Disable' },
			autoFocus: false
		}).closed
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe((result) => {
				if ( result ) {
					this.updateSenderStatus(sender);
				}
			});
	}

	private openSpecificDialog(
		type: SendersDialogType,
		subscription?: SenderSubscription<exist>
	): void {
		this.dialog.open(SendersDialogComponent, {
			data: { subscription, type },
			autoFocus: false
		});
	}

	private updateRows(): void {
		this.tRows.forEach(sender => {
			this.statusGroup.setControl(
				(sender.id ?? 0).toString(),
				new FormControl({
					value: sender.enabled === EnabledDisabledStatus.ENABLED,
					disabled: sender.enabled === EnabledDisabledStatus.DISABLED
						&& !this.isOwner.is
						&& (sender.activeTill
							? new Date(sender.activeTill) < new Date()
							: false)
						|| !this.profile.allowed([AP_PERMISSIONS.SENDERS_E])
						|| sender.lastEvent === SenderSubscriptionEventStatus.IN_PROGRESS
						|| sender.lastEvent === SenderSubscriptionEventStatus.DECLINED
				})
			);
		});
	}
}
