import { AlarisAccountSettingsComponent, RouterDataLinks } from '@campaign-portal/components-library';

import { DashboardComponent } from '../../pages/dashboard/dashboard.component';
import { MccmncComponent } from '../../pages/settings/mccmnc/mccmnc.component';
import { FinanceComponent } from '../../pages/finance/finance.component';
import { SendersComponent } from '../../pages/senders/senders.component';
import { ContractCompaniesComponent } from '../../pages/settings/contract-companies/contract-companies.component';
import { UsersComponent } from '../../pages/users/users.component';
import { StatisticsComponent } from '../../pages/statistics/statistics.component';
import { PaymentSystemsComponent } from '../../pages/settings/payment-systems/payment-systems.component';
import { VendorsComponent } from '../../pages/vendors/vendors.component';
import { RatesImportComponent } from '../components/rates-import/rates-import.component';
import { PartnersComponent } from '../../pages/partners/partners.component';
import { SubscriptionsComponent } from '../../pages/subscriptions/subscriptions.component';
import { PortalsComponent } from '../../pages/portals/portals.component';
import { EditPortalComponent } from '../../pages/portals/edit-portal/edit-portal.component';
import { SubscriptionDetailsComponent } from '../../pages/subscriptions/subscriptions-list/subscription-details/subscription-details.component';
import { CanDeactivateGuard } from '@helpers/can-deactivate/can-deactivate.guard';
import { AP_PERMISSIONS } from '@helpers/types/permissions';


export const routerLinks: RouterDataLinks = {
	dashboard: {
		path: 'dashboard',
		component: DashboardComponent,
		data: {
			permissions: [],
			title: 'dashboard.title',
			icon: 'icon-dashboard-square',
			animationState: 'DashboardComponent',
			overflow: true
		}
	},
	portals: {
		path: 'portals',
		component: PortalsComponent,
		data: {
			permissions: [AP_PERMISSIONS.PORTALS_R],
			title: 'portals.title',
			icon: 'icon-portals',
			animationState: 'PortalsComponent',
			overflow: true
		}
	},
	editPortal: {
		path: 'edit-portals',
		component: EditPortalComponent,
		canDeactivate: [
			CanDeactivateGuard
		],
		data: {
			permissions: [AP_PERMISSIONS.PORTALS_R],
			title: '',
			animationState: 'EditPortalComponent',
			backRoute: true,
			hiddenMenu: true,
			overflow: true
		}
	},
	partners: {
		path: 'partners',
		component: PartnersComponent,
		data: {
			permissions: [],
			title: 'partners.title',
			icon: 'icon-partner',
			animationState: 'PartnersComponent'
		}
	},
	subscriptions: {
		path: 'subscriptions',
		data: {
			permissions: [AP_PERMISSIONS.SUBSCR_R],
			title: 'subscriptions.title',
			icon: 'icon-subscriptions',
			animationState: 'SubscriptionsComponent'
		},
		children: [
			{
				path: '',
				component: SubscriptionsComponent,
				data: {
					permissions: [],
					title: 'subscriptions.title',
					animationState: 'SubscriptionsComponent',
					hiddenMenu: true
				}
			},
			{
				path: 'subscription',
				component: SubscriptionDetailsComponent,
				canDeactivate: [
					CanDeactivateGuard
				],
				data: {
					permissions: [],
					title: '',
					animationState: 'SubscriptionDetailsComponent',
					overflow: true,
					backRoute: true,
					hiddenMenu: true
				}
			}
		]
	},
	ratesImport: {
		path: 'rates-import',
		component: RatesImportComponent,
		canDeactivate: [
			CanDeactivateGuard
		],
		data: {
			permissions: [AP_PERMISSIONS.SUBSCR_E, AP_PERMISSIONS.V_SUBSCR_E],
			permissionComparison: 'some',
			title: 'rates.import-title',
			animationState: 'RatesImportComponent',
			backRoute: true,
			hiddenMenu: true
		}
	},
	rates: {
		path: 'rates',
		component: VendorsComponent,
		data: {
			permissions: [AP_PERMISSIONS.V_SUBSCR_R, AP_PERMISSIONS.ENDPOINTS_R],
			permissionComparison: 'some',
			title: 'rates.title',
			icon: 'icon-rate',
			animationState: 'VendorRatesComponent'
		}
	},
	finance: {
		path: 'finance',
		component: FinanceComponent,
		data: {
			permissions: [AP_PERMISSIONS.PAYMENTS_R],
			title: 'finance.title',
			icon: 'icon-purchase',
			animationState: 'FinanceComponent'
		}
	},
	senders: {
		path: 'senders',
		component: SendersComponent,
		data: {
			permissions: [AP_PERMISSIONS.SENDERS_R],
			title: 'senders.title',
			icon: 'icon-sender-ID',
			animationState: 'SendersComponent'
		}
	},
	statistics: {
		path: 'statistics',
		component: StatisticsComponent,
		data: {
			permissions: [AP_PERMISSIONS.STATS_R],
			title: 'statistics.title',
			icon: 'icon-statistics',
			animationState: 'StatisticsComponent'
		}
	},
	users: {
		path: 'users',
		component: UsersComponent,
		data: {
			permissions: [AP_PERMISSIONS.USERS_R],
			title: 'users.title',
			icon: 'icon-contact',
			animationState: 'UsersComponent'
		}
	},
	settings: {
		path: 'settings',
		data: {
			permissions: [],
			title: 'settings.title',
			icon: 'icon-settings',
			animationState: 'SettingsComponent'
		},
		children: [
			{
				path: '',
				redirectTo: 'payment-systems',
				pathMatch: 'full',
				data: {
					permissions: [],
					title: 'settings.title',
					icon: 'icon-settings',
					animationState: 'SettingsComponent',
					hiddenMenu: true
				}
			},
			{
				path: 'payment-systems',
				component: PaymentSystemsComponent,
				data: {
					permissions: [],
					title: 'payment-systems.title',
					animationState: 'PaymentSystemsComponent'
				}
			},
			{
				path: 'mccmnc',
				component: MccmncComponent,
				data: {
					permissions: [],
					title: 'mccmnc.title',
					animationState: 'MccmncComponent'
				}
			},
			{
				path: 'contract-companies',
				component: ContractCompaniesComponent,
				data: {
					permissions: [],
					title: 'contract-companies.title',
					animationState: 'ContractCompaniesComponent'
				}
			}
		]
	},
	accountSettings: {
		path: 'account-settings',
		component: AlarisAccountSettingsComponent,
		data: {
			permissions: [],
			title: 'accountSettings.title',
			icon: '',
			animationState: 'AccountSettingsComponent',
			hiddenMenu: true
		}
	}
};
