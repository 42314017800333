<alaris-card *alarisLet="(isFiltersApplied || !!activeRatesControl.value) as hasFilter">
	<div *ngIf="tRows.length > 0 || tRows.length === 0 && hasFilter" class="table-management">
		<div class="left">
			<span class="total" data-testid="total">{{ totalTitle | translate }}: {{ total }}</span>
			<button (click)="editTableFilter()" alaris-button bType="outline" data-testid="editFilterBtn" size="lg">
				<alaris-icon name="icon-filter-vertical"></alaris-icon>
				{{ enableButtons.filters.title | translate }}
				<div *ngIf="isFiltersApplied" class="indicator"></div>
			</button>
			<alaris-toggle [formControl]="activeRatesControl" data-testid="onlyActiveToggle">
				{{ 'rates.onlyActive' | translate }}
			</alaris-toggle>
		</div>
		<div class="right">
			<button (click)="selectFields()"
					[alarisTooltip]="'rates.exportAllNote' | translate"
					alaris-button bType="outline" data-testid="exportBtn" size="lg">
				<alaris-icon class="in-outline" name="icon-export-1"></alaris-icon>
				{{ 'actions.export' | translate }}
			</button>
			<button (click)="import()"
					*ngIf="isOwner.is && ([AP_PERMISSIONS.V_SUBSCR_E] | alarisPBAC)"
					alaris-button bType="primary" data-testid="importBtn" size="lg">
				<alaris-icon class="in-primary" name="icon-import"></alaris-icon>
				{{ 'rates.importRates' | translate }}
			</button>
		</div>
	</div>
	<div [alarisLoading]="loading$" class="content">
		@if (tRows.length === 0 && !hasFilter) {
			<app-empty-table (importRates)="import()"
							 [disabled]="!([AP_PERMISSIONS.V_SUBSCR_E] | alarisPBAC)"
							 type="rates">
			</app-empty-table>
		} @else {
			<alaris-table (filterChange)="_applyFilter($event)"
						  (sortingChange)="_applySorting($event)"
						  [(selection)]="selection"
						  [colSpan]="false"
						  [ctxActions]="isOwner.is ? ctxActions : []"
						  [dblClickRowAction]="isOwner.is ? openEditPanel.bind(this) : mock"
						  [expandable]="true"
						  [expandedRowExtraData]="{currencyCode: bs.currencyCode}"
						  [expandedRowTemplate]="expandedRowTemplate"
						  [filters]="filters"
						  [rowActions]="isOwner.is ? rowActions : undefined"
						  [sorting]="sorting"
						  [tHeaders]="tHeaders"
						  [tRows]="tRows"
						  [tSettings]="tSettingsModified"
						  [templates]="{ country, network, subscriptionId, channel,
						  					activeFrom, activeTill, price, currencyId}"
						  alarisOverflowFader>
			</alaris-table>
		}
	</div>
	<div class="footer">
		<alaris-table-selection-bar [(selection)]="selection"
									[additionalActions]="additionalActions"
									[loading$]="loading$"
									[mainActions]="mainActions"
									[total]="total">
		</alaris-table-selection-bar>
		<alaris-pagination (page)="_pageChange($event)"
						   [activePage]="activePage"
						   [class.without-table-bar]="selection !== null && selection.length === 0"
						   [hidden]="total === 0"
						   [pageSizeOptions]="pageSizeOptions"
						   [pageSize]="pageSize"
						   [total]="total"
						   class="pagination">
		</alaris-pagination>
	</div>
</alaris-card>

<ng-template #country [alarisCellCtx]="tRows[0]" let-data="data">
	<div class="country-column">
		<div class="country">{{ data.country.name }}</div>
		<div class="mcc">MCC: {{ data.country.mcc | mccmnc }}</div>
		<div *ngIf="data.history && data.history.length" class="history">({{ data.history.length }})</div>
		<div [class.disabled]="!data.history?.length"
			 class="arrow">
			<alaris-icon [attr.data-testid]="'expandRowBtn/' + data.id"
						 aria-expanded="true" name="icon-caret-right" style="cursor: pointer">
			</alaris-icon>
		</div>
	</div>
</ng-template>

<ng-template #network [alarisCellCtx]="tRows[0]" let-data="data">
	{{ data.network?.name || ((data.network?.mnc || data.network?.mnc === 0) ? '' : ('endpoints.allNetworks' | translate)) }}
	@if (data.network && (data.network.mnc || data.network.mnc === 0)) {
		<div class="network-column">MNC: {{ data.network.mnc | mccmnc }}</div>
	}
</ng-template>

<ng-template #subscriptionId [alarisCellCtx]="tRows[0]" let-data="data">
	{{ (vsService.map$ | async)?.get(data.subscriptionId)?.name }}
</ng-template>

<ng-template #channel [alarisCellCtx]="tRows[0]" let-data="data">
	<ng-container *alarisLet="vsService.map.get(data.subscriptionId) as product">
		{{
			product ? (channelUtilsService.purpose(
				product.trafficType, product.settings?.viberSettings?.messagePurpose) | translate) : ''
		}}
	</ng-container>
</ng-template>

<ng-template #activeFrom [alarisCellCtx]="tRows[0]" let-data="data" let-key="key">
	{{ data.activeFrom | date: 'd MMM y, HH:mm' }}
</ng-template>

<ng-template #activeTill [alarisCellCtx]="tRows[0]" let-data="data" let-key="key">
	{{ data.activeTill ? (data.activeTill | date: 'd MMM y, HH:mm') : ('gl.neverExpires' | translate) }}
</ng-template>

<ng-template #price [alarisCellCtx]="tRows[0]" let-data="data">
	<div class="price-column">
		{{ data.price | currency: bs.currencyCode:'symbol':'1.0-5' }}
	</div>
</ng-template>

<ng-template #currencyId [alarisCellCtx]="tRows[0]" let-data="data">
	{{ (data.currencyId | alarisCurrency)?.name }}
</ng-template>

<ng-template #expandedRowTemplate [alarisExpandedCellCtx]="tRows[0]" let-element let-extra=extra>
	<ng-container *alarisLet="element.price as currentPrice">
		<tr *ngFor="let history of element.history; let last=last"
			[class.last]="last"
			[style.font-weight]="history.type === RateStatus.CURRENT? 700: 500"
			[style.opacity]="history.type === RateStatus.CLOSED? .5: 1"
			class="expanded-row">
			<td>
				<alaris-tag>{{ 'enums.' + history.type | translate }}</alaris-tag>
			</td>
			<td></td>
			<td></td>
			<td></td>
			<td>{{ history.activeFrom | date: 'd MMM y, HH:mm' }}</td>
			<td>{{ history.activeTill | date: 'd MMM y, HH:mm' }}</td>
			<td [style.color]="history.price > currentPrice ?
									'var(--as-TextNegative)' : history.price < currentPrice ?
										'var(--as-TextPositive)' : 'var(--as-TextPrimary)'"
				class="price-column with-shift">
				{{ history.price | currency: bs.currencyCode:'symbol':'1.0-5' }}
			</td>
			<td>{{ extra?.currencyCode }}</td>
		</tr>
	</ng-container>
</ng-template>
