<alaris-tabs #financeTabs (tabChanged)="cd.detectChanges()" [underline]="false" id="financeTabs">
	<alaris-tab *alarisPBAC="[AP_PERMISSIONS.PAYMENTS_R]"
				[label]="'tabs.payments' | translate"
				tabId="payments">
		<app-payments></app-payments>
	</alaris-tab>
	<alaris-tab *alarisPBAC="[AP_PERMISSIONS.PAYMENTS_R , AP_PERMISSIONS.TRANSACTIONS_R]"
				[label]="'tabs.transactionHistory' |translate"
				tabId="transactionHistory">
		<app-transaction-history *ngIf="financeTabs.activeTab?.tabId === 'transactionHistory'">
		</app-transaction-history>
	</alaris-tab>
</alaris-tabs>


<ng-template #amount let-amount>
	<span [style.color]="amount > 0 ? 'var(--as-TextPositive)' : 'var(--as-TextPrimary)'">
		{{amount > 0 ? '+' : null}}
		{{amount | currency: bs.currencyCode}}
	</span>
</ng-template>

<ng-template #partnerId let-partnerId>
	<ng-container *ngIf="partnerId === (profile.user$ | async)?.partnerId; else defaultPartner">
		<alaris-tag [fill]="false" [rounded]="true" size="lg">
			<span class="partner">{{ 'finance.myPartner' | translate }}</span>
		</alaris-tag>
	</ng-container>
	<ng-template #defaultPartner>
		{{partnerId ? (partnerId | partner)?.name || '' : ''}}
	</ng-template>
</ng-template>
