import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject, takeUntil } from 'rxjs';

import { AbstractCRUDService, AlarisLanguageService } from '@campaign-portal/components-library';

import { EntityField, InputComplexType, InputType } from '@campaign-portal/namespace/common/entityField';
import { ReadResponse } from '@campaign-portal/namespace/common/implementations';
import { FilterType } from '@campaign-portal/namespace/common/rpc.params';
import {
	EnabledDisabledStatus,
	SenderSubscriptionEventStatus,
	TrafficType
} from '@campaign-portal/namespace/common/enums';
import { ChannelsService } from '@helpers/services/channels.service';
import { PartnersService } from '../../partners/partners.service';

@Injectable()
export class SendersListFieldsService implements AbstractCRUDService, OnDestroy {

	readonly entity = '';
	readonly title = '';
	readonly loading$ = new BehaviorSubject<boolean>(false);
	readonly name: EntityField = {
		id: 1,
		name: 'columns.sender',
		variable: 'name',
		required: true,
		type: InputType.TEXT,
		readonly: true,
		filterType: FilterType.LIKE
	};
	readonly enabled: EntityField = {
		id: 2,
		name: 'columns.state',
		variable: 'enabled',
		required: true,
		type: InputComplexType.SELECT,
		data: [
			{
				value: EnabledDisabledStatus.ENABLED,
				name: this.lService.translate('enums.' + EnabledDisabledStatus.ENABLED)
			},
			{
				value: EnabledDisabledStatus.DISABLED,
				name: this.lService.translate('enums.' + EnabledDisabledStatus.DISABLED)
			}
		],
		readonly: true,
		filterType: FilterType.EXACT
	};
	readonly trafficType: EntityField = {
		id: 3,
		name: 'columns.channel',
		variable: 'trafficType',
		required: true,
		type: InputComplexType.MULTISELECT,
		readonly: true,
		data: [
			{ value: TrafficType.SMS, name: this.lService.translate('gl.sms') },
			{ value: TrafficType.VIBER, name: this.lService.translate('gl.viber') }
		],
		filterType: FilterType.IN,
		property: 'channelType'
	};
	readonly activeFrom: EntityField = {
		id: 4,
		name: 'columns.startDate',
		variable: 'activeFrom',
		required: true,
		type: InputComplexType.DATE_RANGE,
		readonly: true,
		filterType: FilterType.BETWEEN
	};
	readonly activeTill: EntityField = {
		id: 5,
		name: 'columns.activeTill',
		variable: 'activeTill',
		required: true,
		type: InputComplexType.DATE_RANGE,
		readonly: true,
		filterType: FilterType.BETWEEN
	};
	readonly documents: EntityField = {
		id: 6,
		name: 'columns.documents',
		variable: 'documents',
		required: true,
		type: InputComplexType.CHECKBOX,
		readonly: true,
		filterType: FilterType.EXACT
	};
	readonly lastUpdated: EntityField = {
		id: 7,
		name: 'columns.lastUpdated',
		variable: 'lastUpdated',
		required: true,
		type: InputComplexType.DATE_RANGE,
		readonly: true,
		filterType: FilterType.BETWEEN
	};
	readonly partner: EntityField = {
		id: 8,
		name: 'columns.partner',
		variable: 'partner',
		required: true,
		type: InputComplexType.MULTISELECT,
		readonly: true,
		data: [],
		filterType: FilterType.IN,
		property: 'id'
	};

	readonly status: EntityField = {
		id: 1,
		name: 'columns.status',
		variable: 'status',
		required: true,
		type: InputComplexType.MULTISELECT,
		readonly: true,
		data: Object.keys(SenderSubscriptionEventStatus).map((status) => {
			return { name: this.lService.translate('enums.' + status), value: status };
		}),
		filterType: FilterType.IN
	};
	readonly headers: EntityField[] = [
		this.name, this.enabled, this.trafficType, this.activeFrom, this.activeTill, this.documents, this.lastUpdated
	];

	private readonly ngUnsubscribe = new Subject<void>();

	constructor(
		private readonly lService: AlarisLanguageService,
		private readonly channelService: ChannelsService,
		private readonly partnersService: PartnersService
	) {
		this.channelService.list$
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((list) => {
				this.trafficType.data = list;
			});
		this.partnersService.list$.subscribe((list) => {
			this.partner.data = list;
		});
	}

	ngOnDestroy(): void {
		this.loading$.complete();
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}

	create(): Observable<void> {
		return of(undefined);
	}

	delete(): Observable<void> {
		return of(undefined);
	}

	read(): Observable<ReadResponse<EntityField[]>> {
		return of({
			Success: true,
			Total: this.headers.length,
			Data: this.headers
		});
	}

	update(): Observable<void> {
		return of(undefined);
	}
}
