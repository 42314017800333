<div [alarisLoading]="subGroupsService.loading$">
	<div class="edit-panel-header">
		<alaris-icon name="icon-subscription-group"></alaris-icon>
		{{ group.id ? group.name : ('subscriptions.groups.createNewGroup' | translate) }}
	</div>
	<form [formGroup]="form" alarisFade="vertical" alarisFocusFirstInvalidInput
		  class="edit-panel-content form" id="editSubGroupForm">
		<alaris-input [errors]="errors"
					  [label]="'fc.nameGroup' | translate"
					  [placeholder]="'fc.nameGroup' | translate"
					  data-testid="nameInput" errorMode="touched"
					  formControlName="name">
		</alaris-input>
		<alaris-text-area [placeholder]="'fc.description' | translate"
						  data-testid="descriptionInput"
						  formControlName="description">
			{{ 'columns.description' | translate }}
		</alaris-text-area>

		<div class="subscriptions">
			<div class="title">{{ 'subscriptions.title' | translate }}</div>
			<app-subscriptions-picker (goToSubscriptions)="goToSubscriptions()"
									  (selectionChange)="packsPlansUpdate($event)"
									  [group]="group">
			</app-subscriptions-picker>
		</div>
	</form>

	<div class="edit-panel-footer">
		<button (click)="save()"
				*alarisPBAC="[AP_PERMISSIONS.SUBSCR_E]"
				alaris-button bType="primary"
				data-testid="saveBtn" form="editSubGroupForm" size="lg" type="submit">
			<alaris-icon class="in-primary" name="icon-success"></alaris-icon>
			{{ (group.id ? 'actions.save' : 'actions.create') | translate }}
		</button>

		@if (([AP_PERMISSIONS.SUBSCR_E] | alarisPBAC)) {
			<button (click)="cancel()" alaris-button bType="outline" data-testid="cancelBtn" size="lg" type="button">
				{{ 'actions.cancel' | translate }}
			</button>
		} @else {
			<button (click)="close()" alaris-button bType="outline" data-testid="closeBtn" size="lg" type="button">
				{{ 'actions.close' | translate }}
			</button>
		}
	</div>
</div>
