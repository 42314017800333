import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

import {
	AlarisComplexTableComponent,
	AlarisDialogService,
	AlarisEditPanelService,
	AlarisLanguageService,
	AlarisProfileService,
	AlarisTableSettingsService,
	AlarisTableStateService,
	DEFAULT_BUTTONS,
	RowActionSimple,
	TabButtonEntity,
	TableFiltersIndicator,
	TableSortIndicator
} from '@campaign-portal/components-library';

import { exist } from '@campaign-portal/namespace/common/id';
import { Subscription } from '@campaign-portal/namespace/entities/subscriptions/specs';
import { TableTypes } from '@campaign-portal/namespace/entities/table-settings/specs';
import { DecisionMode, SubscriptionType } from '@campaign-portal/namespace/common/enums';
import { FilterType, SortDirection } from '@campaign-portal/namespace/common/rpc.params';

import { SubscriptionsFieldsService } from './subscriptions.fields.service';
import {
	SubscriptionsDialogComponent,
	SubscriptionsDialogType
} from './subscriptions-dialog/subscriptions-dialog.component';
import { SubscriptionsTableService } from './subscriptions.table.service';
import { AP_PERMISSIONS } from '@helpers/types/permissions';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { EntityField } from '@campaign-portal/namespace/common/entityField';
import { CurrencyService } from '@helpers/services/currency.service';
import { ContractCompaniesService } from '../../settings/contract-companies/contract-companies.service';
import { PartnersService } from '../../partners/partners.service';
import { ChannelsService } from '@helpers/services/channels.service';
import { SubscriptionGroupsService } from '../subscription-groups/subscription-groups.service';

@Component({
	selector: 'app-subscriptions-list',
	templateUrl: './subscriptions-list.component.html',
	styleUrls: ['./subscriptions-list.component.scss'],
	providers: [SubscriptionsFieldsService, SubscriptionsTableService],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubscriptionsListComponent extends AlarisComplexTableComponent<Subscription<exist>>
	implements OnInit {

	readonly AP_PERMISSIONS = AP_PERMISSIONS;
	readonly tabs: TabButtonEntity<SubscriptionType | null>[] = [
		{ label: 'tb.all', value: null },
		{ label: 'enums.' + SubscriptionType.PACK, value: SubscriptionType.PACK },
		{ label: 'enums.' + SubscriptionType.PLAN, value: SubscriptionType.PLAN },
		{ label: 'tb.resellingPlan', value: SubscriptionType.RESELLER }
	];
	readonly selectedFilter = new FormControl<SubscriptionType | null>(this.entityService.savedFilters.subscriptionType);
	readonly actions: RowActionSimple<Subscription<exist>>[] = this.getActions();

	override readonly id = 'subscriptions';

	override sorting: TableSortIndicator = new Map()
		.set('name', { enabled: true, value: SortDirection.ASC });

	override filters: TableFiltersIndicator = new Map()
		.set('packSettings', { enabled: false })
		.set('messagesTotal', { enabled: false })
		.set('isPromo', { enabled: false, value: null });

	override readonly loading$ = new BehaviorSubject<boolean>(true);

	constructor(
		public readonly entityService: SubscriptionsTableService,
		public override readonly fieldsService: SubscriptionsFieldsService,
		public override readonly tableSettingsService: AlarisTableSettingsService,
		public override readonly editPanel: AlarisEditPanelService,
		public override readonly cd: ChangeDetectorRef,
		public override readonly stateService: AlarisTableStateService,
		private readonly router: Router,
		private readonly currencyService: CurrencyService,
		private readonly ccService: ContractCompaniesService,
		private readonly partnersService: PartnersService,
		private readonly channelsService: ChannelsService,
		private readonly groupsService: SubscriptionGroupsService,
		private readonly dialog: AlarisDialogService,
		private readonly profile: AlarisProfileService,
		private readonly lService: AlarisLanguageService
	) {
		super(
			entityService,
			fieldsService,
			tableSettingsService,
			editPanel,
			cd,
			TableTypes.INTERNAL,
			DEFAULT_BUTTONS,
			stateService
		);
		this.entityService.stateID = this.id;
		this.filters = new Map([...this.filters, ...entityService.savedFilters.table]);
		this.applyFilter(this.filters);
	}

	override ngOnInit(): void {
		super.ngOnInit();

		this.selectedFilter.valueChanges
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe((value) => {
				this.filters.set('packSettings', { enabled: value === SubscriptionType.PACK });
				this.filters.set('messagesTotal', { enabled: value === SubscriptionType.PACK });
				this.filters.set('isPromo', { enabled: value === SubscriptionType.PACK, value: null });
				this._applyFilter(this.filters);
			});
	}

	override applyHeaders(tHeaders: EntityField[]): void {
		super.applyHeaders(tHeaders);
		this.setRefBooksInFields({
			currencyId: this.currencyService,
			contractCompanies: this.ccService,
			partners: this.partnersService,
			personalFor: this.partnersService,
			trafficType: {
				list$: this.fieldsService.trafficTypeList$,
				loading$: this.channelsService.loading$
			},
			groupId: this.groupsService
		});
	}

	override applyFilter($event: TableFiltersIndicator): void {
		this.entityService.savedFilters.table = $event;
		this.entityService.savedFilters.subscriptionType = this.selectedFilter.value;
		super.applyFilter($event);
		if ( this.selectedFilter.value ) {
			this.readParams.Filters?.push({ Field: 'type', Type: FilterType.EXACT, Value: this.selectedFilter.value });
		}

		this.readParams.Filters?.map(
			f => f.Field === 'billingMode' ?
				(f.Value as DecisionMode[])[0] === this.lService.translate(`enums.${DecisionMode.BY_DELIVERED}`) ?
					f.Value = [DecisionMode.BY_DELIVERED] :
					f.Value = [DecisionMode.BY_SUBMITTED] :
				f
		);
	}

	override refresh(): void {
		this.entityService.firstLoad = true;
		this.load();
	}

	override restoreState(): void {
		super.restoreState();

		this.selectedFilter.setValue(this.entityService.savedFilters.subscriptionType);
		// const isResellerFilter = this.readParams.Filters?.find((f) => {
		// 	return f.Field === 'isReseller' && f.Value;
		// });
		//
		// if ( isResellerFilter ) {
		// 	this.resellerFilter.setValue(true);
		// }
	}

	delete(subscription?: Subscription<exist>): void {
		this.openSpecificDialog('Delete', subscription);
	}

	navigate(subscription?: Subscription): void {
		this.router.navigateByUrl(
			'subscriptions/subscription',
			{ state: { ...subscription } }
		);
	}


	private openSpecificDialog(
		type: SubscriptionsDialogType,
		subscription?: Subscription<exist>
	): void {
		this.dialog.open(SubscriptionsDialogComponent, {
			data: { subscription, type },
			autoFocus: false
		}).closed
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe((result) => {
				if ( result ) {
					this.refresh();
				}
			});
	}

	private getActions(): RowActionSimple<Subscription<exist>>[] {
		const edit: RowActionSimple<Subscription<exist>> = {
			icon: '',
			label: 'actions.edit',
			action: this.navigate.bind(this)
		};
		const details: RowActionSimple<Subscription<exist>> = {
			...edit,
			label: 'actions.details'
		};
		const _delete: RowActionSimple<Subscription<exist>> = {
			icon: '',
			label: 'actions.delete',
			action: this.delete.bind(this),
			disabled: (item): boolean => !!item.partners?.length,
			highlight: true,
			separator: true
		};
		return this.profile.allowed([AP_PERMISSIONS.SUBSCR_E])
			? [edit, _delete]
			: [details];
	}
}
