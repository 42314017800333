import {
	AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	OnDestroy,
	OnInit,
	TemplateRef,
	ViewChild
} from '@angular/core';

import { CubeTypeENUM } from '@campaign-portal/namespace/common/rpc.params';

import {
	AlarisBalanceService,
	ChartUtilsService,
	Day,
	FIRST_DAY,
	SharedTemplatesService
} from '@campaign-portal/components-library';

@Component({
	selector: 'app-chart-templates',
	templateUrl: './chart-templates.component.html',
	styleUrls: ['./chart-templates.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChartTemplatesComponent implements OnInit, AfterViewInit, OnDestroy {
	/* eslint-disable*/
	@ViewChild('period') readonly period!: TemplateRef<any>;
	@ViewChild('barChart') readonly barChart!: TemplateRef<any>;
	@ViewChild('barChartLegend') readonly barChartLegend!: TemplateRef<any>;
	@ViewChild('totalsProgressCharts') readonly totalsProgressCharts!: TemplateRef<any>;
	@ViewChild('totalStats') readonly totalStats!: TemplateRef<any>;
	@ViewChild('barChartSkeleton') readonly barChartSkeleton!: TemplateRef<any>;
	@ViewChild('totalStatistics') readonly totalStatistics!: TemplateRef<any>;
	@ViewChild('chartVisibility') readonly chartVisibility!: TemplateRef<any>;
	@ViewChild('lineCharts') readonly lineCharts!: TemplateRef<any>;
	@ViewChild('lineChartSkeleton') readonly lineChartSkeleton!: TemplateRef<any>;

	readonly Day = Day;
	readonly FIRST_DAY = FIRST_DAY;
	readonly CubeTypeENUM = CubeTypeENUM;

	constructor(
		public readonly bs: AlarisBalanceService,
		private readonly sharedTemplates: SharedTemplatesService,
		public readonly cu: ChartUtilsService
	) {
	}

	get lastWeek(): Day {
		return Day.currentLocal().append({ day: -6 });
	}

	ngOnInit(): void {
	}

	ngAfterViewInit(): void {
		this.sharedTemplates.share({
			period: this.period,
			barChart: this.barChart,
			barChartLegend: this.barChartLegend,
			totalsProgressCharts: this.totalsProgressCharts,
			totalStats: this.totalStats,
			barChartSkeleton: this.barChartSkeleton,
			totalStatistics: this.totalStatistics,
			chartVisibility: this.chartVisibility,
			lineCharts: this.lineCharts,
			lineChartSkeleton: this.lineChartSkeleton
		});
	}

	ngOnDestroy(): void {
		this.sharedTemplates.clear();
	}
}
