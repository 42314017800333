import { ChangeDetectionStrategy, Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, of, Subject, switchMap, takeUntil } from 'rxjs';

import { Portal, PortalPair, PortalType, Theme, ThemeType } from '@campaign-portal/namespace/entities/portals/specs';
import { exist, Id } from '@campaign-portal/namespace/common/id';
import { FileInfo } from '@campaign-portal/namespace/common/fileInfo';
import { Package, Subscription } from '@campaign-portal/namespace/entities/subscriptions/specs';
import { CreditType } from '@campaign-portal/namespace/common/enums';

import {
	AlarisDialogService,
	AlarisLanguageService,
	AlarisProfileService,
	CustomValidators,
	filterWildcardData
} from '@campaign-portal/components-library';

import { SubscriptionsService } from '../../subscriptions/subscriptions-list/subscriptions.service';
import { PortalsDialogComponent, PortalsDialogType } from '../portals-dialog/portals-dialog.component';
import { PortalsService } from '../portals.service';
import { APCustomValidators } from '@helpers/validators/custom-validators';
import { AuthGuardService } from '../../../auth/auth.guard';
import { CanDeactivateComponent } from '@helpers/can-deactivate/can-deactivate.component';
import { AP_PERMISSIONS } from '@helpers/types/permissions';

// const PATH_TO_IMAGES = '/assets/img/';

@Component({
	selector: 'app-edit-portal',
	templateUrl: './edit-portal.component.html',
	styleUrls: ['./edit-portal.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditPortalComponent extends CanDeactivateComponent implements OnInit, OnDestroy {

	readonly AP_PERMISSIONS = AP_PERMISSIONS;
	readonly PortalType = PortalType;
	readonly ThemeTypes: ThemeType[] = ['light', 'dark'];
	readonly CreditTypes: CreditType[] = [CreditType.PREPAID, CreditType.POSTPAID];
	readonly CreditType = CreditType;
	readonly colors: Theme[] = ['default', 'orange', 'yellow', 'green', 'blue'];
	portalForm!: FormGroup;
	promoPacksList: Package<exist>[] = [];

	readonly filterPromoPacksControl = new FormControl('');
	readonly filterPlansControl = new FormControl('');
	filterPlansList: Subscription<exist>[] = [];
	filterPromoPacksList: Subscription<exist>[] = [];

	readonly portalPair!: PortalPair<exist>;
	readonly portal!: Portal | null;
	readonly type!: PortalType;

	readonly errors = [{
		key: 'protocolInDomainName',
		value: 'errors.protocolInDomainName'
	}, {
		key: 'positiveNumber',
		value: 'errors.positiveNumber'
	}];

	readonly allowedDeactivation = new BehaviorSubject<boolean>(true);

	protected readonly ngUnsubscribe: Subject<void> = new Subject<void>();

	constructor(
		public readonly router: Router,
		public readonly subService: SubscriptionsService,
		public readonly portalsService: PortalsService,
		private readonly location: Location,
		private readonly dialog: AlarisDialogService,
		private readonly authGuard: AuthGuardService,
		private readonly lService: AlarisLanguageService,
		private readonly profile: AlarisProfileService
	) {
		super();
		if ( !this.router.getCurrentNavigation()?.extras?.state ) {
			this.router.navigate(this.authGuard.parentRoute.getValue()?.url
				.map(item => item.path) ?? []);
		}

		this.portalPair = this.router.getCurrentNavigation()?.extras?.state?.portalPair;
		this.type = this.router.getCurrentNavigation()?.extras?.state?.type;
		this.portal = this.portalPair ? this.portalPair[this.type] : null;
	}

	@HostBinding('class') get _classes(): string {
		return [this.portalForm.get('customSettings.theme')?.value].join(' ');
	}

	url(iconType: 'logoLight' | 'logoDark' | 'favicon'): string {
		const host = this.portal?.host ?
			'https://' + this.portal?.host + '/assets/img/' :
			'assets/img/';
		switch (iconType) {
			case 'logoLight':
				return host + 'sidebar-logo.png';
			case 'logoDark':
				return host + 'sidebar-logo-contrast.png';
			case 'favicon':
				return host + 'favicon.ico';
			default:
				return '';
		}
	}

	ngOnInit(): void {
		this.subService.plansList$
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((list) => {
				this.filterPlansList = filterWildcardData(this.filterPlansControl.value, list, 'name');
			});
		this.subService.packsList$
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((list) => {
				this.promoPacksList = list.filter(pack => pack.packSettings.isPromo);
				this.filterPromoPacksList = filterWildcardData(
					this.filterPromoPacksControl.value,
					this.promoPacksList, 'name'
				);
			});


		this.portalForm = new FormGroup({
			id: new FormControl(this.portal?.id),
			ccId: new FormControl(this.portal?.ccId || this.portalPair?.ccId),
			portalType: new FormControl(this.portal?.portalType || this.type),
			name: new FormControl(this.portal?.name, Validators.required),
			host: new FormControl(this.portal?.host, [
				Validators.required,
				APCustomValidators.protocolInDomainName
			]),
			sslSettings: new FormGroup({
				certFile: new FormControl(this.portal?.sslSettings?.certFile, Validators.required),
				privateKeyFile: new FormControl(this.portal?.sslSettings?.privateKeyFile, Validators.required)
			}),
			termsUrl: new FormControl(this.portal?.termsUrl),
			privacyUrl: new FormControl(this.portal?.privacyUrl),
			supportEmail: new FormControl(this.portal?.supportEmail, [Validators.email]),
			customSettings: new FormGroup({
				logoLight: new FormControl(this.portal?.customSettings?.logoLight),
				logoDark: new FormControl(this.portal?.customSettings?.logoDark),
				favicon: new FormControl(this.portal?.customSettings?.favicon),
				theme: new FormControl(this.portal?.customSettings?.theme || 'default'),
				themeType: new FormControl(this.portal?.customSettings?.themeType || 'light', Validators.required)
			}),
			campaignPortalDefaults: new FormGroup({
				creditType: new FormControl(this.portal?.campaignPortalDefaults?.creditLimit
					? CreditType.POSTPAID
					: CreditType.PREPAID
				),
				creditLimit: new FormControl({
					value: this.portal?.campaignPortalDefaults?.creditLimit,
					disabled: !this.portal?.campaignPortalDefaults?.creditLimit
				}, { validators: [Validators.required, CustomValidators.positiveNumber] }),
				giftPaymentAmount: new FormControl(this.portal?.campaignPortalDefaults?.giftPaymentAmount),
				minimumPaymentAmount: new FormControl(this.portal?.campaignPortalDefaults?.minimumPaymentAmount),
				defaultPackage: new FormControl(this.portal?.campaignPortalDefaults?.defaultPackage),
				defaultPlan: new FormControl(this.portal?.campaignPortalDefaults?.defaultPlan),
				allowRegistration: new FormControl(this.portal?.campaignPortalDefaults?.allowRegistration)
			})
		});
		if ( this.portalForm.controls.portalType.value === PortalType.ADMIN_PORTAL ) {
			this.portalForm.controls.campaignPortalDefaults.disable();
		} else {
			this.portalForm.get('campaignPortalDefaults.creditType')?.valueChanges
				.pipe(takeUntil(this.ngUnsubscribe))
				.subscribe((value: CreditType) => {
					if ( value === CreditType.POSTPAID ) {
						this.portalForm.get('campaignPortalDefaults.creditLimit')?.enable();
					} else {
						this.portalForm.get('campaignPortalDefaults.creditLimit')?.disable();
					}
				});
		}

		if ( !this.profile.allowed([AP_PERMISSIONS.PORTALS_E]) ) {
			this.portalForm.disable();
		}

		this.filterPromoPacksControl.valueChanges
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((value) => {
				this.filterPromoPacksList = filterWildcardData(value, this.promoPacksList, 'name');
			});

		this.filterPlansControl.valueChanges
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((value) => {
				this.filterPlansList = filterWildcardData(value, this.subService.plansList, 'name');
			});
	}

	displayPacks = (id: Id<exist>): string => {
		return this.subService.packsMap.get(id ?? 0)?.name ?? '';
	};

	displayPlans = (id: Id<exist>): string => {
		return this.subService.plansMap.get(id ?? 0)?.name ?? '';
	};

	displayCreditType = (type: CreditType | null): string => {
		return type ? this.lService.translate('enums.' + type) : '';
	};

	delete(): void {
		if ( this.portal?.id ) {
			return this.openSpecificDialog('Delete', this.portal as Portal<exist>);
		}
		this.location.back();
	}

	save(): void {
		const portal = {
			...this.portal,
			...this.portalForm.value
		};
		delete portal.campaignPortalDefaults?.creditType;
		this.allowedDeactivation.next(false);
		this.portalsService.update(portal)
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((resp) => {
				this.allowedDeactivation.next(true);
				if ( resp.Success ) {
					this.location.back();
				}
			});
	}

	update(key: string, files: (FileInfo | undefined)[]): void {
		const [file, _] = files;
		this.portalForm.get(key)?.setValue(file ?? null);
		this.portalForm.markAsDirty();
	}

	selectTheme(theme: ThemeType): void {
		const control = this.portalForm.get('customSettings.themeType');
		if ( control && !control.disabled ) {
			control.setValue(theme);
			this.portalForm.markAsDirty();
		}
	}

	ngOnDestroy(): void {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}

	private openSpecificDialog(
		type: PortalsDialogType,
		portal: Portal<exist>
	): void {
		this.dialog.open(PortalsDialogComponent, {
			data: { portal, type },
			autoFocus: false
		}).closed
			.pipe(
				switchMap(result => {
					if ( result ) {
						this.allowedDeactivation.next(false);
						return this.portalsService.delete(portal.id);
					}
					return of({ Success: false });
				}),
				takeUntil(this.ngUnsubscribe)
			)
			.subscribe((resp) => {
				this.allowedDeactivation.next(true);
				if ( resp.Success ) {
					this.location.back();
				}
			});
	}
}
