import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy } from '@angular/core';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { BehaviorSubject, Observable, of, Subject, takeUntil } from 'rxjs';

import { exist } from '@campaign-portal/namespace/common/id';
import { RPCResult } from '@campaign-portal/namespace/common/rpc.response';
import { Portal } from '@campaign-portal/namespace/entities/portals/specs';

export type PortalsDialogType = 'Delete';

export interface PortalsDialogData {
	type: PortalsDialogType;
	portal: Portal<exist>;
}

@Component({
	selector: 'app-portals-dialog',
	templateUrl: './portals-dialog.component.html',
	styleUrls: ['./portals-dialog.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PortalsDialogComponent implements OnDestroy {
	readonly portal!: Portal<exist>;

	readonly loading$ = new BehaviorSubject<boolean>(false);

	private readonly ngUnsubscribe = new Subject<void>();

	constructor(
		@Inject(DIALOG_DATA) public readonly data: PortalsDialogData,
		private readonly dialogRef: DialogRef<unknown>,
		private readonly cd: ChangeDetectorRef
	) {
		this.portal = data.portal;
	}

	get disabled(): boolean {
		return this.loading$.getValue();
	}

	close(): void {
		this.dialogRef.close(false);
	}

	confirm(): void {
		this.loading$.next(true);
		let caller: Observable<RPCResult<unknown>> = of({ Success: false });

		switch (this.data.type) {
			case 'Delete':
				caller = of({ Success: true });
				break;
			default:
				break;

		}

		caller.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
			(resp) => {
				this.loading$.next(false);
				this.dialogRef.close(resp.Success);
				this.cd.markForCheck();
			}
		);
	}

	ngOnDestroy(): void {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
		this.loading$.complete();
	}
}
